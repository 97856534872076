/* eslint-disable import-helpers/order-imports */
import React, { useState } from "react";
import Dialog from "components/Dialog/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { Button, CircularProgress, DialogActions, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { actions as holidaysActions, selectors as holidaysSelectors } from "store/modules/holidays";
import { Form, Formik } from 'formik';
import * as yup from "yup";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Event } from "@mui/icons-material";
import moment from "moment";
import { isEmpty } from "lodash";
import MultipleProvinceAutocomplete from "components/Autocomplete/MultipleProvinceAutocomplete";
import { useEffect } from "react";


const style = ({ spacing }) => ({
    container: {
        // marginBottom: spacing(2)
    },
    datePicker: {
        width: "100%",
        "& .MuiInputBase-root": {
            paddingRight: spacing(0.4)
        },
        "& input": {
            padding: `${spacing(1.3)} !important`
        }
    },
    footer: {
        margin: 0,
        textAlign: "center",
        justifyContent: "center",
    }
});

const initialValues = {
    id: null,
    name: "",
    description: "",
    date: moment().add(1, "day"),
    provinces: [],
}

function AddHolidayDialog({ isDetailPage, classes }) {
    const dispatch = useDispatch();
    const isOpenAddDialog = useSelector(holidaysSelectors.getIsAddHolidayDialog);
    const editData = useSelector(holidaysSelectors.getHolidayEditFormData);
    const isLoading = useSelector(holidaysSelectors.getIsDialogActionLoading);
    const [data, setData] = useState(initialValues);

    useEffect(() => {
        if (isOpenAddDialog) setData(editData || initialValues);
    }, [isOpenAddDialog, editData])

    useEffect(() => {
        return () => {
            editData && dispatch(holidaysActions.setEditFormData(null));
            setData(initialValues);
        };
    }, []);

    const hideAddHolidayDialog = () => {
        dispatch(holidaysActions.setIsAddHolidayDialog(false));
    };


    return (
        <Dialog
            open={isOpenAddDialog}
            title={editData ? "Edit Holiday" : "Add New Holiday"}
            onClose={hideAddHolidayDialog}
            onCancel={hideAddHolidayDialog}
            hideActions
            alertType="form"
            confirmText="Add"
            size="sm"
            modalType="editEmployer"
        >
            <div className={classes.container}>
                <Formik
                    initialValues={data}
                    onSubmit={(values, ...p) => {
                        console.log(values, p, "val onSubmit")
                        dispatch(holidaysActions.createHoliday(values,isDetailPage));
                    }}
                    enableReinitialize
                    validationSchema={validationSchema}
                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            dirty,
                            handleChange,
                            handleBlur,
                            setFieldValue
                        } = props;

                        return (
                            <Grid container >
                                <Form style={{ width: "100%" }}>
                                    <Grid item xs={12} >
                                        <TextField
                                            className={classes.textField}
                                            fullWidth
                                            margin="normal"
                                            label="Holiday Name"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={errors.name && touched.name && errors.name}
                                            error={errors.name && touched.name && errors.name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <TextField
                                            className={classes.textField}
                                            fullWidth
                                            margin="normal"
                                            label="Description"
                                            name="description"
                                            value={values.description}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={errors.description && touched.description && errors.description}
                                            error={errors.description && touched.description && errors.description}
                                        />
                                    </Grid>
                                    <Grid item xs={12} marginTop={3.5}>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <MobileDatePicker
                                                disablePast
                                                className={classes.datePicker}
                                                label="Holiday Date"
                                                minDate={moment().add(1, "day")}
                                                name="date"
                                                inputFormat="DD/MM/YYYY"
                                                showToolbar={false}
                                                value={values.date}
                                                onBlur={handleBlur}
                                                onChange={(val) => setFieldValue("date", val, true)}
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    name="date"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="date"
                                                                    size="small">
                                                                    <Event />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        placeholder: "",
                                                        helperText: errors.date && touched.date && errors.date,
                                                        error: errors.date && touched.date && errors.date
                                                    }}
                                                />}

                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid xs={12} marginTop={5}>
                                        <MultipleProvinceAutocomplete
                                            name="provinces"
                                            id="provinces"
                                            onChange={(val) => setFieldValue("provinces", val)}
                                            value={values?.provinces}
                                            onBlur={handleBlur}
                                            textFieldProps={{
                                                helperText: errors.provinces && touched.provinces && errors.provinces,
                                                error: errors.provinces && touched.provinces && errors.provinces
                                            }} />
                                    </Grid>
                                    <Grid xs={12} marginTop={3.5}>
                                        <DialogActions className={classes.footer}>
                                            <Button
                                                disabled={isLoading}
                                                color="error"
                                                type="button"
                                                className="outline"
                                                onClick={hideAddHolidayDialog}>
                                                Close
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                type="submit"
                                                disabled={isLoading || !dirty || !isEmpty(errors)}
                                            >
                                                {isLoading ? <CircularProgress size={20} /> : editData ? "Update" : "Add"}
                                            </Button>
                                        </DialogActions>
                                    </Grid>
                                </Form>
                            </Grid>
                        );
                    }}
                </Formik>
            </div>
        </Dialog>
    )
}


const validationSchema = yup.object().shape({
    name: yup
        .string()
        .max(50, "Holiday name must be less 50 characters")
        .required("Holiday name is required field"),
    description: yup
        .string()
        .max(50, "Holiday description must be less 50 characters")
        .required("Holiday description is required field"),
    date: yup
        .date()
        .typeError("Enter valid date")
        .required("Holiday date is required"),
    provinces: yup
        .array()
        .min(1, "Please select at least one province")
        .required("Province is required")

});

export default (withStyles(style)(AddHolidayDialog));
