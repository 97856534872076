// Loading selectors
export const getLoadingStateFactory = loader => state => {
    return state.documentType.loading[loader] || false;
};

export const getIsTableLoading = getLoadingStateFactory("tableLoading");
export const getIsAddDocumentLoading = getLoadingStateFactory("isAddLoading");
export const getIsDeleteDocumentLoading = getLoadingStateFactory("isDeleteLoading");

// Dialog selectors
export const getDialogStateFactory = loader => state => {
    return state.documentType.dialog[loader] || false;
};

export const getIsAddDocumentTypeDialog = getDialogStateFactory("addDocumentTypeDialog");
export const getIsDeleteDocumentTypeDialog = getDialogStateFactory("deleteDocumentTypeDialog");

// WorkTypes selectors
export const getDocumentTypeList = state => {
    return state.documentType.documentType;
};
