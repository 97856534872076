import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, DialogActions, Grid, TextField, MenuItem, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import iconEdit from "assets/img/iconEdit.svg";
import avatar from "assets/img/profile-placeholder.png";
import { Form, Formik } from "formik";
import { isEmpty } from "lodash";
import * as yup from "yup";
import { actions as AdminActions, selectors as AdminSelectors } from "store/modules/admins";
const useStyles = makeStyles(({ spacing }) => ({
    modal: {
        padding: spacing(6),
        paddingBottom: spacing(1),
        margin: spacing(2),

        alignItems: "center",
        // width: spacing(60
        // overflowY: "unset",
    },
    dateError: {
        "& input": {
            borderColor: "#af2928 !important",
        },
    },
    title: {
        padding: `${spacing(2)} 0 ${spacing(1)} 0`,
    },
    content: {
        padding: `${spacing(1)} 0 ${spacing(1)} 0`,
        // overflowY: "auto",
        // overflowY: "unset",
        width: "100%",
    },
    footer: {
        margin: 0,
        textAlign: "center",
        justifyContent: "center",
    },
    avatarImg: {
        margin: '10px auto',
        width: '100px',
        position: 'relative',
        '& img': {
            borderRadius: '100px',
            border: '2px solid #c4c4c4'
        },
        '& label': {
            position: 'absolute',
            right: '6px',
            background: '#ffffff',
            bottom: '0',
            padding: '0px',
            '&:hover': {
                background: '#FFF'
            },
            '& img': {
                padding: '2px',
            },
        }

    }
}));
function AddAdmin({ hideAddDialog, handleAddDialog, data }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [profileURL, setProfileURL] = useState();
    const [fileObject, setFileObject] = useState();
    const isLoading = useSelector(AdminSelectors.getIsSaveAdminsLoading);
    const controlPadding = {
        leftColumn: { paddingRight: 4, textAlign: "left" },
        rightColumn: { paddingLeft: 4 },
    };
    const handleProfileFile = file => {
        const item = new Blob([file], { type: file.type });
        item.name = `profile_${file.name}`;
        setProfileURL(URL.createObjectURL(item))
        setFileObject(item);
        // dispatch(ProfileActions.uploadEmployerProfileImage(item));
    }
    useEffect(() => {
        setProfileURL(data.signedProfileImageUrl)
    }, [data.signedProfileImageUrl]);
    const onSubmit = values => {
        if (fileObject) {
            values.profileImage = fileObject;
        }
        if (data.id) {
            dispatch(AdminActions.updateAdminData({ ...values, id: data.id }));
        } else {
            dispatch(AdminActions.addAdminData(values));
        }
    };

    return (
        <React.Fragment>
            <Grid
                container
                item
                xs={12}
                alignItems="flex-start"
                style={{ paddingLeft: 0, paddingRight: 0 }}
            >
                <Formik
                    initialValues={{
                        firstName: data.firstName || "",
                        lastName: data.lastName || "",
                        role: data.role || "Admin",
                        title: data.title || "",
                        password: "Grizzly@123",
                    }}
                    onSubmit={(values, actions) => {
                        values.profileImage =
                            onSubmit(values);
                        actions.setSubmitting(false);
                    }}
                    validationSchema={yup.object().shape({
                        firstName: yup
                            .string().typeError('Required field')
                            .max(50, "Length must be less then 50 character")
                            .required("Required field"),
                        lastName: yup
                            .string().typeError('Required field')
                            .max(50, "Length must be less then 50 character")
                            .required("Required field"),
                        ...(!data.id
                            ? {
                                email: yup
                                    .string().typeError('Required field')
                                    .email("Please enter valid email")
                                    .required("Required field"),
                                password: yup.string().required("Required field").min(8, 'Must be at least 8 character'),
                                role: yup.string().required("Select role"),
                            }
                            : {}),
                    })}
                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            dirty,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleReset,
                            setFieldValue,
                        } = props;

                        return (
                            <Form>
                                <Grid container item xs={12} md={12} spacing={1}>
                                    <div className={classes.avatarImg}>
                                        <img width={'100'} height={'100'} src={profileURL || avatar} onError={(e) => { e.target.onerror = null; e.target.src = avatar }} alt="..." />
                                        <IconButton className="edit-icon" disableRipple component="label">
                                            <img src={iconEdit} />
                                            <input type="file" hidden onChange={files => handleProfileFile(files.target.files[0])} />
                                        </IconButton>
                                    </div>
                                </Grid>
                                <Grid item container direction="row" xs={12}>
                                    <Grid item xs={12} md={6} style={controlPadding.leftColumn}>
                                        <TextField
                                            name="firstName"
                                            label="First Name"
                                            type="text"
                                            error={errors.firstName && touched.firstName}
                                            className={classes.textField}
                                            value={values.firstName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            helperText={errors.firstName && touched.firstName && errors.firstName}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} style={controlPadding.rightColumn}>
                                        <TextField
                                            name="lastName"
                                            label="Last Name"
                                            type="text"
                                            error={errors.lastName && touched.lastName}
                                            className={classes.textField}
                                            value={values.lastName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            helperText={errors.lastName && touched.lastName && errors.lastName}
                                            margin="normal"
                                        />
                                    </Grid>
                                    {!data.id && (
                                        <>
                                            <Grid item xs={12} md={6} style={controlPadding.leftColumn}>
                                                <TextField
                                                    name="email"
                                                    label="Email"
                                                    type="email"
                                                    error={errors.email && touched.email}
                                                    className={classes.textField}
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    helperText={errors.email && touched.email && errors.email}
                                                    margin="normal"
                                                />
                                            </Grid>
                                            {/* <Grid item xs={12} md={6} style={controlPadding.rightColumn}>
                                                <TextField
                                                    id="password"
                                                    name="password"
                                                    label="Password"
                                                    type="password"
                                                    className={classes.textField}
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={errors.password && touched.password}
                                                    helperText={errors.password && touched.password && errors.password}
                                                    margin="normal"
                                                />
                                            </Grid> */}
                                        </>
                                    )}
                                    <Grid item xs={12} md={6} style={controlPadding.leftColumn}>
                                        <TextField
                                            name="title"
                                            label="Title"
                                            type="text"
                                            error={errors.title && touched.title}
                                            className={classes.textField}
                                            value={values.title}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            helperText={errors.title && touched.title}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} style={controlPadding.leftColumn}>
                                        <TextField
                                            select
                                            name="role"
                                            label="Role"
                                            type="text"
                                            error={errors.role && touched.role}
                                            className={classes.textField}
                                            value={values.role}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            helperText={errors.role && touched.role}
                                            margin="normal"
                                        >
                                            <MenuItem value="Admin">Admin</MenuItem>
                                            <MenuItem value="SuperAdmin">Super Admin</MenuItem>
                                            <MenuItem value="FinanceAdmin">Finance Admin</MenuItem>
                                            <MenuItem value="SupportAdmin">Support Admin</MenuItem>
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <DialogActions className={classes.footer}>
                                    <Button type="button" className="outline" onClick={hideAddDialog}>
                                        Close
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        type="submit"
                                        disabled={!dirty || !isEmpty(errors) || isLoading || (!fileObject && !profileURL)}
                                    >
                                        Save
                                    </Button>
                                </DialogActions>
                            </Form>
                        );
                    }}
                </Formik>
            </Grid>
        </React.Fragment>
    );
}

export default AddAdmin;
