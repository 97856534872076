import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Dialog from "components/Dialog/Dialog";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";

function NoShowDialog(props) {
    const { open, onClose, onCancel, onConfirm, position } = props;
    const [showBanOption, setShowBanOption] = useState(false);
    const isFixedTermJob = useSelector(JobDetailSelectors.getJobDetails)?.isFixedTerm;

    const companyName = useSelector(JobDetailSelectors.getCompanyName);

    const handleNoShow = () => {
        // if (isFixedTermJob) {
        //     onConfirm(false);
        //     return;
        // }
        setShowBanOption(true);
    };

    const handleNoShowOnly = () => {
        setShowBanOption(false);
        onConfirm(false);
    };

    const handleNoShowBan = () => {
        setShowBanOption(false);
        onConfirm(true);
    };

    return (
        <>
            <Dialog
                open={open && !showBanOption}
                title={`Mark ${position.fullName} as no show?`}
                description={isFixedTermJob ? "This will remove the worker from the job" : "This will remove the worker from the job and suspend them for a period of time"}
                onClose={onClose}
                onConfirm={handleNoShow}
                onCancel={onCancel}
                alertType="warning"
                icon="event_busy"
            />
            <Dialog
                open={open && showBanOption}
                title="Do you also want to ban them?"
                description={`${position.fullName} will be banned from ${companyName}'s job site.`}
                onClose={onClose}
                onConfirm={handleNoShowBan}
                onCancel={handleNoShowOnly}
                cancelText="No, Remove Only"
                alertType="warning"
                icon="not_interested"
            />
        </>
    );
}

NoShowDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    position: PropTypes.object,
};

export default NoShowDialog;
