import gql from "graphql-tag";

export const skillsQuery = gql`
query{
    skills{
      id
      frenchSkillName
      skillName
      isActive
      createdAt
    }
  }
`;
