import { gql } from "@apollo/client";

export const UpdateWorkerEmailMutation = gql`
  mutation updateWorkerEmail($data: WorkerEmailInput!) {
    updateWorkerEmail(data: $data) {
      isEmailVerified

      isActive
      isApproved

      email
    }
  }
`;
export const setWorkerTransportationPreferencesMutation = gql`
  mutation setWorkerTransportationPreferences($data: WorkerTransportationPreferencesInput!) {
    setWorkerTransportationPreferences(data: $data) {
      id
      createdAt
      workerId
      transportationId
      createdAtUtc
      #   updatedAtUtc
      transportation {
        id
        createdAt
        updatedAt
        isDeleted
        isActive
        order
        label
        createdAtUtc
        # updatedAtUtc
      }
    }
  }
`;

export const setWorkerWorkTypePreferencesMutation = gql`
  mutation setWorkerWorkTypePreferences($data: WorkerWorkTypePreferencesInput!) {
    setWorkerWorkTypePreferences(
      #   data: { workerId: "F5F0996C-55A9-465E-9F88-12144C775B22", workTypeIds: [1, 2, 3] }
      data: $data
    ) {
      id
      createdAt
      workerId
      workTypeId
      createdAtUtc
      workType {
        id
        createdAt
        updatedAt
        isDeleted
        label
        order
        isActive
        baseRate
        baseBillingRate
        bannerImageUrl
        bannerImageUrl2x
        bannerImageUrl3x
        createdAtUtc
        # updatedAtUtc
      }
    }
  }
`;

export const UpdateWorkerProfileMutation = gql`
  mutation editWorker($data: EditWorkerInput!) {
    editWorker(data: $data) {
      createdAt
      approvedAt
      id
      firstName
      lastName
      fullName
      profileImageUrl
      sin
      timezone
      dcCustomerNumber
      travelRange
      isEmailVerified
      isStudent
      isSuspended
      isActive
      isApproved
      phone
      email
      street
      city
      region
      country
      postalCode
      rating
      positionCount
      hoursWorkedCount
      banCount
      preferCount
      noShowCount
      grossPay
      #   netPay
      totalDeductions
      workTypePreferences {
        workType {
          id
          label
        }
      }
      availability {
        id
        dayOfWeek
        timeOfDay
        startTime
        endTime
      }
      temporaryAvailability {
        id
        startDt
        endDt
      }
      transportationPreferences {
        transportation {
          id
          label
        }
      }
    }
  }
`;

export const uploadWorkerProfileImageMutation = gql`
  mutation uploadWorkerProfileImage($type: String!, $id: String!, $file: Upload!) {
    uploadWorkerProfileImage(file: $file, id: $id, type: $type) {
      id
      path
      filename
      mimetype
    }
  }
`;

export const uploadFileToS3 = gql`
  mutation uploadFileToS3($isProfile: Boolean!, $file: Upload!, $data: WorkerInput!) {
    uploadFileToS3(isProfile: $isProfile, file: $file, data: $data, ) {
      id
      profileImageUrl
    }
  }
`;

export const workerCustomFileUploadToS3 = gql`
  mutation workerCustomFileUpload($file: Upload!, $workerId: String!) {
    workerCustomFileUpload(file: $file, workerId: $workerId,) {
        fileSignedurl
        filepath
    }
  }
`;

export default gql`
  mutation editWorker($data: EditWorkerInput!) {
    editWorker(data: $data) {
      id
      createdAt
      updatedAt
      isDeleted
      firstName
      lastName
      email
      isEmailVerified
      street
      city
      region
      postalCode
      country
      phone
      sin
      identitiesUserId
      profileImageUrl
      identificationImageUrl
      consent
      isActive
      isApproved
      isSuspended
      approvedAt
      noShowCount
    }
  }
`;
