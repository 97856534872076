import { Add } from '@mui/icons-material';
import Button from 'components/Button/Button'
import MainContent from 'components/Containers/MainContent'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import HolidaysTable from './components/HolidaysTable';
import { actions as holidaysActions, selectors as holidaysSelectors } from "store/modules/holidays"
import HolidayActionMenuDialogs from './components/HolidayActionMenuDialogs';


export default function HolidaysOverview() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(holidaysActions.setHolidayDetail(null));
        dispatch(holidaysActions.setHolidayWorkers([]));
        dispatch(holidaysActions.setHolidayWorkersPayrollSummary([]));
    }, []);

    const handleAddHoliday = () => {
        dispatch(holidaysActions.setIsAddHolidayDialog(true));
    };

    return (
        <>
            {/* Container */}
            <MainContent>

                {/* Add Holiday Dialog opener */}
                <Button
                    style={{ alignSelf: "flex-end", marginBottom: 24 }}
                    label="Add New Holiday"
                    icon={<Add />}
                    alignIcon="left"
                    onClick={handleAddHoliday}
                />

                {/* Holiday Table */}
                <HolidaysTable />
            </MainContent>
            <HolidayActionMenuDialogs />
        </>
    )
}
