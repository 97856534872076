export const paginationSettings = {
  pending: {
    prefix: "pagination_Pending",
    defaultPageSize: 10,
  },
  approved: {
    prefix: "pagination_Approved",
    defaultPageSize: 10,
  },
  rejected: {
    prefix: "pagination_Rejected",
    defaultPageSize: 10,
  },
};
