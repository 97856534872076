import React from 'react'
import AddHolidayDialog from './AddHolidayDialog'
import { useDispatch, useSelector } from 'react-redux'
import Dialog from 'components/Dialog/Dialog'
import { CircularProgress } from '@mui/material';
import { actions as holidaysActions, selectors as holidaysSelectors } from "store/modules/holidays"
import { makeStyles } from '@mui/styles'
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
    loader: {
        "& svg": {
            margin: "0 !important",
            marginTop: "0 !important"
        }
    }
}));

export default function HolidayActionMenuDialogs({isDetailPage,...props}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isOpenAddDialog = useSelector(holidaysSelectors.getIsAddHolidayDialog);
    const selectedHolidayId = useSelector(holidaysSelectors.getIsDeleteHolidayDialog);
    const isDeleting = useSelector(holidaysSelectors.getIsDeleteHolidayLoading);
    const history=useHistory();

    const hideDeleteDialog = () => {
        dispatch(holidaysActions.setIsDeleteHolidayDialog(false));
    };

    const handleDeleteHoliday = () => {
        dispatch(holidaysActions.deleteHoliday(selectedHolidayId,handleRedirect));
    };

    const handleRedirect=()=>{
        if(isDetailPage){
            history.replace("/holidays/overview")
            return;
        };
    }

    return (
        <>
            {/* Dialogs */}
            {isOpenAddDialog && <AddHolidayDialog isDetailPage={isDetailPage} />}
            <Dialog
                open={Boolean(selectedHolidayId)}
                title="Are you sure?"
                onClose={hideDeleteDialog}
                onConfirm={handleDeleteHoliday}
                onCancel={hideDeleteDialog}
                alertType="warning"
                disabled={isDeleting}
                icon="help_outline"
                confirmText={isDeleting ? <CircularProgress className={classes.loader} size={18} /> : "Yes"}
            />
        </>
    )
}
