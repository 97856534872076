/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from "react";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Line from "components/Placeholders/Line";
import WorkerPopover from "components/Popovers/WorkerPopover";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";
import DisableCellOverlay from "../../components/Controls/DisableCellOverlay";
import Can from "components/Authorization/Can";
import { PositionPermissions } from "helpers/constants/permissions";

export default function useName(disabled, isRatingAll = false) {
    const canAddWorker = useSelector(JobDetailSelectors.getCanAddWorkerInDashboard);
    return {
        Header: "Worker",
        id: "fullName",
        width: isRatingAll ? 300 : undefined,
        minWidth: 120,
        sortable: !isRatingAll,
        Cell: ({ row: { original: { fullName, phone, email, city, region } } }) => {
            return (
                <>
                    {fullName ? (
                        <WorkerPopover phone={phone} email={email} city={city} region={region}>
                            <Typography variant="body1">{fullName}</Typography>
                        </WorkerPopover>
                    ) : (
                        <Line />
                    )}
                    <DisableCellOverlay visible={disabled} />
                </>
            );
        },
        Footer: (<Can perform={PositionPermissions.create.fill.value}>
            {allowed => {
                if (!allowed) {
                    return null;
                }
                return canAddWorker && !disabled ? <Line /> : null
            }}
        </Can>)
    };
}
