import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import MainContent from 'components/Containers/MainContent'
import moment from 'moment'
import React, { useEffect } from 'react'
import HolidayHeader from './components/HolidayHeader';
import ProvinceTabs from './components/ProvinceTabs'
import { actions as holidaysActions, selectors as holidaysSelectors } from "store/modules/holidays"
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import HolidayActionMenuDialogs from '../components/HolidayActionMenuDialogs'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: `${theme.palette.common.white}`,
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: theme.shape.borderRadius / 2,
        padding: `${theme.spacing(3)} !important`,
        marginTop: theme.spacing(2),
    },
    container: {
        // borderRight: `1px solid ${theme.palette.grey[500]}`,
        // paddingRight: theme.spacing(3),
        // [theme.breakpoints.down("md")]: {
        //     borderRight: "none",
        //     paddingRight: 0,
        //     borderBottom: `1px solid ${theme.palette.grey[500]}`,
        //     paddingBottom: theme.spacing(3),
        // },
    },
    provienceContainer: {
        paddingLeft: theme.spacing(3),
        [theme.breakpoints.down("md")]: {
            paddingLeft: 0,
            paddingTop: theme.spacing(3)
        },
    },
    list: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap"
    },
    listItem: {
        display: "flex",
        alignItems: "center",
        margin: theme.spacing(0, 1)
    }
}));

export default function HolidayDetail() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isLoading = useSelector(holidaysSelectors.getIsDetailLoading);
    const holidayDetails = useSelector(holidaysSelectors.getHolidayDetail);
    const params = useParams();
    const holidayId = params.id;
    const isCompleted = !moment(moment(holidayDetails?.holidayDate).format("YYYY-MM-DD")).isSame(moment().format("YYYY-MM-DD")) && moment(holidayDetails?.holidayDate).isBefore(moment());

    useEffect(() => {
        dispatch(holidaysActions.fetchsHolidaydetail(holidayId));
    }, [holidayId]);

    return (
        <React.Fragment>
            <MainContent isLoading={isLoading}>
                <HolidayHeader />

                <Grid container className={classes.root}>
                    <Grid container item xs={12} md={12} className={classes.container}>
                        <Grid container item xs={12} direction="column" justifyContent="flex-start">
                            <Typography variant="h4">{holidayDetails?.name}</Typography>
                            <Typography variant="body1">{holidayDetails?.description}</Typography>
                            <Typography variant="body1" gutterBottom marginTop={1}>
                                {moment(holidayDetails?.holidayDate).format("dddd, MMMM Do, YYYY")}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom marginTop={2}>
                                Selected Provinces
                            </Typography>
                            <div className={classes.list}>
                                {holidayDetails?.provinceHoliday && holidayDetails?.provinceHoliday.map((p, index) => <div key={index} className={classes.listItem}>
                                    <Typography variant="body1" color="textSecondary" marginRight={0.4}>
                                        &#8226;
                                    </Typography>
                                    {p?.province?.name}
                                </div>)}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Province tabs */}
                {isCompleted && <ProvinceTabs />}
            </MainContent>
            {moment(holidayDetails?.holidayDate).isAfter(moment()) && <HolidayActionMenuDialogs isDetailPage />}
        </React.Fragment >
    )
}
