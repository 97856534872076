import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import withStyles from '@mui/styles/withStyles';
import MenuItem from "@mui/material/MenuItem";
import MoreVert from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
import { actions as ProvinceDocumentActions } from "store/modules/provinceDocuments";

const styles = ({ typography, spacing }) => ({
    icon: {
        fontSize: typography.pxToRem(20),
        marginRight: spacing(2),
    },
});

function DocumentActionMenu({ classes, document }) {

    const dispatch = useDispatch();
    const [anchorEl, setanchorEl] = useState(null);

    const handleClick = event => {
        setanchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setanchorEl(null);
    };

    const handleDelete = () => {
        dispatch(ProvinceDocumentActions.setIsDeleteProvinceDocumentsDialog(document.id));
        handleClose();
    }

    const handleEdit = () => {
        dispatch(ProvinceDocumentActions.setEditData(document));
        handleClose();
    }

    return (
        <React.Fragment>
            <IconButton aria-haspopup="true" disableRipple onClick={handleClick}>
                <MoreVert color="primary" />
            </IconButton>
            {anchorEl ? (
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    disableAutoFocusItem
                >
                    <MenuItem onClick={handleEdit}>
                        <Edit className={classes.icon} color="action" />
                        Edit
                    </MenuItem>
                    <MenuItem onClick={handleDelete}>
                        <Delete className={classes.icon} color="action" />
                        Delete
                    </MenuItem>
                </Menu>
            ) : null}

        </React.Fragment>
    );
}

export default (withStyles(styles)(DocumentActionMenu));
