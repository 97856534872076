import ApolloClient from "utils/apollo";
import { flattenGraphQLArray } from "utils/graphql";
import * as pagination from "utils/graphql-pagination";
import { actions as MetaActions } from "store/modules/meta";
import { paginationSettings } from "./constants";
import {
    getCustomFieldListPagingData, getEmployerListData, getEmployerPaginationData, getCustomFieldsData, getDetailEmployerPaginationData, getDetailEmployerListData, getActiveTab, getWorkerPaginationData, getWorkerListData, getDetailWorkerPaginationData, getDetailWorkerListData,
} from "./selectors";
import { CustomFieldTypesObject } from "utils/constant";
import types from "./types";
import { CustomFieldsQuery, employerListQuery, workerListQuery } from "./graphql/queries";
import CustomFieldDetailQuery from "./graphql/queries/custom-field-detail";
import createCustomFieldQuery, { deleteCustomFieldQuery } from "./graphql/mutations";
import updateCustomField from "./graphql/mutations/update-custom-field";
import { getRelation } from "utils/redux";

const setLoadingState = key => value => ({
    type: types.SET_LOADING_STATE,
    payload: { key, value },
});

const setDialogState = key => value => ({
    type: types.SET_DIALOG_STATE,
    payload: { key, value },
});

export const setIsAddCustomField = setLoadingState("isAddCustomField");
export const setFillUpdateCustomFieldDataLoading = setLoadingState("fillUpdateData");
export const setCustomFieldDetailLoading = setLoadingState("detailLoading");
export const setIsDeletingCustomField = setLoadingState("isDeleting");
export const setIsRemoveUserLoading = setLoadingState("removeUserLoading");
export const setIsAddUserLoading = setLoadingState("addUserLoading");

export const setIsOpenDeleteConfirmationCustomField = setDialogState("deleteConfirmation");
export const setIsOpenRemoveUserCustomField = setDialogState("removeUser");
export const setIsOpenAddUserCustomField = setDialogState("addUser");

const setEditFormData = form => ({
    type: types.SET_EDIT_FORM_VALUES,
    payload: { form },
});

const setFormDataFactory = key => value => ({
    type: types.SET_FORM_VALUES,
    payload: { key, value },
});

export const setCustomFieldForm = (key, value) => ({
    type: types.SET_FORM_VALUES,
    payload: { key, value },
});

export const clearCustomFieldForm = () => ({
    type: types.CLEAR_FORM,
    payload: {}
});

export const addNewOption = () => ({
    type: types.ADD_NEW_OPTION,
    payload: {}
});

export const setOptionValue = (index, key, value) => ({
    type: types.SET_OPTION_VALUE,
    payload: { index, key, value }
});

export const removeOption = (key) => ({
    type: types.REMOVE_OPTION,
    payload: { key }
});

export const clearAllOptions = () => ({
    type: types.CLEAR_TYPE_OPTIONS,
    payload: {}
});

export const setActiveTab = (activeTab) => ({
    type: types.SET_ACTIVE_TAB,
    payload: { activeTab }
});

export const clearSelectedPickerData = ({ key, value }) => ({
    type: types.CLEAR_SELECTED_PICKER_DATA,
    payload: { key, value }
});

export const setCustomFieldName = setFormDataFactory("name");
export const setCustomFieldAssignTo = setFormDataFactory("assignTo");
export const setCustomFieldSlug = setFormDataFactory("slug");
export const setCustomFieldType = setFormDataFactory("type");
export const setCustomFieldIsRequired = setFormDataFactory("isRequired");
export const setSelectedEmployers = setFormDataFactory("employers");
export const setSelectedWorkers = setFormDataFactory("workers");


export const setCustomFieldListData = (data, part) => ({ type: types.SET_CUSTOM_FIELDS_DATA, payload: { data, part } });
const setCustomFieldDetailData = (key, value) => ({ type: types.SET_CUSTOM_FIELD_DETAIL, payload: { key, value } });
const setCustomFieldDetailEmployerListData = (data, part) => ({ type: types.SET_CUSTOM_FIELD_DETAIL_EMPLOYERS, payload: { data, part } });
const setCustomFieldDetailWorkerListData = (data, part) => ({ type: types.SET_CUSTOM_FIELD_DETAIL_WORKERS, payload: { data, part } });

export const deleteCustomField = (callback) => (dispatch, getState) => {
    const state = getState();
    const data = state?.customFields?.dialog?.deleteConfirmation;

    dispatch(setIsDeletingCustomField(true))
    return ApolloClient.query({
        query: deleteCustomFieldQuery,
        variables: {
            id: data?.id,
        },
    }).then(res => {
        if (!res.errors) {
            dispatch(setIsDeletingCustomField(false));
            dispatch(setIsOpenDeleteConfirmationCustomField(false));
            dispatch(MetaActions.successToast("Custom field deleted successfully"));
            callback ? callback(res.data) : dispatch(fetchCustomFieldsTableData());
        } else {
            dispatch(setIsDeletingCustomField(false));
            dispatch(MetaActions.errorToast(res.errors?.[0]?.message));
        }
    }).catch(e => {
        dispatch(MetaActions.errorToast(e.message));
        dispatch(setIsDeletingCustomField(false));
    });
};

export const removeUser = () => (dispatch, getState) => {
    const state = getState();
    const data = state?.customFields?.dialog?.removeUser;
    const customFieldId = state?.customFields?.detail?.customField.id;
    const assignTo = state?.customFields?.detail?.customField.assignTo;

    dispatch(setIsRemoveUserLoading(true))
    return ApolloClient.query({
        query: updateCustomField,
        variables: {
            customData: {
                customFieldId: customFieldId,
                [assignTo === "WORKER" ? "removeWorkerIds" : "removeEmployerIds"]: [data?.id],
            }
        },
    }).then(res => {
        if (!res.errors) {
            dispatch(setIsRemoveUserLoading(false));
            dispatch(setIsOpenRemoveUserCustomField(false));
            dispatch(MetaActions.successToast(`${assignTo === "WORKER" ? "Worker" : "Employer"} removed successfully`));
            if (assignTo === "WORKER") {
                dispatch(fetchDetailsWorkerList(customFieldId));
            } else if (assignTo === "EMPLOYER") {
                dispatch(fetchDetailsEmployersList(customFieldId));
            };
            // dispatch(getCustomFieldDetail(customFieldId));
        } else {
            dispatch(setIsRemoveUserLoading(false));
            dispatch(MetaActions.errorToast(res.errors?.[0]?.message));
        }
    }).catch(e => {
        dispatch(MetaActions.errorToast(e.message));
        dispatch(setIsRemoveUserLoading(false));
    });
};

export const addUser = () => (dispatch, getState) => {
    const state = getState();
    const customFieldId = state?.customFields?.detail?.customField.id;
    const assignTo = state?.customFields?.detail?.customField.assignTo;
    const newEmployerIds = state?.customFields?.form?.employers;
    const newWorkerIds = state?.customFields?.form?.workers;

    dispatch(setIsAddUserLoading(true))
    return ApolloClient.query({
        query: updateCustomField,
        variables: {
            customData: {
                customFieldId: customFieldId,
                [assignTo === "EMPLOYER" ? "newEmployerIds" : "newWorkerIds"]: assignTo === "EMPLOYER" ? newEmployerIds : newWorkerIds
            }
        },
    }).then(res => {
        if (!res.errors) {
            dispatch(setIsAddUserLoading(false));
            dispatch(setIsOpenAddUserCustomField(false));
            dispatch(clearCustomFieldForm());
            dispatch(MetaActions.successToast(`${assignTo === "WORKER" ? "Worker" : "Employer"} Added successfully`));
            if (assignTo === "WORKER") {
                dispatch(fetchDetailsWorkerList(customFieldId));
            } else if (assignTo === "EMPLOYER") {
                dispatch(fetchDetailsEmployersList(customFieldId));
            };
        } else {
            dispatch(setIsAddUserLoading(false));
            dispatch(MetaActions.errorToast(res.errors?.[0]?.message));
        }
    }).catch(e => {
        dispatch(MetaActions.errorToast(e.message));
        dispatch(setIsAddUserLoading(false));
    });
};

export const fetchCustomFieldsTableData = pageIndex => (dispatch, getState) => {
    const state = getState();
    const activeTab = getActiveTab(state);
    const pageInfo = getCustomFieldListPagingData(state);
    const pagingVars = dispatch(pagination.pagingVarsFactory(paginationSettings.customFieldList)(pageInfo, pageIndex));
    const relations = {
        CustomFieldOptions: ["label"]
    };
    const filterInfo = JSON.parse(JSON.stringify(getCustomFieldsData(state, { part: "filter" })));

    return ApolloClient.query({
        query: CustomFieldsQuery,
        variables: {
            ...pagingVars,
            assignTo: activeTab == 0 ? "EMPLOYER" : "WORKER",
            order: getCustomFieldsData(state, { part: "sort" }).map(sort => {
                return {
                    field: sort.id,
                    direction: sort.desc ? "DESC" : "ASC",
                    relation: getRelation(relations, sort.id),
                };
            }),
            like: filterInfo.map(filter => {
                return {
                    field: filter.id,
                    value: filter.value,
                    relation: getRelation(relations, filter.id),
                };
            }),
        },
    })
        .then(({ data, errors }) => {
            if (!data) {
                dispatch(setCustomFieldListData(flattenGraphQLArray([]), "data"));
                throw Error(errors[0].message);
            } else {
                const { CustomFields } = data;
                const paginationData = {
                    ...CustomFields.pageInfo,
                    totalCount: CustomFields.totalCount,
                };

                dispatch(
                    pagination.updatePageInfo(paginationSettings.customFieldList, paginationData),
                );
                dispatch(setCustomFieldListData(flattenGraphQLArray(CustomFields), "data"));
                dispatch(pagination.doneLoading(paginationSettings.customFieldList));
            }
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(e.message));
            dispatch(pagination.doneLoading(paginationSettings.customFieldList));
        });
};

export const setCustomFieldListSort = sort => dispatch => {
    dispatch(setCustomFieldListData(sort, "sort"));
};

export const setCustomFieldListFilter = filter => dispatch => {
    dispatch(setCustomFieldListData(filter, "filter"));
};

export const changeCustomFieldListPageSize = pageSize => dispatch => {
    dispatch(pagination.updatePageInfo(paginationSettings.customFieldList, { pageSize }));
};

export const createCustomField = (id, callback = () => 0) => (dispatch, getState) => {
    const state = getState();
    const form = state.customFields.form;
    let extraData = {};
    if (id) {
        extraData = {
            customFieldId: id
        }
    } else if (form.assignTo === "EMPLOYER") {
        extraData = {
            isForAll: form.isAllEmployer,
            ...(form.isAllEmployer ? {} : { employerIds: form.employers }),
        };
    } else if (form.assignTo === "WORKER") {
        extraData = {
            isForAll: false,
            workerIds: form.workers,
        };
    };

    const customData = {
        name: form.name,
        slug: form.slug,
        type: form.type,
        isRequired: form.isRequired,
        assignTo: form.assignTo,
        ...extraData,
        ...((form.type === CustomFieldTypesObject.TEXTBOX || form.type === CustomFieldTypesObject.FILE) ? {customFieldsOptions:null} : { customFieldsOptions: form.options }),
    };

    const query = id ? updateCustomField : createCustomFieldQuery;

    dispatch(setIsAddCustomField(true));
    return ApolloClient.mutate({
        mutation: query,
        variables: {
            customData: {
                ...customData
            },
        },
    })
        .then(({ data, errors }) => {
            dispatch(setIsAddCustomField(false));
            if (!errors) {
                dispatch(clearCustomFieldForm());
                dispatch(fetchCustomFieldsTableData());
                dispatch(MetaActions.successToast(id ? "Custom field updated successfully." : "Custom field created successfully."));
                callback(data);
            } else {
                dispatch(setIsAddCustomField(false));
                dispatch(MetaActions.errorToast(errors?.[0]?.message));
            }
        })
        .catch((e) => {
            // dispatch(clearCustomFieldForm());
            dispatch(setIsAddCustomField(false));
            dispatch(MetaActions.errorToast(e.message));
        });
}


export const fetchEmployersList = pageIndex => (dispatch, getState) => {
    const state = getState();
    const pageInfo = getEmployerPaginationData(state);
    const pagingVars = dispatch(pagination.pagingVarsFactory(paginationSettings.employerList)(pageInfo, pageIndex));
    const relations = {
    };
    const filterInfo = JSON.parse(JSON.stringify(getEmployerListData(state, { part: "filter" })));
    return ApolloClient.query({
        query: employerListQuery,
        variables: {
            isActive: true,
            isApproved: true,
            ...pagingVars,
            order: getEmployerListData(state, { part: "sort" }).map(sort => {
                return {
                    field: sort.id,
                    direction: sort.desc ? "DESC" : "ASC",
                    relation: getRelation(relations, sort.id),
                };
            }),
            like: filterInfo.map(filter => {
                return {
                    field: filter.id,
                    value: filter.value,
                    relation: getRelation(relations, filter.id),
                };
            }),
        },
    })
        .then(({ data }) => {
            const { employers } = data;
            const paginationData = {
                ...employers.pageInfo,
                totalCount: employers.totalCount,
            };

            dispatch({
                type: types.SET_EMPLOYERS_LIST_DATA,
                payload: {
                    data: flattenGraphQLArray(employers),
                    part: "data",
                },
            });
            dispatch(pagination.updatePageInfo(paginationSettings.employerList, paginationData));
            dispatch(pagination.doneLoading(paginationSettings.employerList));
        })
        .catch(() => {
            dispatch(pagination.doneLoading(paginationSettings.employerList));
        });
};


export const setEmployerListSort = sort => dispatch => {
    dispatch({
        type: types.SET_EMPLOYERS_LIST_DATA,
        payload: {
            data: sort,
            part: "sort",
        },
    });
};

export const setEmployerListFilter = filter => dispatch => {
    dispatch({
        type: types.SET_EMPLOYERS_LIST_DATA,
        payload: {
            data: filter,
            part: "filter",
        },
    });
};

export const setEmployerListPageSize = pageSize => dispatch => {
    dispatch(pagination.updatePageInfo(paginationSettings.employerList, { pageSize }));
};

export const clearEmployerPickerFilters = () => (dispatch) => {
    dispatch(setEmployerListSort([{ id: "id", desc: true }]));
    dispatch(setEmployerListFilter([]));
    dispatch(setEmployerListPageSize(10));
    dispatch(fetchEmployersList(0));
}

export const getCustomFieldDetail = (id) => (dispatch, getState) => {

    dispatch(setCustomFieldDetailLoading(true));
    return ApolloClient.query({
        query: CustomFieldDetailQuery,
        variables: {
            id,
        },
    }).then(res => {
        const { CustomField } = res.data;
        dispatch(setCustomFieldDetailData("customField", CustomField));
        if (CustomField.assignTo === "EMPLOYER") {
            dispatch(fetchDetailsEmployersList(id));
        } else if (CustomField.assignTo === "WORKER") {
            dispatch(fetchDetailsWorkerList(id));
        };
        dispatch(setCustomFieldDetailLoading(false));
    }).catch(e => {
        dispatch(MetaActions.errorToast(e.message));
        dispatch(setCustomFieldDetailLoading(false));
    });
};


export const fetchDetailsEmployersList = (id, pageIndex) => (dispatch, getState) => {
    const state = getState();
    const pageInfo = getDetailEmployerPaginationData(state);
    const pagingVars = dispatch(pagination.pagingVarsFactory(paginationSettings.detailEmployerList)(pageInfo, pageIndex));
    const relations = {
    };
    const filterInfo = JSON.parse(JSON.stringify(getDetailEmployerListData(state, { part: "filter" })));
    return ApolloClient.query({
        query: employerListQuery,
        variables: {
            customFieldId: id,
            ...pagingVars,
            order: getDetailEmployerListData(state, { part: "sort" }).map(sort => {
                return {
                    field: sort.id,
                    direction: sort.desc ? "DESC" : "ASC",
                    relation: getRelation(relations, sort.id),
                };
            }),
            like: filterInfo.map(filter => {
                return {
                    field: filter.id,
                    value: filter.value,
                    relation: getRelation(relations, filter.id),
                };
            }),
        },
    })
        .then(({ data }) => {
            const { employers } = data;
            const paginationData = {
                ...employers.pageInfo,
                totalCount: employers.totalCount,
            };

            dispatch(setCustomFieldDetailEmployerListData(flattenGraphQLArray(employers), "data"));
            dispatch(pagination.updatePageInfo(paginationSettings.detailEmployerList, paginationData));
            dispatch(pagination.doneLoading(paginationSettings.detailEmployerList));
        })
        .catch((e) => {
            dispatch(pagination.doneLoading(paginationSettings.detailEmployerList));
            dispatch(MetaActions.errorToast(e.message));
        });
};


export const setDetailEmployerListSort = sort => dispatch => {
    dispatch(setCustomFieldDetailEmployerListData(sort, "sort"));
};

export const setDetailEmployerListFilter = filter => dispatch => {
    dispatch(setCustomFieldDetailEmployerListData(filter, "filter"));
};

export const setDetailEmployerListPageSize = pageSize => dispatch => {
    dispatch(pagination.updatePageInfo(paginationSettings.detailEmployerList, { pageSize }));
};

// workerlist
export const fetchDetailsWorkerList = (id, pageIndex) => (dispatch, getState) => {
    const state = getState();
    const pageInfo = getDetailWorkerPaginationData(state);
    const pagingVars = dispatch(pagination.pagingVarsFactory(paginationSettings.detailWorkerList)(pageInfo, pageIndex));
    const relations = {
    };
    const filterInfo = JSON.parse(JSON.stringify(getDetailWorkerListData(state, { part: "filter" })));
    return ApolloClient.query({
        query: workerListQuery,
        variables: {
            customFieldId: id,
            ...pagingVars,
            order: getDetailWorkerListData(state, { part: "sort" }).map(sort => {
                return {
                    field: sort.id,
                    direction: sort.desc ? "DESC" : "ASC",
                    relation: getRelation(relations, sort.id),
                };
            }),
            like: filterInfo.map(filter => {
                return {
                    field: filter.id,
                    value: filter.value,
                    relation: getRelation(relations, filter.id),
                };
            }),
        },
    })
        .then(({ data }) => {
            const { workers } = data;
            const paginationData = {
                ...workers.pageInfo,
                totalCount: workers.totalCount,
            };

            dispatch(setCustomFieldDetailWorkerListData(flattenGraphQLArray(workers), "data"));
            dispatch(pagination.updatePageInfo(paginationSettings.detailWorkerList, paginationData));
            dispatch(pagination.doneLoading(paginationSettings.detailWorkerList));
        })
        .catch((e) => {
            dispatch(pagination.doneLoading(paginationSettings.detailWorkerList));
            dispatch(MetaActions.errorToast(e.message));
        });
};


export const setDetailWorkerListSort = sort => dispatch => {
    dispatch(setCustomFieldDetailWorkerListData(sort, "sort"));
};

export const setDetailWorkerListFilter = filter => dispatch => {
    dispatch(setCustomFieldDetailWorkerListData(filter, "filter"));
};

export const setDetailWorkerListPageSize = pageSize => dispatch => {
    dispatch(pagination.updatePageInfo(paginationSettings.detailWorkerList, { pageSize }));
};


export const getEditCustomFieldDetails = (id) => (dispatch, getState) => {
    dispatch(setFillUpdateCustomFieldDataLoading(true));
    return ApolloClient.query({
        query: CustomFieldDetailQuery,
        variables: {
            id,
        },
    }).then(res => {
        const { CustomField } = res.data;
        const form = {
            name: CustomField?.name,
            slug: CustomField?.slug,
            type: CustomField?.type,
            assignTo: CustomField?.assignTo,
            isRequired: CustomField?.isRequired,
            options: CustomField?.getCustomFieldsOptions?.map(p => ({ label: p.label, value: p.value })) || [],
        };
        dispatch(setEditFormData(form));
        dispatch(setFillUpdateCustomFieldDataLoading(false));

    }).catch(e => {
        dispatch(MetaActions.errorToast(e.message));
        dispatch(setFillUpdateCustomFieldDataLoading(false));
    });
};


// Workerpicker
export const fetchWorkersList = pageIndex => (dispatch, getState) => {
    const state = getState();
    const pageInfo = getWorkerPaginationData(state);
    const pagingVars = dispatch(pagination.pagingVarsFactory(paginationSettings.workerList)(pageInfo, pageIndex));
    const relations = {
    };
    const filterInfo = JSON.parse(JSON.stringify(getWorkerListData(state, { part: "filter" })));
    return ApolloClient.query({
        query: workerListQuery,
        variables: {
            isActive: true,
            isApproved: true,
            ...pagingVars,
            order: getWorkerListData(state, { part: "sort" }).map(sort => {
                return {
                    field: sort.id,
                    direction: sort.desc ? "DESC" : "ASC",
                    relation: getRelation(relations, sort.id),
                };
            }),
            like: filterInfo.map(filter => {
                return {
                    field: filter.id,
                    value: filter.value,
                    relation: getRelation(relations, filter.id),
                };
            }),
        },
    })
        .then(({ data }) => {
            const { workers } = data;
            const paginationData = {
                ...workers.pageInfo,
                totalCount: workers.totalCount,
            };

            dispatch({
                type: types.SET_WORKERS_LIST_DATA,
                payload: {
                    data: flattenGraphQLArray(workers),
                    part: "data",
                },
            });
            dispatch(pagination.updatePageInfo(paginationSettings.workerList, paginationData));
            dispatch(pagination.doneLoading(paginationSettings.workerList));
        })
        .catch(() => {
            dispatch(pagination.doneLoading(paginationSettings.workerList));
        });
};


export const setWorkerListSort = sort => dispatch => {
    dispatch({
        type: types.SET_WORKERS_LIST_DATA,
        payload: {
            data: sort,
            part: "sort",
        },
    });
};

export const setWorkerListFilter = filter => dispatch => {
    dispatch({
        type: types.SET_WORKERS_LIST_DATA,
        payload: {
            data: filter,
            part: "filter",
        },
    });
};

export const setWorkerListPageSize = pageSize => dispatch => {
    dispatch(pagination.updatePageInfo(paginationSettings.workerList, { pageSize }));
};

export const clearWorkerPickerFilters = () => (dispatch) => {
    dispatch(setWorkerListSort([{ id: "id", desc: true }]));
    dispatch(setWorkerListFilter([]));
    dispatch(setWorkerListPageSize(10));
    dispatch(fetchWorkersList(0));
}
