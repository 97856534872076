import React, { useRef } from "react";
import { CloudDownload } from "@mui/icons-material";
import { IconButton, Link } from "@mui/material";
import ActionColumnCell from "./ActionColumnCell";


export const idColumn = {
    Header: "ID",
    id: "id",
    accessor: "id",
    className: "-cursor-pointer",
};

export const documentTypeColumn = {
    Header: "Document Type",
    id: "name",
    accessor: ({ documentType }) => (documentType.name || ''),
    className: "-cursor-pointer",
};

export const documentNameColumn = {
    Header: "Document Name",
    id: "documentName",
    accessor: ({ documentName }) => (documentName || ''),
    className: "-cursor-pointer",
};

export const documentColumn = {
    Header: "Document",
    id: "ducumentURL",
    accessor: ({ signedDocumentUrl }) => (signedDocumentUrl),
    className: "-cursor-pointer",
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row: { original: { signedDocumentUrl, documentName } } }) => {
        return (
            <>
                <IconButton href={signedDocumentUrl} download={documentName} target="_blank" >
                    <CloudDownload />
                </IconButton>
            </>
        );
    }
};

export const workerFirstNameColumn = {
    Header: "Woker First Name",
    id: "firstName",
    accessor: ({ worker }) => (worker ? worker.firstName : ''),
    className: "-cursor-pointer",
};
export const workerLastNameColumn = {
    Header: "Woker Last Name",
    id: "lastName",
    accessor: ({ worker }) => (worker ? worker.lastName : ''),
    className: "-cursor-pointer",
};
export const workerEmailNameColumn = {
    Header: "Woker Email",
    id: "email",
    accessor: ({ worker }) => (worker ? worker.email : ''),
    className: "-cursor-pointer",
};

export const approvedByColumn = {
    Header: "Approved By",
    id: "approvedBy",
    accessor: ({ approvedBy }) => approvedBy,
    className: "-cursor-pointer",
};

export const rejectedByColumn = {
    Header: "Rejected By",
    id: "rejectedBy",
    accessor: ({ rejectedBy }) => rejectedBy,
    className: "-cursor-pointer",
};

export const rejectReasonColumn = {
    Header: "Reject Reason",
    id: "rejectReason",
    accessor: ({ rejectReason }) => rejectReason,
    className: "-cursor-pointer",
};

export const addedByColumn = {
    Header: "Added By",
    id: "addedBy",
    accessor: ({ addedBy }) => addedBy,
    className: "-cursor-pointer",
};

export const actionColumn = {
    Header: "Action",
    id: "action",
    className: "-cursor-pointer",
    collapse: true,
    Cell: ActionColumnCell
};
