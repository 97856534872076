import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import {
    CircularProgress,
    Grid,
    Grow,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import { isEmpty } from "lodash";
import * as yup from "yup";
import logo from "assets/img/grizzlyLogo.png";
import { KeyboardArrowRight } from '@mui/icons-material';
import { actions as LoginActions } from "store/modules/login";
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
    root: {
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    card: {
        background: theme.palette.common.white,
        overflow: "hidden",
        borderRadius: theme.spacing(1),
        display: "flex",
        flexDirection: "column",

    },
    header: {
        display: 'flex',
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(2),
        background: theme.palette.secondary.light
    },
    footer: {
        display: 'flex',
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(2),
        background: theme.palette.secondary.light,
        color: theme.palette.common.white
    },
    contaner: {
        padding: theme.spacing(3),
        width: 330
    },
    action: {
        border: `2px solid ${theme.palette.common.white}`,
        color: theme.palette.common.white
    },
    successContainer: {
        display: 'flex',
        flexDirection: "column",
        alignItems: "center",
        height: 444.45,
        justifyContent: "center",
        padding: theme.spacing(2),
        "& > p": {
            margin: theme.spacing(3, 0),
        }
    },
    checkmark: {
        borderRadius: 200,
        height: 100,
        width: 100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#9ABC66",
        margin: "0 auto",
        "& i": {
            color: "#F8FAF5",
            fontSize: "60px",
            lineHeight: "120px",
            marginLeft: -15
        }
    },
}));

export default function ResetPassword(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const params = new URLSearchParams(props.history.location.search);
    const email = params.get("email");
    const controlPadding = {
        leftColumn: { paddingRight: 4, textAlign: "left" },
        rightColumn: { paddingLeft: 4 },
    };

    const onSubmit = values => {
        if (values) {
            setLoading(true)
            dispatch(LoginActions.changePassword({ ...values })).then(res => {
                setLoading(false);
                setIsCompleted(true);
            }).catch(er => {
                setLoading(false);
                setIsCompleted(false);
            });
        }
    };

    return (
        <Formik
            initialValues={{
                password: "",
                confimrPassword: ""
            }}
            onSubmit={(values, actions) => {
                onSubmit(values);
                actions.setSubmitting(false);
            }}
            validationSchema={yup.object().shape({
                password: yup.string().required("Required field").min(8, 'Must be at least 8 character'),
                confimrPassword: yup.string().required('Required field').oneOf(
                    [yup.ref('password'), null],
                    "Passwords must match"
                )
            })}
        >
            {props => {
                const {
                    values,
                    touched,
                    errors,
                    dirty,
                    handleChange,
                    handleBlur,
                    handleSubmit
                } = props;

                return (
                    <div className={classes.root}>
                        {isCompleted ? (<>
                            <Grow in={isCompleted} timeout={1500}>
                                <div className={classes.container}>
                                    <div className={classNames(classes.card, classes.successContainer)}>
                                        <div className={classes.checkmark}>
                                            <i>✓</i>
                                        </div>
                                        <p>
                                            Your password has been reset successfully.
                                        </p>
                                    </div>
                                </div>
                            </Grow>
                        </>) : (<div className={classes.card}>
                            <div className={classes.header}>
                                <img style={{ height: 50 }} src={logo} alt={"logo"} />
                                <Typography color={"black"} fontSize={22} marginTop={1}>Change Password</Typography>
                            </div>
                            <div className={classes.contaner}>
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    alignItems="flex-start"
                                    style={{ paddingLeft: 0, paddingRight: 0 }}
                                >
                                    <Form>
                                        <Grid item xs={12} marginBottom={3} textAlign="center">
                                            <Typography>Enter a new password for</Typography>
                                            <Typography>{email}</Typography>
                                        </Grid>
                                        <Grid item container direction="row" xs={12}>
                                            <Grid item xs={12} md={12} style={controlPadding.rightColumn}>
                                                <TextField
                                                    id="password"
                                                    name="password"
                                                    label="New Password"
                                                    type="password"
                                                    className={classes.textField}
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={errors.password && touched.password}
                                                    helperText={errors.password && touched.password && errors.password}
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12} style={controlPadding.rightColumn}>
                                                <TextField
                                                    id="confimrPassword"
                                                    name="confimrPassword"
                                                    label="Confirm Password"
                                                    type="password"
                                                    className={classes.textField}
                                                    value={values.confimrPassword}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={errors.confimrPassword && touched.confimrPassword}
                                                    helperText={errors.confimrPassword && touched.confimrPassword && errors.confimrPassword}
                                                    margin="normal"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Form>

                                </Grid>
                            </div>
                            <div className={classes.footer}>
                                <IconButton color="inherit" onClick={handleSubmit} size={"large"} className={classes.action} disabled={!dirty || !isEmpty(errors) || loading}>
                                    {loading ? <CircularProgress size={20} color="inherit" /> : <KeyboardArrowRight size={26} />}
                                </IconButton>
                            </div>
                        </div>)}
                    </div>
                );
            }}
        </Formik>
    )
};
