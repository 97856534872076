import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as ReportsActions, selectors as ReportsSelectors } from "store/modules/reports";
import { convertUTCToTimezoneWithOffset } from "../../../../utils/time";
import { sharedReactTableProps } from "../../../../utils/constant";
import ReactTableCustom from "components/ReactTable";
import moment from "moment";
import { formatMoney } from "utils/stringUtils";

const EmptyDataRender = ({ value, ...props }) => (value && value.trim()) ? value : "-";

function WeeklyBillingTable() {
    const dispatch = useDispatch();
    const workerData = useSelector(ReportsSelectors.getWeeklyBillingReportData)
    const PaginationData = useSelector(ReportsSelectors.getWeeklyBillingReportPagingData);
    const defaultFilter = useSelector(ReportsSelectors.getWeeklyBillingReportFilter);

    const fetchData = useCallback(({ pageIndex, pageSize, filters }) => {
        dispatch(ReportsActions.changeWeeklyBillingReportTablePageSize(pageSize))
        dispatch(ReportsActions.changeWeeklyBillingReportFilter(filters))
        dispatch(ReportsActions.fetchWeeklyBillingReportsTableData(pageIndex))
    }, []);

    const columns = [
        {
            Header: "First Name",
            disableSortBy: true,
            accessor: "FirstName",
            Cell: ({ row: { original } }) => <EmptyDataRender value={original.FirstName || "-"} />
        },
        {
            Header: "Last Name",
            disableSortBy: true,
            accessor: "LastName",
            Cell: ({ row: { original } }) => <EmptyDataRender value={original.LastName || "-"} />
        },
        {
            Header: "Date of Birth",
            disableSortBy: true,
            disableFilters: true,
            accessor: "DateOfBirth",
            Cell: ({ row: { original } }) => <EmptyDataRender value={moment(original.DateOfBirth).format("DD/MM/YYYY")} />
        },
        {
            Header: "Address",
            disableSortBy: true,
            minWidth: 200,
            accessor: "Address",
            Cell: ({ row: { original } }) => <EmptyDataRender value={original.Address || "-"} />
        },
        {
            Header: "City",
            disableSortBy: true,
            disableFilters: true,
            accessor: "City",
            Cell: ({ row: { original } }) => <EmptyDataRender value={original.City || "-"} />
        },
        {
            Header: "Province",
            disableSortBy: true,
            disableFilters: true,
            accessor: "Province",
            Cell: ({ row: { original } }) => <EmptyDataRender value={original.Province || "-"} />
        },
        {
            Header: "Postal Code",
            disableSortBy: true,
            accessor: "PostalCode",
            Cell: ({ row: { original } }) => <EmptyDataRender value={original.PostalCode || "-"} />
        },
        {
            Header: "Country",
            disableSortBy: true,
            disableFilters: true,
            accessor: "Country",
            Cell: ({ row: { original } }) => <EmptyDataRender value={original.Country || "-"} />
        },
        {
            Header: "SIN",
            disableSortBy: true,
            disableFilters: true,
            accessor: "SIN",
            Cell: ({ row: { original } }) => <EmptyDataRender value={original.SIN || "-"} />
        },
        {
            Header: "Job Start Date",
            disableSortBy: true,
            disableFilters: true,
            accessor: "StartDate",
            width: 200,
            Cell: ({ row: { original } }) => <EmptyDataRender value={moment(original.StartDate).format("MMM Do, YYYY h:mm a")} />
        },
        {
            Header: "Job Address",
            disableSortBy: true,
            disableFilters: true,
            accessor: "JobAddress",
            Cell: ({ row: { original } }) => <EmptyDataRender value={original.JobAddress || "-"} />
        },
        {
            Header: "Job City",
            disableSortBy: true,
            disableFilters: true,
            accessor: "JobCity",
            Cell: ({ row: { original } }) => <EmptyDataRender value={original.JobCity || "-"} />
        },
        {
            Header: "Job Province",
            disableSortBy: true,
            disableFilters: true,
            accessor: "JobProvince",
            Cell: ({ row: { original } }) => <EmptyDataRender value={original.JobProvince || "-"} />
        },
        {
            Header: "Job Zip Code",
            disableSortBy: true,
            disableFilters: true,
            accessor: "JobZipCode",
            Cell: ({ row: { original } }) => <EmptyDataRender value={original.JobZipCode || "-"} />
        },
        {
            Header: "Employer First Name",
            disableSortBy: true,
            accessor: "EmployerFirstName",
            Cell: ({ row: { original } }) => <EmptyDataRender value={original.EmployerFirstName || "-"} />
        },
        {
            Header: "Employer Last Name",
            disableSortBy: true,
            accessor: "EmployerLastName",
            Cell: ({ row: { original } }) => <EmptyDataRender value={original.EmployerLastName || "-"} />
        },
        {
            Header: "Employer Address",
            disableSortBy: true,
            disableFilters: false,
            accessor: "EmployerAddress",
            Cell: ({ row: { original } }) => <EmptyDataRender value={original.EmployerAddress || "-"} />
        },
        {
            Header: "Employer Unit",
            disableSortBy: true,
            disableFilters: true,
            accessor: "EmployerUnit",
            Cell: ({ row: { original } }) => <EmptyDataRender value={original.EmployerUnit || "-"} />
        },
        {
            Header: "Employer Floor",
            disableSortBy: true,
            disableFilters: true,
            accessor: "EmployerFloor",
            Cell: ({ row: { original } }) => <EmptyDataRender value={original.EmployerFloor || "-"} />
        },
        {
            Header: "Employer City",
            disableSortBy: true,
            disableFilters: true,
            accessor: "EmployerCity",
            Cell: ({ row: { original } }) => <EmptyDataRender value={original.EmployerCity || "-"} />
        },
        {
            Header: "Employer Province",
            disableSortBy: true,
            disableFilters: true,
            accessor: "EmployerProvince",
            Cell: ({ row: { original } }) => <EmptyDataRender value={original.EmployerProvince || "-"} />
        },
        {
            Header: "Employer Zip code",
            disableSortBy: true,
            disableFilters: true,
            accessor: "EmployerZipcode",
            Cell: ({ row: { original } }) => <EmptyDataRender value={original.EmployerZipcode || "-"} />
        },
        {
            Header: "Pay Rate",
            disableSortBy: true,
            disableFilters: true,
            accessor: "PayRate",
            Cell: ({ row: { original } }) => <EmptyDataRender value={formatMoney(original.PayRate)} />
        },
        {
            Header: "Billing Rate",
            disableSortBy: true,
            disableFilters: true,
            accessor: "BillingRate",
            Cell: ({ row: { original } }) => <EmptyDataRender value={formatMoney(original.BillingRate)} />
        },
    ];

    return (
        <React.Fragment>
            <ReactTableCustom
                {...sharedReactTableProps}
                fetchData={fetchData}
                data={workerData}
                hasFilter={true}
                loading={PaginationData.paging.isLoading}
                pageCount={PaginationData.paging.totalCount}
                currentPage={PaginationData.paging.currentPage}
                defaultPageSize={PaginationData.paging.pageSize}
                defaultFilter={defaultFilter?.like || []}
                columns={columns}
            />
        </React.Fragment>
    );
}

export default WeeklyBillingTable;
