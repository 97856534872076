/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from "react";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';

import debounce from "debounce-promise";

import Control from "./components/Control";
import Menu from "./components/Menu";
import MultiValue from "./components/MultiValue";
import NoOptionsMessage from "./components/NoOptionsMessage";
import Option from "./components/Option";
import Placeholder from "./components/Placeholder";
import SingleValue from "./components/SingleValue";
import ValueContainer from "./components/ValueContainer";

import { fetchPlacePredictions, fetchPlaceDetails } from "./helpers/actions";
import baseStyles from "./helpers/styles";


const styles = theme => {
    const base = baseStyles(theme);
    return {
        ...base,
        paper: {
            ...base.paper,
            position: "absolute !important",
            top: "auto !important",
            bottom: (props) => props.menuPortalTarget ? "calc(100% - -40px) !important" : "calc(100% - 10px) !important",
        },
    };
};

class EmployerAutocomplete extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            value: "",
            suggestions: [],
            userInputValue: props.value,
            ready: !props.googleCallbackName,
        };

        this.debouncedFetchPredictions = debounce(this.fetchPredictions, 500);
    }

    componentDidMount() {
        const { googleCallbackName } = this.props;
        if (googleCallbackName) {
            if (!window.google) {
                window[googleCallbackName] = this.init;
            } else {
                this.init();
            }
        } else {
            this.init();
        }
    }

    componentWillUnmount() {
        const { googleCallbackName } = this.props;
        if (googleCallbackName && window[googleCallbackName]) {
            delete window[googleCallbackName];
        }
    }

    init = () => {
        if (!window.google) {
            throw new Error(
                "[AddressAutocomplete]: Google Maps JavaScript API library must be loaded.",
            );
        }

        if (!window.google.maps.places) {
            throw new Error(
                "[AddressAutocomplete]: Google Maps Places library must be loaded. Please add `libraries=places` to the src URL.",
            );
        }

        this.setState(state => {
            if (state.ready) {
                return null;
            } else {
                return { ready: true };
            }
        });
    };

    handleSelection = raw => {
        if (!raw) {
            return this.props.onChange(null, null);
        }
        fetchPlaceDetails(raw.data.placeId).then(location => this.props.onChange(raw, location));
    };

    selectComponents = {
        Control,
        Menu,
        MultiValue,
        NoOptionsMessage,
        Option,
        Placeholder,
        SingleValue,
        ValueContainer,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
    };

    get selectStyles() {
        return {
            container: base => {
                return {
                    ...base,
                    marginTop: "16px",
                };
            },
            clearIndicator: base => {
                return {
                    ...base,
                    padding: 0,
                    cursor: "pointer",
                };
            },
            loadingIndicator: base => {
                return {
                    ...base,
                    padding: 0,
                };
            },
            input: base => {
                return {
                    ...base,
                    color: this.props.theme.palette.text.primary,
                    "& input": {
                        font: "inherit",
                    },
                };
            },
            menuPortal: (base) => ({ ...base, zIndex: 9999 })
        };
    }

    render() {
        const { classes, menuPortalTarget = null } = this.props;

        return (
            <AsyncSelect
                classes={classes}
                styles={this.selectStyles}
                isClearable
                value={this.props.value}
                menuPortalTarget={menuPortalTarget}
                placeholder="Search an address..."
                loadOptions={debounce(fetchPlacePredictions, 1200)}
                onChange={this.handleSelection}
                components={this.selectComponents}
                textFieldProps={{
                    label: "Location",
                    required: this.props.required || false
                }}
            />
        );
    }
}

EmployerAutocomplete.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(EmployerAutocomplete);
