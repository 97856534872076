/* eslint-disable import-helpers/order-imports */
import ApolloClient from "utils/apollo";
import types from "./types";
import { holidayQuery, holidayByIdQuery } from "./graphql/queries";
import createHolidayMutation from "./graphql/mutations/create-holiday";
import updateHolidayMutation from "./graphql/mutations/update-holiday";
import deleteHolidayMutation from "./graphql/mutations/delete-holiday";
import refreshWorkersMutation from "./graphql/mutations/refresh-holiday-workers";
import initializeHolidayPayrollDetailMutation from "./graphql/mutations/initialize-holiday-payroll";
import payHolidayPaymentMutation from "./graphql/mutations/pay-holiday-payment";
import cancelHolidayPaymentMutation from "./graphql/mutations/cancel-eTransfer";
import cancelHolidayReversalMutation from "./graphql/mutations/cancel-reversal";
import resendHolidayReversalMutation from "./graphql/mutations/resend-reversal";
import resolveHolidayReversalMutation from "./graphql/mutations/resolve-reversal";
import resendHolidayPaymentMutation from "./graphql/mutations/resend-eTransfer";
import resolveHolidayPaymentMutation from "./graphql/mutations/resolve-eTransfer";
import requestHolidayPaymentMutation from "./graphql/mutations/request-eTransfer";
import createPayStubMutation from "./graphql/mutations/create-paystub";
import downloadPaystubSummaryMutation from "./graphql/mutations/download-paystub";
import getHolidayWorkersQuery from "./graphql/queries/get-holiday-workers";
import { actions as MetaActions } from "store/modules/meta";
import { getIsCancelETransferDialog, getIsCancelReversalDialog, getIsRequestReversalDialog, getIsResendETransferDialog, getIsResendPayStubDialog, getIsResendReversalDialog, getIsResolveETransferDialog, getIsResolveReversalDialog } from "./selectors";

const setLoadingState = key => value => ({
    type: types.SET_LOADING_STATE,
    payload: { key, value },
});

const setIsTableLoading = setLoadingState("tableLoading");
const setIsDetailLoading = setLoadingState("detailLoading");
const setIsDialogActionLoading = setLoadingState("dialogActionLoading");
const setIsDeleteHolidayLoading = setLoadingState("isDeleteHolidayLoading");
const setIsWorkersLoading = setLoadingState("workersLoading");
const setIsRefreshWorkers = setLoadingState("refreshWorkers");
const setIsInitializingHolidayPayroll = setLoadingState("initializingHolidayPayroll");
const setIsPayPosition = setLoadingState("payPosition");
const setIsCreatePaystubLoading = setLoadingState("createPaystubLoading");
export const setIsDownloadPaystubLoading = setLoadingState("downloadPaystubLoading");

const setDialogState = key => value => ({
    type: types.SET_DIALOG_STATE,
    payload: { key, value },
});

export const setIsAddHolidayDialog = setDialogState("addHolidayDialog");
export const setIsDeleteHolidayDialog = setDialogState("deleteHolidayDialog");
export const setIsEditHolidayDialog = setDialogState("editHolidayDialog");
export const setIsPayrollSummaryDialog = setDialogState("payrollSummary");
export const setIsResendPayStubDialog = setDialogState("resendPayStub");
export const setIsCancelETransferDialog = setDialogState("cancelETransfer");
export const setIsResendETransferDialog = setDialogState("resendETransfer");
export const setIsRequestReversalDialog = setDialogState("requestReversal");
export const setIsResolveETransferDialog = setDialogState("resolveETransfer");
export const setIsResolveReversalDialog = setDialogState("resolveReversal");
export const setIsResendReversalDialog = setDialogState("resendReversal");
export const setIsCancelReversalDialog = setDialogState("cancelReversal");

export const setEditFormData = value => ({
    type: types.SET_EDIT_FORM_DATA,
    payload: { value }
});

export const setHolidayDetail = details => ({
    type: types.SET_HOLIDAY_DETAIL,
    payload: { details }
});

const setHolidays = lists => ({
    type: types.SET_HOLIDAYS_LIST_DATA,
    payload: { lists }
});

export const setHolidayWorkers = data => ({
    type: types.SET_HOLIDAY_WORKERS,
    payload: { data }
});

export const setHolidayWorkersPayrollSummary = payrollSummary => ({
    type: types.SET_HOLIDAY_WORKERS_PAYROLL_SUMMARY,
    payload: { payrollSummary }
});

export const fetchsHolidaysList = () => (dispatch) => {
    dispatch(setIsTableLoading(true));
    return ApolloClient.query({
        query: holidayQuery,
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            else {
                dispatch(setIsTableLoading(false));
                dispatch(setHolidays(data.holiday));
            }
        })
        .catch(e => {
            dispatch(setIsTableLoading(false));
            dispatch(MetaActions.errorToast(`Could not find data: ${e.message}`));
        });
};

export const fetchsHolidaydetail = (holidayById) => (dispatch) => {
    dispatch(setIsDetailLoading(true));
    return ApolloClient.query({
        query: holidayByIdQuery,
        variables: {
            holidayById
        }
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            else {
                dispatch(setIsDetailLoading(false));
                dispatch(setHolidayDetail(data.holidayById));
            }
        })
        .catch(e => {
            dispatch(setIsDetailLoading(false));
            dispatch(MetaActions.errorToast(`Could not find data: ${e.message}`));
        });
};

export const createHoliday = (values, isDetailPage) => (dispatch) => {
    dispatch(setIsDialogActionLoading(true));
    const isEdit = values.id || values.id === 0;
    const query = isEdit ? updateHolidayMutation : createHolidayMutation;

    const payload = {
        name: values.name,
        description: values.description,
        holidayDate: values.date.format("YYYY-MM-DD"),
        provinceIds: values.provinces.map(a => a.value),
        ...(isEdit ? { id: values.id } : {})
    };
    return ApolloClient.mutate({
        mutation: query,
        variables: { data: payload },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            if (isDetailPage) {
                dispatch(fetchsHolidaydetail(values.id));
            } else {
                dispatch(fetchsHolidaysList());
            }
            dispatch(setIsDialogActionLoading(false))
            dispatch(setIsAddHolidayDialog(false));
            return data;
        }).catch((e) => {
            dispatch(MetaActions.errorToast(e.message));
            dispatch(setIsDialogActionLoading(false))
        });
};

export const deleteHoliday = (id, callback) => (dispatch) => {
    const deleteHolidayId = parseInt(id);
    dispatch(setIsDeleteHolidayLoading(true))
    return ApolloClient.mutate({
        mutation: deleteHolidayMutation,
        variables: { deleteHolidayId }
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            } else {
                dispatch(setIsDeleteHolidayLoading(false))
                dispatch(setIsDeleteHolidayDialog(false))
                dispatch(fetchsHolidaysList());
                dispatch(MetaActions.successToast("Holiday has been deleted."));
                callback && callback();
            }
        })
        .catch(e => {
            // dispatch(setIsDeleteHolidayDialog(false))
            dispatch(setIsDeleteHolidayLoading(false))
            dispatch(
                MetaActions.errorToast(`${e.message}`),
            );
        });
};

export const refreshHolidayWorkersData = (provinceId) => (dispatch, getState) => {
    const state = getState();
    const holidayId = state.holidays.details.id;
    dispatch(setIsRefreshWorkers(true));

    return ApolloClient.mutate({
        mutation: refreshWorkersMutation,
        variables: {
            provinceId,
            holidayId
        }
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            } else {
                dispatch(setIsRefreshWorkers(false));
                dispatch(getHolidayWorkersByProvince(provinceId));
            }
        })
        .catch(e => {
            dispatch(setIsRefreshWorkers(false))
            dispatch(
                MetaActions.errorToast(`${e.message}`),
            );
        });
};

export const getHolidayWorkersByProvince = (provinceId) => (dispatch, getState) => {
    const state = getState();
    dispatch(setIsWorkersLoading(true));

    return ApolloClient.query({
        query: getHolidayWorkersQuery,
        variables: {
            provinceId,
            holidayId: state.holidays.details.id
        }
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            } else {
                dispatch(setIsWorkersLoading(false));
                dispatch(setHolidayWorkers(data.getHolidayWorker));
            }
        })
        .catch(e => {
            dispatch(setIsWorkersLoading(false))
            dispatch(
                MetaActions.errorToast(`${e.message}`),
            );
        });
};

export const initializeHolidayPayrollDetail = (provinceId, holidayId) => async (dispatch, getState) => {
    dispatch(setIsInitializingHolidayPayroll(true));

    return ApolloClient.mutate({
        mutation: initializeHolidayPayrollDetailMutation,
        variables: {
            provinceId: parseInt(provinceId),
            holidayId: parseInt(holidayId)
        },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            } else {
                dispatch(setHolidayWorkersPayrollSummary(data.initializeHolidayPayroll));
                dispatch(setIsInitializingHolidayPayroll(false));
            }
        })
        .catch(e => {
            dispatch(setIsInitializingHolidayPayroll(false));
            dispatch(MetaActions.errorToast(`Failed to fetch payroll. (${e.message})`));
            throw e;
        });
};


export const payPosition = ({ workerId, provinceId, holidayId }, callback) => dispatch => {
    dispatch(setIsPayPosition(workerId));
    return ApolloClient.mutate({
        mutation: payHolidayPaymentMutation,
        variables: { workerId, provinceId, holidayId }
    })
        .then(({ errors }) => {
            if (errors && errors.length > 0) {
                throw new Error(errors[0].message);
            }
        })
        .then(() => dispatch(getHolidayWorkersByProvince(provinceId)))
        .then(() => {
            dispatch(setIsPayPosition(false));
            dispatch(MetaActions.successToast(`Payment for position ${workerId} is processing.`));
            callback && callback();
        })
        .catch(e => {
            dispatch(setIsPayPosition(false));
            dispatch(MetaActions.errorToast(e.message));
        });
};

export const payHolidayPayment = ({ provinceId, holidayId }, callback) => async (dispatch, getState) => {
    dispatch(setIsPayPosition(true));
    const state = getState();
    const details = state.holidays.details;

    return ApolloClient.mutate({
        mutation: payHolidayPaymentMutation,
        variables: { provinceId, holidayId },
    })
        .then(({ errors }) => {
            if (errors.length > 0) {
                throw new Error(errors[0].message);
            }
        })
        .then(() => dispatch(getHolidayWorkersByProvince(provinceId)))
        .then(() => {
            dispatch(MetaActions.successToast(`Payroll eTransfers for holiday ${details.name} has been initiated.`))
            dispatch(setIsPayPosition(false));
            callback && callback();
        })
        .catch(e => {
            dispatch(setIsPayPosition(false));
            dispatch(MetaActions.errorToast(`${e.message}`));
        });
};

export const cancelETransfer = ({ reason, selectedProvince }, callback) => async (dispatch, getState) => {
    dispatch(setIsDialogActionLoading(true));
    const state = getState();
    const eTransferData = getIsCancelETransferDialog(state);

    return ApolloClient.mutate({
        mutation: cancelHolidayPaymentMutation,
        variables: { reason, paymentId: eTransferData.payment.id },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
        })
        .then(() => dispatch(getHolidayWorkersByProvince(selectedProvince.provinceId)))
        .then(() => {
            dispatch(MetaActions.successToast(`Payment cancelled successfully.`))
            dispatch(setIsDialogActionLoading(false));
            dispatch(setIsCancelETransferDialog(false));
            callback && callback();
        })
        .catch(e => {
            dispatch(setIsDialogActionLoading(false));
            dispatch(MetaActions.errorToast(`An error occurred.`));
        });
};

export const resendETransfer = ({ selectedProvince }, callback) => async (dispatch, getState) => {
    dispatch(setIsDialogActionLoading(true));
    const state = getState();
    const eTransferData = getIsResendETransferDialog(state);

    return ApolloClient.mutate({
        mutation: resendHolidayPaymentMutation,
        variables: { paymentId: eTransferData.payment.id },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
        })
        .then(() => dispatch(getHolidayWorkersByProvince(selectedProvince.provinceId)))
        .then(() => {
            dispatch(MetaActions.successToast(`Payment #${eTransferData.payment.id} has been retried`))
            dispatch(setIsDialogActionLoading(false));
            dispatch(setIsResendETransferDialog(false));
            callback && callback();
        })
        .catch(e => {
            dispatch(setIsDialogActionLoading(false));
            dispatch(MetaActions.errorToast(`An error occurred - ${e.message}`));
        });
};

export const requestReversal = ({ request, selectedProvince }, callback) => async (dispatch, getState) => {
    dispatch(setIsDialogActionLoading(true));
    const state = getState();
    const eTransferData = getIsRequestReversalDialog(state);

    return ApolloClient.mutate({
        mutation: requestHolidayPaymentMutation,
        variables: { reason: request.reason, paymentId: eTransferData.payment.id },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
        })
        .then(() => dispatch(getHolidayWorkersByProvince(selectedProvince.provinceId)))
        .then(() => {
            dispatch(MetaActions.successToast(`Transfer #${eTransferData.payment.dcTransferId} has been sent a reversal request.`))
            dispatch(setIsDialogActionLoading(false));
            dispatch(setIsRequestReversalDialog(false));
            callback && callback();
        })
        .catch(e => {
            dispatch(setIsDialogActionLoading(false));
            dispatch(MetaActions.errorToast(`Failed ${e.message}`));
        });
};

export const resolveETransfer = ({ selectedProvince }, callback) => async (dispatch, getState) => {
    dispatch(setIsDialogActionLoading(true));
    const state = getState();
    const eTransferData = getIsResolveETransferDialog(state);

    return ApolloClient.mutate({
        mutation: resolveHolidayPaymentMutation,
        variables: { paymentId: eTransferData.payment.id },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
        })
        .then(() => dispatch(getHolidayWorkersByProvince(selectedProvince.provinceId)))
        .then(() => {
            dispatch(MetaActions.successToast(`Payment #${eTransferData.payment.id} has been marked as resolved`))
            dispatch(setIsDialogActionLoading(false));
            dispatch(setIsResolveETransferDialog(false));
            callback && callback();
        })
        .catch(e => {
            dispatch(setIsDialogActionLoading(false));
            dispatch(MetaActions.errorToast(`Failed to mark #${eTransferData.payment.id} as resolved. (${e.message})`));
        });
};

export const resolveReversal = ({ selectedProvince }, callback) => async (dispatch, getState) => {
    dispatch(setIsDialogActionLoading(true));
    const state = getState();
    const eTransferData = getIsResolveReversalDialog(state);

    return ApolloClient.mutate({
        mutation: resolveHolidayReversalMutation,
        variables: { reversalId: eTransferData.payment.id },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
        })
        .then(() => dispatch(getHolidayWorkersByProvince(selectedProvince.provinceId)))
        .then(() => {
            dispatch(MetaActions.successToast(`Reversal #${eTransferData.payment.id} has been marked as resolved`))
            dispatch(setIsDialogActionLoading(false));
            dispatch(setIsResolveReversalDialog(false));
            callback && callback();
        })
        .catch(e => {
            dispatch(setIsDialogActionLoading(false));
            dispatch(MetaActions.errorToast(`Failed to mark #${reversalId} as resolved. (${e.message})`));
        });
};

export const resendReversal = ({ reason, selectedProvince }, callback) => async (dispatch, getState) => {
    dispatch(setIsDialogActionLoading(true));
    const state = getState();
    const eTransferData = getIsResendReversalDialog(state);

    return ApolloClient.mutate({
        mutation: resendHolidayReversalMutation,
        variables: { reason, reversalId: eTransferData.payment.id },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
        })
        .then(() => dispatch(getHolidayWorkersByProvince(selectedProvince.provinceId)))
        .then(() => {
            dispatch(MetaActions.successToast(`Payment #${eTransferData.payment.id} has been retried`))
            dispatch(setIsDialogActionLoading(false));
            dispatch(setIsResendReversalDialog(false));
            callback && callback();
        })
        .catch(e => {
            dispatch(setIsDialogActionLoading(false));
            dispatch(MetaActions.errorToast(`Failed to retry ${eTransferData.payment.id}. (${e.message})`));
        });
};

export const cancelReversal = ({ reason, selectedProvince }, callback) => async (dispatch, getState) => {
    dispatch(setIsDialogActionLoading(true));
    const state = getState();
    const eTransferData = getIsCancelReversalDialog(state);

    return ApolloClient.mutate({
        mutation: cancelHolidayReversalMutation,
        variables: { reason, reversalId: eTransferData.payment.id },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
        })
        .then(() => dispatch(getHolidayWorkersByProvince(selectedProvince.provinceId)))
        .then(() => {
            dispatch(MetaActions.successToast(`Payment cancelled successfully.`))
            dispatch(setIsDialogActionLoading(false));
            dispatch(setIsCancelReversalDialog(false));
            callback && callback();
        })
        .catch(e => {
            dispatch(setIsDialogActionLoading(false));
            dispatch(MetaActions.errorToast(`An error occurred.`));
        });
};

export const sendPaystubEmail = () => async (dispatch, getState) => {
    dispatch(setIsCreatePaystubLoading(true));
    const state = getState();
    const holidayWorkerId = getIsResendPayStubDialog(state);

    return ApolloClient.mutate({
        mutation: createPayStubMutation,
        variables: { holidayWorkerId },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
        })
        .then(() => {
            dispatch(MetaActions.successToast(`Paystub for worker #${holidayWorkerId} has been sent.`))
            dispatch(setIsCreatePaystubLoading(false));
            dispatch(setIsResendPayStubDialog(false));
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(`Failed send paystub for worker #${holidayWorkerId}. (${e.message})`));
            dispatch(setIsCreatePaystubLoading(false));
        });
};

export const downloadPaystubSummary = holidayWorkerId => dispatch => {
    dispatch(setIsDownloadPaystubLoading(holidayWorkerId));
    return ApolloClient.mutate({ mutation: downloadPaystubSummaryMutation, variables: { holidayWorkerId } })
        .then(({ data, errors }) => {
            if (data) {
                return data.downloadHolidayPaystub.url
            } else if (errors && errors.length > 0) {
                throw new Error(errors[0].message);
            }
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(`Failed download paystub. (${e.message})`),);
            dispatch(setIsDownloadPaystubLoading(false));
        });
};

export const closeAllPaymentDialogs = () => async (dispatch, getState) => {
    dispatch(setIsCancelETransferDialog(false));
    dispatch(setIsResolveETransferDialog(false));
    dispatch(setIsResendETransferDialog(false));
    dispatch(setIsResolveReversalDialog(false));
    dispatch(setIsResendReversalDialog(false));
    dispatch(setIsCancelReversalDialog(false));
    dispatch(setIsRequestReversalDialog(false));
    dispatch(setIsResendPayStubDialog(false));
};
