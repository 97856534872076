import { gql } from "@apollo/client";

export const updateOvertimeSpec = gql`
    mutation UpdateOvertimeSpec($data: UpdateOvertimeSpecInput!) {
        updateOvertimeSpec(data: $data) {
            id
            createdAt
            updatedAt
            region
            isDailyOT
            gst
            minDailyOTMinutes
            maxDailyOTMinutes
            dailyOTRate
            isDailyDT
            minDailyDTMinutes
            maxDailyDTMinutes
            dailyDTRate
            isWeeklyOT
            minWeeklyOTMinutes
            maxWeeklyOTMinutes
            weeklyOTRate
            firstDayOfWeek
        }
    }
`;
