import { gql } from "@apollo/client";

export default gql`
mutation UploadWorkerPermitData($workerId: String!, $workerPermitExpiryDate: String!, $file: Upload!) {
    uploadWorkerPermitData(workerId: $workerId, workerPermitExpiryDate: $workerPermitExpiryDate, file: $file) {
      approvedAt
      workPermitUrl
      signedWorkPermitImageUrl
      workPermitStatus
      workPermitExpiryDate
      firstName
      fullName
      lastName
    }
  }
`;
