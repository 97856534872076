// Loading selectors
export const getLoadingStateFactory = loader => state => {
    return state.skills.loading[loader] || false;
};

export const getIsTableLoading = getLoadingStateFactory("tableLoading");

// Dialog selectors
export const getDialogStateFactory = loader => state => {
    return state.skills.dialog[loader] || false;
};

export const getIsdeleteDialog = getDialogStateFactory("deleteDialog");
export const getIsAddSkillDialog = getDialogStateFactory("addSkillDialog");
export const getIsEditSkillDialog = getDialogStateFactory("editDialog");

// skills selectors
export const getSkillsList = state => {
    return state.skills.skills;
};
