import { CardContent, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import DynamicWorkTypeDetail from './components/DynamicWorkTypeDetail'
import Dialog from "components/Dialog/Dialog";
import { actions as EmployerActions, selectors as EmployerSelectors } from 'store/modules/employerDetails'
import { useDispatch, useSelector } from 'react-redux';
import DynamicWorkTypeForm from './components/DynamicWorkTypeForm';
import { makeStyles } from "@mui/styles";
import Button from "components/Button/Button";

export const useStyles = makeStyles(() => ({

    cardHeading: {
        display: 'flex',
        '& button': {
            padding: '0'
        }
    },
    card: {
        height: '100%',
        position: "relative"
    },

    overlayLoading: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "#d2d2d282"
    }
}));

export default function DynamicWorkTypeTab() {
    const removeDynamicWorkTypeRateDialog = useSelector(EmployerSelectors.GetRemoveDynamicWorkTypeRateDialog);
    const isLoadingRemoveDynamicWorkTypeRate = useSelector(EmployerSelectors.getIsRemoveDynamicWorkTypeRateLoading);
    const dispatch = useDispatch();
    const classes = useStyles();
    const [tab, setTab] = useState(0);


    const showNewDynamicWorkTypeRateDialog = () => {
        setTab(1);
        dispatch(EmployerActions.setEditDynamicWorkTypeRateForm());
    };
    const hideRemoveDynamicWorkTypeRateDialog = () => {
        dispatch(EmployerActions.setRemoveDynamicWorkTypeRateDialog(false));
    };
    const handleRemoveDynamicWorkTypeRate = () => {
        dispatch(EmployerActions.removeDynamicWorkTypeRate());
    };

    return (
        tab === 0 ? <>
            <Grid item xs={12} sm={12} md={12} className={classes.card}>
                <CardContent>
                    <Typography
                        sx={{ fontSize: 14 }}
                        className={classes.cardHeading}
                        color="text.secondary"
                        gutterBottom
                        justifyContent={"space-between"}
                    >
                        <div />
                        <div>
                            <Button
                                style={{ alignSelf: "flex-end", marginBottom: 16, padding: 0 }}
                                label="Add"
                                alignIcon="left"
                                onClick={showNewDynamicWorkTypeRateDialog}
                            />
                        </div>
                    </Typography>
                    <DynamicWorkTypeDetail setTab={(val) => setTab(val)} />
                </CardContent>
            </Grid>
            <Dialog
                open={removeDynamicWorkTypeRateDialog}
                title={"Are You Sure?"}
                onClose={hideRemoveDynamicWorkTypeRateDialog}
                onConfirm={handleRemoveDynamicWorkTypeRate}
                onCancel={hideRemoveDynamicWorkTypeRateDialog}
                alertType="warning"
                icon="remove_circle"
                disabled={false}
                isLoading={isLoadingRemoveDynamicWorkTypeRate}
            />
        </> : <DynamicWorkTypeForm setTab={(val) => setTab(val)} />
    )
}
