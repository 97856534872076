import { gql } from "@apollo/client";

export default gql`
  query getAllDocumentMaster(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $status: DocumentStatus
    $like: [DocumentFilterInput!]
    $order: [DocumentOrderInput!]
  ) {
    getAllDocumentMaster(
      first: $first
      last: $last
      after: $after
      before: $before
      status: $status
      like: $like
      order: $order
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
                id
                signedDocumentUrl
                documentTypeId
                workerId
                worker {
                    firstName
                    lastName
                    email
                }
                documentType {
                    name
                }
                documentName
                documentURL
                status
                approvedBy
                rejectedBy
                rejectReason
                addedBy
                updatedBy
                deletedBy
                deletedAt
                createdAt
                updatedAt
                signedDocumentMasterImageUrl
            }
        }
      }
  }
`;
