import gql from "graphql-tag";

export const documentTypeQuery = gql`
query{
    getAllDocumentType{
    id
    name
    slug
    helpText
    }
  }
`;
