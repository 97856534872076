import React, { useState } from "react";
import { Typography, Button as Button1 } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Button from "components/Button/Button";
import Dialog from "components/Dialog/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { actions as JobActions, selectors as JobSelectors } from "store/modules/jobs";
import CSVErrorTable from './tables/CSVErrorTable'


const useStyles = makeStyles(({ spacing }) => ({
    input: {
        display: "none",
    },
    container: {
        textAlign: "left",
    },
    uploadSection: {
        display: "flex",
        justifyContent: "space-between",
    },
    uploadButton: {
        paddingLeft: "0",
        width: 'fit-content'
    },
}));

function UploadPrivateJobCSV({ open, onCancel, onClose }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const aTag = React.useRef();
    const [csvURL, setCsvURL] = React.useState("");
    const [fileName, setFileName] = useState("");
    const [fileObject, setFileObject] = useState("");
    const fileError = useSelector(JobSelectors.getImportCSVError)
    const isLoading = useSelector(JobSelectors.getImportPrivateJobLoading)

    const handleFileChange = e => {
        if (e && e.target && e.target.files && e.target.files.length > 0) {
            setFileObject(e.target.files[0])
            setFileName(e.target.files[0].name);
        } else {
            setFileName("");
        }
    };

    const uploadFile = () => {
        if (fileObject) {
            dispatch(JobActions.importPrivateJobCSV(fileObject))
        }
    }

    const closeDialog = () => {
        setFileName('');
        setFileObject('');
        dispatch(JobActions.setImportCSVErrorData([]))
        onCancel()
    }

    const downloadSampleCSV = () => {
        dispatch(JobActions.downloadPrivateJobSampleCSV()).then(data => {
            if (data) {
                setCsvURL(data);
                aTag.current.click();
            }
        })
    }

    return (
        <Dialog
            title={
                <React.Fragment>
                    <Typography component="span" style={{ width: "100%", fontSize: 18 }}>
                        {`Upload Private job CSV`}
                        <Button
                            style={{ float: "right", marginRight: -30 }}
                            label="X"
                            size="small"
                            onClick={closeDialog}
                            color="transparent"
                        />
                    </Typography>
                </React.Fragment>
            }
            open={open}
            description={null}
            onClose={closeDialog}
            onCancel={closeDialog}
            size="lg"
            hideActions
            alertType="form"
            fullWidth
            modalType="editEmployer">
            <React.Fragment>
                <div className={classes.container}>
                    <div className={classes.uploadSection}>
                        <label htmlFor="contained-button-file">
                            <input
                                accept="text/csv"
                                id="contained-button-file"
                                onChange={handleFileChange}
                                className={classes.input}
                                type="file"
                            />
                            <Button1 variant="contained" component="span" color="primary">
                                Select Job CSV
                            </Button1>
                            &nbsp;
                            {fileName}
                        </label>
                        <Button
                            label="Download Sample CSV"
                            className={classes.uploadButton}
                            onClick={downloadSampleCSV}
                            color="transparent"
                        />
                    </div>
                    <br />
                    <br />
                    {fileName && (
                        <Button
                            label="Upload"
                            isLoading={isLoading}
                            className={classes.uploadButton}
                            onClick={uploadFile}
                        />
                    )}
                </div>
            </React.Fragment>
            {fileError && fileError.length > 0 && (<CSVErrorTable data={fileError} />)}
            <a ref={aTag} href={csvURL} target="_blank" rel="noreferrer" />
        </Dialog>
    );
}

export default UploadPrivateJobCSV;
