import React from "react";
import { Checkbox } from "@mui/material";
import CheckboxColumn from "./CheckboxColumn";
import { Link } from "react-router-dom";

export default (customFieldId) => [
    {
        Header: "",
        disableSortBy: true,
        disableFilters: true,
        accessor: "id",
        width: 10,
        collapse:true,
        Cell: (p) => <CheckboxColumn {...p} assignTo="EMPLOYER" customFieldId={customFieldId} />
    },
    {
        Header: "Company",
        accessor: "companyName",
        isNotClickable: true,
        Cell: ({ row: { original }, value }) => {
            return <Link to={`/employers/${original.id}`}>{value || "-"}</Link>;
        },
    },
    {
        Header: "First Name",
        accessor: "firstName",
    },
    {
        Header: "Last Name",
        accessor: "lastName",
    },
    {
        Header: "Email",
        accessor: "email",
    },
];
