import { Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CustomFieldTypes, CustomFieldTypesObject } from 'utils/constant'
import FormRow from './FormRow'
import { actions as CustomFieldActions, selectors as CustomFieldSelectors } from "store/modules/customFields";
import { Add, Close } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    option: {
        display: "flex",
        alignItems: "flex-start",
        gap: 5
    },
    textbox: {
        "& .MuiInputBase-root": {
            paddingRight: 0
        }
    },
    addTitleContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: theme.spacing(1)
    }
}));

export default function CustomFieldTypeSelection() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const type = useSelector((state) => CustomFieldSelectors.getFormData(state, "type"));
    const options = useSelector((state) => CustomFieldSelectors.getFormData(state, "options"));

    const handleChangeType = ({ target: { value } }) => {
        dispatch(CustomFieldActions.setCustomFieldType(value));
        dispatch(CustomFieldActions.clearAllOptions());
    };

    const handleAddNewOption = () => {
        dispatch(CustomFieldActions.addNewOption());
    };

    const handleRemoveOption = (i) => {
        dispatch(CustomFieldActions.removeOption(i));
    };

    const handleOptionValueChange = ({ target: { value, name } }, i) => {
        dispatch(CustomFieldActions.setOptionValue(i, name, value));
    };

    return (
        <>
            <FormRow style={{ marginTop: 24 }}>
                <FormControl fullWidth>
                    <InputLabel id="custom-field-type">Type</InputLabel>
                    <Select
                        name="type"
                        labelId="custom-field-type"
                        fullWidth={true}
                        value={type || " "}
                        margin="normal"
                        label="Type"
                        onChange={handleChangeType}
                    >
                        <MenuItem value=" ">
                            Select type...
                        </MenuItem>
                        {CustomFieldTypes?.map(op => (<MenuItem key={op.value} value={op.value}>{op.label}</MenuItem>))}
                    </Select>
                </FormControl>
            </FormRow>
            {type && type !== " " && type !== CustomFieldTypesObject.TEXTBOX && type !== CustomFieldTypesObject.FILE && (
                <FormRow style={{ marginTop: 24 }}>
                    <div>
                        <div className={classes.addTitleContainer}>
                            <Typography>Options</Typography>
                            <Button
                                size="small"
                                onClick={handleAddNewOption}
                                variant="outlined"
                                startIcon={<Add />}
                                style={{ width: "fit-content" }}>
                                ADD NEW OPTION
                            </Button>
                        </div>
                        {options?.map((op, i) => (
                            <div key={i} className={classes.option}>
                                <TextField
                                    className={classes.textbox}
                                    value={op.label}
                                    onChange={(e) => handleOptionValueChange(e, i)}
                                    fullWidth={true}
                                    margin="normal"
                                    label={"Option label"}
                                    name="label"
                                    placeholder="Enter option label"
                                    error={op?.label?.length>50}
                                    helperText={op?.label?.length>50?"Lable must be less 50 characters":""}
                                />
                                <TextField
                                    className={classes.textbox}
                                    value={op.value}
                                    onChange={(e) => handleOptionValueChange(e, i)}
                                    fullWidth={true}
                                    name="value"
                                    margin="normal"
                                    label={"Option value"}
                                    placeholder="Enter option value"
                                    error={op?.value?.length>50}
                                    helperText={op?.value?.length>50?"Value must be less 50 characters":""}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton color="error" onClick={() => handleRemoveOption(i)}>
                                                    <Close />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </FormRow>
            )}
        </>
    )
}
