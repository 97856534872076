import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as ReportsActions, selectors as ReportsSelectors } from "store/modules/reports";
import { convertUTCToTimezoneWithOffset } from "../../../../utils/time";
import { sharedReactTableProps } from "../../../../utils/constant";
import { formatMoney } from "utils/stringUtils";
import ReactTableCustom from "components/ReactTable";
import { useStyles } from "views/Reports/styles";
import classNames from "classnames";
import moment from "moment";

function WorkOrderTable() {
    const dispatch = useDispatch();
    const employerData = useSelector(ReportsSelectors.getWorkOrderReportData);
    const PaginationData = useSelector(ReportsSelectors.getWorkOrderReportPagingData);
    const fetchData = useCallback(({ pageIndex, pageSize }) => {
        dispatch(ReportsActions.changeWorkOrderPageSize(pageSize));
        dispatch(ReportsActions.fetchWorkOrderTableData(pageIndex));
    }, []);
    const classes=useStyles();

    const columns = [
        {
            Header: "Worker Id",
            disableSortBy: true,
            disableFilters: true,
            accessor: "WorkerID",
        },
        {
            Header: "First Name",
            disableSortBy: true,
            disableFilters: true,
            accessor: "FirstName",
        },
        {
            Header: "Last Name",
            disableSortBy: true,
            disableFilters: true,
            accessor: "LastName",
        },
        {
            Header: "Street",
            disableSortBy: true,
            disableFilters: true,
            accessor: "Street",
        },
        {
            Header: "City",
            disableSortBy: true,
            disableFilters: true,
            accessor: "City",
        },
        {
            Header: "Province",
            disableSortBy: true,
            disableFilters: true,
            accessor: "Region",
        },
        {
            Header: "Postal Code",
            disableSortBy: true,
            disableFilters: true,
            accessor: "PostalCode",
        },
        {
            Header: "Country",
            disableSortBy: true,
            disableFilters: true,
            accessor: "Country",
        },
        {
            Header: "Date of Birth",
            disableSortBy: true,
            disableFilters: true,
            Cell: ({ row: { original } }) => moment(original.BirthDate).format("DD/MM/YYYY"),
            accessor: "BirthDate",
        },
        {
            Header: "SIN (Tax ID)",
            disableSortBy: true,
            disableFilters: true,
            accessor: "SIN",
        },
        {
            Header: "Job Start Date",
            disableSortBy: true,
            disableFilters: true,
            accessor: "JobStartDateUtc",
            Cell: ({ row: { original } }) => convertUTCToTimezoneWithOffset(original.JobStartDateUtc, '', 'DD MMM YYYY h:mm a'),
        },
        {
            Header: "Job End Date",
            disableSortBy: true,
            disableFilters: true,
            accessor: "JobEndDateUtc",
            Cell: ({ row: { original } }) => convertUTCToTimezoneWithOffset(original.JobEndDateUtc, '', "DD MMM YYYY h:mm a"),
        },
        {
            Header: "Province of Work",
            disableSortBy: true,
            disableFilters: true,
            accessor: "JobRegion",
        },
        {
            Header: "Pay Rate",
            disableSortBy: true,
            disableFilters: true,
            accessor: "PaymentRate",
            Cell: ({ row: { original } }) => formatMoney(original.PaymentRate),
        },
        {
            Header: "Bill Rate",
            disableSortBy: true,
            disableFilters: true,
            accessor: "BillingRate",
            Cell: ({ row: { original } }) => formatMoney(original.BillingRate),
        },
        {
            Header: "Company Name",
            disableSortBy: true,
            disableFilters: true,
            accessor: "CompanyName",
        },
    ];
    return (
        <React.Fragment>
            <ReactTableCustom
                {...sharedReactTableProps}
                fetchData={fetchData}
                data={employerData}
                hasFilter={false}
                loading={PaginationData.paging.isLoading}
                pageCount={PaginationData.paging.totalCount}
                currentPage={PaginationData.paging.currentPage}
                classes={classNames(sharedReactTableProps.classes,classes.table)}
                defaultPageSize={PaginationData.paging.pageSize}
                columns={columns}
            />
        </React.Fragment>
    );
}

export default WorkOrderTable;
