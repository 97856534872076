import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVert from "@mui/icons-material/MoreVert";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actions as CustomFieldActions } from "store/modules/customFields";

const useStyles = makeStyles(theme => ({
    button: {
        color: theme.palette.grey[600],
        "&:hover": {
            backgroundColor: "transparent",
            color: theme.palette.grey[900],
        },
    },
}));

const ActionColumn = ({row:{original}}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleDelete = () => {
        dispatch(CustomFieldActions.setIsOpenRemoveUserCustomField(original));
        handleClose();
    };

    const id = `action-menu-${original?.id}`;

    return (
        <React.Fragment>
            <IconButton
                classes={{ root: classes.button }}
                aria-owns={anchorEl ? id : undefined}
                aria-haspopup="true"
                disableRipple
                onClick={handleClick}
                size="small">
                <MoreVert />
            </IconButton>
            {anchorEl ? (
                <Menu
                    id={id}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    disableAutoFocusItem>
                    <MenuItem classes={{ root: classes.listItem }} onClick={handleDelete}>
                        Remove
                    </MenuItem>
                </Menu>
            ) : null}
        </React.Fragment>
    )
}

export default ActionColumn;
