import * as pagination from "utils/graphql-pagination";
import { createReducer } from "utils/redux";
import { paginationSettings } from "./constants";
import types from "./types";

const initialLoadingState = {
    getListData: false,
    documentStatusUpdate: false
};

const initialDialogState = {
    rejectReason: false,
};

const initialListState = {
    sort: [{ id: "id", desc: true }],
    filter: [],
    data: [],
};

const initialState = {
    loading: { ...initialLoadingState },
    dialog: { ...initialDialogState },
    pending: { ...initialListState },
    approved: { ...initialListState },
    rejected: { ...initialListState },
    rejectReason: "",
    selectedDocument: null,
    ...pagination.initialState(paginationSettings),
};

const actionsMap = {
    [types.SET_LOADING_STATE]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            loading: {
                ...state.loading,
                [key]: value,
            },
        };
    },
    [types.SET_DIALOG_STATE]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            dialog: {
                ...state.dialog,
                [key]: value,
            },
        };
    },
    [types.SET_REJECT_REASON_STATE]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            [key]: value,
        };
    },
    [types.SET_SELECTED_DOCUMENT_STATE]: (state, { payload }) => {
        const { value } = payload;
        return {
            ...state,
            selectedDocument: value,
        };
    },
    [types.SET_LIST_DATA]: (state, { payload }) => {
        const { data, statusframe, part } = payload;
        if (!statusframe) {
            return state;
        }

        if (state[statusframe].data) {
            return {
                ...state,
                [statusframe]: {
                    ...state[statusframe],
                    [part]: data,
                },
            };
        }

        return state;
    },
    ...pagination.createPaginationActions(paginationSettings),
};

export default createReducer(initialState, actionsMap);
