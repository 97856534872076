export default {
  SET_IS_LOADING: "posting/SET_IS_LOADING",
  SET_EMPLOYER: "posting/SET_EMPLOYER",
  SET_JOB_TYPE_LIST: "posting/SET_JOB_TYPE_LIST",
  SET_DATE: "posting/SET_DATE",
  SET_BREAK_TYPE: "posting/SET_BREAK_TYPE",
  SET_BREAK_MINS: "posting/SET_BREAK_MINS",
  SET_START_TIME: "posting/SET_START_TIME",
  SET_END_TIME: "posting/SET_END_TIME",
  SET_WORKERS_NEEDED: "posting/SET_WORKERS_NEEDED",
  SET_JOB_TYPE: "posting/SET_JOB_TYPE",
  SET_LOCATION: "posting/SET_LOCATION",
  SET_DESCRIPTION: "posting/SET_DESCRIPTION",
  SET_JOB_IS_FIXED_TERM: "posting/SET_JOB_IS_FIXED_TERM",
  SET_JOB_IS_RECURRING: "posting/SET_JOB_IS_RECURRING",
  SET_JOB_RECURRING_TYPE: "posting/SET_JOB_RECURRING_TYPE",
  SET_JOB_RECURRING_REPEAT: "posting/SET_JOB_RECURRING_REPEAT",
  SET_JOB_RECURRING_END_DATE: "posting/SET_JOB_RECURRING_END_DATE",
  SET_JOB_RECURRING_WEEK_DAYS: "posting/SET_JOB_RECURRING_WEEK_DAYS",
  SET_JOB_RECURRING_MONTH_DATE: "posting/SET_JOB_RECURRING_MONTH_DATE",
  SET_UNIT: "posting/SET_UNIT",
  SET_FLOOR: "posting/SET_FLOOR",
  SET_LOCATION_INSTRUCTION:"posting/SET_LOCATION_INSTRUCTION",
  SET_SKILLS : "posting/SET_SKILLS",
  SET_JOB_ANNOUNCEMENT: "posting/SET_JOB_ANNOUNCEMENT",
  SET_MANAGER_LIST: "posting/SET_MANAGER_LIST",
  SET_SITE_MANAGER: "posting/SET_SITE_MANAGER",
  SET_CHECK_IN_MANAGER: "posting/SET_CHECK_IN_MANAGER",
  SET_CHECK_OUT_MANAGER: "posting/SET_CHECK_OUT_MANAGER",
  RESET: "posting/RESET",
  SET_EDIT: "posting/SET_EDIT",
  SET_JOB_IS_PRIVATE: "posting/SET_JOB_IS_PRIVATE",
  SET_JOB_TAGS: "posting/SET_JOB_TAGS",
  SET_GEARS : "posting/SET_GEARS",
  SET_SELECTED_DOCUMENTS_IDS : "posting/SET_SELECTED_DOCUMENTS_IDS",
  SET_TIMEZONE : "posting/SET_TIMEZONE",
  SET_CALCULATED_JOB_AMOUNT: "posting/SET_CALCULATED_JOB_AMOUNT"
};
