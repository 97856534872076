import React from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";

const useStyles = makeStyles(({ spacing }) => ({
  filledBreakdown: {
    marginRight: spacing(1),
  },
}));

function PositionStatus() {
  const classes = useStyles();
  const positionsFilled = useSelector(JobDetailSelectors.getMultiShiftPositionsFilled);
  const peopleNeeded = useSelector(JobDetailSelectors.getPeopleNeeded);
  const isSearching = useSelector(JobDetailSelectors.getIsSearchingForWorkers);

  return (
    <Grid container item xs={6} direction="row" alignItems="center">
      <Typography className={classes.filledBreakdown} variant="subtitle2">
        {`Workers (${positionsFilled}/${peopleNeeded})`}
      </Typography>
      {isSearching && (
        <Typography variant="body1" color="textSecondary">
          {"Searching..."}
        </Typography>
      )}
    </Grid>
  );
}

export default PositionStatus;
