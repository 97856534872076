import { UploadFile } from '@mui/icons-material';
import { Autocomplete, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { connect, useSelector } from 'react-redux'
import {
    actions as JobDetailActions,
    selectors as JobDetailSelectors,
} from "store/modules/jobDetails";
import {
    actions as WorkerDetailActions,
} from "store/modules/workerDetails";


const useStyles = makeStyles(theme => ({
    loader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(3),
        flex: 1
    }
}));

function DocumentsSelection({ handleClose, onApplyJob, ...props }) {
    const classes = useStyles();
    const requiredDocumentTypes = useSelector(JobDetailSelectors.getSelectedJobDocumentTypes);
    const isAdding = useSelector(JobDetailSelectors.getIsAddingWorker);
    const [isLoading, setIsLoading] = useState(false);
    const [documents, setDocuments] = useState([])
    const [selectedDocuments, setSelectedDocuments] = useState({});

    useEffect(() => {
        setIsLoading(true)
        props.getWorkerDocuments(props.workerId).then(res => {
            setIsLoading(false);
            setDocuments(res.map(a => ({ id: a.id, label: a.documentName || "" })));
        }).catch(() => {
            setIsLoading(false);
            setDocuments([]);
        });
    }, []);

    const handleChange = (documentTypeId, e, value) => {
        const data = { ...selectedDocuments, [documentTypeId]: value };
        setSelectedDocuments(Object.keys(data).reduce((p, key) => (data[key] ? { ...p, [key]: data[key] } : { ...p }), {}));
    };

    const handleApply = () => {
        onApplyJob(Object.keys(selectedDocuments).map(key => ({ documentTypeId: +key, id: +selectedDocuments[key].id })));
        handleClose();
    };

    return (
        <Grid container maxWidth={450} marginRight={0}>
            {isLoading ?
                <div className={classes.loader}>
                    <CircularProgress size={22} />
                </div> :
                requiredDocumentTypes?.map(({ documentType }) => (
                    <Grid key={documentType.id} item xs={12} textAlign={"left"} marginTop={4.5}>
                        <Grid item xs={12}>
                            <Autocomplete
                                size="small"
                                fullWidth
                                disablePortal
                                value={selectedDocuments[documentType.id] || null}
                                id={documentType.id}
                                options={documents}
                                onChange={(...rest) => handleChange(documentType.id, ...rest)}
                                renderInput={(params) => <TextField {...params} label={<Typography>Select Document for {documentType?.name}</Typography>} placeholder="Document" />}
                            />
                        </Grid>
                    </Grid>
                ))}
            {!isLoading && <Grid item xs={12} marginTop={4} display="flex">
                <Button
                    style={{ marginRight: "auto" }}
                    color="secondary"
                    variant='contained'
                    size="small"
                    onClick={() => {
                        props.setShowUploadWorkerDocument(true);
                        handleClose();
                    }}
                    disabled={isAdding}
                    startIcon={<UploadFile />}>Upload Document</Button>
                <Button
                    color="error"
                    onClick={handleClose}
                    style={{ marginRight: 8 }}
                    size="small" >Cancel</Button>
                <Button
                    disabled={isAdding || Object.keys(selectedDocuments)?.length !== requiredDocumentTypes?.length}
                    color="secondary"
                    onClick={handleApply}
                    variant='contained'
                    {...(isAdding ? { startIcon: <CircularProgress size={14} /> } : {})}
                    size="small">Apply</Button>
            </Grid>}
        </Grid>
    )
}
const mapActions = {
    getWorkerDocuments: JobDetailActions.getWorkerDocuments,
    setShowUploadWorkerDocument: WorkerDetailActions.setShowUploadWorkerDocument,
}
export default connect(null, mapActions)(DocumentsSelection);
