import { Button, CircularProgress, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import MainContent from 'components/Containers/MainContent'
import Dialog from 'components/Dialog/Dialog'
import moment from 'moment'
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import CustomFieldDetailHeader from './components/CustomFieldDetailHeader';
import { actions as CustomFieldActions, selectors as CustomFieldSelectors } from "store/modules/customFields";
import { useHistory, useParams } from 'react-router-dom'
import { CustomFieldTypesObject } from 'utils/constant'
import EmployerList from './components/EmployerList'
import EmployerPicker from '../CustomFieldOverview/components/EmployerPicker'
import WorkerList from './components/WorkerList'
import WorkerPicker from '../CustomFieldOverview/components/WorkerPicker'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: `${theme.palette.common.white}`,
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: theme.shape.borderRadius / 2,
        padding: `${theme.spacing(3)} !important`,
        marginTop: theme.spacing(2),
    },
    container: {
        // borderRight: `1px solid ${theme.palette.grey[500]}`,
        // paddingRight: theme.spacing(3),
        // [theme.breakpoints.down("md")]: {
        //     borderRight: "none",
        //     paddingRight: 0,
        //     borderBottom: `1px solid ${theme.palette.grey[500]}`,
        //     paddingBottom: theme.spacing(3),
        // },
    },
    provienceContainer: {
        paddingLeft: theme.spacing(3),
        [theme.breakpoints.down("md")]: {
            paddingLeft: 0,
            paddingTop: theme.spacing(3)
        },
    },
    list: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap"
    },
    listItem: {
        display: "flex",
        alignItems: "center",
        margin: theme.spacing(0, 1)
    },
    svgRoot: {
        marginTop: `0 !important`,
        margin: `0 !important`
    },
}));

export default function CustomFieldDetail() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const deleteConfirmation = useSelector(CustomFieldSelectors.getIsOpenDeleteConfirmationCustomField);
    const removeUser = useSelector(CustomFieldSelectors.getIsOpenRemoveUserCustomField);
    const isDeleting = useSelector(CustomFieldSelectors.getIsDeletingCustomField);
    const isRemoving = useSelector(CustomFieldSelectors.getIsRemoveUserLoading);
    const isAdding = useSelector(CustomFieldSelectors.getIsAddUserLoading);
    const isLoading = useSelector(CustomFieldSelectors.getIsDetailPageLoading);
    const customField = useSelector(CustomFieldSelectors.getCustomFieldDetail);
    const history = useHistory();
    const params = useParams();
    const isOpenPicker = useSelector(CustomFieldSelectors.getIsOpenAddUserCustomField);
    const scrollEnd = useRef();
    const selectedEmployers = useSelector((state) => CustomFieldSelectors.getFormData(state, "employers"));
    const activeTab = useSelector(CustomFieldSelectors.getActiveTab)
    const selectedWorkers = useSelector((state) => CustomFieldSelectors.getFormData(state, "workers"));

    useEffect(() => {
        dispatch(CustomFieldActions.getCustomFieldDetail(+params.id));
        return () => {
            handleCloseAddPicker();
        };
    }, []);

    useEffect(() => {
        isOpenPicker && scrollToEnd();
    }, [isOpenPicker]);


    const handleCloseDeleteDialog = () => {
        dispatch(CustomFieldActions.setIsOpenDeleteConfirmationCustomField(false));
    };

    const handleCloseRemoveDialog = () => {
        dispatch(CustomFieldActions.setIsOpenRemoveUserCustomField(false));
    };

    const handleConfirmDeleteDialog = () => {
        dispatch(CustomFieldActions.deleteCustomField(deleteCallback));
    };

    const handleConfirmRemoveDialog = () => {
        dispatch(CustomFieldActions.removeUser());
    };

    const handleConfirmAddPicker = () => {
        dispatch(CustomFieldActions.addUser());
    };

    const handleCloseAddPicker = () => {
        dispatch(CustomFieldActions.setIsOpenAddUserCustomField(false));
    };

    const deleteCallback = (data) => {
        history.replace(`/custom-field/overview?type=${activeTab === null ? 0 : activeTab}`);
    };

    const scrollToEnd = () => scrollEnd?.current?.scrollIntoView({ behavior: "smooth" });

    return (
        <React.Fragment>
            <MainContent isLoading={isLoading} noTopPadding>
                <CustomFieldDetailHeader />

                <Grid container className={classes.root}>
                    <Grid container item xs={12} md={12} className={classes.container}>
                        <Grid container item xs={12} direction="column" justifyContent="flex-start">
                            <Typography variant="h4">{customField?.name}</Typography>
                            <Typography variant="caption" gutterBottom marginTop={1}>
                                {customField?.slug}
                            </Typography>
                            <Typography variant="caption" gutterBottom textTransform={"capitalize"}>
                                {customField?.type}
                            </Typography>

                            {(customField?.type !== CustomFieldTypesObject.TEXTBOX && customField?.type !== CustomFieldTypesObject.FILE) && <>
                                <Typography variant="subtitle2" marginTop={2}>Options</Typography>
                                <div className={classes.list}>
                                    {customField?.getCustomFieldsOptions && customField?.getCustomFieldsOptions?.map((p, index) => (
                                        <div key={index} className={classes.listItem}>
                                            <Typography variant="body1" color="textSecondary" marginRight={0.4}>
                                                &#8226;
                                            </Typography>
                                            {p.label}
                                        </div>
                                    ))}
                                </div>
                            </>}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item xs={12} direction="column" justifyContent="flex-start" marginTop={3}>
                    {customField?.assignTo === "WORKER" ? <WorkerList /> : customField?.assignTo === "EMPLOYER" ? <EmployerList /> : null}
                </Grid>

                {isOpenPicker && <Grid container item xs={12} direction="column" justifyContent="flex-start" marginTop={3}>
                    <Grid item xs={12} marginBottom={1} display="flex" justifyContent="flex-end" alignItems={"flex-end"}>
                        <Button
                            color="secondary"
                            variant="contained"
                            size="small"
                            style={{ marginRight: 8 }}
                            onClick={handleCloseAddPicker}
                        >
                            Close
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            disabled={isAdding || (customField?.assignTo === "WORKER" ? (!selectedWorkers || !selectedWorkers.length) : (!selectedEmployers || !selectedEmployers?.length))}
                            size="small"
                            onClick={handleConfirmAddPicker}
                        >
                            {isAdding ?
                                <CircularProgress classes={{ svg: classes.svgRoot }} size={18} />
                                : "Save"}
                        </Button>
                    </Grid>
                    {customField?.assignTo === "WORKER" ? <WorkerPicker customFieldId={customField?.id} isShowWorkerPicker={isOpenPicker} /> : <EmployerPicker customFieldId={customField?.id} hideIsAll isShowEmployerPicker={isOpenPicker} />}
                </Grid>}
                <div className={classes.scrollEnd} ref={scrollEnd} />
            </MainContent>
            <Dialog
                open={Boolean(deleteConfirmation)}
                title={`Delete Custom Field`}
                description={`Are you sure to delete ${customField?.name || ""} custom field?`}
                confirmText={isDeleting ? <CircularProgress classes={{ svg: classes.svgRoot }} size={18} /> : "Delete"}
                disabled={isDeleting}
                onClose={handleCloseDeleteDialog}
                onConfirm={handleConfirmDeleteDialog}
                onCancel={handleCloseDeleteDialog}
                icon="delete"
                alertType="question" />
            <Dialog
                open={Boolean(removeUser)}
                title={`Remove ${customField?.assignTo === "WORKER" ? "Worker" : "Employer"}`}
                description={`Are you sure to remove ${removeUser?.firstName || ""} from ${customField?.name || ""} field?`}
                confirmText={isRemoving ? <CircularProgress classes={{ svg: classes.svgRoot }} size={18} /> : "Delete"}
                disabled={isRemoving}
                onClose={handleCloseRemoveDialog}
                onConfirm={handleConfirmRemoveDialog}
                onCancel={handleCloseRemoveDialog}
                icon="delete"
                alertType="question" />
        </React.Fragment >
    )
}
