import { gql } from "@apollo/client";

export default gql`
  query employers(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $order: [OrderInput!]
    $like: [FilterInput!]
    $canPost: Boolean
  ) {
    employers(
      first: $first
      last: $last
      after: $after
      before: $before
      order: $order
      like: $like
      canPost: $canPost
    ) {
      edges {
        node {
          id
          companyName
          street
          city
          region
          country
          firstName
          lastName
          email
          logoImageUrl
          signedLogoImageUrl
          mobilePhone
          billingRate
          payRate
          canPostPrivateJob
          employerDocumentTypes {
            id
            grizzlyEmployerId
            documentTypeId
            createdAt
            updatedAt
            documentType {
              id
              name
              slug
              helpText
              createdBy
              updatedBy
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;

export const workerListQuery = gql`
  query getWorkers(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $jobTags: [String!]
    $like: [WorkerFilterInput!]
    $order: [WorkerOrderInput!]
    $workerName: String
  ) {
    workers(
      first: $first
      last: $last
      after: $after
      before: $before
      jobTags: $jobTags
      like: $like
      order: $order
      workerName: $workerName
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          firstName
          lastName
          email
          fullName
          phone
          street
          city
          region
          postalCode
        }
      }
    }
  }
`;

export const documentTypesQuery = gql`
query{
    getAllDocumentType{
    id
    name
    slug
    helpText
  }
}
`;

export const ProvincesQuery = gql`
query Regions {
    regions {
      id
      name
      code
      isActive
      countryId
    }
  }
`;
