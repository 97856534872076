import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions as EmployerActions, selectors as EmployerSelectors } from 'store/modules/employerDetails'
import { CircularProgress, DialogActions, Grid, InputAdornment, MenuItem, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Button from 'components/Button/Button';
import { useEffect } from 'react';
import { fetchWorkTypes } from 'store/modules/workTypes/actions';
import { selectors as workTypesSelectors } from 'store/modules/workTypes';
import { Form, Formik } from 'formik';
import * as yup from "yup";
import _ from "lodash";

const isMobile = false;
const controlPadding = {
    leftColumn: { paddingRight: isMobile ? 0 : 4 },
    rightColumn: { paddingLeft: isMobile ? 0 : 4 },
};

const useStyles = makeStyles(theme => ({
    weekDays: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    days: {
        padding: '14px 0',
        border: '1px solid',
        borderRadius: '100px',
        width: '50px',
        height: '50px',
        textAlign: 'center',
        marginRight: '10px',
        fontWeight: '400',
        borderColor: '#b2b2c4',
        color: '#848499',
        cursor: 'pointer',
        marginBottom: '10px'
    },
    selectedDays: {
        borderColor: '#00c8a9 !important',
        color: '#00c8a9 !important',
        background: '#FFF',
        fontWeight: '400'
    },
    disabledDay: {
        cursor: "not-allowed",
        pointerEvents: "none",
        touchAction: "none",
        color: "#84849985"
    },
    card: {
        boxShadow: "none",
        marginBottom: `${theme.spacing(1)} !important`,
    },
    jobTypeCard: {
        display: "flex",
        flexWrap: "wrap",
        gap: theme.spacing(3),
        "& $card": {
            minWidth: 320,
            width: "calc(50% - 24px)",
            flex: 1,
            cursor: "pointer",
            background: "transparent",
            transition: "1s all",
            border: "1px solid rgba(0, 0, 0, 0.23)",
        },
        "& .MuiFormControlLabel-label": {
            color: theme.palette.text.primary,
        },
        "& .MuiCheckbox-root": {
            paddingTop: 0,
            paddingBottom: 0,
        },
        "& .desc": {
            fontSize: 10,
            marginLeft: theme.spacing(3.6)
        }
    },
    footer: {
        marginTop: theme.spacing(2),
        paddingRight: theme.spacing(0),
    }
}));

export default function DynamicWorkTypeForm({ setTab, ...props }) {
    const classes = useStyles();
    const open = useSelector(EmployerSelectors.GetNewDynamicWorkTypeRateDialog);
    const formData = useSelector(EmployerSelectors.getDynamicWorkTypeRateForm);
    const canPost = useSelector(EmployerSelectors.canAddUpdateDynamicWorkTypeRate);
    const isLoading = useSelector(EmployerSelectors.getIsAddDynamicWorkTypeRateListLoading);
    const dispatch = useDispatch();
    const DynamicWorkTypeRate = useSelector(EmployerSelectors.getSelectedDynamicWorkTypeRate);
    const workTypes = useSelector(workTypesSelectors.getWorkTypesList);
    const isLoadingWorkTypes = useSelector(workTypesSelectors.getIsTableLoading);

    useEffect(() => {
        dispatch(fetchWorkTypes());
        return () => {
            dispatch(EmployerActions.clearDynamicWorkTypeRateForm());
        };
    }, []);

    const onCancel = () => {
        setTab(0);
        dispatch(EmployerActions.clearDynamicWorkTypeRateForm());
    };

    // const handleChange = (value, key) => {
    //     dispatch(EmployerActions.setDynamicWorkTypeRateForm({ key, value }));
    // };

    const onConfirm = async (values) => {
        const { workType: workTypeId, ...rest } = values
        dispatch(EmployerActions.addDynamicWorkTypeRate({ ...rest, workTypeId })).then((e) => {
            if (!e)
                setTab(0)
        });
    };

    return (
        <Formik
            initialValues={{
                id: formData.id,
                workType: formData?.workType?.id,
                payRate: formData.payRate,
                billingRate: formData.billingRate,
            }}
            onSubmit={(values, actions) => {
                onConfirm(values);
                actions.setSubmitting(false);
            }}
            onReset={() => {
                onCancel();
            }}
            validationSchema={yup.object().shape({
                workType: yup.number().required("Work Type is required"),
                payRate: yup.number().min(0.01, "Pay rate is invalid").required("Pay rate is required"),
                billingRate: yup.number().test("billingRate", "Billing rate is invalid", function (billingRate) {
                    return billingRate > this.parent.payRate;
                }).required("Billing rate is required"),
            })}>
            {props => {
                const {
                    values,
                    touched,
                    errors,
                    dirty,
                    handleChange,
                    handleBlur,
                    handleReset,
                    handleSubmit,
                } = props;
                return (
                    <Form style={{ width: "100%" }}>
                        <div>
                            <div style={{ marginTop: 24 }}>
                                <Grid container textAlign="left" maxWidth={"100%"}>
                                    <Grid item md={12} xs={12} sm={12} style={{ marginTop: "8px" }}>
                                        <TextField
                                            select
                                            fullWidth
                                            label={"Select Work Type"}
                                            placeholder={"Select Work Type"}
                                            name="workType"
                                            value={values.workType}
                                            margin="none"
                                            disabled={Boolean(values.id)}
                                            InputProps={{
                                                endAdornment: isLoadingWorkTypes ? (
                                                    <InputAdornment position="end" style={{ marginRight: 16 }}>
                                                        <CircularProgress size={12} />
                                                    </InputAdornment>
                                                ) : null,
                                            }}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.workType && touched.workType}
                                            helperText={errors.workType && touched.workType && errors.workType}
                                        >
                                            {workTypes?.map(workType => (
                                                <MenuItem key={workType.id} value={workType.id}>{workType.label}</MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6} style={{ ...controlPadding.leftColumn, paddingTop: 8 }}>
                                        <TextField
                                            className={classes.textField}
                                            name="payRate"
                                            label="Pay Rate"
                                            fullWidth
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.payRate && touched.payRate}
                                            helperText={errors.payRate && touched.payRate && errors.payRate}
                                            margin="normal"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            inputProps={{
                                                step: 0.05,
                                                type: "number",
                                            }}
                                            value={values.payRate}
                                        />
                                    </Grid>
                                    <Grid item xs={6} style={{ ...controlPadding.rightColumn, paddingTop: 8 }}>
                                        <TextField
                                            className={classes.textField}
                                            name="billingRate"
                                            label="Billing Rate"
                                            fullWidth
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.billingRate && touched.billingRate}
                                            helperText={
                                                errors.billingRate && touched.billingRate && errors.billingRate
                                            }
                                            margin="normal"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            inputProps={{
                                                step: 0.05,
                                                type: "number",
                                                min: Number(values.payRate) + 1
                                            }}
                                            value={values.billingRate}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                            <DialogActions className={classes.footer}>
                                <Button
                                    label={"Cancel"}
                                    onClick={handleReset}
                                    disabled={isLoading}
                                    color="transparent" />
                                <Button
                                    type="submit"
                                    onClick={handleSubmit}
                                    label={DynamicWorkTypeRate ? "Update" : "Add"}
                                    isLoading={isLoading}
                                    color="secondary"
                                    style={{ paddingRight: 0 }}
                                    disabled={isLoading || !_.isEmpty(errors) || !dirty}
                                />
                            </DialogActions>
                        </div >
                    </Form >
                )
            }}
        </Formik >
    )
};
