import { Chip, Tooltip } from '@mui/material';
import React from 'react'

export default function CustomFieldOptionsColumns({ row: { original } }) {
    const options = original.getCustomFieldsOptions;
    if (!options || !options.length) return "-";
    return (
        <div>
            {options && options.map((option, index) => {
                if (index < 2) {
                    return <Chip
                        key={index}
                        size="small"
                        style={{ margin: '1px' }}
                        label={option.label}
                    />
                }
            })}
            {options && options.length > 2 && (
                <Tooltip
                title={options.map((option, index) => `${index > 1 ? option.label + (index + 1 != options.length ? ', ' : '') : ''}`)}
                placement="top">
                    <Chip
                        size="small"
                        style={{ margin: '1px' }}
                        label={'More'}
                    />
                </Tooltip>
            )}
        </div>
    )
}
