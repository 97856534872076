import gql from "graphql-tag";

export const DocumentsQuery = gql`
query GetDocumentList {
    getDocumentList {
      id
      documentName
      documentUrl
      provinceId
      helpText
      documentType
      createdAt
      updatedAt
      deletedAt
      region {
        id
        name
        code
        isActive
        countryId
      }
      signedDocumentImageUrl
    }
  }
`;
