import gql from "graphql-tag";

export default gql`
mutation PayHolidayPayment($provinceId: Int!, $holidayId: Int!, $workerId: String) {
    payHolidayPayment(provinceId: $provinceId, holidayId: $holidayId, workerId: $workerId) {
      id
      createdAt
      updatedAt
      holidayWorkerId
      grossPay
      netPay
      cpp
      ei
      fedTax
      provTax
      grizzlyCPP
      grizzlyEI
      grizzlyWCB
      eht
    }
  }
`;
