import React, { useState } from "react";
import MainContent from "components/Containers/MainContent";
import SubNav from "../../../components/Navigation/SubNav";
import SubNavTab from "../../../components/Navigation/SubNavTab";
import { Assessment } from "@mui/icons-material";
import WorkOrderTable from "./components/WorkOrderTable";
import WorkOrderFilter from "./components/WorkOrderFilter";

function ActiveEmployerView() {
    const [tavValue, setTabValue] = useState(0);
    const handleTabChange = (event, value) => {
        setTabValue(value);
    };
    return (
        <React.Fragment>
            <SubNav
                value={tavValue}
                onChange={handleTabChange}
                tabs={[
                    <SubNavTab
                        key="workOrder"
                        label="Work Order Reports"
                        icon={<Assessment />}
                        count={null}
                    />,
                ]}
            />
            <MainContent>
                {tavValue == 0 && (
                    <>
                        <WorkOrderFilter />
                        <WorkOrderTable />
                    </>
                )}
            </MainContent>
        </React.Fragment>
    );
}

export default ActiveEmployerView;
