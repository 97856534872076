/* eslint-disable react/jsx-key */
import React from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import {
    convertUTCToLocal,
    convertUTCToTimezoneWithOffset,
    displayLocalDateTime,
    formattedTimeWithOffset,
} from "utils/time";
import JobListItemMenu from "../components/JobListItemMenu";
import { Chip, Tooltip } from "@mui/material";
import TagsInput from "components/TagsInput";
import { isEqual } from "lodash";
import history from "utils/history";

export const sharedProps = {
    showPageSizeOptions: true,
    manual: true,
    showPaginationBottom: true,
    filterable: true,
    showPageJump: false,
    showPaginationTop: false,
    resizable: false,
    classes: "-highlight",
    minRows: 0,
    pageSizeOptions: [10, 20, 40, 80, 100], // No more than this
};

export const jobIdColumn = {
    Header: "ID",
    id: "id",
    accessor: "id",
    className: "-cursor-pointer",
    width: 80,
};
export const companyNameColumn = {
    Header: "Company",
    id: "companyName",
    // eslint-disable-next-line react/display-name, react/prop-types
    accessor: ({ employer }) => (employer.companyName ? employer.companyName : <i>Empty</i>),
    className: "-cursor-pointer",
    // eslint-disable-next-line react/display-name, react/prop-types
    disbaleRowClick: true,
    Cell: ({ row: { original }, value }) => {
        return <Link to={`/employers/${original.employer.id}`}>{value}</Link>;
    },
};
export const startDateColumn = (isPrivate) => ({
    Header: "Start Date",
    id: "startDate",
    accessor: ({ startUtc }) => moment(startUtc).format("MMM Do, YYYY"),
    disableFilters: true,
    Cell: ({ row: { original } }) => {
        if (original?.isFixedTerm) {
            return (
                <div>
                    {`${convertUTCToTimezoneWithOffset(original.startUtc, original.timezone, "YYYY/MM/DD", true)} - ${moment(original.jobRecurring.frequencyEndAt).format("YYYY/MM/DD")} ${convertUTCToTimezoneWithOffset(original.startUtc, original.timezone, " ")} ${displayLocalDateTime(original.startUtc, original.timezone, "YYYY/MM/DD", moment(original.jobRecurring.frequencyEndAt).format("YYYY/MM/DD"))}`}
                </div>
            )
        }
        return (
            <div>
                {convertUTCToTimezoneWithOffset(original.startUtc, original.timezone, "MMM Do, YYYY")}
                {!isPrivate && <br />}
                {displayLocalDateTime(original.startUtc, original.timezone, "MMM Do, YYYY")}
            </div>
        );
    },
    disableSortBy: true,
    ...(!isPrivate ? { maxWidth: 200 } : {}),
    className: "-cursor-pointer",
});
export const startUtcColumn = (isPrivate) => ({
    Header: "Shift",
    id: "startUtc",
    Cell: ({ row: { original } }) => {
        return formattedTimeWithOffset(original.startUtc, original.endUtc, original.timezone);
    },
    accessor: ({ startUtc, endUtc }) => {
        const startTime = moment(startUtc).format("h:mm a");
        const endTime = moment(endUtc).format("h:mm a");
        return `${startTime} - ${endTime}`;
    },
    disableFilters: true,
    ...(!isPrivate ? { maxWidth: 200 } : {}),
    className: "-cursor-pointer",
});
export const cityColumn = {
    Header: "City",
    id: "city",
    accessor: ({ city }) => `${city}`,
    maxWidth: 200,
    className: "-cursor-pointer",
};
export const regionColumn = {
    Header: "Province",
    id: "region",
    accessor: ({ region }) => `${region}`,
    maxWidth: 200,
    className: "-cursor-pointer",
};
export const peopleNeededColumn = (isScheduledJob)=>({
    Header: "Workers",
    id: "peopleNeeded",
    disableFilters: true,
    disableSortBy: true,
    width: 100,
    accessor: ({ peopleNeeded, positionsFilled, isFixedTerm }) => {
        if (!isScheduledJob && isFixedTerm) {
            return `${peopleNeeded}`
        };
        return `${positionsFilled} / ${peopleNeeded}`
    },
    style: { cursor: "pointer", textAlign: "center" },
    styles: { cursor: "pointer", textAlign: "center" },
    headerStyle: { textAlign: "center" },
});

export const sharedColumns = [
    jobIdColumn,
    companyNameColumn,
    startDateColumn(),
    startUtcColumn(),
    cityColumn,
    regionColumn,
    peopleNeededColumn()
];

export const tagsColumn = {
    Header: "Tag",
    id: "tags",
    filterable: true,
    width: 120,
    disableSortBy: true,
    enableColumnFilter: false,
    accessor: ({ tags }) => {
        return tags
    },
    Filter: ({ column, setFilter }) => {
        return (
            <div>
                <TagsInput
                    selectedTags={(data) => {
                        if (data) {
                            if (column) {
                                if (!isEqual(column.filterValue, data)) {
                                    setFilter(column.id, data)
                                }
                            } else {
                                if (data.length == 0 && column) {
                                    setFilter(column.id, data)
                                } else if (data.length > 0) {
                                    setFilter(column.id, data)
                                }
                            }
                        }
                    }}
                    fullWidth
                    defaultValue={column.filterValue ? column.filterValue : []}
                    isFilterField
                    id="tags"
                    name="tags"
                />
            </div>
        )
    },
    Cell: ({ row }) => {
        return (
            <div>
                {row.original.tags && row.original.tags.map((n, index) => {
                    if (index < 2) {
                        return <Chip
                            size="small"
                            style={{ margin: '1px' }}
                            label={n}
                        />
                    }
                })}
                {row.original.tags && row.original.tags.length > 2 && (
                    <Tooltip title={row.original.tags.map((n, index) => `${index > 1 ? n + (index + 1 != row.original.tags.length ? ', ' : '') : ''}`)} placement="top">
                        <Chip
                            size="small"
                            style={{ margin: '1px' }}
                            label={'More'}
                        />
                    </Tooltip>
                )}
            </div>
        )
    },
    style: {
        textAlign: "center",
    },
    headerStyle: {
        textAlign: "center",
    },
};

export const createActionColumn = ({
    canDelete = false,
    canEnable = false,
    toggleFilters,
    showFilters,
} = {}) => {
    return {
        // Header: (
        //   <Tooltip title={`Toggle Filters`} placement="bottom">
        //     <FilterList
        //       style={{ float: "right", marginTop: 5 }}
        //       color={showFilters ? "secondary" : "primary"}
        //       onClick={() => toggleFilters()}
        //       fontSize="large"
        //     />
        //   </Tooltip>
        // ),
        id: "actions",
        disableSortBy: true,
        disableFilters: true,
        collapse: true,
        // eslint-disable-next-line react/display-name, react/prop-types
        Cell: ({ row: { original } }) => {
            let ensureCanEnable = canEnable;
            if (convertUTCToLocal(original.startUtc).isBefore(moment())) {
                ensureCanEnable = false;
            }

            return (
                <JobListItemMenu jobId={original.id} canDelete={canDelete} canEnable={ensureCanEnable} />
            );
        },
    };
};

export const checkInOutColumn = {
    Header: "Ins / Outs",
    id: "inAndOut",
    disableFilters: true,
    disableSortBy: true,
    width: 100,
    accessor: ({ checkedInCount, checkedOutCount }) => `${checkedInCount} / ${checkedOutCount}`,
    className: "-cursor-pointer",
};

export const confirmedColoumn = {
    Header: "Confirmed",
    id: "confirmed",
    disableFilters: true,
    disableSortBy: true,
    width: 80,
    accessor: ({ positions, positionsFilled }) => {
        const confirmedCount = (positions || []).filter(({ isConfirmed }) => isConfirmed === true)
            .length;
        return `${confirmedCount}/${positionsFilled}`;
    },
    style: { cursor: "pointer", textAlign: "center" },
    styles: { cursor: "pointer", textAlign: "center" },
    headerStyle: { textAlign: "center" },
};

export const reconfirmedColoumn = {
    Header: "Reconfirmed",
    id: "reconfirmed",
    disableFilters: true,
    disableSortBy: true,
    width: 80,
    accessor: ({ positions, positionsFilled }) => {
        const reconfirmedCount = (positions || []).filter(({ isReconfirmed }) => isReconfirmed === true)
            .length;
        return `${reconfirmedCount}/${positionsFilled}`;
    },
    style: { cursor: "pointer", textAlign: "center" },
    styles: { cursor: "pointer", textAlign: "center" },
    headerStyle: { textAlign: "center" },
};

export const payrollStatusColumn = {
    Header: "Payroll",
    id: "payrollStatus",
    accessor: ({ payrollStatus }) => {
        return payrollStatus.status;
    },
    disableFilters: true,
    disableSortBy: true,
    width: 100,
    className: "-cursor-pointer",
    // eslint-disable-next-line react/display-name
    //   Cell: ({ original }) => {
    //     return <JobPayrollBadge payrollStatus={original.payrollStatus.status} iconOnly />;
    //   },
};

export function getThProps(rowInfo) {
    if (rowInfo && rowInfo.row) {
        return {
            onClick: (e, handleOriginal) => {
                if (handleOriginal) {
                    handleOriginal();
                }
            },
        };
    } else {
        return {};
    }
}


export function getTdProps(state, rowInfo, column) {
    if (rowInfo && rowInfo.row) {
        return {
            onClick: (e, handleOriginal) => {
                if (handleOriginal) {
                    handleOriginal();
                }

                if (column.id === "payrollStatus") {
                    return;
                }
                if (column.id !== "payrollStatus" && column.id !== "actions" && e.target.tagName !== "A") {
                    if (e.metaKey || e.ctrlKey) {
                        const win = window.open(
                            `${window.location.origin}/jobs/${rowInfo.original.id}`,
                            "_blank"
                        );
                        win.focus();
                        return;
                    }

                    this.props.history.push(`/jobs/${rowInfo.original.id}`);
                }
            },
        };
    } else {
        return {};
    }
}

export function getTdPropsV7(row, cell, e) {
    if (cell && cell.row) {
        if (e && e.target && e.target.href) {
            return;
        }

        if (cell.column.id === "payrollStatus") {
            return;
        }
        if (cell.column.id !== "payrollStatus" && cell.column.id !== "actions") {
            const isFixedTerm = history?.location?.pathname?.includes("multi-shift");
            // console.log(isFixedTerm, "isFixedTerm", history?.location?.pathname)
            if (e.metaKey || e.ctrlKey) {
                const win = window.open(
                    `${window.location.origin}/jobs/${cell.row.original.id}${isFixedTerm ? "/dashboard" : ""}`,
                    "_blank"
                );
                win.focus();
                return;
            }
            history.push(`/jobs/${cell.row.original.id}${isFixedTerm ? "/dashboard" : ""}`);
        }
    }
}
export const privateJobColumns = [
    jobIdColumn,
    startDateColumn(true),
    startUtcColumn(true),
    peopleNeededColumn(),
    checkInOutColumn,
    confirmedColoumn,
    reconfirmedColoumn
];
