import React, { useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import _debounce from "lodash/debounce";
import { actions as JobActions, selectors as JobSelectors } from "store/modules/jobs";
import {
    checkInOutColumn,
    confirmedColoumn,
    createActionColumn,
    getTdProps,
    getTdPropsV7,
    payrollStatusColumn,
    reconfirmedColoumn,
    sharedColumns,
    sharedProps,
} from "./helpers/tableProps";
import ReactTable from "components/ReactTable";

const TableWrapper = (props) => {
    const dispatch = useDispatch()
    const fetchData = useCallback(({ pageSize, pageIndex, filters, sortBy }) => {
        dispatch(() => props.onSortedChange(sortBy));
        dispatch(() => props.onFilteredChange(filters));
        dispatch(() => props.onPageSizeChange(pageSize));
        dispatch(() => props.onPageChange(pageIndex));
    }, []);

    return (
        <ReactTable
            {...sharedProps}
            loading={props.isLoading}
            data={props.jobs}
            columns={[
                ...sharedColumns,
                confirmedColoumn,
                reconfirmedColoumn,
                checkInOutColumn,
                payrollStatusColumn,
                createActionColumn(),
            ]}
            fetchData={fetchData}
            defaultFilter={props.pageFilter}
            currentPage={props.currentPage}
            defaultPageSize={props.pageSize}
            pageCount={props.totalCount}
            defaultSort={props.pageSort}
            onRowClick={getTdPropsV7}
            hiddenColumns={props.hiddenColumns}
        />
    );
}

class CompletedTodayTable extends React.Component {
    render() {
        return (
            <TableWrapper {...this.props} />
        );
    }
}

const mapState = state => {
    const {
        totalCount,
        pageCount,
        pageSize,
        currentPage,
        isLoading,
    } = JobSelectors.getTodayCompletedPaginationData(state);
    return {
        jobs: JobSelectors.getListData(state, {
            timeframe: "today",
            status: "completed",
            part: "data",
        }),
        pageFilter: JobSelectors.getListData(state, {
            timeframe: "today",
            status: "completed",
            part: "filter",
        }),
        pageSort: JobSelectors.getListData(state, {
            timeframe: "today",
            status: "completed",
            part: "sort",
        }),
        pageCount,
        pageSize,
        currentPage,
        isLoading,
        totalCount
    };
};
const mapAction = {
    fetchJobs: JobActions.fetchTodayCompletedJobs,
    onPageChange: JobActions.fetchTodayCompletedJobs,
    onSortedChange: JobActions.setTodayCompletedSort,
    onFilteredChange: JobActions.setTodayCompletedFilter,
    onPageSizeChange: JobActions.setTodayCompletedPageSize,

};

export default connect(mapState, mapAction)(withRouter(CompletedTodayTable));
