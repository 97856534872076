// Loading selectors
export const getLoadingStateFactory = loader => state => {
  return state.overtime.loading[loader] || false;
};

export const getIsOvertimeSpecsLoading = getLoadingStateFactory("overtimeSpecs");
export const getIsOpenEditDialog = getLoadingStateFactory("isOpenEditDialog");
export const getIsUpdateOvertimeSpecsLoading = getLoadingStateFactory("updateOvertimeSpecs");

/*
Admin Data
*/

export const getOvertimeSpecs = state => state.overtime.overtimeSpecs;
export const getOvertimeSpecsUpdateData = state => state.overtime.updateOvertimeSpecsData;
