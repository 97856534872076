import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, CircularProgress, DialogActions, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import Dialog from "components/Dialog/Dialog";
import { actions as documentTypesActions, selectors as documentTypeSelectors } from "store/modules/documentType";

const style = ({ spacing }) => ({
    container: {
        marginBottom: spacing(1)
    },
    footer: {
        padding: spacing(2, 0),
        display: "flex",
        justifyContent: "center"
    }
});

function AddDocumentTypeDialog({ classes, selectedDocument }) {
    const dispatch = useDispatch();
    const isOpen = useSelector(documentTypeSelectors.getIsDeleteDocumentTypeDialog);
    const isLoading = useSelector(documentTypeSelectors.getIsDeleteDocumentLoading);

    const hideDeleteDocumentTypeDialog = () => {
        dispatch(documentTypesActions.setIsDeleteDocumentTypeDialog(false));
    };

    const handleDelete = () => {
        dispatch(documentTypesActions.deleteDocumentType(selectedDocument.id));
    }

    return (
        <Dialog
            open={isOpen}
            title={<Typography>Are you sure to delete <span style={{ fontWeight: "bold" }}>{selectedDocument?.name}</span> document type!</Typography>}
            onClose={hideDeleteDocumentTypeDialog}
            onCancel={hideDeleteDocumentTypeDialog}
            // onConfirm={handleDelete}
            alertType="info"
            size="sm"
            modalType="scrollable"
            hideActions
        >

            <DialogActions className={classes.footer}>
                <Button disabled={isLoading} type="button" className="outline" onClick={hideDeleteDocumentTypeDialog}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    type="submit"
                    onClick={handleDelete}
                    disabled={isLoading}
                >
                    {isLoading ? <CircularProgress size={20} /> : "Delete"}
                </Button>
            </DialogActions>
        </Dialog>
    )
};


export default (withStyles(style)(AddDocumentTypeDialog));
