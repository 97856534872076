import { CheckCircleOutline, HighlightOff } from '@mui/icons-material';
import { Button, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setIsOpenRejectDialog, setSelectedDocument, updateDocumentStatus } from 'store/modules/documents/actions';
import { getIsDocumentStatusUpdate } from "store/modules/documents/selectors";

const ActionColumnCell = ({ row: { original } }) => {
    const dispatch = useDispatch();
    const isLoading = useSelector(getIsDocumentStatusUpdate);
    const [selectedType, setSelectedType] = useState(null);

    useEffect(() => {
        if (!isLoading && selectedType) setSelectedType(null);
    }, [isLoading]);

    const handleApprove = () => {
        setSelectedType("APPROVED");
        dispatch(updateDocumentStatus({ id: original.id, status: "APPROVED" }));
    }
    const handleReject = () => {
        dispatch(setSelectedDocument(original));
        dispatch(setIsOpenRejectDialog(true));
        setSelectedType("REJECT");
    };

    return (
        <div style={{ display: "flex" }}>
            <Button
                onClick={handleApprove}
                size={"small"}
                disabled={isLoading === original.id}
                color="secondary"
                variant="outlined"
                startIcon={<CheckCircleOutline />}
                style={{ marginRight: 8 }}>
                {isLoading === original.id && selectedType === "APPROVED" ?
                    <CircularProgress size={18} /> :
                    "Approve"
                }
            </Button>
            <Button
                onClick={handleReject}
                size={"small"}
                disabled={isLoading === original.id}
                color="error"
                variant="outlined"
                startIcon={<HighlightOff />}>
                {isLoading === original.id && selectedType === "REJECT" ?
                    <CircularProgress size={18} /> :
                    "Reject"
                }
            </Button>
        </div >
    )
}

export default ActionColumnCell;
