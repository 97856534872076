import { gql } from "@apollo/client";

export const findPayrollByPositionIdQuery = gql`
  query findPayrollByPositionId($positionId: Int!) {
    findPayrollByPositionId(positionId: $positionId) {
      createdAt
      updatedAt
      completedAt
      firstName
      lastName
      email
      jobId
      UserId
      grossPay
      netPay
      bonusValue
      hourlyPayRate
      shiftLength
      breakValue
      hoursWorked
      hoursWorkedLessBreak
      straighttimeHours
      overtimeHours
      doubletimeHours
      adjustmentType
      adjustedPayrollId
      payrollTypeId
      positionId
      breakDeductionMins
      bonus
    }
  }
`;
export const weeklyBillingPerformanceQuery = gql`
  query weeklyBillingPerformance($week: Int!) {
    weeklyBillingPerformance(week: $week) {
      week
      employerId
      jobdate
      # workerstartshift
      # workerendshift
      checkin
      checkout
      jobid
      # jobworkerid
      # email
      # contact
      # addressprovince
      # addresscity
      # prov
      # city
      # street
      # addressstreet
      worker
      employer
      bonus
      breakvalue
      hoursworked
      durationMinusBreak
      billingrate
      duration
      total
      hoursworkedlessbreak
      regularhoursworked
      overtimehoursworked
      doubletimehoursworked
    }
  }
`;
export const billingWeekSummaryQuery = gql`
  query billingWeekSummary($week: Int!, $year: Int) {
    billingWeekSummary(week: $week, year: $year) {
      week
      qbId
      employer
      employerId
      bonusValue
      shifts
      jobs
      qbInvoiceCount
      invoiceCount

      breakValue
      hoursWorked
      hoursWorkedLessBreak
      regularHoursWorked
      overtimeHoursWorked
      doubletimeHoursWorked
      count
      totalBilling
    }
  }
`;
export const billingYearSummaryQuery = gql`
  query billingYearSummary {
    billingYearSummary {
      adjustments
      adjustedPayroll {
        jobDate
        companyName
        positionId
        payrollId
        adjustmentType
        adjustedPayrollId
        jobId
        createdAt
        firstName
        lastName
        email
        grossPay
        netPay
        bonusValue
        hourlyPayRate
        shiftLength
        breakValue
        hoursWorked
        hoursWorkedLessBreak
        straighttimeHours
        overtimeHours
        doubletimeHours
        breakDeductionMins
        bonus
      }
      jobs
      year
      week
      bonusValue
      shifts

      breakValue
      hoursWorked
      hoursWorkedLessBreak
      regularHoursWorked
      overtimeHoursWorked
      doubletimeHoursWorked
      approxBilling
    }
  }
`;
export const employerInvoiceQuery = gql`
  query employerInvoice($invoiceId: Int!) {
    employerInvoice(invoiceId: $invoiceId) {
      id
      qbInvoiceId
      employerId
      invoiceYear
      invoiceWeek
      invoiceFileUrl
      createdAt
      employer {
        qbId
        companyName
        fullName
        email
        title
        payRate
        billingRate
        street
        city
        region
        country
        postalCode
        businessPhone
      }
      invoiceItems {
        worker
        id
        createdAt
        positionId
        invoiceId
        rate
        checkIn
        checkOut
        breakDeductionMins
        totalUnits
        invoicableUnits
        isInvoicable
      }
    }
  }
`;
export const deletedInvoiceQuery = gql`
  query deletedInvoiceLogsByYearAndWeek($invoiceWeek: Int!, $invoiceYear: Int!, $employerId: String!) {
    deletedInvoiceLogsByYearAndWeek(invoiceWeek: $invoiceWeek, invoiceYear: $invoiceYear, employerId: $employerId) {
      id
      invoiceId
      employerId
      fileName
      invoiceYear
      invoiceWeek
      deletedBy
      createdAt
    }
  }
`;
export const employerInvoicesQuery = gql`
  query employerInvoices($employerId: String!) {
    employerInvoices(employerId: $employerId) {
      id
      qbInvoiceId
      employerId
      invoiceYear
      invoiceWeek
      invoiceFileUrl
      createdAt
      employer {
        qbId
        companyName
        fullName
        email
        title
        payRate
        billingRate
        street
        city
        region
        country
        postalCode
        businessPhone
      }
      invoiceItems {
        id

        worker
        createdAt
        positionId
        invoiceId
        rate
        jobDate
        checkIn
        checkOut
        checkInUtc
        checkOutUtc
        breakDeductionMins
        totalUnits
        invoicableUnits
        isInvoicable
      }
    }
  }
`;

export const employerInvoicesByWeekQuery = gql`
  query employerInvoicesByWeek($employerId: String!, $week: Int!, $year: Int) {
    employerInvoicesByWeek(employerId: $employerId, week: $week, year: $year) {
      id
      qbInvoiceId
      employerId
      invoiceYear
      invoiceWeek
      invoiceFileUrl
      createdAt
      employer {
        qbId
        companyName
        fullName
        email
        title
        payRate
        billingRate
        street
        city
        region
        country
        postalCode
        businessPhone
      }
      invoiceItems {
        id

        createdAt
        positionId
        invoiceId
        rate
        worker
        checkIn
        checkOut
        checkInUtc
        checkOutUtc
        breakDeductionMins
        totalUnits
        invoicableUnits
        isInvoicable
        payroll {
          position {
            id
            jobId
            workerId
            job {
              timezone
            }
            worker {
              firstName
              lastName
            }
          }
          payrollType {
            id
            name
          }
          createdAt
          grossEarnings
          netEarnings
          rate
          breakDeductionMins
          payrollTypeId
          positionId
          bonus
          totalUnits
          payableUnits
        }
        hasAdjustments
        jobDate
      }
    }
  }
`;

export const employerInvoiceItemsQuery = gql`
  query employerInvoiceItems($invoiceId: Int!) {
    employerInvoiceItems(invoiceId: $invoiceId) {
      id

      createdAt
      positionId
      invoiceId
      rate
      checkIn
      checkOut
      checkInUtc
      # checkOutUtc
      breakDeductionMins
      totalUnits
      invoicableUnits
      isInvoicable
      worker
      jobDate
    }
  }
`;

export const downloadWeeklyBillingZipQuery = gql`
  query downloadWeeklyBillingZip($week: Int!, $year: Int!) {
    downloadWeeklyBillingZip(week: $week, year: $year)
  }
`;
export const downloadWeeklyBillingTimesheetQuery = gql`
  query downloadWeeklyBillingTimesheet($employerId: String!, $id: Int!) {
    downloadWeeklyBillingTimesheet(employerId: $employerId, id: $id)
  }
`;
export const downloadDeletedInvoiceQuery = gql`
  query deletedInvoice($id: Int!) {
    deletedInvoice(id: $id)
  }
`;
export const createWeeklyBillingSpreadsheetQuery = gql`
  query createWeeklyBillingSpreadsheet($year: Int!, $week: Int!) {
    createWeeklyBillingSpreadsheet(year: $year, week: $week)
  }
`;
export const checkWeekInvoicesQuery = gql`
  query checkWeekInvoices($year: Int!, $week: Int!) {
    checkWeekInvoices(year: $year, week: $week)
  }
`;

export const clearWeekInvoicesQuery = gql`
  query clearWeekInvoices($week: Int!, $year: Int!) {
    clearWeekInvoices(week: $week, year: $year)
  }
`;

export const employerWeeklyBillingQuery = gql`
  query employerWeeklyBilling($employerId: String!, $week: Int!, $year: Int) {
    employerWeeklyBilling(employerId: $employerId, week: $week, year: $year) {
      payroll {
        position {
          id
          jobId
          workerId
          job {
            timezone
          }
          worker {
            firstName
            lastName
          }
        }
        payrollType {
          id
          name
        }
        createdAt
        # updatedAt
        # completedAt
        # cancelledAt
        # firstName
        # lastName
        # email
        # jobId
        # UserId
        grossEarnings
        netEarnings
        # cpp
        # ei
        # vacationPay
        # fedTax
        # provTax
        rate
        # shiftLength
        breakDeductionMins
        # hoursWorked
        # hoursWorkedLessBreak
        # straighttimeHours
        # overtimeHours
        # doubletimeHours
        # adjustmentType
        # adjustedPayrollId
        payrollTypeId
        positionId
        # taxRegionId
        # breakDeductionMins
        bonus
        totalUnits
        payableUnits
      }
      hourlyPayRate
      year
      week
      jobDate
      #   workerStartShift
      #   workerEndShift
      checkin
      checkout
      checkInUtc
      checkOutUtc
      jobId
      jobWorkerId
      # totalEmployers
      email
      employerId
      contact

      prov
      city
      street

      worker
      employer
      # totalShifts
      # totalHours
      bonus
      breakValue
      hoursWorked
      durationMinusBreak
      billingRate
      duration
      total
      hoursWorkedLessBreak
      regularHoursWorked
      overtimeHoursWorked
      doubletimeHoursWorked
    }
  }
`;
export const qbInvoicesQuery = gql`
  query qbInvoices($params: QbQueryArgs!) {
    qbInvoices(params: $params) {
      id
      InvoiceItems {
        Id
        DetailType
        ItemName
        ItemValue
        Description
        Qty
        UnitPrice
        Amount
        jobDate
      }
      TxnDate
      domain
      PrintStatus
      SalesTermRef
      DocNumber
      CustomerMemo
      total
      TotalTax
      Balance
      Deposit
      ApplyTaxAfterDiscount
      sparse
      DueDate
      LastUpdatedTime
      CreateTime
      CustomerName
      SyncToken
      EmailStatus
      BillEmail
      #     BillingAddress {
      #       Id
      #       Lat
      #       Long
      #       Line1
      #       Line2
      #       Line3
      #       Line4
      #     }
    }
  }
`;

export const employerInvoicesCountByWeekQuery = gql`
  query employerInvoicesCountByWeek($week: Int!, $year: Int, $employerId: String!) {
    employerInvoicesCountByWeek(week: $week, year: $year, employerId: $employerId)
  }
`;

export default weeklyBillingPerformanceQuery;
