import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import withStyles from '@mui/styles/withStyles';
import { actions as Actions, selectors as Selectors } from "store/modules/workers";
import ReactTableCustom from "components/ReactTable";
import { useCallback } from "react";
import { sharedProps, sharedWorkPermitWorkerColumns } from "./tables/helpers/tableProps";
import { CircularProgress } from "@mui/material";
import Dialog from "components/Dialog/Dialog";


const styles = ({ spacing }) => ({
    profileImage: {
        marginLeft: spacing(2),
        width: "40px",
        height: "40px",
        borderRadius: "50%",
    },
    profileImageHide: {
        marginLeft: spacing(2),
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        visibility: "hidden",
    },
    approvalIcon: {
        width: 21,
        height: 24,
        marginLeft: spacing(2),
    },
});

const WorkerListTable = (props) => {
    const {
        classes,
        workerList,
        onFilteredChange,
        onSortedChange,
        onPageSizeChange,
        onPageChange,
        data,
    } = props;


    const fetchData = useCallback(({ filters, pageIndex, pageSize, sortBy }) => {
        onSortedChange(sortBy)
        onFilteredChange(filters)
        onPageSizeChange(pageSize)
        onPageChange(pageIndex)
    }, []);

    return (
        <ReactTableCustom
            {...sharedProps}
            fetchData={fetchData}
            defaultPageSize={workerList.pageSize}
            loading={workerList.isLoading}
            data={data}
            pageCount={workerList.totalCount}
            currentPage={workerList.currentPage}
            defaultSort={workerList.pageSort}
            defaultFilter={workerList.pageFilter}
            columns={[
                ...sharedWorkPermitWorkerColumns(
                    classes.profileImage,
                    workerList.isLoading,
                    classes.profileImageHide,
                ),
            ]}
        />
    );
}

class WorkerPermitTab extends React.Component {
    constructor(props) {
        super(props);
    }


    render() {
        const {
            classes,
            onFilteredChange,
            onSortedChange,
            onPageSizeChange,
            onPageChange,
            toggleIsApproved,
        } = this.props;
        return (
            <>
                <div>
                    <WorkerListTable
                        {...this.props}
                        data={this.props.data}
                        toggleIsApproved={toggleIsApproved}
                        workerList={this.props.workerList}
                        classes={classes}
                        onFilteredChange={onFilteredChange}
                        onPageChange={onPageChange}
                        onPageSizeChange={onPageSizeChange}
                        onSortedChange={onSortedChange}
                    />
                </div>
            </>
        );
    }
}

const mapState = (state) => {
    const {
        workPermitDetails,
    } = state.workers;

    // let listType;
    const data = workPermitDetails;
    const paginationFunctionList = {
        workPermitWorkerList: Selectors.getWorkPermitWorkerListPagingData,
    };

    return {
        workerList: {
            pageSort: paginationFunctionList['workPermitWorkerList'](state).sort,
            pageFilter: paginationFunctionList['workPermitWorkerList'](state).filter,
            ...paginationFunctionList['workPermitWorkerList'](state).paging,
        },
        data: Object.keys(data).map(workerId => {
            return {
                ...data[workerId],
            };
        }),
    };
};

const mapActions = (dispatch) => {
    return {
        onPageSizeChange: size => dispatch(Actions.changePageSize(size, "workPermitWorkerList")),
        onPageChange: idx => dispatch(Actions.fetchTableData(idx, "workPermitWorkerList")),
        onSortedChange: sort => dispatch(Actions.changeSort(sort, "workPermitWorkerList")),
        onFilteredChange: filter => dispatch(Actions.changeFilter(filter, "workPermitWorkerList")),
    };
};

export default connect(mapState, mapActions)(withStyles(styles)(withRouter(WorkerPermitTab)));
