import React from "react";
import { useDispatch } from "react-redux";
import Button from "components/Button/Button";
import Typography from "@mui/material/Typography";
import Dialog from "components/Dialog/Dialog";
import { updateQbCustomer } from "store/modules/finance/actions/qbActions";
import AccountSection from "./AccountSection";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    editEmployerModal:{
        "& > .MuiDialog-container > .MuiPaper-root":{
            width: 900,
        },
    },
}));

function EditEmployerDialog(props) {
  const [tab, setTab] = React.useState(1);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [isQbDisabled, setIsQbDisabled] = React.useState(false);
  const classes= useStyles();
  const dispatch = useDispatch();

  const {
    updateFormData,
    companyName,
    createdAt,
    firstName,
    lastName,
    email,
    mobilePhone,
    street,
    city,
    region,
    country,
    postalCode,
    isActive,
    isApproved,
    allowPreferredOnly,
    isMassCheckinEnabled,
    businessPhone,
    title,
    websiteUrl,
    qbId,
    billingRate,
    payRate,
    isAutoConfirmEnabled,
    onClose,
    onConfirm,
    onCancel,
    openDialog,
    handleToggleChange,
    handleSetCanEdit,
    id,
    jobNotification,
    allowVaccinatedOnly,
    canPostPrivateJob,
    customFieldValues
  } = props;
  React.useEffect(() => {}, [isDisabled]);
  const setTabIdx = idx => setTab(idx);

  const handleDisabled = val => setIsDisabled(!val);

  const handleQbDisabled = val => setIsQbDisabled(!val);

  const qbDisabled = () => isQbDisabled || !qbId;
  const handleUpdateQbEmployer = () => {
    handleSetCanEdit();
    dispatch(updateQbCustomer());
  };
  return (
    <Dialog
      open={openDialog}
      title={
        <React.Fragment>
          <Typography component="span" style={{ width: "100%", fontSize: 18 }}>
            {`Manage Employer Settings`}
            <Button
              style={{ float: "right", marginRight: -30 }}
              label="X"
              size="small"
              onClick={handleSetCanEdit}
              color="transparent" />
          </Typography>
        </React.Fragment>
      }
      description={null}
      confirmText={tab === 3 ? "Update Quickbooks" : "Update Employer"}
      onClose={onClose}
      disabled={tab === 3 ? qbDisabled() : isDisabled}
      hideActions={tab === 0 ? true : true}
      onConfirm={tab === 3 ? handleUpdateQbEmployer : onConfirm}
      onCancel={onCancel}
      alertType="form"
      className={classes.editEmployerModal}
      modalType="editEmployer">
      <AccountSection
        companyName={companyName}
        firstName={firstName}
        lastName={lastName}
        websiteUrl={websiteUrl}
        title={title}
        email={email}
        qbId={qbId}
        createdAt={createdAt}
        phone={businessPhone}
        handleDisabled={handleDisabled}
        handleQbDisabled={handleQbDisabled}
        mobilePhone={mobilePhone}
        businessPhone={businessPhone}
        street={street}
        city={city}
        setTab={setTabIdx}
        region={region}
        country={country}
        postalCode={postalCode}
        isActive={isActive}
        allowPreferredOnly={allowPreferredOnly}
        payRate={payRate}
        billingRate={billingRate}
        isMassCheckinEnabled={isMassCheckinEnabled}
        isAutoConfirmEnabled={isAutoConfirmEnabled}
        isApproved={isApproved}
        setUpdateEmployerData={updateFormData}
        handleToggleChange={handleToggleChange}
        id={id}
        jobNotification={jobNotification}
        allowVaccinatedOnly={allowVaccinatedOnly}
        canPostPrivateJob={canPostPrivateJob}
        customFieldValues={customFieldValues}
      />
    </Dialog>
  );
}

export default EditEmployerDialog;
