import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import withStyles from '@mui/styles/withStyles';
import { actions as Actions, selectors as Selectors } from "store/modules/workers";
import { sharedProps, sharedWorkerColumns } from "./helpers/tableProps";
import ReactTableCustom from "components/ReactTable";
import { useCallback } from "react";


const styles = ({ spacing }) => ({
    profileImage: {
        marginLeft: spacing(2),
        width: "40px",
        height: "40px",
        borderRadius: "50%",
    },
    profileImageHide: {
        marginLeft: spacing(2),
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        visibility: "hidden",
    },
    approvalIcon: {
        width: 21,
        height: 24,
        marginLeft: spacing(2),
    },
});

const WorkerListTable = (props) => {
    const {
        classes,
        workerList,
        onFilteredChange,
        onSortedChange,
        onPageSizeChange,
        onPageChange,
        data,
    } = props;


    const fetchData = useCallback(({ filters, pageIndex, pageSize, sortBy }) => {
        onSortedChange(sortBy)
        onFilteredChange(filters)
        onPageSizeChange(pageSize)
        onPageChange(pageIndex)
    }, []);

    return (
        <ReactTableCustom
            {...sharedProps}
            fetchData={fetchData}
            defaultPageSize={workerList.pageSize}
            loading={workerList.isLoading}
            data={data}
            pageCount={workerList.totalCount}
            currentPage={workerList.currentPage}
            defaultSort={workerList.pageSort}
            defaultFilter={workerList.pageFilter}
            columns={[
                ...sharedWorkerColumns(
                    classes.profileImage,
                    workerList.isLoading,
                    classes.profileImageHide,
                ),
            ]}
        />
    );
}

class WorkerTable extends React.Component {
    constructor(props) {
        super(props);
    }


    render() {
        const {
            classes,
            onFilteredChange,
            onSortedChange,
            onPageSizeChange,
            onPageChange,
            toggleIsApproved,
        } = this.props;
        return (
            <div>
                <WorkerListTable
                    {...this.props}
                    data={this.props.data}
                    toggleIsApproved={toggleIsApproved}
                    workerList={this.props.workerList}
                    classes={classes}
                    onFilteredChange={onFilteredChange}
                    onPageChange={onPageChange}
                    onPageSizeChange={onPageSizeChange}
                    onSortedChange={onSortedChange}
                />
            </div>
        );
    }
}

const mapState = (state) => {
    const {
        identityDetails,
    } = state.workers;

    // let listType;
    const data = identityDetails;
    const paginationFunctionList = {
        identityWorkerList: Selectors.getIdentityWorkerListPagingData,
    };

    return {
        workerList: {
            pageSort: paginationFunctionList['identityWorkerList'](state).sort,
            pageFilter: paginationFunctionList['identityWorkerList'](state).filter,
            ...paginationFunctionList['identityWorkerList'](state).paging,
        },
        data: Object.keys(data).map(workerId => {
            return {
                ...data[workerId],
            };
        })
    };
};

const mapActions = (dispatch) => {
    return {
        onPageSizeChange: size => dispatch(Actions.changePageSize(size, "identityWorkerList")),
        onPageChange: idx => dispatch(Actions.fetchTableData(idx, "identityWorkerList", { workerIdVerification: true })),
        onSortedChange: sort => dispatch(Actions.changeSort(sort, "identityWorkerList")),
        onFilteredChange: filter => dispatch(Actions.changeFilter(filter, "identityWorkerList")),
    };
};

export default connect(mapState, mapActions)(withStyles(styles)(withRouter(WorkerTable)));
