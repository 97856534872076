import * as pagination from "utils/graphql-pagination";
import { verifyEmail } from "utils/stringUtils";
import { paginationSettings } from "./constants";

export const getEmployerListPaginationData = state => {
  return {
    paging: state.employers[pagination.getPageInfoAttr(paginationSettings.employerList)],
    sort: state.employers.pageSort,
    filter: state.employers.pageFilter,
    params: { isApproved: true, isSuspended: false, isActive: true },
    pagingVars: pagination.pagingVarsFactory(paginationSettings.employerList),
  };
};
export const getDeactivatedEmployerListPaginationData = state => {
  return {
    paging: state.employers[pagination.getPageInfoAttr(paginationSettings.deactivatedEmployerList)],
    sort: state.employers.deactivatedPageSort,
    filter: state.employers.deactivatedPageFilter,
    params: { isApproved: true, isSuspended: false, isActive: false },
    pagingVars: pagination.pagingVarsFactory(paginationSettings.deactivatedEmployerList),
  };
};
export const getUnapprovedEmployerListPaginationData = state => {
  return {
    paging: state.employers[pagination.getPageInfoAttr(paginationSettings.unapprovedEmployerList)],
    sort: state.employers.unapprovedPageSort,
    filter: state.employers.unapprovedPageFilter,
    params: { isApproved: false, isSuspended: false, isActive: true },
    pagingVars: pagination.pagingVarsFactory(paginationSettings.unapprovedEmployerList),
  };
};
//
//
//

export const getEmployerListSortData = state => state.employers.pageSort;
export const getEmployerListFilterData = state => state.employers.pageFilter;

// export const getDeactivatedEmployerListPaginationData = state => {
//     return state.employers[pagination.getPageInfoAttr(paginationSettings.employerList)];
// };
export const getDeactivatedEmployerListSortData = state => state.employers.deactivatedPageSort;
export const getDeactivatedEmployerListFilterData = state => state.employers.deactivatedPageFilter;

// export const getUnapprovedEmployerListPaginationData = state => {
//     return state.employers[pagination.getPageInfoAttr(paginationSettings.employerList)];
// };

export const getUnapprovedEmployerListSortData = state => state.employers.unapprovedPageSort;
export const getUnapprovedEmployerListFilterData = state => state.employers.unapprovedPageFilter;

// export const getEmployerListPaginationData = state => {
//     return state.employers[pagination.getPageInfoAttr(paginationSettings.employerList)];
// };

// export const getEmployerListSortData = state => state.employers.pageSort;
// export const getEmployerListFilterData = state => state.employers.pageFilter;

// export const getDeactivatedEmployerListPaginationData = state => {
//     return state.employers[pagination.getPageInfoAttr(paginationSettings.employerList)];
// };
// export const getDeactivatedEmployerListSortData = state => state.employers.pageSort;
// export const getDeactivatedEmployerListFilterData = state => state.employers.pageFilter;

// export const getUnapprovedEmployerListPaginationData = state => {
//     return state.employers[pagination.getPageInfoAttr(paginationSettings.employerList)];
// };
// export const getUnapprovedEmployerListSortData = state => state.employers.pageSort;
// export const getUnapprovedEmployerListFilterData = state => state.employers.pageFilter;

// Bans
export const employerBannedByPageInfo = state => {
  return state.employers[pagination.getPageInfoAttr(paginationSettings.employerBannedBy)];
};

export const getEmployerBansSortData = state => state.employers.pageSort;
export const getEmployerBansFilterData = state => state.employers.pageFilter;

// Prefers
export const getEmployerPreferredPaginationData = state => {
  return state.employers[pagination.getPageInfoAttr(paginationSettings.employerPreferredBy)];
};
export const getEmployerPreferredSortData = state => state.employers.pageSort;
export const getEmployerPreferredFilterData = state => state.employers.pageFilter;

export const canSubmitForm = state => {
  if (
    state.employers.addEmployerData.firstName &&
    state.employers.addEmployerData.lastName &&
    state.employers.addEmployerData.title &&
    state.employers.addEmployerData.phone &&
    verifyEmail(state.employers.addEmployerData.email) &&
    state.employers.addEmployerData.location &&
    state.employers.addEmployerData.companyName &&
    state.employers.addEmployerData.payRate &&
    state.employers.payRateIsValid &&
    state.employers.addEmployerData.billingRate &&
    state.employers.billingRateIsValid &&
    state.employers.addEmployerData.logo
  ) {
    return true;
  }
  return false;
};
