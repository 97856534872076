import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import makeStyles from '@mui/styles/makeStyles';
import { PaymentConstants } from "helpers/constants/index";
import Dialog from "components/Dialog/Dialog";
import { calculateMargins } from "utils/money";
import { formatMoney } from "utils/stringUtils";
import CurrencyInput from "../../PositionTable/components/Dialogs/CurrencyInput";

const useStyles = makeStyles(({ spacing }) => ({
    container: {
        marginTop: spacing(2),
    },
    inputContainer: {
        minHeight: 70,
    },
}));

function EditRatesDialog(props) {
    const { open, onClose, onCancel, onConfirm } = props;
    const classes = useStyles();
    const [newPayRate, setNewPayRate] = useState(PaymentConstants.MINIMUM_WAGE || 0);
    const [newBillingRate, setNewBillingRate] = useState(0);
    const { percentage, dollars } = calculateMargins(newPayRate, newBillingRate);
    const tooltip = `Margin/hr: ${formatMoney(dollars)} (${percentage}%)`;

    const onPayRateChange = value => {
        setNewPayRate(value);
    };

    const handleClose = () => {
        setNewPayRate(PaymentConstants.MINIMUM_WAGE || 0)
        onClose();
    };

    const handleCancel = () => {
        setNewPayRate(PaymentConstants.MINIMUM_WAGE || 0)
        onCancel();
    };

    const canSave = newPayRate >= PaymentConstants.MINIMUM_WAGE;
    const handleUpdateRates = () => {
        onConfirm(newPayRate, newBillingRate);
        setNewPayRate(PaymentConstants.MINIMUM_WAGE || 0)
    };

    return (
        <Dialog
            open={open}
            title={`Edit rate for all workers`}
            description={null}
            onClose={handleClose}
            onConfirm={handleUpdateRates}
            onCancel={handleCancel}
            confirmText="Update"
            alertType="question"
            icon="local_atm"
            disabled={!canSave}>
            <Grid container direction="row" alignItems="center" className={classes.container}>
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={12} className={classes.inputContainer}>
                        <CurrencyInput
                            label="Pay rate"
                            minValue={PaymentConstants.MINIMUM_WAGE}
                            minValueHelperText={`Minimum wage is: ${formatMoney(PaymentConstants.MINIMUM_WAGE)}`}
                            value={newPayRate}
                            onChange={onPayRateChange}
                            tooltip={tooltip}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
}

EditRatesDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export default EditRatesDialog;
