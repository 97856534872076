import React from "react";
import { connect } from "react-redux";
import withStyles from '@mui/styles/withStyles';
import Can from "components/Authorization/Can";
import MainContent from "components/Containers/MainContent";
import { actions as WorkerActions, selectors as WorkerSelectors } from "store/modules/workers";
import { tabStyles } from "./helpers/tabStyles";
import WorkerTab from "./Components/WorkerTab";
import { DashboardViewsPermissions } from "helpers/constants/permissions";
import Dialog from "components/Dialog/Dialog";
import { CircularProgress } from "@mui/material";

const styles = {
    ...tabStyles,
    progress: {
        color: "#11b2a8",
        position: "absolute",
        top: "50%",
        left: "45%",
    },
    svgRoot: {
        marginTop: `0 !important`,
        margin: `0 !important`
    }
};

class WorkerOverview extends React.Component {

    render() {

        return (
            <React.Fragment>
                <WorkerTab />
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(WorkerOverview);
