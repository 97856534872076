import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Delete from "@mui/icons-material/Delete";
import MoreVert from "@mui/icons-material/MoreVert";
import Dialog from "components/Dialog/Dialog";
import history from "utils/history";
import { actions as AdminActions, selectors as AdminSelectors } from "store/modules/admins";
import Can from "components/Authorization/Can";
import { AdminPermissions } from "helpers/constants/permissions";

function AdminActionMenu({ onEdit, onResetPassword, data }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEditClick = () => {
    setAnchorEl(null);
    onEdit(data);
  };

  const onPermissionClick = () => {
    setAnchorEl(null);
    history.push(`/admins/${data.id}`);
  };

  const onResetClick = () => {
    setAnchorEl(null);
    onResetPassword(data);
  };

  const handleDeleteJob = () => {
    setAnchorEl(null);
    setShowDeleteDialog(true);
  };

  const hideDeleteDialog = () => setShowDeleteDialog(false);

  const handleConfirmDelete = () => {
    setShowDeleteDialog(false);
    dispatch(AdminActions.removeAdminData(data.id));
  };

  const menuId = `action-menu-${data.id}`;

  return (
    <React.Fragment>
      <IconButton
        aria-owns={anchorEl ? menuId : undefined}
        aria-haspopup="true"
        disableRipple
        onClick={handleClick}
      >
        <MoreVert color="primary" />
      </IconButton>
      {anchorEl ? (
        <Menu
          id={menuId}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          disableAutoFocusItem
        >
          <Can perform={AdminPermissions.update.profile.value}>
            {allowed => {
              if (!allowed) {
                return null;
              }
              return <MenuItem onClick={onEditClick}>Edit</MenuItem>;
            }}
          </Can>
          {/* <Can perform={AdminPermissions.read.assignpermissions.value}>
            {allowed => {
              if (!allowed) {
                return null;
              }
              return <MenuItem onClick={onPermissionClick}>Permission</MenuItem>;
            }}
          </Can> */}
          {/* <Can perform={AdminPermissions.update.admin.value}>
            {allowed => {
              if (!allowed) {
                return null;
              }
              return <MenuItem onClick={onResetClick}>Change Password</MenuItem>;
            }}
          </Can> */}
          <Can perform={AdminPermissions.delete.hard.value}>
            {allowed => {
              if (!allowed) {
                return null;
              }
              return <MenuItem onClick={handleDeleteJob}>Delete</MenuItem>;
            }}
          </Can>
        </Menu>
      ) : null}

      <Dialog
        open={showDeleteDialog}
        title="Are You Sure?"
        description="This admin details will be deleted."
        confirmText="Yes"
        onClose={hideDeleteDialog}
        onConfirm={handleConfirmDelete}
        onCancel={hideDeleteDialog}
        alertType="warning"
      />
    </React.Fragment>
  );
}

export default AdminActionMenu;
