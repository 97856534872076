import React, { useCallback } from "react";
import { withRouter } from "react-router-dom";
import _debounce from "lodash/debounce";
import {
    confirmedColoumn,
    createActionColumn,
    getTdProps,
    getTdPropsV7,
    reconfirmedColoumn,
    sharedColumns,
    sharedProps,
    tagsColumn,
} from "./helpers/tableProps";
import ReactTable from "components/ReactTable";
import { useDispatch } from "react-redux";

const NotStartedTable = (props) => {
    const dispatch = useDispatch()
    const fetchData = useCallback(({ pageSize, pageIndex, filters, sortBy }) => {
        dispatch(() => props.onSortedChange(sortBy));
        dispatch(() => props.onFilteredChange(filters));
        dispatch(() => props.onPageSizeChange(pageSize));
        dispatch(() => props.onPageChange(pageIndex));
    }, []);
    return (

        <ReactTable
            {...sharedProps}

            columns={[
                ...sharedColumns,
                confirmedColoumn,
                reconfirmedColoumn,
                tagsColumn,
                createActionColumn({ canDelete: true }),
            ]}
            fetchData={fetchData}
            defaultFilter={props.pageFilter}
            currentPage={props.currentPage}
            defaultPageSize={props.pageSize}
            loading={props.isLoading}
            pageCount={props.totalCount}
            data={props.data}
            defaultSort={props.pageSort}
            // getTdProps={getTdProps.bind(this)}
            onRowClick={getTdPropsV7}
            hiddenColumns={props.hiddenColumns}


        />
    )
}

export default withRouter(NotStartedTable);
