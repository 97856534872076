import { gql } from "@apollo/client";

export default gql`
  mutation updateTermsAndConditions($data: UpdateTermsAndConditions!) {
    updateTermsAndConditions(data: $data) {
      id
      termsAndConditions
      work
      workFrench
      hire
      hireFrench
    }
  }
`;
