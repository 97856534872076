import { gql } from "@apollo/client";

export default gql`
  fragment WorkerFields on Worker {
    createdAt
    approvedAt
    createdAtUtc
    approvedAtUtc
    suspensionEndUtc
    suspensionStartUtc
    id
    firstName
    lastName
    fullName
    profileImageUrl
    signedProfileImageUrl
    signedIdentificationImageUrl
    vaccinationStatus
    sin
    isWorkerSin9
    isSuspended
    isActive
    isApproved
    isOnboarded
    sin
    timezone
    dcCustomerNumber
    travelRange
    isEmailVerified
    isStudent
    employmentType
    phone
    email
    street
    city
    region
    country
    postalCode
    rating
    positionCount
    hoursWorkedCount
    banCount
    preferCount
    noShowCount
    grossPay
    netPay
    totalDeductions
    tags
    workPermitUrl
    signedWorkPermitImageUrl
    workPermitStatus
    workPermitExpiryDate
    workTypePreferences {
      workType {
        id
        label
      }
    }
    availability {
      id
      dayOfWeek
      timeOfDay
      startTime
      endTime
    }
    temporaryAvailability {
      id
      startDt
      startDtUtc
      endDt
      endDtUtc
    }
    transportationPreferences {
      transportation {
        id
        label
      }
    }
    customFieldValues {
        id
        workerId
        customFieldId
        value
        createdAt
        updatedAt
        customField {
          id
          name
          slug
          type
          assignTo
          isRequired
          isForAll
          createdBy
          updatedBy
          deletedBy
          createdAt
          updatedAt
          getCustomFieldsOptions {
            id
            customFieldId
            label
            value
            createdAt
            updatedAt
          }
          getWorkerCustomFieldsValues {
            id
            workerId
            customFieldId
            value
            createdAt
            updatedAt
            signedCustomFileUrl
          }
        }
        signedCustomFileUrl
      }
  }
`;
