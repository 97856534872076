import * as pagination from "utils/graphql-pagination";
import { createReducer } from "utils/redux";
import { paginationSettings } from "./constants";
import types from "./types";

const initialLoadingState = {
    isAddCustomField: false,
    isDeleting: false,
    detailLoading: false,
    removeUserLoading: false,
    addUserLoading: false,
    fillUpdateData: false,
};

const initialDialogState = {
    addCsutomField: false,
    deleteConfirmation: false,
    removeUser: false,
    addUser: false
};

const initialListState = {
    sort: [
        { id: "id", desc: true }
    ],
    filter: [],
    data: [],
};

const initialFormData = {
    name: "",
    slug: "",
    type: "",
    isAllEmployer: false,
    employers: [],
    workers: [],
    options: [],
    assignTo: "EMPLOYER",
    isRequired: false
}

const initialState = {
    activeTab: null,
    customFieldList: { ...initialListState },
    dialog: { ...initialDialogState },
    loading: { ...initialLoadingState },
    employerList: { ...initialListState },
    workerList: { ...initialListState },
    form: { ...initialFormData },
    detail: {
        customField: {},
        employerList: { ...initialListState },
        workerList: { ...initialListState },
    },
    ...pagination.initialState(paginationSettings),
};

const actionsMap = {
    [types.SET_LOADING_STATE]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            loading: {
                ...state.loading,
                [key]: value,
            },
        };
    },
    [types.SET_DIALOG_STATE]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            dialog: {
                ...state.dialog,
                [key]: value,
            },
        };
    },
    [types.SET_EDIT_FORM_VALUES]: (state, { payload }) => {
        const { form } = payload;
        return {
            ...state,
            form
        };
    },
    [types.CLEAR_SELECTED_PICKER_DATA]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            form: {
                ...state.form,
                [key]: value
            }
        };
    },
    [types.SET_FORM_VALUES]: (state, { payload }) => {
        const { key, value } = payload;
        if (key === "employers") {
            const data = [...(state.form.employers || [])];
            const index = data.findIndex(a => a === value);
            if (index >= 0) data.splice(index, 1);
            else data.push(value);
            return {
                ...state,
                form: {
                    ...state.form,
                    employers: data
                }
            }
        }
        if (key === "workers") {
            const data = [...(state.form.workers || [])];
            const index = data.findIndex(a => a === value);
            if (index >= 0) data.splice(index, 1);
            else data.push(value);
            return {
                ...state,
                form: {
                    ...state.form,
                    workers: data
                }
            }
        }
        if (key === "isAllEmployer" && value) {
            return {
                ...state,
                form: {
                    ...state.form,
                    [key]: value,
                    employers: []
                },
            }
        }
        return {
            ...state,
            form: {
                ...state.form,
                [key]: value,
            },
        };
    },
    [types.ADD_NEW_OPTION]: (state) => {
        return {
            ...state,
            form: {
                ...state.form,
                options: [...state.form.options, { value: "", label: "" }]
            },
        };
    },
    [types.SET_OPTION_VALUE]: (state, { payload }) => {
        const { key, value, index } = payload;
        return {
            ...state,
            form: {
                ...state.form,
                options: Object.assign([], state.form.options, {
                    [index]: {
                        ...state.form.options[index],
                        [key]: value
                    }
                })
            },
        };
    },
    [types.REMOVE_OPTION]: (state, { payload }) => {
        const { key } = payload;
        return {
            ...state,
            form: {
                ...state.form,
                options: state.form.options.filter((a, i) => i !== key)
            },
        };
    },
    [types.CLEAR_TYPE_OPTIONS]: (state) => {
        return {
            ...state,
            form: {
                ...state.form,
                options: []
            },
        };
    },
    [types.CLEAR_FORM]: (state) => {
        return {
            ...state,
            form: {
                ...initialFormData
            },
        };
    },
    [types.SET_CUSTOM_FIELDS_DATA]: (state, { payload }) => {
        const { data, part } = payload;
        if (state.employerList.data) {
            return {
                ...state,
                customFieldList: {
                    ...state.customFieldList,
                    [part]: data,
                },
            };
        }
        return state;
    },
    [types.SET_EMPLOYERS_LIST_DATA]: (state, { payload }) => {
        const { data, part } = payload;
        if (state.employerList.data) {
            return {
                ...state,
                employerList: {
                    ...state.employerList,
                    [part]: data,
                },
            };
        }
        return state;
    },
    [types.SET_WORKERS_LIST_DATA]: (state, { payload }) => {
        const { data, part } = payload;
        if (state.workerList.data) {
            return {
                ...state,
                workerList: {
                    ...state.workerList,
                    [part]: data,
                },
            };
        }
        return state;
    },
    [types.SET_CUSTOM_FIELD_DETAIL]: (state, { payload }) => {
        const { value, key } = payload;
        return {
            ...state,
            detail: {
                ...state.detail,
                [key]: value,
            },
        };
    },
    [types.SET_CUSTOM_FIELD_DETAIL_EMPLOYERS]: (state, { payload }) => {
        const { data, part } = payload;
        if (state.detail.employerList.data) {
            return {
                ...state,
                detail: {
                    ...state.detail,
                    employerList: {
                        ...state.detail.employerList,
                        [part]: data,
                    }
                },
            };
        }
        return state;
    },
    [types.SET_CUSTOM_FIELD_DETAIL_WORKERS]: (state, { payload }) => {
        const { data, part } = payload;
        if (state.detail.workerList.data) {
            return {
                ...state,
                detail: {
                    ...state.detail,
                    workerList: {
                        ...state.detail.workerList,
                        [part]: data,
                    }
                },
            };
        }
        return state;
    },
    [types.SET_ACTIVE_TAB]: (state, { payload }) => {
        const { activeTab } = payload;
        return {
            ...state,
            activeTab,
        };
    },
    ...pagination.createPaginationActions(paginationSettings),
};

export default createReducer(initialState, actionsMap);
