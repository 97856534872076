import React, { useState, useEffect } from "react";
import Dialog from "components/Dialog/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { actions as workTypesActions, selectors as workTypesSelectors } from "store/modules/workTypes";

const style = ({ spacing }) => ({
    container: {
        marginBottom: spacing(2)
    }
})

function EditWorkTypeDialog({ classes, workType }) {

    const dispatch = useDispatch();
    const [workTypeLabel, setWorkTypeLabel] = useState("");
    const [frenchName, setFrenchName] = useState("");
    const isEditGearDialog = useSelector(workTypesSelectors.getIsEditWorkTypeDialog);

    useEffect(() => {
        setWorkTypeLabel(workType.label);
        setFrenchName(workType.frenchName);
    }, [workType.label,workType.frenchName])

    const handleWorkType = (event) => {
        setWorkTypeLabel(event.target.value);
    }

    const handleFrenchName = (event) => {
        setFrenchName(event.target.value);
    }

    const hideEditWorkTypeDialog = () => {
        dispatch(workTypesActions.setIsEditWorkTypeDialog(false));
    }

    const updateWorkType = () => {
        dispatch(workTypesActions.updateWorkType({
            id: workType.id,
            label: workTypeLabel,
            frenchName,
            order: workType.order,
            isActive: workType.isActive,
            baseRate: workType.baseRate,
            baseBillingRate: workType.baseBillingRate
        }));
        setWorkTypeLabel("");
        setFrenchName("");
        hideEditWorkTypeDialog();
    }

    return (
        <Dialog
            open={isEditGearDialog}
            title="Edit Work Type"
            onClose={hideEditWorkTypeDialog}
            onCancel={hideEditWorkTypeDialog}
            onConfirm={updateWorkType}
            disabled={!workTypeLabel?.trim?.() || !frenchName?.trim?.()}
            alertType="form"
            confirmText="Update"
            size="sm"
            modalType="scrollable"
        >
            <div className={classes.container}>
                <TextField
                    id="label"
                    margin="normal"
                    label="Label"
                    value={workTypeLabel}
                    onChange={handleWorkType}
                    required
                    fullWidth
                />
                <TextField
                    id="label"
                    margin="normal"
                    label="French Label"
                    value={frenchName}
                    onChange={handleFrenchName}
                    required
                    fullWidth
                />
            </div>
        </Dialog>
    )
}

export default (withStyles(style)(EditWorkTypeDialog))
