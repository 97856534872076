/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import withStyles from '@mui/styles/withStyles';

import Typography from "@mui/material/Typography";
import { formatMapUrlByLatLong } from "utils/map";

const styles = ({ spacing, palette, shape }) => ({
  map: {
    border: `1px solid ${palette.grey[500]}`,
    borderRadius: `${shape.borderRadius / 2}px`,
    marginTop: spacing(2),
  },
});

class LoggedInUserView extends React.Component {
  render() {
    const { classes, profile,me } = this.props;

    console.log(me,"me")

    return (
      <Grid container style={{ marginTop: 30 }}>
        <Grid item xs={8}>
          <Typography variant="h6" gutterBottom color="secondary">
            User Info
          </Typography>
          <Grid container>
            <Grid item xs={3}>
              <List>
                <ListItem>
                  <ListItemText primary="Name" secondary={me?.admin?.firstName} />
                </ListItem>
                {/* <ListItem>
                  <ListItemText primary="City" secondary={profile?.locationData?.city_name} />
                </ListItem> */}
                <ListItem>
                  <ListItemText
                    primary="Verified Email"
                    secondary={profile.email_verified ? "Yes" : "No"}
                  />
                </ListItem>
                {/* <ListItem>
                  <ListItemText primary="Timezone" secondary={profile?.locationData?.time_zone} />
                </ListItem> */}
                <ListItem>
                  <ListItemText
                    primary="Roles"
                    secondary={profile.roles.map(role => {
                      return `${role} `;
                    })}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={3}>
              <List>
                <ListItem>
                  <ListItemText primary="Email" secondary={me?.admin?.email} />
                </ListItem>
                {/* <ListItem>
                  <ListItemText primary="Country" secondary={profile.locationData.country_name} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Login Count" secondary={profile.loginCount} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="user Ip" secondary={profile.lastIp} />
                </ListItem> */}
              </List>
            </Grid>
            {/* <Grid item xs={6}>
              <img
                alt="Location Map"
                className={classes.map}
                src={formatMapUrlByLatLong(
                  profile.locationData.latitude,
                  profile.locationData.longitude
                )}
                style={{ width: "100%" }}
              />
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapState = state => {
  const { profile,me } = state.login;
  return {
    profile,
    me
  };
};

export default connect(mapState)(withStyles({ ...styles })(LoggedInUserView));
