/* eslint-disable no-unused-vars */
import ApolloClient from "utils/apollo";
import { actions as EmployerActions } from "store/modules/employers";
import { actions as MetaActions } from "store/modules/meta";
import {
    createEmployerInvoiceMutation,
    deleteInvoiceItemMutation,
    deleteInvoiceMutation,
    generateBillingMutation,
} from "../graphql/mutations";
import {
    billingWeekSummaryQuery,
    billingYearSummaryQuery,
    checkWeekInvoicesQuery,
    clearWeekInvoicesQuery,
    createWeeklyBillingSpreadsheetQuery,
    deletedInvoiceQuery,
    downloadDeletedInvoiceQuery,
    downloadWeeklyBillingTimesheetQuery,
    downloadWeeklyBillingZipQuery,
    employerInvoiceItemsQuery,
    employerInvoiceQuery,
    employerInvoicesByWeekQuery,
    employerInvoicesCountByWeekQuery,
    employerInvoicesQuery,
    employerWeeklyBillingQuery,
    weeklyBillingPerformanceQuery,
} from "../graphql/queries/billing.weeklyBilling";
import types from "../types";
import { convertLocalToUTC } from "../../../../utils/time";
//

// export const downloadWeeklyBillingTimesheet = (employerId, id) => async dispatch =>
//   ApolloClient.query({ query: downloadWeeklyBillingTimesheetQuery, variables: { employerId, id } })
//     .then(({ data }) => data.downloadWeeklyBillingTimesheet.uri)
//     .catch(e => dispatch(MetaActions.errorToast(`An Error Occurred  . ${e.message}`)));

//
export const downloadWeeklyBillingZip = (week, year) => async dispatch =>
  ApolloClient.query({ query: downloadWeeklyBillingZipQuery, variables: { week, year } })
    .then(({ data, errors }) => {
        if(data){
            return data.downloadWeeklyBillingZip.url
        } else if(errors && errors.length > 0) {
            throw new Error(errors[0].message);
        }
    })
    .catch(e => dispatch(MetaActions.errorToast(`An Error Occurred  . ${e.message}`)));

//

export const downloadWeeklyBillingTimesheet = (employerId, id) => async dispatch =>
  ApolloClient.query({ query: downloadWeeklyBillingTimesheetQuery, variables: { employerId, id } })
    .then(({ data, errors }) => {
        if(data){
            return data.downloadWeeklyBillingTimesheet.url
        } else if(errors && errors.length > 0) {
            throw new Error(errors[0].message);
        }
    })
    .catch(e => dispatch(MetaActions.errorToast(`An Error Occurred  . ${e.message}`)));

export const downloadDeletedTimesheet = (id) => async dispatch =>
  ApolloClient.query({ query: downloadDeletedInvoiceQuery, variables: { id } })
    .then(({ data, errors }) => {
        if(data){
            return data.deletedInvoice.url
        } else if(errors && errors.length > 0) {
            throw new Error(errors[0].message);
        }
    })
    .catch(e => dispatch(MetaActions.errorToast(`An Error Occurred  . ${e.message}`)));
//
// export const downloadWeeklyBillingZip = (week, year) => async dispatch =>
//   ApolloClient.query({ query: downloadWeeklyBillingZipQuery, variables: { week, year } })
//     .then(({ data }) => data.downloadWeeklyBillingZip.uri)
//     .catch(e => dispatch(MetaActions.errorToast(`An Error Occurred  . ${e.message}`)));

// //

export const popErrorAlert = error => dispatch => dispatch(MetaActions.errorToast(`${error}`));
const setLoadingState = isLoading => ({
  type: types.SET_LOADING_STATE,
  payload: { isLoading },
});
const setLoadingBillingSummeryState = loading => ({
  type: types.SET_LOADING_BILLING_SUMMERY,
  payload: { loading },
});
const setBillingWeekSummary = data => ({ type: types.SET_BILLING_WEEK_SUMMARY, payload: data });

const setGeneratedBilling = data => ({ type: types.SET_GENERATED_BILLING, payload: data });

export const generateBilling = (week, year) => dispatch => {
  dispatch(setGeneratedBilling([]));
  dispatch(setLoadingState({ isLoading: true }));
  return ApolloClient.mutate({
    mutation: generateBillingMutation,
    // variables: { week, year: new Date().getFullYear() },
    variables: { week, year },
  })
    .then(({ data }) => {
      dispatch(MetaActions.successToast(`Timesheet generation has completed successfully.`));
      dispatch(setLoadingState({ isLoading: false }));
      dispatch(setGeneratedBilling(data.generateBillingWeek));
      dispatch(fetchBillingWeekSummary(week, year));
      return { started: true };
    })

    .catch(e => dispatch(MetaActions.errorToast(`An Error Occurred -  ${e.message}`)));
};
export const clearWeekInvoices = (week, year) => dispatch => {
  dispatch(setGeneratedBilling());
  dispatch(setLoadingState({ isLoading: true }));
  return ApolloClient.query({
    query: clearWeekInvoicesQuery,
    // variables: { week, year: new Date().getFullYear() },
    variables: { week, year },
  })
    .then(() => {
      dispatch(MetaActions.successToast(`All uncomitted invoices have been deleted successfully.`));
      dispatch(setLoadingState({ isLoading: false }));
      dispatch(fetchBillingWeekSummary(week, year));
      // return data.generateBillingWeek[0].invoices;
      return { started: true };
    })

    .catch(e => dispatch(MetaActions.errorToast(`An Error Occurred -  ${e.message}`)));
};
export const setUpdateEmployerBillingWeekData = data => {
  return { type: types.UPDATE_EMPLOYER_BILLING_WEEK_DATA, payload: { ...data } };
};
export const setUpdateEmployerBillingWeekInvoiceData = data => {
  return { type: types.UPDATE_EMPLOYER_BILLING_WEEK_INVOICE_DATA, payload: { ...data } };
};

const setWeeklyBillingPerformance = data => ({
  type: types.SET_WEEKLY_BILLING_PERFORMANCE,
  payload: data,
});
const setPreviousBillingWeekSummary = data => ({
  type: types.SET_PREVIOUS_BILLING_WEEK_SUMMARY,
  payload: data,
});
const setEmployerBillingWeekSummary = data => ({
  type: types.SET_EMPLOYER_BILLING_WEEK_SUMMARY,
  payload: data,
});
const setEmployerBillingYearSummary = data => ({
  type: types.SET_BILLING_YEAR_SUMMARY,
  payload: data,
});
const setEmployerInvoices = data => ({ type: types.SET_EMPLOYER_INVOICES, payload: data });
const setDeletedInvoice = data => ({ type: types.SET_DELETED_INVOICES, payload: data });
const setEmployerWeekSummeru = data => ({ type: types.SET_EMPLOYER_WEEK_SUMMERY, payload: data });
const setEmployerQbInvoices = data => ({ type: types.SET_EMPLOYER_QB_INVOICES, payload: data });
const setEmployerInvoice = data => ({ type: types.SET_EMPLOYER_INVOICE, payload: data });
const setEmployerInvoiceItems = data => ({ type: types.SET_EMPLOYER_INVOICEITEMS, payload: data });

//
export const createEmployerInvoiceFromInvoice = () => (dispatch, getState) => {
  const data = getState().finance.activeInvoiceItems;
  const { invoiceWeek, invoiceYear, employerId } = getState().finance.activeInvoice;
  const newData = data.filter(n => !n.isRemoved)
  const payload = {
    employerId,
    invoiceYear,
    invoiceWeek,
    shifts: newData.map(shift => {
        return {
            positionId: shift.positionId,
            checkIn: shift.checkIn,
            checkOut: shift.checkOut,
            checkOutUtc: shift.checkOutUtc,
            checkInUtc: shift.checkInUtc,
            bonus: shift.bonus || 0,
            jobId: shift.payroll[0].position.jobId,
            breakDeductionMins: shift.breakDeductionMins,
            rate: shift.rate,
        };
    }),
  };
  return ApolloClient.mutate({
    mutation: createEmployerInvoiceMutation,
    variables: { data: payload },
  })
  .then(({ errors }) => {
      if (errors && errors.length > 0) {
        throw new Error(errors[0].message);
      }
      dispatch(MetaActions.successToast(`Timesheet generation has started successfully.`));
      setTimeout(() => {
        dispatch(fetchEmployerInvoicesCountByWeek(payload.employerId, payload.invoiceWeek, payload.invoiceYear));
        dispatch(
          fetchEmployerInvoicesByWeek(payload.employerId, payload.invoiceWeek, payload.invoiceYear),
        );
      }, 3000);
    })

    .catch(e => dispatch(MetaActions.errorToast(`An Error Occurred -  ${e.message}`)));
};

//
//
export const createEmployerInvoice = () => (dispatch, getState) => {
  const data = getState().finance.employerBillingWeekSummary;
  const newData = data.filter(n => !n.isRemoved);
  const shifts = newData.map(shift => {
    return {
      positionId: shift.jobWorkerId,
      checkIn: shift.checkin,
      checkOut: shift.checkout,
      checkInUtc: convertLocalToUTC(shift.checkInUtc),
      checkOutUtc: convertLocalToUTC(shift.checkOutUtc),
      bonus: shift.bonus,
      jobId: shift.jobId,
      //   breakValue: shift.breakValue,
      //   hoursWorked: shift.positionId,
      //   durationMinusBreak:  shift.positionId,
      breakDeductionMins: shift.breakValue,
      rate: shift.billingRate,
      //   duration:  shift.positionId,
    };
  });

  const payload = {
    employerId: data[0].employerId,
    invoiceYear: data[0].year,
    invoiceWeek: data[0].week,
    shifts,
  };

  return ApolloClient.mutate({
    mutation: createEmployerInvoiceMutation,
    variables: { data: payload },
  })
  .then(({ errors }) => {
      if (errors && errors.length > 0) {
        throw new Error(errors[0].message);
      }
      dispatch(MetaActions.successToast(`Timesheet generation has started successfully.`));
      setTimeout(() => {
        dispatch(fetchEmployerInvoicesCountByWeek(payload.employerId, payload.invoiceWeek, payload.invoiceYear));
        dispatch(
          fetchEmployerInvoicesByWeek(payload.employerId, payload.invoiceWeek, payload.invoiceYear),
        );
        dispatch(MetaActions.successToast(`Timesheet generation has completed successfully.`));
      }, 3000);
    })
    .then(() => {})
    .catch(e => dispatch(MetaActions.errorToast(`An Error Occurred -  ${e.message}`)));
};

//

export const deleteEmployerInvoice = (employerId, invoiceId) => dispatch => {
  return ApolloClient.mutate({
    mutation: deleteInvoiceMutation,
    variables: { employerId, invoiceId },
  })
    .then(() => dispatch(MetaActions.successToast(`Invoice has been deleted.`)))
    .catch(e => dispatch(MetaActions.errorToast(`An Error Occurred -  ${e.message}`)));
};

export const deleteEmployerInvoiceItem = (employerId, invoiceItemId) => dispatch =>
  ApolloClient.mutate({
    mutation: deleteInvoiceItemMutation,
    variables: { employerId, invoiceItemId },
  })
    .then(({ data }) => console.log("Create Employer Invoice data yo", data.createEmployerInvoice))
    .catch(e => dispatch(MetaActions.errorToast(`An Error Occurred -  ${e.message}`)));

//
//
export const createWeeklyBillingSpreadsheet = (week, year) => async dispatch =>
  ApolloClient.query({ query: createWeeklyBillingSpreadsheetQuery, variables: { week, year } })
    .then(({ data, errors }) => {
        if(data){
            return data.createWeeklyBillingSpreadsheet.uri
        } else if(errors && errors.length > 0) {
            throw new Error(errors[0].message);
        }
    })
    .catch(e => dispatch(MetaActions.errorToast(`An Error Occurred  . ${e.message}`)));

//
export const checkWeekInvoices = (week, year) => async dispatch =>
  ApolloClient.query({ query: checkWeekInvoicesQuery, variables: { week, year } })
    .then(({ data }) => {
      //   data.checkWeekInvoices.invoiceCount > 0 &&
      //     dispatch(
      //       MetaActions.errorToast(`invoices Exist . (${data.checkWeekInvoices.invoiceCount})`)
      //     );
      // dispatch(setEmployerInvoice(data.employerInvoice))
      return data.checkWeekInvoices;
    })
    .catch(e => dispatch(MetaActions.errorToast(`An Error Occurred  . ${e.message}`)));

//
export const fetchEmployerInvoice = invoiceId => async dispatch =>
  ApolloClient.query({ query: employerInvoiceQuery, variables: { invoiceId } })
    .then(({ data }) => dispatch(setEmployerInvoice(data.employerInvoice)))
    .catch(e => dispatch(MetaActions.errorToast(`An Error Occurred  . ${e.message}`)));

export const fetchDeletedInvoices = (employerId, invoiceWeek, invoiceYear) => async dispatch =>
  ApolloClient.query({ query: deletedInvoiceQuery, variables: { employerId, invoiceWeek, invoiceYear } })
    .then(({ data }) => dispatch(setDeletedInvoice(data.deletedInvoiceLogsByYearAndWeek)))
    .catch(e => dispatch(MetaActions.errorToast(`An Error Occurred  . ${e.message}`)));

//
export const fetchEmployerInvoices = employerId => async dispatch =>
  ApolloClient.query({ query: employerInvoicesQuery, variables: { employerId } })
    .then(({ data }) => dispatch(setEmployerInvoices(data.employerInvoices)))
    .catch(e => dispatch(MetaActions.errorToast(`An Error Occurred  . ${e.message}`)));

//

export const fetchEmployerInvoicesByWeek = (employerId, week, year) => async dispatch =>
  ApolloClient.query({ query: employerInvoicesByWeekQuery, variables: { employerId, week, year } })
    .then(({ data }) => {
      dispatch(setEmployerInvoices(data.employerInvoicesByWeek));
      return data.employerInvoicesByWeek;
    })
    .catch(e => dispatch(MetaActions.errorToast(`An Error Occurred  yo. ${e.message}`)));

export const fetchEmployerInvoicesCountByWeek = (employerId, week, year) => async dispatch =>
  ApolloClient.query({ query: employerInvoicesCountByWeekQuery, variables: { employerId, week, year } })
    .then(({ data }) => {
      dispatch(setEmployerWeekSummeru(data.employerInvoicesCountByWeek));
      return data.employerInvoicesCountByWeek;
    })
    .catch(e => console.log(e));

export const fetchEmployerInvoiceItems = invoiceId => async dispatch =>
  ApolloClient.query({ query: employerInvoiceItemsQuery, variables: { invoiceId } })
    .then(({ data }) => dispatch(setEmployerInvoiceItems(data.employerInvoiceItems)))
    .catch(e => dispatch(MetaActions.errorToast(`An Error Occurred  . ${e.message}`)));

//
export const fetchBillingWeeks = () => async (dispatch, getState) => {
  const state = getState().finance;

  try {
    return state.billingWeeks;
  } catch (e) {
    console.log(e);
  }
};

export const fetchEmployerBillingWeek = () => async (dispatch, getState) => {
  const state = getState().finance;

  try {
    return state.billingWeeks;
  } catch (e) {
    console.log(e);
  }
};

export const fetchEmployerBillingWeekShifts = () => async (dispatch, getState) => {
  try {
    const state = await getState().finance.employerBillingWeekShifts;
    return state;
  } catch (e) {
    console.log(e);
  }
};

//
export const fetchWeeklyBillingPerformance = week => async dispatch => {
  try {
    const payload = await ApolloClient.query({
      query: weeklyBillingPerformanceQuery,
      variables: { week },
    });
    dispatch(setWeeklyBillingPerformance(payload.data.weeklyBillingPerformance));
  } catch (e) {
    console.log(e);
  }
};

//
export const fetchBillingWeekSummary = (week, year) => async dispatch => {
  try {
    const payload = await ApolloClient.query({
      query: billingWeekSummaryQuery,
      variables: { week, year },
    });

    dispatch(setBillingWeekSummary(payload.data.billingWeekSummary));
  } catch (e) {
    console.log(e);
  }
};

export const fetchPreviousBillingWeekSummary = (week, year) => async dispatch => {
  try {
    const payload = await ApolloClient.query({
      query: billingWeekSummaryQuery,
      variables: { week, year },
    });
    dispatch(setPreviousBillingWeekSummary(payload.data.billingWeekSummary));
  } catch (e) {
    console.log("error", e);
  }
};

//
export const fetchEmployerBillingWeekSummary2 = (employerId, week, year) => async dispatch => {
  dispatch(EmployerActions.fetchEmployer(employerId));
  try {
    const payload = await ApolloClient.query({
      query: employerWeeklyBillingQuery,
      variables: { employerId, week, year },
    });

    dispatch(setEmployerBillingWeekSummary(payload.data.employerWeeklyBilling));

    return payload.data.employerWeeklyBilling;
  } catch (e) {
    console.log(e);
  }
};
export const fetchEmployerBillingWeekSummary = (employerId, week, year) => async dispatch =>
  ApolloClient.query({ query: employerWeeklyBillingQuery, variables: { employerId, week, year } })
    .then(({ data }) => {
      dispatch(setEmployerBillingWeekSummary(data.employerWeeklyBilling));
      dispatch(EmployerActions.fetchEmployer(employerId));
      dispatch(fetchEmployerInvoicesCountByWeek(employerId, week, year));

      // ApolloClient.query({
      //   query: employerWeeklyBillingQuery,
      //   variables: { employerId, week: week - 1, year },
      // })
      //   .then(({ data }) => dispatch(setPreviousBillingWeekSummary(data.employerWeeklyBilling)))
      //   .catch(e => dispatch(MetaActions.errorToast(`An Error Occurred  . ${e.message}`)));
      return data.employerWeeklyBilling;
    })
    .catch(e => dispatch(MetaActions.errorToast(`An Error Occurred  . ${e.message}`)));

//
export const fetchEmployerBillingYearSummary = () => async (dispatch, getState) => {
  try {
    // if (getState().finance.billingYearSummary.length) return;

    dispatch(setLoadingBillingSummeryState(true));
    const payload = await ApolloClient.query({
        query: billingYearSummaryQuery,
        // variables: { employerId, week },
    });
    dispatch(setLoadingBillingSummeryState(false));
    dispatch(setEmployerBillingYearSummary(payload.data.billingYearSummary));
} catch (e) {
    console.log(e);
    dispatch(setLoadingBillingSummeryState(false));
  }
};
