/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from "react";
import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";
import DisableCellOverlay from "../../components/Controls/DisableCellOverlay";
import Can from "components/Authorization/Can";
import { PositionPermissions } from "helpers/constants/permissions";

export default function useAvatar(disabled) {
    const canAddWorker = useSelector(JobDetailSelectors.getCanAddWorkerInDashboard);
    return {
        Header: "",
        width: 80,
        sortable: false,
        id: "profileImage",
        Cell: ({ row: { original: { fullName, signedProfileImageUrl, vaccinationStatus } } }) => (
            <>
                <Avatar alt={fullName} src={signedProfileImageUrl} style={vaccinationStatus === 'Approved' ? { border: '3px solid #3e938b' } : {}} />
                <DisableCellOverlay visible={disabled} />
            </>
        ),
        Footer: (<Can perform={PositionPermissions.create.fill.value}>
            {allowed => {
                if (!allowed) {
                    return null;
                }
                return canAddWorker && !disabled ? <Avatar /> : null
            }}
        </Can>)
    };
}
