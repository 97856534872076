import gql from "graphql-tag";

export default gql`mutation ResolveHolidayPayment($paymentId: Int!) {
    resolveHolidayPayment(paymentId: $paymentId) {
      id
      createdAt
      updatedAt
      holidayWorkerId
      responseAt
      sentAt
      workerId
      holidayPayrollId
      retryPaymentId
      paymentStatusId
      dcTransferId
      dcCustomerNumber
      paymentProviderReferenceCode
      email
      securityQuestion
      securityAnswer
      amount
      errorCode
      errorDescription
      notes
    }
  }`;
