import React from "react";
import FormRow from "./FormRow";
import { useDispatch } from "react-redux";
import AddressAutocomplete from "components/Autocomplete/AddressAutocomplete";
import PropTypes from "prop-types";
import { actions as PostingActions, selectors as PostingSelectors } from "store/modules/posting";
import { Grid, TextField, FormControl } from "@mui/material";
import * as tzlookup from 'tz-lookup';

function LocationSection(props) {

    const {
        selectedLocation,
        unit,
        floor,
        locationInstruction,
    } = props;

    const dispatch = useDispatch();

    const onAddressChange = (selectedLocation, location) => {
        if (location) {
            const timezone = tzlookup(location.lat, location.lng);
            dispatch(PostingActions.setTimeZone(timezone));
        }
        dispatch(PostingActions.setLocation(selectedLocation, location));
    }

    const onUnitChange = (event) => {
        if (event.target.value.length <= 50) {
            dispatch(PostingActions.setUnit(event.target.value))
        }
    }

    const onFloorChange = (e) => {
        if (e.target.value.length <= 50) {
            dispatch(PostingActions.setFloor(e.target.value));
        }
    }

    const handleChangeInstruction = (e) => {
        if (e.target.value.length <= 100) {
            dispatch(PostingActions.setLocationInstruction(e.target.value));
        }
    }

    return (
        <React.Fragment>
            <FormRow >
                <AddressAutocomplete value={selectedLocation} onChange={onAddressChange} menuPortalTarget={document.body}/>
            </FormRow>

            <FormRow style={{ marginTop: 32 }}>
                <FormControl fullWidth>
                    <Grid container item xs={12}>
                        <Grid container item xs={6} style={{ paddingRight: 4, zIndex: 100 }}>
                            <TextField
                                id="unit"
                                label="Unit"
                                helperText="Optional"
                                value={unit}
                                placeholder="Unit"
                                onChange={onUnitChange}
                                margin="none"
                                fullWidth
                            />
                        </Grid>
                        <Grid container item xs={6} style={{ paddingRight: 4, zIndex: 100 }}>
                            <TextField
                                id="floor"
                                name="floor"
                                label="Floor"
                                placeholder="Floor"
                                helperText="Optional"
                                value={floor}
                                onChange={onFloorChange}
                                margin="none"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </FormControl>
            </FormRow>

            <FormRow style={{ marginTop: 32 }}>
                <FormControl fullWidth>
                    <TextField
                        id="locationInstruction"
                        name="locationInstruction"
                        label="Additional Instructions"
                        placeholder="Location Instructions"
                        value={locationInstruction}
                        onChange={handleChangeInstruction}
                        helperText="Optional"
                        fullWidth
                        margin="none"
                    />
                </FormControl>
            </FormRow>
        </React.Fragment>
    )
}

LocationSection.propTypes = {
    unit: PropTypes.string.isRequired,
    floor: PropTypes.string.isRequired,
    locationInstruction: PropTypes.string.isRequired,
    selectedLocation: PropTypes.object,
}

LocationSection.defaultProps = {
    selectedLocation: null,
}

export default LocationSection
