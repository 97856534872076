import { gql } from "@apollo/client";

export default gql`
mutation updateCustomField($customData: UpdateCustomField!) {
    updateCustomField(updateCustomField: $customData) {
        id
        name
        slug
        type
        isRequired
        isForAll
        createdBy
        updatedBy
        deletedBy
        createdAt
        updatedAt
    }
}`;
