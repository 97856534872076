import { CustomFieldTypesObject } from "utils/constant";
import * as pagination from "utils/graphql-pagination";
import { paginationSettings } from "./constants";

// Loading selectors
export const getLoadingStateFactory = loader => state => {
    return state.customFields.loading[loader] || false;
};

export const getDialogStateFactory = loader => state => {
    return state.customFields.dialog[loader] || false;
};

export const getIsAddCustomField = getLoadingStateFactory("isAddCustomField");
export const getIsFillUpdateCustomFieldDataLoading = getLoadingStateFactory("fillUpdateData");
export const getIsDeletingCustomField = getLoadingStateFactory("isDeleting");
export const getIsDetailPageLoading = getLoadingStateFactory("detailLoading");
export const getIsRemoveUserLoading = getLoadingStateFactory("removeUserLoading");
export const getIsAddUserLoading = getLoadingStateFactory("addUserLoading");

export const getIsOpenDeleteConfirmationCustomField = getDialogStateFactory("deleteConfirmation");
export const getIsOpenRemoveUserCustomField = getDialogStateFactory("removeUser");
export const getIsOpenAddUserCustomField = getDialogStateFactory("addUser");


export const getCustomFieldListPagingData = state => {
    return state.customFields[pagination.getPageInfoAttr(paginationSettings.customFieldList)];
};

export const getCustomFieldsData = (state, { part }) => state.customFields.customFieldList[part];

export const getEmployerListData = (state, { part }) => state.customFields.employerList[part];

export const getWorkerListData = (state, { part }) => state.customFields.workerList[part];

export const getEmployerPaginationData = state => {
    return state.customFields[pagination.getPageInfoAttr(paginationSettings.employerList)];
};

export const getWorkerPaginationData = state => {
    return state.customFields[pagination.getPageInfoAttr(paginationSettings.workerList)];
};

export const getFormData = (state, part) => part ? state.customFields.form[part] : state.customFields.form || {};

export const canAddCustomField = (state, isEdit) => {
    return (
        state.customFields.form.name?.length <= 50 &&
        state.customFields.form.name?.trim() &&
        state.customFields.form.slug?.length <= 50 &&
        state.customFields.form.slug?.trim() &&
        (state.customFields.form.type?.trim() && (
            state.customFields?.form?.type === CustomFieldTypesObject?.TEXTBOX ||
            state.customFields?.form?.type === CustomFieldTypesObject?.FILE ||
            (state.customFields?.form?.options?.length && state.customFields?.form?.options?.every(a => a.value?.length <= 50 && a.value?.trim() && a.label?.length <= 50 && a.label?.trim()))
        ) && (isEdit || (state.customFields?.form?.assignTo === "WORKER" ? state.customFields?.form?.workers?.length : (state.customFields?.form?.isAllEmployer || state.customFields?.form?.employers?.length))))
    )
}

export const getCustomFieldDetail = (state) => state.customFields.detail.customField;

export const getDetailEmployerListData = (state, { part }) => state.customFields.detail.employerList[part];

export const getDetailWorkerListData = (state, { part }) => state.customFields.detail.workerList[part];

export const getDetailEmployerPaginationData = state => {
    return state.customFields[pagination.getPageInfoAttr(paginationSettings.detailEmployerList)];
};

export const getDetailWorkerPaginationData = state => {
    return state.customFields[pagination.getPageInfoAttr(paginationSettings.detailWorkerList)];
};

export const getActiveTab = (state) => state.customFields.activeTab;
