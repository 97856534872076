import React, { useEffect, useState } from "react";
import { actions as FaqActions, selectors as FaqSelectors } from "store/modules/faqs";
import { useDispatch, useSelector } from "react-redux";
import SubNav from "components/Navigation/SubNav";
import SubNavTab from "components/Navigation/SubNavTab";
import { Contacts, RecentActors } from "@mui/icons-material";
import TermsOfServiceEditor from "./Editor";

const TermsOfService = () => {
    const dispatch = useDispatch();
    const termsOfService = useSelector(FaqSelectors.getTermsOfService);
    const isLoading = useSelector(FaqSelectors.getIsLoading);
    const [tab, setTab] = useState(0);

    useEffect(() => {
        dispatch(FaqActions.fetchTermsOfService());
    }, []);

    const saveChange = (request) => {
        dispatch(FaqActions.updateTermsAndConditions({
            work: termsOfService?.work || "",
            // termsAndConditions: termsOfService?.termsAndConditions || "",
            workFrench: termsOfService?.workFrench || "",
            hire: termsOfService?.hire || "",
            hireFrench: termsOfService?.hireFrench || "",
            ...request
        }));
    };

    const handleTabChange = (event, value) => {
        setTab(value);
    };

    return (
        <div>
            <SubNav
                value={tab}
                onChange={handleTabChange}
                tabs={[
                    <SubNavTab
                        key="workerFaqs"
                        label="Worker English T&C"
                        icon={<RecentActors />}
                        count={null}
                    />,
                    <SubNavTab
                        key="workerFaqsFrench"
                        label="Worker French T&C"
                        icon={<RecentActors />}
                        count={null}
                    />,
                    <SubNavTab
                        key="employerFaqs"
                        label="Employer Einglish T&C"
                        icon={<Contacts />}
                        count={null}
                    />,
                    <SubNavTab
                        key="employerFaqsFrench"
                        label="Employer French T&C"
                        icon={<Contacts />}
                        count={null}
                    />,
                ]}
            />

            {tab === 0 && <TermsOfServiceEditor
                isLoading={isLoading}
                saveChange={(work) => saveChange({ work })}
                termsOfService={termsOfService?.work || ""}
            />}
            {tab === 1 && <TermsOfServiceEditor
                isLoading={isLoading}
                saveChange={(workFrench) => saveChange({ workFrench })}
                termsOfService={termsOfService?.workFrench || ""}
            />}
            {tab === 2 && <TermsOfServiceEditor
                isLoading={isLoading}
                saveChange={(hire) => saveChange({ hire })}
                termsOfService={termsOfService?.hire || ""}
            />}
            {tab === 3 && <TermsOfServiceEditor
                isLoading={isLoading}
                saveChange={(hireFrench) => saveChange({ hireFrench })}
                termsOfService={termsOfService?.hireFrench || ""}
            />}
        </div >
    );
};

export default TermsOfService;
