import gql from "graphql-tag";

export default gql`
mutation($data: CreateGearInput!) {
    createGear(data: $data){
        gearName
        frenchGearName
        isActive
    }
}
`
