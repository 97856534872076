import React, { useState } from "react";
import Dialog from "components/Dialog/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { actions as workTypesActions, selectors as workTypesSelectors } from "store/modules/workTypes";

const style = ({ spacing }) => ({
    container: {
        marginBottom: spacing(2)
    }
})

function AddWorkTypeDialog({ classes }) {

    const dispatch = useDispatch();
    const [workTypeLabel, setWorkTypeLabel] = useState("");
    const [frenchName, setFrenchName] = useState("");
    const isAddGearDialog = useSelector(workTypesSelectors.getIsAddWorkTypeDialog);

    const hideAddWorkTypeDialog = () => {
        dispatch(workTypesActions.setIsAddWorkTypeDialog(false));
    }

    const handleWorkType = (event) => {
        setWorkTypeLabel(event.target.value);
    }

    const handleFrenchName = (event) => {
        setFrenchName(event.target.value);
    }

    const AddWorkType = () => {
        dispatch(workTypesActions.createWorkType({ frenchName, label: workTypeLabel }));
        setWorkTypeLabel("");
        setFrenchName("");
    }

    return (
        <Dialog
            open={isAddGearDialog}
            title="Add Work Type"
            onClose={hideAddWorkTypeDialog}
            onCancel={hideAddWorkTypeDialog}
            onConfirm={AddWorkType}
            disabled={!workTypeLabel?.trim?.() || !frenchName?.trim?.()}
            alertType="form"
            confirmText="Add"
            size="sm"
            modalType="scrollable"
        >
            <div className={classes.container}>
                <TextField
                    id="label"
                    margin="normal"
                    label="Label"
                    value={workTypeLabel}
                    onChange={handleWorkType}
                    required
                    fullWidth
                />
                <TextField
                    id="label"
                    margin="normal"
                    label="French Label"
                    value={frenchName}
                    onChange={handleFrenchName}
                    required
                    fullWidth
                />
            </div>
        </Dialog>
    )
}

export default (withStyles(style)(AddWorkTypeDialog))
