export const tabStyles = ({ spacing, shape, palette, customPalette }) => ({
  tabHeader: {},
  tabLabel: {
    lineHeight: 1.5,
    marginRight: spacing(2),
    "& + span": {
      fontWeight: 500,
    },
  },
  createWorkerWrapper: {
    // width: "100%",
    float: "right",
    textAlign: "right",
  },
  tabRoot: {
    marginRight: spacing(3),
    padding: `${spacing(2)} ${spacing(3)}`,
    borderRadius: `${shape.borderRadius}px`,
    opacity: 1,
    minWidth: spacing(21),
    "&:hover": {
      border: `1px solid ${palette.secondary.main}`,
      padding: `calc(${spacing(2)} - 1px) calc(${spacing(3)} - 1px)`,
    },
  },
  tabLabelContainer: {
    padding: 0,
  },
  tabSelected: {
    padding: `${spacing(2)} calc(${spacing(3)} - 2px)`,

    border: `2px solid ${palette.secondary.main}`,
    backgroundColor: customPalette.primary[200],
    "&:hover": {
      border: `2px solid ${palette.secondary.main}`,
      padding: `${spacing(2)} calc(${spacing(3)} - 2px)`,
    },
    "& $tabHeader, & $tabLabel": {
      color: palette.secondary.main,
    },
  },
  tabTotalWrapper: {
    padding: `${spacing(2)} ${spacing(3)}`,
    borderLeft: `2px solid ${palette.action.disabled}`,
    maxWidth: spacing(26),
    "& span": {
      color: palette.text.primary,
    },
  },
  tabIndicator: {
    display: "none !important",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    // padding: `${spacing(4)} 0px`,
  },
  contentContainerNoPad: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: `${spacing(1)} 0px`,
  },
  progress: {
    color: palette.secondary.light,
    position: "absolute",
    top: "50%",
    left: "45%",
  },
});
