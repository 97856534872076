import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { sample } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import CustomFieldTableActionColumn from 'views/CustomFields/CustomFieldOverview/helper/CustomFieldTableActionColumn';

const useStyles = makeStyles((theme) => ({
    container: {
        "& p": {
            marginRight: theme.spacing(2),
        },
        "& svg": {
            marginRight: theme.spacing(1),
        },
    },
    moreIconButton: {
        padding: 0
    }
}));


export default function CustomFieldDetailHeader() {
    const classes = useStyles();
    const params = useParams();

    return (
        <Grid container>
            <Grid container item xs={12} justifyContent="flex-end" alignItems="center">
                <CustomFieldTableActionColumn field={{ id: +params.id }} />
            </Grid>
        </Grid>
    )
}
