/* eslint-disable import-helpers/order-imports */
import React, { useEffect, useState } from "react";
import MainContent from "components/Containers/MainContent";
import DocumentTypeTable from "./components/DocumentTypeTable";
import { Add } from "@mui/icons-material";
import Button from "components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import AddDocumentTypeDialog from "./components/AddDocumentTypeDialog";
import { actions as documentTypeActions, selectors as documentTypeSelectors } from "store/modules/documentType";
import DeleteDocumentTypeDialog from "./components/DeleteDocumentTypeDialog";

function DocumentTypeOverview() {
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [selectedDeleteDocument, setSelectedDeleteDocument] = useState(null);
    const isOpenDialog = useSelector(documentTypeSelectors.getIsAddDocumentTypeDialog);
    const isOpenDeleteDialog = useSelector(documentTypeSelectors.getIsDeleteDocumentTypeDialog);
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedDocument && !isOpenDialog) setSelectedDocument(null);
    }, [isOpenDialog, selectedDocument]);

    useEffect(() => {
        if (selectedDeleteDocument && !isOpenDeleteDialog) setSelectedDeleteDocument(null);
    }, [isOpenDeleteDialog, selectedDeleteDocument]);

    const addNewDocumentType = () => {
        dispatch(documentTypeActions.setIsAddDocumentTypeDialog(true));
    }

    const showEditDialog = (documentType) => {
        dispatch(documentTypeActions.setIsAddDocumentTypeDialog(true));
        setSelectedDocument(documentType)
    }

    const showDeleteDialog = (documentType) => {
        dispatch(documentTypeActions.setIsDeleteDocumentTypeDialog(true));
        setSelectedDeleteDocument(documentType)
    }

    return (
        <>
            <MainContent>
                <Button
                    style={{ alignSelf: "flex-end", marginBottom: 24 }}
                    label="Add"
                    icon={<Add />}
                    alignIcon="left"
                    onClick={addNewDocumentType}
                />
                <DocumentTypeTable onEdit={showEditDialog} onDelete={showDeleteDialog}/>
            </MainContent>
            <AddDocumentTypeDialog selectedDocument={selectedDocument} />
            <DeleteDocumentTypeDialog selectedDocument={selectedDeleteDocument} />
        </>
    )
}

export default DocumentTypeOverview
