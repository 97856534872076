// Loading selectors
export const getLoadingStateFactory = loader => state => {
    return state.holidays.loading[loader] || false;
};

export const getIsTableLoading = getLoadingStateFactory("tableLoading");
export const getIsDetailLoading = getLoadingStateFactory("detailLoading");
export const getIsDialogActionLoading = getLoadingStateFactory("dialogActionLoading");
export const getIsDeleteHolidayLoading = getLoadingStateFactory("isDeleteHolidayLoading");
export const getIsWorkersLoading = getLoadingStateFactory("workersLoading");
export const getIsRefreshWorkers = getLoadingStateFactory("refreshWorkers");
export const getIsInitializingHolidayPayroll = getLoadingStateFactory("initializingHolidayPayroll");
export const getIsPayPosition = getLoadingStateFactory("payPosition");
export const getIsCreatePaystubLoading = getLoadingStateFactory("createPaystubLoading");
export const getIsDownloadPaystubLoading = getLoadingStateFactory("downloadPaystubLoading");

// Dialog selectors
export const getDialogStateFactory = loader => state => {
    return state.holidays.dialog[loader] || false;
};

export const getIsAddHolidayDialog = getDialogStateFactory("addHolidayDialog");
export const getIsDeleteHolidayDialog = getDialogStateFactory("deleteHolidayDialog");
export const getIsEditHolidayDialog = getDialogStateFactory("editHolidayDialog");
export const getIsPayrollSummaryDialog = getDialogStateFactory("payrollSummary");
export const getIsResendPayStubDialog = getDialogStateFactory("resendPayStub");
export const getIsCancelETransferDialog = getDialogStateFactory("cancelETransfer");
export const getIsResendETransferDialog = getDialogStateFactory("resendETransfer");
export const getIsRequestReversalDialog = getDialogStateFactory("requestReversal");
export const getIsResolveETransferDialog = getDialogStateFactory("resolveETransfer");
export const getIsResolveReversalDialog = getDialogStateFactory("resolveReversal");
export const getIsResendReversalDialog = getDialogStateFactory("resendReversal");
export const getIsCancelReversalDialog = getDialogStateFactory("cancelReversal");

// holidays selectors
export const getHolidaysList = state => {
    return state.holidays.lists;
};


export const getHolidayEditFormData = state => {
    return state.holidays.editFormData;
};

// Holiday details
export const getHolidayDetail = state => {
    return state?.holidays?.details;
};

export const getHolidayWorkers = state => {
    return state.holidays.holidayWorkers;
};

export const getHolidayPayrollSummaryWorkers = state => {
    return state.holidays.payrollSummary;
};
