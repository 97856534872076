import { gql } from "@apollo/client";

export default gql`
mutation UpdateWorkerPermitData($status: Boolean, $workerId: String) {
    updateWorkerPermitData(status: $status, workerId: $workerId) {
      approvedAt
      firstName
      fullName
      workPermitUrl
      signedWorkPermitImageUrl
      workPermitStatus
      workPermitExpiryDate
    }
  }
`;
