import React, { useEffect, useState } from "react";
import MainContent from "components/Containers/MainContent";
import { Add, Contacts, RecentActors } from "@mui/icons-material";
import Button from "components/Button/Button";
import CustomFieldTable from "./components/CustomFieldTable";
import { useDispatch, useSelector } from "react-redux";
import { actions as CustomFieldActions, selectors as CustomFieldSelectors } from "store/modules/customFields";
import Dialog from "components/Dialog/Dialog";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SubNav from "components/Navigation/SubNav";
import SubNavTab from "components/Navigation/SubNavTab";
import SwipeableViews from "react-swipeable-views";

const useStyles = makeStyles(theme => ({
    progress: {
        color: "#11b2a8",
        position: "absolute",
        top: "50%",
        left: "45%",
    },
    svgRoot: {
        marginTop: `0 !important`,
        margin: `0 !important`
    },
    addButton: {
        alignSelf: "flex-end",
        padding: 0
    }
}));

function CustomFieldOverview(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const deleteConfirmation = useSelector(CustomFieldSelectors.getIsOpenDeleteConfirmationCustomField);
    const isDeleting = useSelector(CustomFieldSelectors.getIsDeletingCustomField);
    const activeTab = useSelector(CustomFieldSelectors.getActiveTab);
    const params = new URLSearchParams(props.history.location.search);
    const type = parseInt(params.get("type"), 10);

    useEffect(() => {
        if (props.history.location.search) {
            if (!isNaN(type)) {
                dispatch(CustomFieldActions.setActiveTab(parseInt(type, 10)));
            }
        } else {
            clearData();
            props.history.push(`/custom-field/overview?type=${0}`);
        };
    }, [type]);

    const handleChangeTab = (event, value) => {
        props.history.push(`/custom-field/overview?type=${value}`);
        clearData();
        dispatch(CustomFieldActions.setActiveTab(value));
    };

    const clearData = () => {
        dispatch(CustomFieldActions.setCustomFieldListData([], "data"));
        dispatch(CustomFieldActions.setCustomFieldListSort([{ id: "id", desc: true }]));
        dispatch(CustomFieldActions.setCustomFieldListFilter([]));
    }

    const handleAdd = () => {
        dispatch(CustomFieldActions.clearCustomFieldForm());
        dispatch(CustomFieldActions.setCustomFieldForm("assignTo", activeTab === 0 ? "EMPLOYER" : "WORKER"));
        props.history.push("/custom-field/add");
    };

    const handleCloseDeleteDialog = () => {
        dispatch(CustomFieldActions.setIsOpenDeleteConfirmationCustomField(false));
    };

    const handleConfirmDeleteDialog = () => {
        dispatch(CustomFieldActions.deleteCustomField());
    };


    return (
        <>
            <SubNav value={activeTab} onChange={handleChangeTab} tabs={[
                <SubNavTab key="Employer" label="Employer" icon={<Contacts />} />,
                <SubNavTab key="Worker" label="Worker" icon={<RecentActors />} />,
                <Button
                    key={"ADD-CUSTOMFIELD"}
                    type="Button"
                    className={classes.addButton}
                    label="Add new custom field"
                    icon={<Add />}
                    alignIcon="left"
                    onClick={handleAdd}
                />
            ]} />
            <MainContent>
                <SwipeableViews axis="x" index={activeTab}>
                    {type === 0 ? <>
                        <CustomFieldTable tab={0} activeTab={activeTab} />
                    </> : <></>}
                    {type === 1 ? <>
                        <CustomFieldTable tab={0} activeTab={activeTab} />
                    </> : <></>}
                </SwipeableViews>
            </MainContent>
            <Dialog
                open={Boolean(deleteConfirmation)}
                title={`Delete Custom Field`}
                description={`Are you sure to delete ${deleteConfirmation?.name || ""} custom field?`}
                confirmText={isDeleting ? <CircularProgress classes={{ svg: classes.svgRoot }} size={18} /> : "Delete"}
                disabled={isDeleting}
                onClose={handleCloseDeleteDialog}
                onConfirm={handleConfirmDeleteDialog}
                onCancel={handleCloseDeleteDialog}
                icon="delete"
                alertType="question" />
        </>
    )
}

export default CustomFieldOverview;
