import React, { useEffect, useState } from 'react'
import { fetchProvinces } from './helpers/actions';
import { regions as Regions } from 'utils/constant';
import { CircularProgress, InputAdornment, MenuItem, TextField } from '@mui/material';

export default function ProvinceDropDown({ ...props }) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        setLoading(true)
        fetchProvinces().then(({ data: { regions } }) => {
            setData(regions.map(a => ({
                value: Regions[a.code] || a.code,
                label: a.name,
            })));
            setLoading(false);
        }).catch(er => {
            setLoading(false);
        })
    }, []);

    return (
        <TextField
            select
            id="region"
            label="Region"
            name="region"
            fullWidth
            margin="normal"
            InputProps={{
                endAdornment: loading ? (
                    <InputAdornment position="end" style={{ marginRight: 16 }}>
                        <CircularProgress size={12} />
                    </InputAdornment>
                ) : null,
            }}
            {...props}
        >
            {data.map(d => (<MenuItem key={d.value} value={d.value}>{d.label}</MenuItem>))}
        </TextField>
    )
}
