import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank } from '@mui/icons-material';
import { Autocomplete, Checkbox, CircularProgress, createFilterOptions, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { fetchProvinces } from './helpers/actions';

const useStyles = makeStyles(theme => ({
    inputPadding: {
        "& input": {
            padding: `${theme.spacing(.3)} !important`
        }
    },
}));

export default function MultipleProvinceAutocomplete({ value = [], onChange = () => 0, textFieldProps = {}, ...props }) {
    const classes = useStyles();
    const [state, setState] = useState({ loading: false, options: [] });

    useEffect(() => {
        setState(p => ({ ...p, isLoading: true }));
        fetchProvinces().then(({ data: { regions } }) => {
            setState(p => ({
                ...p,
                isLoading: false,
                options: regions.map(a => ({
                    value: a.id,
                    label: a.name,
                    data: a
                }))
            }));
        }).catch(er => {
            setState(p => ({ ...p, isLoading: false }));
        })
    }, []);

    return (
        <Autocomplete
            multiple
            className={classes.inputPadding}
            id="provinces"
            loading={state.isLoading}
            name="provinces"
            options={state.options}
            disableCloseOnSelect
            filterOptions={(options, params) => {
                const filtered = createFilterOptions()(options, params);
                return [{ label: "Select All Provinces", id: "select-all", value: "select-all" }, ...filtered];
            }}
            onChange={(_, val, reason, op) => {
                if (op && op.option && op.option.value === "select-all") {
                    onChange(state.options?.length === value?.length ? [] : state.options)
                    return;
                };
                if (reason === "clear") {
                    onChange(val)
                    return;
                }
                const data = Object.assign([], value);
                const ind = data.findIndex(o => o.value === op.option.value);
                if (ind >= 0) data.splice(ind, 1)
                else data.push(op.option);
                onChange(data)
            }}
            value={value}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
                <li {...props}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        indeterminate={option.value === "select-all" && value?.length && value?.length !== state.options.length}
                        style={{ marginRight: 8 }}
                        size="small"
                        checked={Boolean(option.value === "select-all" ? value?.length === state.options.length : value.find(a => a.value === option.value))}
                    />
                    {option.label}
                </li>
            )}
            size="small"
            style={{ width: 280 }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name="provinces"
                    label="Select Provinces"
                    placeholder="Select Province..."
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {state.isLoading ? <CircularProgress color="inherit" size={18} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    {...textFieldProps}
                />
            )}
            {...props}
        />
    )
}
