import Callback from "Callback.jsx";
import Dashboard from "layouts/Dashboard.jsx";
import EmailVerifySuccess from "../views/Email/EmailVerifySuccess";
import ResetPassword from "../views/Login/ResetPassword";

var indexRoutes = [
  { path: "/callback", component: Callback },
  { path: "/email-verified", component: EmailVerifySuccess },
  { path: "/reset-password", component: ResetPassword },
  { path: "/", name: "Home", component: Dashboard },
];

export default indexRoutes;
