import { gql } from "@apollo/client";

export default gql`
mutation UpdateWorkTypeRate($data: EditWorkTypeRateInput!) {
    updateWorkTypeRate(data: $data) {
      id
      createdAt
      updatedAt
      employerId
      workTypeId
      payRate
      billingRate
      workType {
        label
      }
    }
  }
`;
