import { ArrowDropDown, ArrowRight, MonetizationOn, Refresh } from '@mui/icons-material';
import { Avatar, Grid, Tooltip, Typography, Button as MuiButton, IconButton } from '@mui/material'
import { PayrollBadge } from 'components/Payments/Badge';
import Line from 'components/Placeholders/Line';
import ReactTableSimple from 'components/ReactTable/ReactTableSimple';
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { formatMoney } from 'utils/stringUtils';
import { actions as HolidayActions, selectors as HolidaySelectors } from "store/modules/holidays"
import { useSelector } from 'react-redux';
import HolidayWorkerActionMenu from './Menu/HolidayWorkerActionMenu';
import HolidayWorkerPayrollTable from './HolidayWorkerPayrollTable';

export default function HolidayWorkerTable({ holiday, province }) {
    const dispatch = useDispatch();
    const isLoading = useSelector(HolidaySelectors.getIsWorkersLoading);
    const workers = useSelector(HolidaySelectors.getHolidayWorkers);

    useEffect(() => {
        if (province?.provinceId)
            dispatch(HolidayActions.getHolidayWorkersByProvince(province?.provinceId))
    }, [province?.provinceId]);

    return (
        <>
            <ReactTableSimple
                loading={isLoading}
                data={workers}
                // initialSort={[{ id: "holidayDate", desc: true }]}
                expandView={props => <HolidayWorkerPayrollTable holiday={holiday} province={province} {...props} />}
                columns={[
                    {
                        Header: "",
                        disableFilters: true,
                        disableSortBy: true,
                        id: "profileImage",
                        headerStyle: { minWidth: 80 },
                        collapse: true,
                        Cell: ({ row: { original: { worker: { fullName, signedProfileImageUrl, vaccinationStatus } } } }) => (
                            <>
                                <Avatar alt={fullName} src={signedProfileImageUrl} style={vaccinationStatus === 'Approved' ? { border: '3px solid #3e938b' } : {}} />
                            </>
                        ),
                    },
                    {
                        Header: "Worker",
                        id: "name",
                        accessor: ({ worker: { fullName, id } }) => <Link to={`/workers/${id}`}>{fullName}</Link>,
                    },
                    {
                        Header: "Email",
                        id: "email",
                        accessor: ({ worker: { email } }) => email,
                    },
                    {
                        //   width: 75,
                        sortable: false,
                        id: "worker.dcCustomerNumber",
                        style: { alignItems: "left" },
                        Header: "DC Bank Id",
                        accessor: ({ worker: { dcCustomerNumber } }) => dcCustomerNumber || <em>Empty</em>
                    },
                    {
                        Header: "Gross Pay",
                        id: "grossPay",
                        width: 80,
                        Cell: ({ row: { original } }) => {
                            return (
                                <Typography variant="body1">{formatMoney(original.grossPay)}</Typography>
                            )
                        }
                    },
                    {
                        Header: "Status",
                        id: "payrollStatus",
                        width: 40,
                        Cell: ({ row: { original: { payrollStatus } } }) => {
                            return (
                                <PayrollBadge payrollStatus={payrollStatus} iconOnly />
                            )
                        }
                    },
                    {
                        expander: true,
                        width: 30,
                        id: "expander",
                        /* eslint-disable react/display-name, react/prop-types */
                        Cell: ({ row: { original, isExpanded, ...row }, column }) => {
                            const { holidayPayroll } = original;
                            if (holidayPayroll && holidayPayroll.holidayPayment && holidayPayroll.holidayPayment.length) {
                                return (
                                    <IconButton {...row.getToggleRowExpandedProps()}>
                                        {isExpanded ? <ArrowDropDown /> : <ArrowRight />}
                                    </IconButton>
                                );
                                // };
                            }
                            return null;
                        }
                        /* eslint-enable react/display-name, react/prop-types */
                        // Cell: null,
                    },
                    {
                        Header: "",
                        id: "actions",
                        collapse: true,
                        style: { textAlign: "right" },
                        Cell: ({ row: { original } }) => {
                            return (<HolidayWorkerActionMenu position={original} />)
                        }
                    }
                ]}
            />
        </>
    )
}
