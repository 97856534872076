import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import DialogActions from "@mui/material/DialogActions";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import { Field, Form, Formik } from "formik";
import { fieldToTextField } from "formik-mui";
import _ from "lodash";
import { Autocomplete } from "formik-mui";
import * as yup from "yup";
import { getJobTypeList } from "store/modules/posting/selectors";
import { saveWorkerSetting, saveWorkerWorkTypes } from "store/modules/workerDetails/actions";

import { getTransportationTypes } from "store/modules/workerDetails/selectors";
import { setTimezoneList } from "../../../../../../store/modules/login/actions";
import { getTimezoneList } from "../../../../../../store/modules/login/selectors";
const isMobile = false;
const useStyles = makeStyles(theme => ({
    formControl: {
        marginTop: theme.spacing(4),
        width: "100%",
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: 0.5,
    },
    footer: {
        margin: 0,
        textAlign: "center",
        justifyContent: "center",
        width: "100%",
        marginTop: theme.spacing(2),
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    root: {
        margin: "auto",
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    list: {
        // width: 200,
        // height: 230,
        backgroundColor: theme.palette.background.paper,
        overflow: "auto",
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));

function WorkerSettingsTab(props) {
    const dispatch = useDispatch();
    let jobTypes = useSelector(getJobTypeList);
    let timezoneList = useSelector(getTimezoneList);
    const classes = useStyles();
    const theme = useTheme();
    const [personName] = React.useState([]);
    const [workTypeList, setWorkTypeList] = React.useState([]);
    const [workPrefs, setWorkPrefs] = React.useState([]);
    const [workerTrans, setWorkerTrans] = React.useState([]);
    const [, setEmpType] = React.useState("PartTime");
    let transportationTypes = useSelector(getTransportationTypes);
    // transportationTypes = transportationTypes.map(type => ({ id: type.id, label: type.label }));
    const {
        value,
        isActive,
        isApproved,
        isEmailVerified,
        travelRange,
        employmentType,
        timezone,
        isStudent,
        vaccinationStatus,
        isSuspended,
        handleSetCanEdit,
        workTypePreferences,
        transportationPreferences,
        isOnboarded
    } = props;

    useEffect(() => {
        // dispatch(fetchWorkTypes());
        // dispatch(fetchTransportation());
        dispatch(setTimezoneList());
    }, [dispatch]);
    useEffect(() => {
        setWorkTypeList(jobTypes.map(type => ({ label: type.label, id: type.value })));
        if (employmentType) {
            if (employmentType === "PT") {
                setEmpType("PartTime");
            }
            if (employmentType === "FT") {
                setEmpType("FullTime");
            }
        }

        setWorkPrefs([...workTypePreferences]);
        setWorkerTrans([...transportationPreferences]);
    }, [jobTypes, employmentType, transportationPreferences, workTypePreferences]);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }
    const controlPadding = {
        leftColumn: { paddingRight: isMobile ? 0 : 4 },
        rightColumn: { paddingLeft: isMobile ? 0 : 4 },
    };

    const TabPanel = props => {
        const { children, value, index, height, ...other } = props;
        return (
            <div
                role="tabpanel"
                style={{ height: height ? height : "100%", paddingTop: 20 }}
                hidden={value !== index}
                id={`scrollable-auto-tabpanel-${index}`}
                aria-labelledby={`scrollable-auto-tab-${index}`}
                {...other}>
                {value === index && children}
            </div>
        );
    };
    const handleSwitchChange = ({ target: { name, checked } }) => {
        dispatch(saveWorkerSetting({ [name]: checked }));
    };

    const FormikAutocomplete = ({ textFieldProps, ...props }) => {
        const {
            form: { setTouched, setFieldValue },
        } = props;
        const { error, helperText, ...field } = fieldToTextField(props);
        const { name } = field;

        return (
            <Autocomplete
                {...props}
                {...field}
                onChange={(_, value) => setFieldValue(name, value)}
                onBlur={() => setTouched({ [name]: true })}
                isOptionEqualToValue={(item, current) => item.id === current.id}
                renderInput={props => (
                    <TextField {...props} {...textFieldProps} helperText={helperText} error={error} />
                )}
            />
        );
    };

    const RenderEmployerSettingsForm = () => (
        <TabPanel value={value} index={1}>
            <Grid
                container
                alignContent="flex-start"
                alignItems="flex-start"
                item
                xs={12}
                style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Grid
                    item
                    xs={12}
                    md={12}
                    alignContent="flex-start"
                    alignItems="flex-start"
                    container
                    style={{ ...controlPadding.leftColumn, paddingLeft: 0, paddingRight: 20 }}>
                    <Formik
                        initialValues={{
                            travelRange,
                            transportationPreferences: [...workerTrans],
                            workTypePreferences: [...workPrefs],
                            employmentType,
                            timezone,
                        }}
                        onSubmit={(values, actions) => {
                            onSubmit(values);
                            actions.setSubmitting(false);
                        }}
                        validationSchema={yup.object().shape()}
                    >
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                dirty,
                                isSubmitting,
                                handleChange,
                                handleBlur,
                                handleReset,
                            } = props;

                            return (
                                <Form>
                                    <Grid
                                        item
                                        container
                                        direction="row"
                                        xs={12}
                                        style={{ paddingLeft: 0, paddingRight: 0, width: "100%", textAlign: "left" }}>
                                        <Grid item xs={12} md={6} style={controlPadding.leftColumn}>
                                            <Tooltip
                                                title={`The distance in Kms the worker is prepared to travel to get the a job.`}
                                                placement="top">
                                                <TextField
                                                    select
                                                    id="travelRange"

                                                    name="travelRange"
                                                    label="Travel Range"
                                                    className={classes.textField}
                                                    value={values.travelRange}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={errors.travelRange && touched.travelRange}
                                                    helperText={
                                                        errors.travelRange && touched.travelRange && errors.travelRange
                                                    }
                                                    margin="normal">
                                                    <MenuItem value={10}>10 Km</MenuItem>
                                                    <MenuItem value={20}>20 Km</MenuItem>
                                                    <MenuItem value={30}>30 Km</MenuItem>
                                                    <MenuItem value={40}>40 Km</MenuItem>
                                                    <MenuItem value={50}>50 Km</MenuItem>
                                                </TextField>
                                            </Tooltip>
                                        </Grid>

                                        <Grid item xs={12} md={6} style={controlPadding.leftColumn}>
                                            <Tooltip title={`The default timezone for the worker.`} placement="top">
                                                <TextField
                                                    select
                                                    fullWidth
                                                    id="timezone"
                                                    name="timezone"

                                                    label="Default Timezone"
                                                    onChange={handleChange}
                                                    margin="normal"
                                                    defaultValue={timezone}>
                                                    {timezoneList && timezoneList.length > 0 && timezoneList.map(n => (
                                                        <MenuItem key={n.id} value={n.timezoneName}>{n.timezoneName}</MenuItem>
                                                    ))}
                                                </TextField>
                                            </Tooltip>
                                        </Grid>
                                        {/* <Grid item xs={12} md={4} style={controlPadding.leftColumn}>
                                            <TextField
                                                select
                                                fullWidth
                                                id="vaccinationStatus"
                                                name="vaccinationStatus"
                                                label="Vaccination Status"

                                                onChange={handleChange}
                                                margin="normal"
                                                defaultValue={vaccinationStatus}>
                                                <MenuItem value={'NotVerified'}>Not Verified</MenuItem>
                                                <MenuItem value={'PendingApproval'}>Pending Approval</MenuItem>
                                                <MenuItem value={'Approved'}>Approved</MenuItem>
                                                <MenuItem value={'Decline'}>Decline</MenuItem>
                                            </TextField>
                                        </Grid> */}
                                        <Grid item xs={12} style={controlPadding.rightColumn}>
                                            <FormControl className={classes.formControl}>
                                                <InputLabel id="workTypes3">Transportation Preferences</InputLabel>
                                                <Field
                                                    name="transportationPreferences"
                                                    component={FormikAutocomplete}
                                                    label="Transportation Preferences"
                                                    options={transportationTypes.map(type => ({
                                                        id: type.id,
                                                        label: type.label,
                                                    }))}
                                                    textFieldProps={{
                                                        fullWidth: true,
                                                        margin: "none",
                                                        variant: "outlined",
                                                    }}
                                                    multiple
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} style={controlPadding.leftColumn}>
                                            <FormControl className={classes.formControl}>
                                                <InputLabel id="workTypes2">Work Types</InputLabel>
                                                <Field
                                                    name="workTypePreferences"
                                                    component={FormikAutocomplete}
                                                    label="Work Types"
                                                    margin="none"
                                                    options={jobTypes.map(type => ({
                                                        id: type.value,
                                                        label: type.label,
                                                    }))}
                                                    textFieldProps={{
                                                        fullWidth: true,
                                                        margin: "none",
                                                        variant: "outlined",
                                                    }}
                                                    multiple
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid hidden item xs={12} style={controlPadding.leftColumn}>
                                            <FormControl className={classes.formControl}>
                                                <InputLabel id="workTypes2">Work Types</InputLabel>
                                                <Select
                                                    labelId="workTypes"
                                                    id="workTypes2"
                                                    name="workTypePreferences"
                                                    multiple
                                                    value={values.workTypePreferences}
                                                    onChange={handleChange}
                                                    input={<Input id="select-multiple-chip2" />}
                                                    renderValue={() => (
                                                        <div className={classes.chips}>
                                                            {values.workTypePreferences.map((type, i) => (
                                                                <Chip
                                                                    style={{ padding: 4, paddingLeft: 8 }}
                                                                    key={i}
                                                                    label={type.label}
                                                                    className={classes.chip}
                                                                />
                                                            ))}
                                                        </div>
                                                    )}
                                                    MenuProps={MenuProps}>
                                                    {workTypeList.map((type, i) => {
                                                        if (type.id === 3) {
                                                        }
                                                        return (
                                                            <MenuItem
                                                                key={i}
                                                                value={type}
                                                                style={getStyles(type.label, personName, theme)}>
                                                                <Checkbox
                                                                    checked={values.workTypePreferences.indexOf(type) > -1}
                                                                    onChange={e => {
                                                                        handleChange(e);
                                                                    }}
                                                                />
                                                                <ListItemText primary={type.label} />
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            alignContent="flex-start"
                                            alignItems="flex-start"
                                            xs={12}
                                            md={12}
                                            style={{ ...controlPadding.rightColumn, paddingTop: 20 }}>
                                            <Grid item md={3} xs={12} style={controlPadding.leftColumn}>
                                                <Tooltip title={`Activation Status`} placement="top">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                id="isActive"
                                                                defaultChecked={isActive}
                                                                onChange={handleSwitchChange}
                                                                name="isActive"
                                                                color="secondary"
                                                            />
                                                        }
                                                        style={{ color: "rgb(33 42 52)", width: "100%" }}
                                                        label="Activation Status"
                                                    />
                                                </Tooltip>
                                            </Grid>

                                            <Grid item xs={12} md={3} style={controlPadding.rightColumn}>
                                                <Tooltip title={`Approval Status`} placement="top">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                id="isApproved"
                                                                defaultChecked={isApproved}
                                                                onChange={handleSwitchChange}
                                                                name="isApproved"
                                                                color="secondary"
                                                            />
                                                        }
                                                        label="Approval Status"
                                                        style={{ color: "rgb(33 42 52)", width: "100%", fontWeight: 700 }}
                                                    />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item md={3} xs={12} style={controlPadding.leftColumn}>
                                                <Tooltip title={`Email Verification Status`} placement="top">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                id="isActive"
                                                                defaultChecked={isEmailVerified}
                                                                onChange={handleSwitchChange}
                                                                name="isEmailVerified"
                                                                color="secondary"
                                                            />
                                                        }
                                                        style={{ color: "rgb(33 42 52)", width: "100%" }}
                                                        label="Email Status"
                                                    />
                                                </Tooltip>
                                            </Grid>

                                            <Grid item md={3} xs={12} style={controlPadding.rightColumn}>
                                                <Tooltip title={`Suspension Status`} placement="top">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                id="isSuspended"
                                                                defaultChecked={isSuspended}
                                                                onChange={handleSwitchChange}
                                                                name="isSuspended"
                                                                color="secondary"
                                                            />
                                                        }
                                                        style={{ color: "rgb(33 42 52)", width: "100%" }}
                                                        label="Suspension Status"
                                                    />
                                                </Tooltip>
                                            </Grid>

                                            <Grid item xs={12} md={3} style={controlPadding.leftColumn}>
                                                <Tooltip title={`Student Status`} placement="top">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                id="isStudent"
                                                                defaultChecked={isStudent}
                                                                onChange={handleSwitchChange}
                                                                name="isStudent"
                                                                color="secondary"
                                                            />
                                                        }
                                                        label="Student Status"
                                                        style={{ color: "rgb(33 42 52)", width: "100%" }}
                                                    />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={12} md={3} style={controlPadding.rightColumn}>
                                                <Tooltip title={`Onboarding Status`} placement="top">
                                                <FormControlLabel
                                                    control={
                                                    <Switch
                                                        id="isOnboarded"
                                                        defaultChecked={isOnboarded}
                                                        onChange={handleSwitchChange}
                                                        name="isOnboarded"
                                                    />
                                                    }
                                                    label="Onboarding Status"
                                                    style={{ color: "rgb(33 42 52)", width: "100%" }}
                                                />
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                        <DialogActions className={classes.footer}>
                                            <Button
                                                type="button"
                                                className="outline"
                                                onClick={handleReset}
                                                disabled={!dirty || isSubmitting}>
                                                Reset
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                type="submit"
                                                disabled={!_.isEmpty(errors)}>
                                                Update Profile
                                            </Button>
                                        </DialogActions>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                </Grid>
            </Grid>
        </TabPanel>
    );

    const onSubmit = data => {
        dispatch(saveWorkerWorkTypes(data));
        handleSetCanEdit();
    };
    return <RenderEmployerSettingsForm />;
}

export default WorkerSettingsTab;
