import { gql } from "@apollo/client";

export const CustomFieldsQuery = gql`
  query CustomFields(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $assignTo: CustomFieldAssignTo
    $order: [CustomFieldOrderInput!]
    $like: [CustomFieldFilterInput!]
) {
    CustomFields(
        before: $before
        after: $after
        first: $first
        last: $last
        assignTo: $assignTo
        order: $order
        like: $like
        ){
        totalCount
        pageInfo{
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges{
          cursor
          cursorDecoded
          node{
          id
          name
          slug
          type
          isRequired
          isForAll
          createdBy
          updatedBy
          deletedBy
          createdAt
          updatedAt
          getCustomFieldsOptions {
            id
            customFieldId
            label
            value
            createdAt
            updatedAt
          }
        }
    }
    }
}`;

export const employerListQuery = gql`
query employers(
  $first: Int
  $last: Int
  $after: String
  $before: String
  $customFieldId: Float
  $order: [OrderInput!]
  $like: [FilterInput!]
  $isActive: Boolean
  $isApproved: Boolean
) {
  employers(
    first: $first
    last: $last
    after: $after
    before: $before
    customFieldId: $customFieldId
    order: $order
    like: $like
    isActive: $isActive
    isApproved: $isApproved
    isDeleted: false
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        qbId
        companyName
        firstName
        lastName
        email
        businessPhone
        mobilePhone
        city
        region
        rating
        jobCount
        createdAt
        createdAtUtc
        customFieldValues {
            customFieldId
        }
      }
    }
  }
}
`;

export const workerListQuery = gql`
query getWorkers(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $customFieldId: Int
    $isActive: Boolean
    $isApproved: Boolean
    $order: [WorkerOrderInput!]
    $like: [WorkerFilterInput!]
  ) {
workers(
    first: $first
    last: $last
    after: $after
    before: $before
    customFieldId: $customFieldId
    order: $order
    like: $like
    isActive: $isActive
    isApproved: $isApproved
    isDeleted: false
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        createdAt
        createdAtUtc
        firstName
        lastName
        email
        phone
        isActive
        isApproved
        isSuspended
        positionCount
        futureJobCount
        rating
        city
        region
        sin
        profileImageUrl
        signedProfileImageUrl
        vaccinationStatus
        tags
        customFieldValues {
            customFieldId
        }
      }
    }
  }
}
`;
