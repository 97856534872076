export default {
  RESET: "employerDetails/RESET",
  SET_LOADING_STATE: "employerDetails/SET_LOADING_STATE",
  SET_EMPLOYER: "employerDetails/SET_EMPLOYER",
  SET_MANAGER: "employerDetails/SET_MANAGER",
  SET_HOURS_TREND_DATA: "employerDetails/SET_HOURS_TREND_DATA",
  SET_WORKER_EXCEPTIONS_LIST_DATA: "employerDetails/SET_WORKER_EXCEPTIONS_LIST_DATA",
  SET_JOBS_LIST_DATA: "employerDetails/SET_JOBS_LIST_DATA",
  SET_EMPLOYER_NOTE: "employerDetails/SET_EMPLOYER_NOTE",
  RESET_EMPLOYER_NOTE: "employerDetails/RESET_EMPLOYER_NOTE",
  ADD_CHANGE: "employerDetails/ADD_CHANGE",
  EDIT_FORM_PENDING: "employerDetails/EDIT_FORM_PENDING",
  EDIT_FORM_SUCCESS: "employerDetails/EDIT_FORM_SUCCESS",
  SET_UP_EDIT_FORM: "employerDetails/SET_UP_EDIT_FORM",
  SET_UPDATE_UPDATE_EMPLOYER_DATA: "employerDetails/SET_UPDATE_UPDATE_EMPLOYER_DATA",
  SET_WORKER_PICKER_LIST_DATA: "employerDetails/SET_WORKER_PICKER_LIST_DATA",
  CLEAR_UPDATE_UPDATE_EMPLOYER_DATA: "employerDetails/CLEAR_UPDATE_UPDATE_EMPLOYER_DATA",
  SET_EMPLOYER_CUSTOM_RATES: "employerDetails/SET_EMPLOYER_CUSTOM_RATES",
  SET_UPDATE_EMPLOYER_CUSTOM_RATE_DATA: "employerDetails/SET_UPDATE_EMPLOYER_CUSTOM_RATE_DATA",
  SET_NEW_EMPLOYER_CUSTOM_RATE_DATA: "employerDetails/SET_NEW_EMPLOYER_CUSTOM_RATE_DATA",
  SET_EDIT_EMPLOYER_CUSTOM_RATE_DATA: "employerDetails/SET_EDIT_EMPLOYER_CUSTOM_RATE_DATA",
  CLEAR_UPDATE_EMPLOYER_CUSTOM_RATE_DATA: "employerDetails/CLEAR_UPDATE_EMPLOYER_CUSTOM_RATE_DATA",
  CLEAR_CREATE_EMPLOYER_CUSTOM_RATE_DATA: "employerDetails/CLEAR_CREATE_EMPLOYER_CUSTOM_RATE_DATA",
  SET_DIALOG_STATE: "employerDetails/SET_DIALOG_STATE",
  SET_TIME_BASE_PREMIUM_LIST: "employerDetails/SET_TIME_BASE_PREMIUM_LIST",
  SET_TIME_BASE_PREMIUM_FORM: "employerDetails/SET_TIME_BASE_PREMIUM_FORM",
  SET_EDIT_TIME_BASE_PREMIUM_FORM: "employerDetails/SET_EDIT_TIME_BASE_PREMIUM_FORM",
  CLEAR_TIME_BASE_PREMIUM_FORM: "employerDetails/CLEAR_TIME_BASE_PREMIUM_FORM",
  SET_SELECTED_TIMEBASE_PREMIUM: "employerDetails/SET_SELECTED_TIMEBASE_PREMIUM",
  SET_DYNAMIC_WORK_TYPE_RATE_LIST: "employerDetails/SET_DYNAMIC_WORK_TYPE_RATE_LIST",
  SET_DYNAMIC_WORK_TYPE_RATE_FORM: "employerDetails/SET_DYNAMIC_WORK_TYPE_RATE_FORM",
  SET_EDIT_DYNAMIC_WORK_TYPE_RATE_FORM: "employerDetails/SET_EDIT_DYNAMIC_WORK_TYPE_RATE_FORM",
  CLEAR_DYNAMIC_WORK_TYPE_RATE_FORM: "employerDetails/CLEAR_DYNAMIC_WORK_TYPE_RATE_FORM",
  SET_SELECTED_DYNAMIC_WORK_TYPE_RATE: "employerDetails/SET_SELECTED_DYNAMIC_WORK_TYPE_RATE",
};
