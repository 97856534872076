import ApolloClient from "utils/apollo";
import { actions as MetaActions } from "store/modules/meta";
import { OvertimeSpecs } from "./graphql/queries";
import types from "./types";
import { updateOvertimeSpec } from "./graphql/mutation/update-overtime-specs";

const setLoadingState = key => value => ({
    type: types.SET_LOADING_STATE,
    payload: { key, value },
});

export const setIsOvertimeSpecsLoading = setLoadingState("overtimeSpecs");
export const setIsOpenEditDialog = setLoadingState("isOpenEditDialog");
export const setIsUpdateOvertimeSpecsLoading = setLoadingState("updateOvertimeSpecs");

/*
OT CalculationList
*/
const setOvertimeSpecsData = data => ({ type: types.SET_OT_CALCULATION_DATA, payload: { data } });
export const setOvertimeSpecsDetailData = data => ({ type: types.SET_OT_CALCULATION_UPDATE_DATA, payload: { data } });
export const updateOvertimeSpecsData = data => ({ type: types.UPDATE_OT_CALCULATION_DATA, payload: { data } });

export const fetchOvertimeSpecsData = () => (dispatch) => {
    dispatch(setIsOvertimeSpecsLoading(true));
    return ApolloClient.query({
        query: OvertimeSpecs,
        variables: {},
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw Error(errors[0].message);
            } else {
                const { overtimeSpecs } = data;
                dispatch(setOvertimeSpecsData(overtimeSpecs));
                dispatch(setIsOvertimeSpecsLoading(false));
            }
        })
        .catch(e => {
            dispatch(setIsOvertimeSpecsLoading(false));
            dispatch(MetaActions.errorToast(e.message));
        });
};


export const setAndOpenEditDialog = (data) => (dispatch) => {
    dispatch(setOvertimeSpecsDetailData(data));
    dispatch(setIsOpenEditDialog(true));
}

export const colseEditDialog = () => (dispatch) => {
    dispatch(setIsOpenEditDialog(false));
    dispatch(setOvertimeSpecsDetailData({}));
}

export const updateOvertimeSpecs = (data) => (dispatch) => {
    dispatch(setIsUpdateOvertimeSpecsLoading(true));
    return ApolloClient.query({
        query: updateOvertimeSpec,
        variables: {
            data
        },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw Error(errors[0].message);
            } else {
                dispatch(setOvertimeSpecsDetailData({}));
                dispatch(setIsOpenEditDialog(false));
                dispatch(setIsUpdateOvertimeSpecsLoading(false))
                dispatch(fetchOvertimeSpecsData());
            }
        })
        .catch(e => {
            dispatch(setIsUpdateOvertimeSpecsLoading(false))
            dispatch(MetaActions.errorToast(e.message));
        });
}
