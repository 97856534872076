import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ChevronRight from '@mui/icons-material/ChevronRight';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { withStyles } from '@mui/styles';
import LoginImage from "assets/img/gf-spark-illustration.svg";
import logo from "assets/img/grizzly-force-logo-horizontal-white.svg";
import Button from "components/Button/Button";
import { actions as LoginActions, selectors as LoginSelectors } from "store/modules/login";
import LoginStyles from "./styles";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = { time: {}, seconds: 30 };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.round(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }

  componentDidMount() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
  }

  startTimer() {
    clearInterval(this.timer);
    this.timer = 0;
    this.setState({ seconds: 30 })
    setTimeout(() => {
      if (this.timer == 0 && this.state.seconds > 0) {
        this.timer = setInterval(this.countDown, 1000);
      }
    }, 100)

  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds == 0) {
      clearInterval(this.timer);
    }

  }

  handleEmailChange = event => {
    this.props.onEmailChange(event.target.value);
  };

  handleSubmit = event => {
    event.preventDefault();
    if (this.props.isValidLoginData) {
      this.props.onOTPChange('');
      this.startTimer();
      this.props.login();
    }
  };
  handleSubmitOTP = event => {
    event.preventDefault();
    if (this.props.isValidLoginData) {
      this.props.submitOTP();
    }
  };

  handleOTPChange = event => {
    if (event.target.value.length <= 6 && (/^[0-9]+$/.test(event.target.value) || event.target.value === '')) {
      this.props.onOTPChange(event.target.value);
    }
  };

  resendCode = (event) => {
    event.preventDefault();
    if (this.props.isValidLoginData) {
      this.props.login(true);
      this.startTimer()
    }
  }


  render() {
    const {
      classes,
      email,
      otp,
      isUsernameVerify,
      submitOTP,
      isValidLoginData,
      isLoginLoading,
      isOtpLoading
    } = this.props;

    return (
      <Grid container className={classes.pageContainer}>
        <Grid
          container
          item
          md={6}
          justifyContent="center"
          alignContent="center"
          className={classes.loginContainer}>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            item
            md={12}
            className={classes.loginContainer}>
            <div>
              {!isUsernameVerify && (
                <form onSubmit={this.handleSubmit}>
                  <Grid item className={classes.logo}>
                      <img alt="Grizzly Force Logo" src={logo} />
                  </Grid>
                  <Grid item md={12} className={classes.loginItemContainer}>
                    <TextField
                      fullWidth
                      id="email"
                      InputLabelProps={{
                        style: { color: "#929FB0" },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.inputStyle,
                        },
                      }}
                      placeholder="Email address"
                      value={email}
                      margin="normal"
                      size="small"
                      autoFocus
                      onChange={this.handleEmailChange}
                      onKeyPress={event => {
                        if (event.key === "Enter") {
                          return this.handleSubmit(event);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item md={12} className={classes.buttonContainer}>

                    <Button
                      label="Continue"
                      icon={<ChevronRight />}
                      alignIcon="right"
                      size="medium"
                      type="submit"
                      isLoading={isLoginLoading}
                      onClick={this.handleSubmit}
                      disabled={!isValidLoginData}
                      className={classes.nextButton}
                    />
                    <div className={classes.resendSection}>
                    </div>
                  </Grid>
                </form>
              )}
              {isUsernameVerify && (
                <form onSubmit={this.handleSubmitOTP}>
                  <Grid item className={classes.logo}>
                    <img alt="Grizzly Force Logo" src={logo} />
                  </Grid>
                  <Grid item md={12} className={classes.loginItemContainer}>
                    <TextField
                      fullWidth
                      id="otp"
                      InputLabelProps={{
                        style: { color: "#929FB0" },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.inputStyle,
                        },
                      }}
                      placeholder="Enter 6-digit code"
                      value={otp}
                      margin="normal"
                      size="small"
                      autoFocus
                      onChange={this.handleOTPChange}
                      onKeyPress={event => {
                        if (event.key === "Enter") {
                          return this.handleSubmitOTP(event);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item md={12} className={classes.buttonContainer}>
                    <Button
                      label="Next"
                      icon={<ChevronRight />}
                      alignIcon="right"
                      size="medium"
                      type="submit"
                      onClick={submitOTP}
                      isLoading={isOtpLoading}
                      disabled={otp.length < 6}
                      className={classes.nextButton}
                    />
                    <div className={classes.resendSection}>
                      {this.state.time.s > 0 && <div className="resend-disable">RESEND CODE IN</div>}
                      {this.state.time.s > 0 && <div className="minutes">{this.state.time.m > 9 ? this.state.time.m : '0' + this.state.time.m} : {this.state.time.s > 9 ? this.state.time.s : '0' + this.state.time.s}</div>}
                      {this.state.time.s === 0 && <div className="link" onClick={this.resendCode}>Resend Code</div>}
                    </div>

                  </Grid>
                </form>
              )}
            </div>
          </Grid>

        </Grid>

        <Grid item lg={6} className={classes.imageContainer}>
          <img alt="Grizzly Force Login" src={LoginImage} />
        </Grid>
      </Grid>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  isValidLoginData: PropTypes.bool.isRequired,
  rememberUser: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  onEmailChange: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
};

const mapState = state => {
  const { rememberUser, email, otp } = state.login;
  return {
    isValidLoginData: LoginSelectors.getisValidLoginData(state),
    isUsernameVerify: LoginSelectors.getIsUsernameVerify(state),
    rememberUser,
    email,
    otp,
    isLoginLoading: LoginSelectors.getIsLoginLoading(state),
    isOtpLoading: LoginSelectors.getIsOtpLoading(state),
  };
};

const mapActions = {
  onEmailChange: LoginActions.setEmail,
  onOTPChange: LoginActions.setOTP,
  login: LoginActions.login,
  submitOTP: LoginActions.submitOTP,
  forgetUser: LoginActions.forgetUser,
};

export default connect(mapState, mapActions)(withStyles(LoginStyles)(LoginPage));
