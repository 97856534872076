import gql from "graphql-tag";

export default gql`
mutation($data: CreateSkillInput!) {
    createSkill(data: $data){
        skillName
        frenchSkillName
        isActive
    }
}
`
