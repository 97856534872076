import { Alert, TextField, Typography } from '@mui/material'
import DocumentTypeAutocomplete from 'components/Autocomplete/DocumentTypeAutocomplete'
import Dialog from 'components/Dialog/Dialog'
import React from 'react'
import Dropzone from "components/Dropzone/Dropzone";
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { actions as WorkerDetailActions, selectors as WorkerDetailSelectors } from "store/modules/workerDetails";
import { actions as MetaActions } from "store/modules/meta";
import { isValidWorkerUploadDocuments } from 'utils/file';
import Button from 'components/Button/Button';

const useStyles = makeStyles(theme => ({
    container: {
        width: 450
    },
    documents: {
        color: theme.palette.secondary.main,
        fontSize: "1rem",
    },
    document: {
        borderColor: "#c4c4c4",
        padding: theme.spacing(0, 2, 0, 1),
        color: theme.palette.text.primary,
    },
    selectedDocument: {
        marginTop: theme.spacing(1),
        textAlign: "left",
    },
    actionContainer: {
        display: "flex",
        marginTop: theme.spacing(3),
        justifyContent: "center",
        alignItems: "center"
    }
}));

export default function DocumentSelectionDialog(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const showUploadWorkerDocument = useSelector(WorkerDetailSelectors.getShowUploadWorkerDocument);
    const { documentUpload } = useSelector(WorkerDetailSelectors.getWorkerDetails);
    const isLoading = useSelector(WorkerDetailSelectors.getIsApplyingAction);

    const hideUploadWorkerDocument = () => {
        dispatch(WorkerDetailActions.setShowUploadWorkerDocument(false))
        dispatch(WorkerDetailActions.resetWorkerDocument());
        if (props.callBack) props.callBack();
    };

    const handleUploadWorkerDocument = () => {
        dispatch(WorkerDetailActions.saveWorkerDocument(props.workerId, props.callBack))
    };

    const onUploadWorkerDocumentTypeChange = value => {
        dispatch(WorkerDetailActions.updateWorkerDocumentType(value))
    };

    const onUploadWorkerDocumentNameChange = data => {
        dispatch(WorkerDetailActions.updateWorkerDocumentName(data.target.value))
    };

    const onUploadWorkerDocumentChange = document => {
        if (!document || (document && isValidWorkerUploadDocuments([document]))) {
            let item = document;
            if (document) {
                item = new Blob([document], { type: document.type });
                item.name = `Document_${document.name}`;
            }
            dispatch(WorkerDetailActions.updateWorkerDocument(item))
        } else {
            dispatch(MetaActions.errorToast("Selected document is not valid"));
        }
    };

    return (
        <Dialog
            open={showUploadWorkerDocument}
            title={`Upload Document`}
            description={`Upload a document to worker profile.`}
            confirmText="Upload"
            disabled={!documentUpload?.documentName?.length || !documentUpload?.documentType || !documentUpload?.document}
            onClose={hideUploadWorkerDocument}
            // onConfirm={handleUploadWorkerDocument}
            // onCancel={hideUploadWorkerDocument}
            icon="upload_file"
            hideActions
            alertType="question"
            modalType="editEmployer">
            <div className={classes.container}>
                <div
                    style={{ position: "relative", zIndex: 9999, marginTop: 56, marginBottom: 8 }}
                >
                    <DocumentTypeAutocomplete
                        value={documentUpload?.documentType}
                        menuPortalTarget={document.body}
                        onChange={onUploadWorkerDocumentTypeChange}
                    />
                </div>

                <TextField
                    fullWidth={true}
                    value={documentUpload?.documentName}
                    onChange={onUploadWorkerDocumentNameChange}
                    margin="normal"
                    placeholder="Document Name..."
                    label="Document Name"
                />

                {documentUpload?.document ?
                    <div className={classes.selectedDocument}>
                        <Typography>Selected Document</Typography>
                        <Alert className={classes.document} icon={false} severity="success" variant="outlined" onClose={() => onUploadWorkerDocumentChange(null)}>
                            {documentUpload?.document?.name}
                        </Alert>
                    </div>
                    :
                    <Dropzone
                        accept="image/*,application/pdf,application/msword,.doc,.docx"
                        onFilesAdded={(files) => onUploadWorkerDocumentChange(files[0])}
                    />
                }
                <div className={classes.actionContainer}>
                    <Button label={"Cancel"} onClick={hideUploadWorkerDocument} color="transparent" />
                    <Button
                        isLoading={isLoading}
                        label={"Upload"}
                        onClick={handleUploadWorkerDocument}
                        color="secondary"
                        disabled={isLoading || !documentUpload?.documentName?.length || !documentUpload?.documentType || !documentUpload?.document}
                    />
                </div>
            </div>
        </Dialog>
    )
}
