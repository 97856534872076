import { gql } from "@apollo/client";

export default gql`
    mutation UpdateDocument($updateDocument: UpdateDocument!) {
        updateDocument(updateDocument: $updateDocument) {
            id
            signedDocumentUrl
            documentTypeId
            workerId
            documentName
            documentURL
            status
            approvedBy
            rejectedBy
            rejectReason
            addedBy
            updatedBy
            deletedBy
            deletedAt
            createdAt
            updatedAt
            signedDocumentMasterImageUrl
        }
    }
`;
