import types from "./types";
import { createReducer } from "utils/redux";

const initialLoadingState = {
    tableLoading: true,
    dialogActionLoading: false,
    isDeleteHolidayLoading: false,
    detailLoading: false,
    workersLoading: false,
    refreshWorkers: false,
    initializingHolidayPayroll: false,
    payPosition: false,
    createPaystubLoading:false,
    downloadPaystubLoading:false,
};

const initialDialogState = {
    addHolidayDialog: false,
    deleteHolidayDialog: false,
    editHolidayDialog: false,
    payrollSummary: false,
    resendPayStub:false,
    cancelETransfer:false,
    resendETransfer:false,
    requestReversal:false,
    resolveETransfer:false,
    resolveReversal:false,
    resendReversal:false,
    cancelReversal:false
};

const initialState = {
    loading: { ...initialLoadingState },
    dialog: { ...initialDialogState },
    lists: [],
    editFormData: null,
    details: null,
    holidayWorkers: [],
    payrollSummary: [],
};

const actionsMap = {
    [types.SET_LOADING_STATE]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            loading: {
                ...state.loading,
                [key]: value,
            },
        };
    },
    [types.SET_HOLIDAYS_LIST_DATA]: (state, { payload }) => {
        const { lists } = payload;
        return {
            ...state,
            lists,
        };
    },
    [types.SET_DIALOG_STATE]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            dialog: {
                ...state.dialog,
                [key]: value,
            },
        };
    },
    [types.SET_EDIT_FORM_DATA]: (state, { payload }) => {
        const { value } = payload;
        return {
            ...state,
            editFormData: value
        };
    },
    [types.SET_HOLIDAY_DETAIL]: (state, { payload }) => {
        const { details } = payload;
        return {
            ...state,
            details: details
        };
    },
    [types.SET_HOLIDAY_WORKERS]: (state, { payload }) => {
        const { data } = payload;
        return {
            ...state,
            holidayWorkers: data
        };
    },
    [types.SET_HOLIDAY_WORKERS_PAYROLL_SUMMARY]: (state, { payload }) => {
        const { payrollSummary } = payload;
        return {
            ...state,
            payrollSummary: payrollSummary
        };
    },
}

export default createReducer(initialState, actionsMap);
