import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import makeStyles from '@mui/styles/makeStyles';
import Alarm from "@mui/icons-material/Alarm";
import AlarmOn from "@mui/icons-material/AlarmOn";
import CancelPresentation from "@mui/icons-material/CancelPresentation";
import ExposurePlus1 from "@mui/icons-material/PlusOne";
import GroupAdd from "@mui/icons-material/GroupAdd";
import LocalAtm from "@mui/icons-material/LocalAtm";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import Stars from "@mui/icons-material/Stars";
import noop from "lodash/noop";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";
import { Icon } from "@mui/material";
import Can from "components/Authorization/Can";
import { JobPermissions, PositionPermissions } from "helpers/constants/permissions";

const useStyles = makeStyles(({ spacing, typography }) => ({
    icon: {
        fontSize: typography.pxToRem(20),
        marginRight: spacing(2),
    },
}));

function PositionTableMenu(props) {
    const {
        triggerRateAll,
        triggerCheckInAll,
        triggerCheckOutAll,
        triggerEditAllRates,
        triggerAddWorker,
        triggerIncreasePositions,
        triggerDecreasePositions,
        triggerDequeuePay,
    } = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const menuId = "batch-position-action-menu";

    const openMenu = e => {
        setAnchorEl(e.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const menuItemClickFactory = (triggerFn = noop) => () => {
        closeMenu();
        triggerFn();
    };

    const canRateAll = useSelector(JobDetailSelectors.getCanRateAll);
    const canCheckInAll = useSelector(JobDetailSelectors.getCanCheckInAll);
    const canCheckOutAll = useSelector(JobDetailSelectors.getCanCheckOutAll);
    const canEditAllRates = useSelector(JobDetailSelectors.getCanEditRates);
    const canAddWorker = useSelector(JobDetailSelectors.getCanAddWorker);
    const canIncreasePositions = useSelector(JobDetailSelectors.getCanIncreasePositions);
    const canDecreasePositions = useSelector(JobDetailSelectors.getCanDecreasePositions);
    const canDequeuePay = useSelector(JobDetailSelectors.getCanDequeuePay);
    const isFixedTerm = useSelector(JobDetailSelectors.getIsFixedTermJob);

    // return null if there are no actions available
    if (
        [
            canRateAll,
            canCheckInAll,
            canCheckOutAll,
            canEditAllRates,
            canAddWorker,
            canIncreasePositions,
            canDecreasePositions,
            canDequeuePay,
        ].every(v => !v)
    ) {
        return null;
    }

    const handleRateAll = menuItemClickFactory(triggerRateAll);
    const handleCheckInAll = menuItemClickFactory(triggerCheckInAll);
    const handleCheckOutAll = menuItemClickFactory(triggerCheckOutAll);
    const handleEditAllRates = menuItemClickFactory(triggerEditAllRates);
    const handleAddWorker = menuItemClickFactory(triggerAddWorker);
    const handleIncreasePositions = menuItemClickFactory(triggerIncreasePositions);
    const handleDecreasePositions = menuItemClickFactory(triggerDecreasePositions);
    const handleDequeuePay = menuItemClickFactory(triggerDequeuePay);

    return <>
        {(!isFixedTerm || (canEditAllRates || canCheckInAll || canCheckOutAll)) && <IconButton
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={openMenu}
            disableRipple
            size="large">
            <MoreHoriz color="primary" />
        </IconButton>}
        <Menu
            id={menuId}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={closeMenu}>
            {canRateAll && (
                <MenuItem onClick={handleRateAll}>
                    <Stars className={classes.icon} color="action" />
                    Rate All
                </MenuItem>
            )}
            {canCheckInAll && (
                <Can perform={PositionPermissions.update.checkInAll.value}>
                    {allowed => {
                        if (!allowed) {
                            return null;
                        }
                        return (<MenuItem onClick={handleCheckInAll}>
                            <Alarm className={classes.icon} color="action" />
                            Check-In All
                        </MenuItem>)
                    }}
                </Can>
            )}
            {canCheckOutAll && (
                <Can perform={PositionPermissions.update.checkOutAll.value}>
                    {allowed => {
                        if (!allowed) {
                            return null;
                        }
                        return (<MenuItem onClick={handleCheckOutAll}>
                            <AlarmOn className={classes.icon} color="action" />
                            Check-Out All
                        </MenuItem>)
                    }}
                </Can>
            )}
            {canEditAllRates && (
                <Can perform={PositionPermissions.update.rateAll.value}>
                    {allowed => {
                        if (!allowed) {
                            return null;
                        }
                        return (<MenuItem onClick={handleEditAllRates}>
                            <LocalAtm className={classes.icon} color="action" />
                            Edit rates
                        </MenuItem>)
                    }}
                </Can>
            )}
            {<Can perform={PositionPermissions.create.fill.value}>
                {allowed => {
                    if (!allowed || !canAddWorker) {
                        return null;
                    }
                    return (
                        <MenuItem onClick={handleAddWorker}>
                            <GroupAdd className={classes.icon} color="action" />
                            Add Worker
                        </MenuItem>
                    )
                }}
            </Can>}
            {!isFixedTerm && <Can perform={JobPermissions.update.increasePositions.value}>
                {allowed => {
                    return allowed ? (
                        <>
                            {canIncreasePositions && (
                                <MenuItem onClick={handleIncreasePositions}>
                                    <ExposurePlus1 className={classes.icon} color="action" />
                                    Increase Positions
                                </MenuItem>
                            )}
                            {canDecreasePositions && (
                                <MenuItem onClick={handleDecreasePositions}>
                                    <Icon className={classes.icon} color="action">
                                        exposure_neg_1
                                    </Icon>
                                    Decrease Positions
                                </MenuItem>
                            )}
                        </>
                    ) : null;
                }}
            </Can>}

            {/* {canDequeuePay && (
        <MenuItem onClick={handleDequeuePay}>
          <CancelPresentation className={classes.icon} color="action" />
          Dequeue Pay
        </MenuItem>
      )} */}
        </Menu>
    </>;
}

PositionTableMenu.propTypes = {
    triggerRateAll: PropTypes.func.isRequired,
    triggerCheckInAll: PropTypes.func.isRequired,
    triggerCheckOutAll: PropTypes.func.isRequired,
    triggerEditAllRates: PropTypes.func.isRequired,
    triggerAddWorker: PropTypes.func.isRequired,
    triggerIncreasePositions: PropTypes.func.isRequired,
    triggerDecreasePositions: PropTypes.func.isRequired,
    triggerDequeuePay: PropTypes.func.isRequired,
};

export default PositionTableMenu;
