import { BrokenImage, Close } from '@mui/icons-material'
import { alpha, Avatar, Dialog, IconButton, Slide } from '@mui/material'
import React, { useState } from 'react'
import Loader from "assets/img/CircullerProgress.svg";
import { makeStyles } from '@mui/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction={props.in ? "up" : "down"} ref={ref} {...props} />;
});

export default React.memo(function ImageWithPreview({ src, style, height, width }) {
    const classes = useStyles({ height, width });
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Avatar
                aria-describedby={src}
                onClick={handleClick}
                key={src}
                className={classes.root}
                style={style || {}}
                src={src}
                variant="rounded">
                <div className={classes.fallback} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}>
                    <BrokenImage />
                </div>
            </Avatar>
            <Dialog
                TransitionComponent={Transition}
                open={open}
                onClose={handleClose}
                className={classes.imageViewer}
                scroll={"body"}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >

                <Avatar
                    aria-describedby={src}
                    src={src}
                    className={classes.preview}
                    variant="square" >
                    <BrokenImage style={{ minHeight: 200, minWidth: 200 }} />
                </Avatar>
                <IconButton
                    size="small"
                    onClick={handleClose}
                    color="error"
                    className={classes.closePreviewButton}>
                    <Close />
                </IconButton>
            </Dialog>
        </>
    )
}, (p, c) => (
    (p.src && c.src && p.src === c.src) &&
    (p.style && c.style && p.style === c.style) &&
    (p.width && c.width && p.width === c.width) &&
    (p.height && c.height && p.height === c.height)
));




export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.grey[400],
        backgroundImage: `url(${Loader})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: ({ height }) => height || 44,
        width: ({ width }) => width || 44,
        cursor: "pointer",
        "& img": {
            objectPosition: "right",
            backgroundColor: theme.palette.grey[400],
        }
    },
    fallback: {
        display: "flex",
        height: "100%",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        cursor: "default",
        backgroundColor: theme.palette.grey[400],
        "& svg": {
            height: "90%",
            width: "90%",
        }
    },
    fallbackPreview: {
        display: "flex",
        height: "100%",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.grey[400],
        "& svg": {
            height: 300,
            width: 300,
        }
    },
    closePreviewButton: {
        position: "absolute",
        zIndex: 1,
        background: alpha(theme.palette.common.white, 0.6),
        right: -30,
        top: -27
    },
    imageViewer: {
        "& .MuiPaper-root": {
            overflow: "visible",
            "& .MuiAvatar-root": {
                borderRadius: 4,
                backgroundColor: theme.palette.grey[400],
                backgroundImage: `url(${Loader})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
            }
        }
    },
    preview: {
        margin: 0,
        minHeight: 56,
        minWidth: 56,
        height: "auto",
        width: "auto",
        maxHeight: "auto",
        maxWidth: "auto",
        "& img": {
            backgroundColor: theme.palette.grey[400],
        }
    }
}));
