import ApolloClient from "utils/apollo";
import types from "./types";
import { actions as MetaActions } from "store/modules/meta";
import { documentTypeQuery } from "./graphql/queries/index";
import createDocumentTypeMutation from "./graphql/mutations/create-document-type";
import updateDocumentTypeMutation from "./graphql/mutations/update-document-type";
import deleteDocumentTypeMutation from "./graphql/mutations/delete-document-type";

const setLoadingState = key => value => ({
    type: types.SET_LOADING_STATE,
    payload: { key, value },
});

const setIsTableLoading = setLoadingState("tableLoading");
const setIsAddDocumentLoading = setLoadingState("isAddLoading");
const setIsDeleteDocumentLoading = setLoadingState("isDeleteLoading");

const setDialogState = key => value => ({
    type: types.SET_DIALOG_STATE,
    payload: { key, value },
});

export const setIsAddDocumentTypeDialog = setDialogState("addDocumentTypeDialog");
export const setIsDeleteDocumentTypeDialog = setDialogState("deleteDocumentTypeDialog");

const setDocumentType = documentType => ({
    type: types.SET_DOCUMENT_TYPE_DATA,
    payload: { documentType }
});

export const fetchDocumentType = () => (dispatch) => {
    dispatch(setIsTableLoading(true));
    return ApolloClient.query({
        query: documentTypeQuery,
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            else {
                dispatch(setIsTableLoading(false));
                dispatch(setDocumentType(data.getAllDocumentType));
            }
        })
        .catch(e => {
            dispatch(setIsTableLoading(false));
            dispatch(MetaActions.errorToast(`Could not find data: ${e.message}`));
        });
};

export const createDocumentType = (values) => (dispatch) => {
    dispatch(setIsAddDocumentLoading(true))
    if (values.id) {
        dispatch(updateDocumentType(values));
        return;
    }
    return ApolloClient.mutate({
        mutation: createDocumentTypeMutation,
        variables: { data: values },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            dispatch(fetchDocumentType());
            dispatch(setIsAddDocumentTypeDialog(false));
            dispatch(setIsAddDocumentLoading(false))
            dispatch(MetaActions.successToast(`Document type added successfully`));
            return data;
        }).catch((e) => {
            dispatch(MetaActions.errorToast(e.message));
            dispatch(setIsAddDocumentTypeDialog(false))
            dispatch(setIsAddDocumentLoading(false))
        });
};

export const updateDocumentType = documentType => dispatch => {
    return ApolloClient.mutate({
        mutation: updateDocumentTypeMutation,
        variables: { data: documentType },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            dispatch(fetchDocumentType());
            dispatch(setIsAddDocumentTypeDialog(false));
            dispatch(setIsAddDocumentLoading(false))
            dispatch(MetaActions.successToast(`Document type updated successfully`));
            return data;
        }).catch((e) => {
            dispatch(MetaActions.errorToast(e.message));
            dispatch(setIsAddDocumentTypeDialog(false))
            dispatch(setIsAddDocumentLoading(false))
        });
};

export const deleteDocumentType = id => dispatch => {
    dispatch(setIsDeleteDocumentLoading(true));
    return ApolloClient.mutate({
        mutation: deleteDocumentTypeMutation,
        variables: { id },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            dispatch(fetchDocumentType());
            dispatch(setIsDeleteDocumentTypeDialog(false));
            dispatch(setIsDeleteDocumentLoading(false))
            dispatch(MetaActions.successToast(`Document type deleted successfully`));
            return data;
        }).catch((e) => {
            dispatch(MetaActions.errorToast(e.message));
            dispatch(setIsDeleteDocumentTypeDialog(false))
            dispatch(setIsDeleteDocumentLoading(false))
        });
};
