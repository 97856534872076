export const paginationSettings = {
    customFieldList: {
        prefix: "customFieldList",
        defaultPageSize: 10,
    },
    employerList: {
        prefix: "employerList",
        defaultPageSize: 10,
    },
    workerList: {
        prefix: "workerList",
        defaultPageSize: 10,
    },
    detailEmployerList: {
        prefix: "detailEmployerList",
        defaultPageSize: 10,
    },
    detailWorkerList: {
        prefix: "detailWorkerList",
        defaultPageSize: 10,
    },
};
