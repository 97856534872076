import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import withStyles from '@mui/styles/withStyles';
import Contacts from "@mui/icons-material/Contacts";
import MoreVert from "@mui/icons-material/MoreVert";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import Work from "@mui/icons-material/Work";

const styles = ({ typography, spacing }) => ({
  icon: {
    fontSize: typography.pxToRem(20),
    marginRight: spacing(2),
  },
});

class WorkerActionMenu extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleViewJob = () => {
    this.props.history.push(`/jobs/${this.props.jobId}${this?.props?.job?.job?.isFixedTerm ? "/dashboard" : ""}`);
    this.handleClose();
  };

  handleViewEmployer = () => {
    this.props.history.push(`/employers/${this.props.employerId}`);
    this.handleClose();
  };

  render() {
    const { anchorEl } = this.state;
    const { classes, job, handleShowPayrollDialog, toggleResendStubDialog } = this.props;
    const id = `action-menu-worker`;
    return (
      <React.Fragment>
        <IconButton
          aria-owns={anchorEl ? id : undefined}
          aria-haspopup="true"
          disableRipple
          onClick={this.handleClick}
          size="large">
          <MoreVert color="primary" />
        </IconButton>
        {anchorEl ? (
          <Menu
            id={id}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            disableAutoFocusItem>
            <MenuItem onClick={this.handleViewJob}>
              <Work className={classes.icon} color="action" />
              View Job
            </MenuItem>
            {/* <MenuItem onClick={() => toggleIsViewingPdf(position)}>
              <Work className={classes.icon} color="action" />
              View Pay Stub
            </MenuItem> */}

            {job && job.payrolls && job.payrolls.length && job.payrolls[0].payments.length ? (
              <MenuItem onClick={() => handleShowPayrollDialog(job)}>
                <Work className={classes.icon} color="action" />
                View Payroll
              </MenuItem>
            ) : null}
            <MenuItem onClick={this.handleViewEmployer}>
              <Contacts className={classes.icon} color="action" />
              View Employer
            </MenuItem>

            {job && job.payrolls && job.payrolls.length && job.payrolls[0].payments.length ? (
              <MenuItem onClick={() => toggleResendStubDialog(job.payrolls[0])}>
                <PictureAsPdf className={classes.icon} color="action" />
                Email Paystub
              </MenuItem>
            ) : null}
          </Menu>
        ) : null}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(withRouter(WorkerActionMenu));
