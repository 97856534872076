import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { convertUTCToLocal } from "utils/time";
import { fetchGears } from "store/modules/requiredGear/actions";
import { selectors as RequiredSelectors } from "store/modules/requiredGear";
import RequiredGearActionMenu from "./RequiredGearActionMenu";
import { Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";

const useStyles = makeStyles(theme => ({
    ActiveChip: {
        background: "#4caf50",
        color: "#fff"
    },
    InactiveChip: {
        background: theme.customPalette.red[500],
        color: "#fff"
    }
}));

function RequiredGearTable({ onDelete,onEdit }) {

    const dispatch = useDispatch();
    const data = useSelector(RequiredSelectors.getrequiredGearsList);
    const isLoading = useSelector(RequiredSelectors.getIsTableLoading);

    useEffect(() => {
        dispatch(fetchGears());
    }, [])

    return (
        <ReactTableSimple
            classes="-highlight"
            loading={isLoading}
            data={data}
            columns={[
                {
                    Header: "Gear Name",
                    id: "gearName",
                    disableFilters: true,
                    disableSortBy: true,
                    accessor: ({ gearName }) => gearName,
                },
                {
                    Header: "Gear Name In French",
                    id: "frenchGearName",
                    disableFilters: true,
                    disableSortBy: true,
                    accessor: ({ frenchGearName }) => frenchGearName,
                },
                // {
                //     Header: "Active",
                //     id: "isActive",
                //    disableFilters:true,
                //     disableSortBy:true,
                //     Cell: ({ original }) => {
                //         const { isActive } = original;
                //         if (isActive === true) {
                //             return (
                //                 <div>
                //                     <Chip label="Active" size="small" className={classes.ActiveChip} />
                //                 </div>
                //             )
                //         }
                //         else {
                //             return (
                //                 <div>
                //                     <Chip label="Inactive" size="small" className={classes.InactiveChip} />
                //                 </div>
                //             )
                //         }
                //     }
                // },
                {
                    Header: "Created",
                    id: "createdAt",
                    disableFilters: true,
                    disableSortBy: true,
                    accessor: ({ createdAt }) => convertUTCToLocal(createdAt, "MMM Do, YYYY"),
                },
                {
                    id: "actions",
                    disableSortBy: true,
                    disableFilters: true,
                    collapse: true,
                    Cell: ({ row:{original} }) => {
                        const { id } = original;
                        return (
                            <RequiredGearActionMenu
                                id={id}
                                data={original}
                                onDelete={onDelete}
                                onEdit={onEdit}
                            />
                        );
                    },
                },
            ]}
        />
    )
}

export default RequiredGearTable;
