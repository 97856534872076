/* eslint-disable react/jsx-key */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from "react";
import moment from "moment-timezone";
import Avatar from "@mui/material/Avatar";
import { formatInitials } from "utils/stringUtils";
import { convertUTCToLocal, getTimeZone } from "utils/time";
import ActionColumnCell from "./ActionColumn";
import ImageColumn from "./ImageColumn";
import { Link } from "react-router-dom";

moment.tz.setDefault(getTimeZone());

export const formatPhone = input => {
    if (input) {
        const phone = input.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        return !phone[2] ? phone[1] : `(${phone[1]}) ${phone[2]}${phone[3] ? `-${phone[3]}` : ""}`;
    }
    return;
};

export const numberWithCommas = num => {
    return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
};

export const sharedProps = {
    showPageSizeOptions: true,
    manual: true,
    showPaginationBottom: true,
    disableFilters: true,
    showPageJump: false,
    showPaginationTop: false,
    disableSortBy: true,
    searchable: false,
    defaultPageSize: 10,
    resizable: false,
    classes: "-highlight",
    minRows: 0,
    pageSizeOptions: [10, 20, 40, 100],
};

export const sharedWorkerColumns = () => {
    return [
        {
            Header: "",
            width: 60,
            id: "profileImage",
            disableSortBy: true,
            disableFilters: true,
            Cell: ({ row }) => (
                <Avatar src={row.original.signedProfileImageUrl} >
                    {formatInitials(row.original.firstName, row.original.lastName, "")}
                </Avatar>
            ),
        },
        {
            Header: "First Name",
            id: "firstName",
            accessor: ({ firstName }) => firstName || <i> Empty </i>,
            Cell: ({ row: { original } }) => <Link to={`/workers/${original.id}`}>{original.firstName}</Link>,
        },
        {
            Header: "Last Name",
            id: "lastName",
            accessor: ({ lastName }) => lastName || <i> Empty </i>,
            Cell: ({ row: { original } }) => <Link to={`/workers/${original.id}`}>{original.lastName}</Link>,
        },
        {
            Header: "Email",
            id: "email",
            minWidth: 200,
            accessor: ({ email }) => email || <i> Empty </i>,
            disableSortBy: true,
        },
        {
            Header: "Identification Image",
            id: "signedIdentificationImageUrl",
            disableSortBy: true,
            disableFilters: true,
            styles: { textAlign: "center" },
            Cell: ({ row }) => <ImageColumn src={row.original.signedIdentificationImageUrl} />,
        },
        {
            Header: "Compare Image",
            id: "signedCompareImageUrl",
            disableSortBy: true,
            disableFilters: true,
            styles: { textAlign: "center" },
            Cell: ({ row }) => <ImageColumn src={row.original.signedCompareImageUrl} />,
        },
        {
            Header: "Updated At",
            id: "updatedAtUtc",
            disableFilters: true,
            accessor: ({ updatedAtUtc }) => {
                return updatedAtUtc;
            },
            Cell: ({ row: { original } }) => convertUTCToLocal(original.updatedAtUtc).fromNow()
        },
        {
            Header: "Action",
            id: "actions",
            accessor: ({ actions }) => actions,
            collapse: true,
            style: { textAlign: "right" },
            disableFilters: true,
            disableSortBy: true,
            Cell: ActionColumnCell
        },
    ];
};
