import React from "react";
import { connect } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import CircularProgress from "@mui/material/CircularProgress";
import withStyles from '@mui/styles/withStyles';
import { Badge, Event, HowToReg, RecentActors } from "@mui/icons-material";
import Can from "components/Authorization/Can";
import MainContent from "components/Containers/MainContent";
import Dialog from "components/Dialog/Dialog";
import SubNav from "components/Navigation/SubNav";
import SubNavTab from "components/Navigation/SubNavTab";
import { actions as WorkerActions,selectors as WorkerSelectors } from "store/modules/workers";
import FutureJobTable from "./WorkerOverview/tables/FutureJobTable";
import { tabStyles } from "./WorkerOverview/tables/helpers/styles";
import WaitlistTab from "./WorkerOverview/WaitlistTab";
import WorkerTab from "./WorkerOverview/WorkerTab";
import { hasPermission } from "utils/authorize";
import { DashboardViewsPermissions } from "helpers/constants/permissions";
import WorkerIdentityVerification from "views/WorkerIdentityVerification/WorkerIdentityVerification";
import WorkPermitTab from "./WorkerOverview/WorkPermitTab";

const styles = {
    ...tabStyles,
    progress: {
        color: "#11b2a8",
        position: "absolute",
        top: "50%",
        left: "45%",
    },
    svgRoot: {
        marginTop: `0 !important`,
        margin: `0 !important`
    }
};

class WorkerOverview extends React.Component {
    state = {
        value: 0,
        isLoading: true,
        open: false
    };
    componentDidMount() {
        if (this.props.history.location.search) {
            const params = new URLSearchParams(this.props.history.location.search);
            const workerframe = parseInt(params.get("workerframe"), 10);
            if (!isNaN(workerframe)) {
                this.setState({ value: parseInt(workerframe, 10) });
            }
        } else {
            this.props.history.push(`/workers/overview?workerframe=${this.state.value}`);
        }
        this.props.fetchListStats();
        this.props.getIdentityWorkersStats();
        this.props.fetchTableData(this.props.waitlistTodayPageInfo.currentPage, "waitlistToday");
        this.props.fetchTableData(this.props.waitlistTomorrowPageInfo.currentPage, "waitlistTomorrow");
        this.props.fetchTableData(this.props.identityWorkerListPageInfo.currentPage, "identityWorkerList"),
        this.props.getWorkPermitWorkersStats();
        Promise.all([
            this.props.applyFilter([], "workerList"),
            this.props.fetchTableData(this.props.workerListPageInfo.currentPage, "workerList"),
            this.props.fetchTableData(this.props.suspendedWorkerListPageInfo.currentPage, "suspendedWorkerList"),
            this.props.fetchTableData(this.props.verifiedWorkerListPageInfo.currentPage, "verifiedWorkerList"),
            this.props.fetchTableData(this.props.unverifiedWorkerListPageInfo.currentPage, "unverifiedWorkerList"),
            this.props.fetchTableData(this.props.deactivatedWorkerListPageInfo.currentPage, "deactivatedWorkerList"),
            this.props.fetchTableData(this.props.appliedWorkerListPageInfo.currentPage, "appliedWorkerList"),
        ]).then(this.toggleIsLoading);
    }

    onFutureJobClick = (param) => {
        this.setState({ open: true })
        this.props.getWorkerFutureJobs(param.id)
    }
    onClose = () => {
        this.setState({ open: false })
    }

    toggleIsLoading = () => {
        this.setState(state => ({
            isLoading: !state.isLoading,
        }));
    };

    handleChange = (event, value) => {
        if (this.props.history.location.search) {
            const params = new URLSearchParams(this.props.history.location.search);
            params.append("workerframe", value);
            this.props.history.push(`/workers/overview?${params.toString()}`);
        }
        this.props.history.push(`/workers/overview?workerframe=${value}`);
        this.setState({ value });
    };

    handleCloseRejectDialog = () => {
        this.props.setWorkerIdentityRejectDialog(false);
    };

    handleConfirmRejectDialog = () => {
        this.props.updateWorkerIdentityStatus({ workerId: this.props.selectedWorkerId, isActive: true, isVerified: false, isWarning: true });
    };


    get tabs() {
        const tabs = [];
        const { waitListProps, workPermitListProps, workerStats,identityListProps } = this.props;
        if (hasPermission(DashboardViewsPermissions.worker.list.value)) {
            tabs.push(<SubNavTab
                key="worker"
                label="Workers"
                icon={<RecentActors />}
                count={workerStats ? workerStats.allWorkers : 0}
            />);
        }

        // if (hasPermission(DashboardViewsPermissions.worker.waitlist.value)) {
        //     tabs.push(<SubNavTab
        //         key="waitlist"
        //         label="Wait Lists"
        //         icon={<Event />}
        //         count={waitListProps ? waitListProps.waitlistTotalCount : 0}
        //     />);
        // }

        if (hasPermission(DashboardViewsPermissions.worker.list.value)) {
            tabs.push(<SubNavTab
                key="worker"
                label="Identity Verification"
                icon={<HowToReg />}
                count={identityListProps ? identityListProps.identitylistTotalCount : 0}
            />);
        }

        if (hasPermission(DashboardViewsPermissions.worker.list.value)) {
            tabs.push(<SubNavTab
                key="workpermits"
                label="Work permits"
                icon={<Badge />}
                count={workPermitListProps ? workPermitListProps.workPermitlistTotalCount : 0}
            />);
        }

        return tabs;
    }


    handleCloseRejectWorkPermitDialog = () => {
        this.props.setWorkerWorkPermitRejectDialog(false);
    };

    handleConfirmRejectWorkPermitDialog = () => {
        this.props.updateWorkerWorkPermitStatus({ workerId: this.props.selectedWorkPermitWorkerId, status: false });
    };

    render() {
        return (
            <Can perform="worker:view:base">
                {allowed => {
                    if (!allowed) {
                        return <MainContent>Unauthorized!</MainContent>;
                    }
                    const { selectedWorkPermitWorkerId, classes, waitListProps, workerProps, selectedWorkerId, isUpdateStatus, workerStats } = this.props;
                    if (this.state.isLoading) {
                        return <CircularProgress classes={{ root: classes.progress }} />;
                    }

                    return (
                        <React.Fragment>
                            <SubNav
                                value={this.state.value}
                                onChange={this.handleChange}
                                tabs={this.tabs}
                            />
                            <MainContent>
                                <SwipeableViews axis="x" index={this.state.value}>
                                    <Can perform={DashboardViewsPermissions.worker.list.value}>
                                        {allowed => {
                                            if (!allowed) {
                                                return null;
                                            }
                                            return <WorkerTab {...workerProps} onFutureJobClick={this.onFutureJobClick} />
                                        }}
                                    </Can>
                                    {/* <Can perform={DashboardViewsPermissions.worker.waitlist.value}>
                                        {allowed => {
                                            if (!allowed) {
                                                return null;
                                            }
                                            return <WaitlistTab {...waitListProps} />;
                                        }}
                                    </Can> */}
                                    <Can perform={DashboardViewsPermissions.worker.list.value}>
                                        {allowed => {
                                            if (!allowed) {
                                                return null;
                                            }
                                            return <WorkerIdentityVerification />
                                        }}
                                    </Can>
                                    <Can perform={DashboardViewsPermissions.worker.list.value}>
                                        {allowed => {
                                            if (!allowed) {
                                                return null;
                                            }
                                            return <WorkPermitTab {...workerProps} />
                                        }}
                                    </Can>
                                </SwipeableViews>
                            </MainContent>
                            <Dialog
                                open={this.state.open}
                                title={`Future Jobs`}
                                size="lg"
                                onClose={this.onClose}
                                onConfirm={this.onClose}
                                onCancel={this.onClose}
                                alertType="form"
                                hideActions
                                isCancelIconButtonRequired
                                modalType="editEmployer">
                                <FutureJobTable />
                            </Dialog>
                            <Dialog
                                open={Boolean(selectedWorkerId)}
                                title={`Reject Worker Identity Document`}
                                description="Are you sure to reject worker identity document?"
                                confirmText={isUpdateStatus ? <CircularProgress classes={{ svg: classes.svgRoot }} size={18} /> : "Reject"}
                                disabled={isUpdateStatus}
                                onClose={this.handleCloseRejectDialog}
                                onConfirm={this.handleConfirmRejectDialog}
                                onCancel={this.handleCloseRejectDialog}
                                icon="block"
                                // fullWidth={true}
                                alertType="question" />
                            <Dialog
                                open={Boolean(selectedWorkPermitWorkerId)}
                                title={`Reject worker work permit`}
                                description="Are you sure to reject worker work permit?"
                                confirmText={isUpdateStatus ? <CircularProgress classes={{ svg: classes.svgRoot }} size={18} /> : "Reject"}
                                disabled={isUpdateStatus}
                                onClose={this.handleCloseRejectWorkPermitDialog}
                                onConfirm={this.handleConfirmRejectWorkPermitDialog}
                                onCancel={this.handleCloseRejectWorkPermitDialog}
                                icon="block"
                                // fullWidth={true}
                                alertType="question" />
                        </React.Fragment>
                    );
                }}
            </Can>
        );
    }
}

const mapState = state => {
    const {
        workPermitWorkerStats,
        waitlistTodayPageInfo,
        waitlistTomorrowPageInfo,
        workerStats,
        identityWorkerStats,
        workerListPageInfo,
        unverifiedWorkerListPageInfo,
        verifiedWorkerListPageInfo,
        workerBannedByPageInfo,
        workerPreferredByPageInfo,
        suspendedWorkerListPageInfo,
        deactivatedWorkerListPageInfo,
        appliedWorkerListPageInfo,
        identityWorkerListPageInfo,
        isInfoLoading
    } = state.workers;
    return {
        workerStats,
        workerListPageInfo,
        waitlistTodayPageInfo,
        waitlistTomorrowPageInfo,
        unverifiedWorkerListPageInfo,
        verifiedWorkerListPageInfo,
        workerBannedByPageInfo,
        workerPreferredByPageInfo,
        suspendedWorkerListPageInfo,
        deactivatedWorkerListPageInfo,
        appliedWorkerListPageInfo,
        identityWorkerListPageInfo,
        workerProps: {
            workerStats,
        },
        waitListProps: {
            waitlistTotalCount: waitlistTodayPageInfo.totalCount + waitlistTomorrowPageInfo.totalCount,
            waitlistTodayTotalCount: waitlistTodayPageInfo.totalCount,
            waitlistTodayIsLoading: waitlistTodayPageInfo.isLoading,
            waitlistIsLoading: waitlistTodayPageInfo.isLoading,
            waitlistTomorrowTotalCount: waitlistTomorrowPageInfo.totalCount,
            waitlistTomorrowIsLoading: waitlistTomorrowPageInfo.isLoading,
        },
        identityListProps: {
            identitylistTotalCount: identityWorkerStats?.totalCount
        },
        selectedWorkerId: WorkerSelectors.getIsOpenWorkerIdentityRejectDialog(state),
        workPermitListProps: {
            workPermitlistTotalCount: workPermitWorkerStats?.totalCount
        },
        selectedWorkPermitWorkerId: WorkerSelectors.getIsOpenWorkerWorkPermitRejectDialog(state),
        isUpdateStatus: Boolean(isInfoLoading)
    };
};
const mapActions = {
    fetchTableData: WorkerActions.fetchTableData,
    fetchListStats: WorkerActions.fetchListStats,
    getIdentityWorkersStats: WorkerActions.getIdentityWorkersStats,
    applyFilter: WorkerActions.changeFilter,
    getWorkerFutureJobs: WorkerActions.getWorkerFutureJobs,
    updateWorkerIdentityStatus: WorkerActions.updateWorkerIdentityStatus,
    setWorkerIdentityRejectDialog: WorkerActions.setWorkerIdentityRejectDialog,
    getWorkPermitWorkersStats: WorkerActions.getWorkPermitWorkersStats,
    updateWorkerWorkPermitStatus: WorkerActions.updateWorkerWorkPermitStatus,
    setWorkerWorkPermitRejectDialog: WorkerActions.setWorkerWorkPermitRejectDialog,
};

export default connect(mapState, mapActions)(withStyles(styles)(WorkerOverview));
