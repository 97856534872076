import { amber, deepOrange, deepPurple, green, red } from "@mui/material/colors";
import { createTheme, adaptV4Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { StyleSheet } from "@react-pdf/renderer";
const isMobile = false;
// export const reversalsTabTableActionsStyles = makeStyles(({ spacing, typography }) => ({
//   icon: {
//     fontSize: typography.pxToRem(20),
//     marginRight: spacing(2),
//   },
//   table: {
//     minWidth: "100%",
//   },
// }));

export const billingLandingStyles = withStyles(({ palette, spacing }) => ({
    container: { paddingRight: spacing(2) },
    card: { backgroundColor: palette.common.white },
}));

export const adjustmentStyles = {
    width: "calc(100% + 30px)",
    fontSize: 12,
    backgroundColor: "inherit",
    marginTop: 5,
    marginBottom: -10,
    marginRight: -15,
    marginLeft: -15,
    paddingLeft: 15,
};

export const holidayStyle = {
    color: '#ed3833',
    border: '1px solid',
    padding: '0px 5px',
    borderRadius: '15px',
    width: 'fit-content'
}

export const invoiceItemTableStyles = withStyles(({ spacing, shape, palette, customPalette }) => ({
    tabbedTableContainer: {
        marginTop: spacing(0),
    },
    container: {
        display: "flex",
        flexWrap: "wrap",
    },

    textField: {
        marginLeft: spacing(1),
        marginRight: spacing(1),
        width: 200,
    },
    tableContainer: {
        width: "100%",
    },
    tabIndicator: {
        display: "none !important",
    },
    iconInfo: {
        textAlign: "center",
        fontSize: 22,
        color: "#0f3cb8",
    },
    wrapper: { padding: `${spacing(1)} ${spacing(0)} !important` },
    tabRoot: {
        marginRight: spacing(1),
        borderRadius: `${shape.borderRadius}px`,
        opacity: 1,
        "&:hover": {
            border: `1px solid ${palette.secondary.main}`,
        },
    },
    tabLabelContainer: {
        padding: 0,
    },
    tabLabel: {
        fontSize: "1rem",
    },
    tabSelected: {
        border: `2px solid ${palette.secondary.main}`,
        backgroundColor: customPalette.primary[200],
        "&:hover": {
            border: `2px solid ${palette.secondary.main}`,
        },
    },
    documents: {
        color: palette.secondary.main,
        fontSize: "1rem",
    },
    answerStyle: {
        padding: spacing(3),
        backgroundColor: "#f8f9fa",
    },
    subtableHead: {
        backgroundColor: "#e1e8ec",
        height: 24,
        fontSize: 12,
        color: "#404b5a",
    },
    subtableCell: {
        border: "none",
    },
    dateTimePicker:{
        "& .MuiDialogContent-root .MuiClockPicker-root":{
            "&>div":{
                "&>button":{
                    height:35,
                    width:35,
                    "&>span":{
                        fontWeight:"bold",
                        color:"inherit"
                    }
                }
            }
        }
    }
}));

export const billingSummaryStatsCardStyles = withStyles(
    ({ breakpoints, palette, spacing, shape }) => ({
        container: {
            marginTop: spacing(1),
            paddingRight: spacing(2),
            [breakpoints.down("sm")]: {
                paddingBottom: spacing(1),
                width: "100%",
                "&:nth-child(2)": { paddingRight: 0 },
            },
            "&:last-child": { paddingRight: 0 },
        },
        upOrDown: {
            fontSize: 16,
            marginLeft: 5,
            [breakpoints.down("sm")]: {
                fontSize: 12,
                marginLeft: 5,
            },
        },
        cardValue: {
            [breakpoints.down("sm")]: {
                fontSize: 18,
                marginLeft: 5,
            },
        },
        card: {
            backgroundColor: palette.common.white,
            border: `1px solid ${palette.grey[500]}`,
            borderRadius: shape.borderRadius,
            padding: `${spacing(1)} ${spacing(1)} !important`,
            height: "100%",
            [breakpoints.down("sm")]: { padding: `${spacing(1)} !important` },
        },
        star: {
            color: palette.text.primary,
        },
        leftSection: {
            float: 'left'
        },
        rightSection: {
            float: 'right'
        },
        availabilityContainer: {
            backgroundColor: palette.grey[500],
            width: 25,
            height: 25,
        },
        availableCell: {
            backgroundColor: palette.primary.main,
        },
    })
);

export const billingDetailsViewStyles = withStyles(
    ({ spacing, shape, palette, customPalette }) => ({
        tabbedTableContainer: {
            marginTop: isMobile ? spacing(1) : spacing(3),
        },
        tableContainer: {
            width: "100%",
        },
        tabIndicator: {
            display: "none !important",
        },

        infoIcon: {
            width: 13,
            height: 13,
            color: "#0f3cb8",
        },
        tabRoot: {
            marginRight: spacing(1),
            borderRadius: `${shape.borderRadius}px`,
            opacity: 1,
            "&:hover": {
                border: `1px solid ${palette.secondary.main}`,
            },
        },
        tabLabelContainer: {
            padding: 0,
        },
        tabLabel: {
            fontSize: "1rem",
        },
        tabSelected: {
            border: `2px solid ${palette.secondary.main}`,
            backgroundColor: customPalette.primary[200],
            "&:hover": {
                border: `2px solid ${palette.secondary.main}`,
            },
        },
        documents: {
            color: palette.secondary.main,
            fontSize: "1rem",
        },
        answerStyle: {
            padding: spacing(3),
            backgroundColor: "#f8f9fa",
        },
        subtableHead: {
            backgroundColor: "#e1e8ec",
            height: 24,
            fontSize: 12,
            color: "#404b5a",
        },
        badgeRoot: {
            marginTop: spacing(0),
            marginBottom: spacing(0),
        },
        subtableCell: {
            border: "none",
        },
        subTitle: {
            color: "#aebecd",
            fontWeight: 500,
            fontSize: 20,
            fontFamily: "Roboto",
        },
        iconInfo: {
            textAlign: "center",
            fontSize: 16,
            color: "#0f3cb8",
        },
        iconCheck: {
            textAlign: "center",
            fontSize: 16,
            color: "green",
        },
        iconWarning: {
            textAlign: "center",
            fontSize: 16,
            color: "orange",
        },
        iconBlink: {
            textAlign: "center",
            fontSize: 9,
            fontWeight: 700,
            color: "#ffffff",
        },
    })
);

export const topBarCardStyles = withStyles(({ spacing, breakpoints, palette }) => ({
    title: {
        marginTop: isMobile ? spacing(0) : spacing(1),
        [breakpoints.down("sm")]: { fontSize: 12 },
    },
    statHoliday: {
        width: 65,
        height: 12,
        fontFamily: "Roboto",
        fontSize: 15,
        fontWeight: "bold",
        fontStyle: "normal",
        marginLeft: 12,
        letterSpacing: 0,
        color: "#f44336",
        padding: '5px',
        borderRadius: '15px',
        border: '1px solid #f44336',
    },
    spreadsheetService: { flex: "auto" },
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    formControl: {
        margin: spacing(1),
        minWidth: 120,
    },
    root: {
        display: "flex",
        alignItems: "center",
    },
    wrapper: {
        margin: spacing(1),
        position: "relative",
    },
    disabledButton: {
        backgroundColor: palette.primary || "red",
    },
    buttonSuccess: {
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: "absolute",
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

export const invoiceTableStyles = withStyles(
    ({ typography, breakpoints, transitions, palette, spacing, shape }) => ({
        container: {
            icon: {
                fontSize: typography.pxToRem(20),
                marginRight: spacing(2),
            },
            paddingRight: spacing(2),
            "&:last-child": { paddingRight: 0 },
            marginTop: spacing(0),
            [breakpoints.down("sm")]: { paddingRight: spacing(0), paddingBottom: spacing(1) },
        },
        wrapper: { padding: `${spacing(1)} ${spacing(0)} !important` },
        card: {
            backgroundColor: palette.common.white,
            border: `1px solid ${palette.grey[500]}`,
            borderRadius: shape.borderRadius,
            padding: `${spacing(0)} !important`,
            height: "100%",

            "&:last-child": { marginRight: 0 },
        },
        cardRoot: {
            root: {
                boxShadow: "none",
                padding: `${spacing(0)} !important`,
                width: "100%",
            },
        },
        star: {
            color: palette.text.primary,
        },
        availabilityContainer: {
            backgroundColor: palette.grey[500],
            width: 25,
            height: 25,
        },
        availableCell: {
            backgroundColor: palette.primary.main,
        },
        root: {
            boxShadow: "none",
            padding: `${spacing(0)} !important`,
            width: "100%",
        },

        paper: {
            width: "100%",
            margin: `${spacing(0)} !important`,
            padding: `${spacing(0)} !important`,
            marginBottom: spacing(2),
        },
        table: {
            // width: "100%",
            margin: `${spacing(0)} !important`,
            padding: `${spacing(0)} !important`,
            /* Styles applied to the root element. */
            root: {
                ...typography.body2,
                display: "table-cell",
                verticalAlign: "inherit",
                // Workaround for a rendering bug with spanned columns in Chrome 62.0.
                // Removes the alpha (sets it to 1), and lightens or darkens the theme color.
                // borderBottom: `1px solid
                // ${ palette.mode === "light" ? lighten(alpha(theme.palette.divider, 1), 0.88) : darken(alpha(theme.palette.divider, 1), 0.68)}`,
                textAlign: "left",
                padding: 0,
            },
            /* Styles applied to the root element if `variant="head"` or `context.table.head`. */
            head: {
                color: palette.text.primary,
                lineHeight: typography.pxToRem(24),
                fontWeight: typography.fontWeightMedium,
            },
            /* Styles applied to the root element if `variant="body"` or `context.table.body`. */
            body: {
                color: palette.text.primary,
            },
            /* Styles applied to the root element if `variant="footer"` or `context.table.footer`. */
            footer: {
                color: palette.text.secondary,
                lineHeight: typography.pxToRem(21),
                fontSize: typography.pxToRem(12),
            },
            /* Styles applied to the root element if `size="small"`. */
            sizeSmall: {
                padding: "6px 2px 6px 2px",
                "&:last-child": {
                    paddingRight: 6,
                },
                "&$paddingCheckbox": {
                    width: 24, // prevent the checkbox column from growing
                    padding: "0 12px 0 16px",
                    "&:last-child": {
                        paddingLeft: 12,
                        paddingRight: 16,
                    },
                    "& > *": {
                        padding: 0,
                    },
                },
            },
            /* Styles applied to the root element if `padding="checkbox"`. */
            paddingCheckbox: {
                width: 48, // prevent the checkbox column from growing
                padding: "0 0 0 4px",
                "&:last-child": {
                    paddingLeft: 0,
                    paddingRight: 4,
                },
            },
            /* Styles applied to the root element if `padding="none"`. */
            paddingNone: {
                padding: 0,
                "&:last-child": {
                    padding: 0,
                },
            },
            /* Styles applied to the root element if `align="left"`. */
            alignLeft: {
                textAlign: "left",
            },
            /* Styles applied to the root element if `align="center"`. */
            alignCenter: {
                textAlign: "center",
            },
            /* Styles applied to the root element if `align="right"`. */
            alignRight: {
                textAlign: "right",
                flexDirection: "row-reverse",
            },
            /* Styles applied to the root element if `align="justify"`. */
            alignJustify: {
                textAlign: "justify",
            },
            /* Styles applied to the root element if `context.table.stickyHeader={true}`. */
            stickyHeader: {
                position: "sticky",
                top: 0,
                left: 0,
                zIndex: 2,
                backgroundColor: palette.background.default,
            },
        },

        visuallyHidden: {
            border: 0,
            clip: "rect(0 0 0 0)",
            height: 1,
            margin: -1,
            overflow: "hidden",
            padding: 0,
            position: "absolute",
            top: 20,
            width: 1,
        },
        media: {
            height: 0,
            paddingTop: "56.25%", // 16:9
        },
        expand: {
            transform: "rotate(0deg)",
            marginLeft: "auto",
            transition: transitions.create("transform", {
                duration: transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: "rotate(180deg)",
        },
        avatar: {
            fontSize: 38,
            fontWeight: 500,
            color: palette.secondary.main,
        },
        title: {
            fontSize: 16,
            fontWeight: 700,
        },
        subHeader: {
            fontSize: 13,
            fontWeight: 500,
        },
    })
);

export const billingWeeksMenuStyles = makeStyles(({ spacing, typography }) => ({
    icon: {
        fontSize: typography.pxToRem(20),
        marginRight: spacing(2),
    },
}));

export const invoiceBuilderStyles = withStyles(({ spacing, breakpoints, palette }) => ({
    title: {
        marginTop: isMobile ? spacing(0) : spacing(1),
        [breakpoints.down("sm")]: { fontSize: 12 },
    },
    statHoliday: {
        width: 65,
        height: 12,
        fontFamily: "Roboto",
        fontSize: 12,
        fontWeight: "normal",
        fontStyle: "normal",
        marginLeft: 12,
        letterSpacing: 0,
        color: "#664000",
    },
    spreadsheetService: { flex: "auto" },
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    formControl: {
        margin: spacing(1),
        minWidth: 120,
    },
    root: {
        display: "flex",
        alignItems: "center",
    },
    wrapper: {
        margin: spacing(1),
        position: "relative",
    },
    disabledButton: {
        backgroundColor: palette.primary || "red",
    },
    buttonSuccess: {
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: "absolute",
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

export const spreadsheetBuilderStyles = withStyles(({ spacing, breakpoints, palette }) => ({
    title: {
        marginTop: isMobile ? spacing(0) : spacing(1),
        [breakpoints.down("sm")]: { fontSize: 12 },
    },
    statHoliday: {
        width: 65,
        height: 12,
        fontFamily: "Roboto",
        fontSize: 12,
        fontWeight: "normal",
        fontStyle: "normal",
        marginLeft: 12,
        letterSpacing: 0,
        color: "#664000",
    },
    spreadsheetService: { flex: "auto" },
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    formControl: {
        margin: spacing(1),
        minWidth: 120,
    },
    root: {
        display: "flex",
        alignItems: "center",
    },
    wrapper: {
        margin: spacing(1),
        position: "relative",
    },
    disabledButton: {
        backgroundColor: palette.primary || "red",
    },
    buttonSuccess: {
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: "absolute",
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

export const workerActionMenuStyles = withStyles(({ typography, spacing }) => ({
    icon: {
        fontSize: typography.pxToRem(20),
        marginRight: spacing(2),
    },
}));

export const billingServiceStyles = withStyles(({ spacing, breakpoints, palette }) => ({
    title: {
        marginTop: isMobile ? spacing(0) : spacing(1),
        [breakpoints.down("sm")]: { fontSize: 12 },
    },
    statHoliday: {
        width: 65,
        height: 12,
        fontFamily: "Roboto",
        fontSize: 12,
        fontWeight: "normal",
        fontStyle: "normal",
        marginLeft: 12,
        letterSpacing: 0,
        color: "#664000",
    },
    spreadsheetService: { flex: "auto" },
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    formControl: {
        margin: spacing(1),
        minWidth: 120,
    },
    root: {
        display: "flex",
        alignItems: "center",
    },
    wrapper: {
        margin: spacing(1),
        position: "relative",
    },
    disabledButton: {
        backgroundColor: palette.primary || "red",
    },
    buttonSuccess: {
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: "absolute",
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

export const billingWeeksDialogStyles = withStyles(({ spacing, breakpoints, palette }) => ({
    title: {
        marginTop: isMobile ? spacing(0) : spacing(1),
        [breakpoints.down("sm")]: { fontSize: 12 },
    },
    statHoliday: {
        width: 65,
        height: 12,
        fontFamily: "Roboto",
        fontSize: 12,
        fontWeight: "normal",
        fontStyle: "normal",
        marginLeft: 12,
        letterSpacing: 0,
        color: "#664000",
    },
    spreadsheetService: { flex: "auto" },
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    formControl: {
        margin: spacing(1),
        minWidth: 120,
    },
    root: {
        display: "flex",
        alignItems: "center",
    },
    wrapper: {
        margin: spacing(1),
        position: "relative",
    },
    disabledButton: {
        backgroundColor: palette.primary || "red",
    },
    buttonSuccess: {
        backgroundColor: green[500],

        "&:hover": {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: "absolute",
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

export const customButtonTheme = createTheme(({
    palette: {
        action: {
            disabledBackground: "set color of background here",
            disabled: "set color of text here",
        },
        primary: {
            light: "#6e7a8a",
            main: "#404b5a",
            dark: "#212a34",
            contrastText: "#fff",
        },
        secondary: {
            light: "#11b2a8",
            main: "#0b938b",
            dark: "#024a46",
            contrastText: "#fff",
        },
        error: {
            light: "#F98786",
            main: "#df2c2a",
            dark: "#af2928",
            contrastText: "#fff",
        },
    },
}));

export const weeklySummaryPopoverStyles = makeStyles(theme => ({
    popover: { pointerEvents: "none" },
    paper: {
        padding: theme.spacing(1),
        border: "0.7px solid lightgrey",
        borderRadius: 2,
        boxShadow: "0 1px 2px 0 rgba(174, 190, 205, 0.5)",
    },
}));

export const invoiceModalTableStyles = withStyles(
    ({ typography, breakpoints, transitions, palette, spacing, shape }) => ({
        container: {
            icon: {
                fontSize: typography.pxToRem(20),
                marginRight: spacing(2),
            },
            paddingRight: spacing(2),
            "&:last-child": { paddingRight: 0 },
            marginTop: spacing(0),
            [breakpoints.down("sm")]: { paddingRight: spacing(0), paddingBottom: spacing(1) },
        },
        wrapper: { padding: `${spacing(1)} ${spacing(0)} !important` },
        card: {
            backgroundColor: palette.common.white,
            border: `1px solid ${palette.grey[500]}`,
            borderRadius: shape.borderRadius,
            padding: `${spacing(0)} !important`,
            height: "100%",

            "&:last-child": { marginRight: 0 },
        },
        cardRoot: {
            root: {
                boxShadow: "none",
                padding: `${spacing(0)} !important`,
                width: "100%",
            },
        },
        star: {
            color: palette.text.primary,
        },
        availabilityContainer: {
            backgroundColor: palette.grey[500],
            width: 25,
            height: 25,
        },
        availableCell: {
            backgroundColor: palette.primary.main,
        },
        root: {
            boxShadow: "none",
            padding: `${spacing(0)} !important`,
            width: "100%",
        },

        paper: {
            width: "100%",
            margin: `${spacing(0)} !important`,
            padding: `${spacing(0)} !important`,
            marginBottom: spacing(2),
        },
        table: {
            // width: "100%",
            margin: `${spacing(0)} !important`,
            padding: `${spacing(0)} !important`,
            /* Styles applied to the root element. */
            root: {
                ...typography.body2,
                display: "table-cell",
                verticalAlign: "inherit",
                // Workaround for a rendering bug with spanned columns in Chrome 62.0.
                // Removes the alpha (sets it to 1), and lightens or darkens the theme color.
                // borderBottom: `1px solid
                // ${ palette.mode === "light" ? lighten(alpha(theme.palette.divider, 1), 0.88) : darken(alpha(theme.palette.divider, 1), 0.68)}`,
                textAlign: "left",
                padding: 0,
            },
            /* Styles applied to the root element if `variant="head"` or `context.table.head`. */
            head: {
                color: palette.text.primary,
                lineHeight: typography.pxToRem(24),
                fontWeight: typography.fontWeightMedium,
            },
            /* Styles applied to the root element if `variant="body"` or `context.table.body`. */
            body: {
                color: palette.text.primary,
            },
            /* Styles applied to the root element if `variant="footer"` or `context.table.footer`. */
            footer: {
                color: palette.text.secondary,
                lineHeight: typography.pxToRem(21),
                fontSize: typography.pxToRem(12),
            },
            /* Styles applied to the root element if `size="small"`. */
            sizeSmall: {
                padding: "6px 2px 6px 2px",
                "&:last-child": {
                    paddingRight: 6,
                },
                "&$paddingCheckbox": {
                    width: 24, // prevent the checkbox column from growing
                    padding: "0 12px 0 16px",
                    "&:last-child": {
                        paddingLeft: 12,
                        paddingRight: 16,
                    },
                    "& > *": {
                        padding: 0,
                    },
                },
            },
            /* Styles applied to the root element if `padding="checkbox"`. */
            paddingCheckbox: {
                width: 48, // prevent the checkbox column from growing
                padding: "0 0 0 4px",
                "&:last-child": {
                    paddingLeft: 0,
                    paddingRight: 4,
                },
            },
            /* Styles applied to the root element if `padding="none"`. */
            paddingNone: {
                padding: 0,
                "&:last-child": {
                    padding: 0,
                },
            },
            /* Styles applied to the root element if `align="left"`. */
            alignLeft: {
                textAlign: "left",
            },
            /* Styles applied to the root element if `align="center"`. */
            alignCenter: {
                textAlign: "center",
            },
            /* Styles applied to the root element if `align="right"`. */
            alignRight: {
                textAlign: "right",
                flexDirection: "row-reverse",
            },
            /* Styles applied to the root element if `align="justify"`. */
            alignJustify: {
                textAlign: "justify",
            },
            /* Styles applied to the root element if `context.table.stickyHeader={true}`. */
            stickyHeader: {
                position: "sticky",
                top: 0,
                left: 0,
                zIndex: 2,
                backgroundColor: palette.background.default,
            },
        },

        visuallyHidden: {
            border: 0,
            clip: "rect(0 0 0 0)",
            height: 1,
            margin: -1,
            overflow: "hidden",
            padding: 0,
            position: "absolute",
            top: 20,
            width: 1,
        },
        media: {
            height: 0,
            paddingTop: "56.25%", // 16:9
        },
        expand: {
            transform: "rotate(0deg)",
            marginLeft: "auto",
            transition: transitions.create("transform", {
                duration: transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: "rotate(180deg)",
        },
        avatar: {
            fontSize: 38,
            fontWeight: 500,
            color: palette.secondary.main,
        },
        title: {
            fontSize: 16,
            fontWeight: 700,
        },
        subHeader: {
            fontSize: 13,
            fontWeight: 500,
        },
    })
);

export const employerStatsCardStyles = withStyles(({ breakpoints, palette, spacing, shape }) => ({
    statsContainer: {
        paddingRight: spacing(0),
        paddingBottom: spacing(1),
        [breakpoints.down("sm")]: {
            paddingRight: spacing(0),
            paddingBottom: spacing(1),
            "&:nth-child(2)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
            "&:nth-child(3)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
            "&:nth-child(4)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
        },
    },
    container: {
        paddingRight: spacing(2),
        paddingBottom: spacing(1),
        [breakpoints.down("sm")]: {
            //   paddingRight: spacing(0),
            paddingBottom: spacing(1),
            "&:nth-child(2n)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
            //   "&:nth-child(3)": { paddingBottom: spacing(0), paddingRight: spacing(1) },
            //   "&:nth-child(4)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
        },
    },
    employerContainer: {
        paddingRight: spacing(2),
        paddingBottom: spacing(1),
        [breakpoints.down("sm")]: {
            paddingRight: spacing(0),
            paddingBottom: spacing(1),
            //   "&:nth-child(2n)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
            //   "&:nth-child(3)": { paddingBottom: spacing(0), paddingRight: spacing(1) },
            //   "&:nth-child(4)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
        },
    },
    wrapper: {
        marginTop: spacing(2),
        [breakpoints.down("sm")]: {
            marginTop: spacing(0),
        },
    },
    card: {
        backgroundColor: palette.common.white,
        border: `1px solid ${palette.grey[500]}`,
        borderRadius: shape.borderRadius,
        padding: `${spacing(3)} !important`,
        [breakpoints.down("sm")]: {
            padding: `${spacing(1)} !important`,
            fontSize: 13,
            "&:h5": { fontSize: 13 },
            // "&:nth-child(2)": { paddingRight: spacing(1) },
            // "&:last-child": { paddingLeft: spacing(1) },
        },
    },
    statsCard: {
        backgroundColor: palette.common.white,
        border: `1px solid ${palette.grey[500]}`,
        borderRadius: shape.borderRadius,
        padding: `${spacing(3)} !important`,
        [breakpoints.down("sm")]: {
            padding: `${spacing(1)} !important`,
            fontSize: 13,
            "&:h5": { fontSize: 13 },
            // "&:nth-child(2)": { paddingRight: spacing(1) },
            // "&:last-child": { paddingLeft: spacing(1) },
        },
    },
    upOrDown: {
        [breakpoints.down("sm")]: {
            fontSize: 11,
        },
    },
    mobileH5: {
        [breakpoints.down("sm")]: {
            // padding: `${spacing(1)} !important`,
            fontSize: 13,
            // "&:h5": { fontSize: 13 },
            // "&:nth-child(2)": { paddingRight: spacing(1) },
            // "&:last-child": { paddingLeft: spacing(1) },
        },
    },
    employerCard: {
        backgroundColor: palette.common.white,
        border: `1px solid ${palette.grey[500]}`,
        borderRadius: shape.borderRadius,
        padding: `${spacing(3)} !important`,
        paddingTop: `${spacing(1)} !important`,
        paddingBottom: `${spacing(1)} !important`,
        height: "100%",
    },
    star: { color: palette.text.primary },
}));

export const invoicePdfDialogStyles = withStyles(
    ({ spacing, typography, shape, palette, customPalette }) => ({
        tabbedTableContainer: { marginTop: spacing(3) },
        tableContainer: { width: "100%" },
        tabIndicator: { display: "none !important" },
        tabRoot: {
            marginRight: spacing(1),
            borderRadius: `${shape.borderRadius}px`,
            opacity: 1,
            "&:hover": { border: `1px solid ${palette.secondary.main}` },
        },
        tabLabelContainer: { padding: 0 },
        tabLabel: { fontSize: "1rem" },
        tabSelected: {
            border: `2px solid ${palette.secondary.main}`,
            backgroundColor: customPalette.primary[200],
            "&:hover": { border: `2px solid ${palette.secondary.main}` },
        },
        documents: { color: palette.secondary.main, fontSize: "1rem" },
        answerStyle: { padding: spacing(3), backgroundColor: "#f8f9fa" },
        subtableHead: { backgroundColor: "#e1e8ec", height: 24, fontSize: 12, color: "#404b5a" },
        dialogTitle: { backgroundColor: "#fff", borderRadius: 4, fontSize: 12, color: "#000" },
        dialogActions: {
            backgroundColor: "#fff",

            fontSize: 12,
            color: "#000",
        },
        dialogContent: {
            // backgroundColor: "#fff",
            backgroundColor: "transparent !important",
            fontSize: 12,
            color: "#000",
        },
        dialog: {
            backgroundColor: "transparent !important",
            fontSize: 12,
            color: "#000",
        },
        dialogTopActions: {
            backgroundColor: "transparent !important", // padding: 15,
            paddingBottom: 0,
            fontSize: 12,
            color: "#fff",
            fontWeight: 700,
            alignSelf: "flex-end",
        },
        whiteTitle: { color: "#fff", fontSize: 16, alignSelf: "flex-end" },
        white: { color: "#fff" },
        icon: { fontSize: typography.pxToRem(20), marginRight: spacing(2) },
        root: { margin: 0, backgroundColor: "transparent !important", padding: spacing(2) },
        iconButton: {
            backgroundColor: "transparent !important",
            paddingBottom: 5,
            paddingLeft: 5,
            paddingTop: 0,
            paddingRight: 0,
        },
    })
);

export const employerBillingDetailsViewStyles = withStyles(
    ({ spacing, shape, palette, customPalette }) => ({
        tabbedTableContainer: {
            marginTop: spacing(0),
        },
        tableContainer: {
            width: "100%",
        },
        tabIndicator: {
            display: "none !important",
        },
        wrapper: { padding: `${spacing(1)} ${spacing(0)} !important` },
        tabRoot: {
            marginRight: spacing(1),
            borderRadius: `${shape.borderRadius}px`,
            opacity: 1,
            "&:hover": {
                border: `1px solid ${palette.secondary.main}`,
            },
        },
        tabLabelContainer: {
            padding: 0,
        },
        iconInfo: {
            textAlign: "center",
            fontSize: 22,
            color: "#0f3cb8",
        },
        tabLabel: {
            fontSize: "1rem",
        },
        tabSelected: {
            border: `2px solid ${palette.secondary.main}`,
            backgroundColor: customPalette.primary[200],
            "&:hover": {
                border: `2px solid ${palette.secondary.main}`,
            },
        },
        documents: {
            color: palette.secondary.main,
            fontSize: "1rem",
        },
        answerStyle: {
            padding: spacing(3),
            backgroundColor: "#f8f9fa",
        },
        subtableHead: {
            backgroundColor: "#e1e8ec",
            height: 24,
            fontSize: 12,
            color: "#404b5a",
        },
        subtableCell: {
            border: "none",
        },
        dateTimePicker:{
            "& .MuiDialogContent-root .MuiClockPicker-root":{
                "&>div":{
                    "&>button":{
                        height:35,
                        width:35,
                        "&>span":{
                            fontWeight:"bold",
                            color:"inherit"
                        }
                    }
                }
            }
        },
        hideArrows: {
            '& input': {
                padding: "8px 0px !important",
                border: "none !important",
                height: "auto !important",
                backgroundImage: "none !important",
            },
            '& input[type=number]': {
                '-moz-appearance': 'textfield'
            },
            '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            }
        }
    })
);

export const addInvoiceNoteDialogStyles = withStyles(({ spacing }) => ({
    modal: {
        padding: spacing(4),
        alignItems: "center",
        width: spacing(60),
        maxWidth: 400,
        overflowY: "unset",
    },
    qbConfirmModal: {
        padding: spacing(2),
        alignItems: "center",
        // width: spacing(60),
        overflowY: "unset",
    },
    title: {
        padding: `${spacing(2)} 0 ${spacing(1)} 0`,
    },
    content: {
        padding: `${spacing(1)} 0 ${spacing(4)} 0`,
        overflowY: "unset",
        width: "100%",
        alignItems: "center",
    },
    footer: {
        margin: 0,
    },
}));

export const invoiceCardStyles = withStyles(
    ({ breakpoints, transitions, palette, spacing, shape }) => ({
        placeholderContainer: {
            paddingRight: spacing(0),
            boxShadow: "none",
            marginTop: spacing(0),
            [breakpoints.down("sm")]: { paddingRight: spacing(0), paddingBottom: spacing(0) },
        },
        container: {
            paddingRight: spacing(2),
            boxShadow: "none",
            paddingBottom: spacing(0.7),
            // "&:last-child": { paddingRight: 0 },
            "&:nth-child(3n)": { paddingRight: 0 },
            marginTop: spacing(0),
            [breakpoints.down("sm")]: { paddingRight: spacing(0), paddingBottom: spacing(1) },
        },
        deletedContainer: {
            paddingRight: spacing(2),
            boxShadow: "none",
            paddingBottom: spacing(0.7),
            // "&:last-child": { paddingRight: 0 },
            "&:nth-child(2n)": { paddingRight: 0 },
            marginTop: spacing(0),
            minWidth: '300px',
            [breakpoints.down("sm")]: { paddingRight: spacing(0), paddingBottom: spacing(1) },
        },
        wrapper: {
            boxShadow: "none",
            width: "100%",
            padding: `${spacing(1)} ${spacing(0)} !important`,
        },
        card: {
            backgroundColor: palette.common.white,
            border: `1px solid ${palette.grey[500]}`,
            borderRadius: shape.borderRadius,
            padding: `${spacing(0)} !important`,
            height: "100%",
            boxShadow: "none",
        },
        deletedCard: {
            backgroundColor: palette.common.white,
            border: `1px solid #f44336`,
            borderRadius: shape.borderRadius,
            padding: `${spacing(0)} !important`,
            height: "100%",
            boxShadow: "none",
        },
        placeholderCard: {
            backgroundColor: palette.common.white,
            border: `0px solid ${palette.grey[500]}`,
            borderRadius: shape.borderRadius,
            padding: `${spacing(0)} !important`,
            height: "100%",

            "&:last-child": { marginRight: 0 },
        },
        cardRoot: {
            boxShadow: "none",
            padding: `${spacing(0)} !important`,
            width: "100%",
            textAlign: 'left'
        },
        star: {
            color: palette.text.primary,
        },
        availabilityContainer: {
            backgroundColor: palette.grey[500],
            width: 25,
            height: 25,
        },
        availableCell: {
            backgroundColor: palette.primary.main,
        },
        root: {
            boxShadow: "none",
            padding: `${spacing(0)} !important`,
            width: "100%",
        },

        paper: {
            width: "100%",
            margin: `${spacing(0)} !important`,
            padding: `${spacing(0)} !important`,
            marginBottom: spacing(2),
            boxShadow: "none",
        },
        table: {
            width: "100%",
            margin: `${spacing(0)} !important`,
            padding: `${spacing(0)} !important`,
        },

        visuallyHidden: {
            border: 0,
            clip: "rect(0 0 0 0)",
            height: 1,
            margin: -1,
            overflow: "hidden",
            padding: 0,
            position: "absolute",
            top: 20,
            width: 1,
        },
        media: {
            height: 0,
            paddingTop: "56.25%", // 16:9
        },
        expand: {
            transform: "rotate(0deg)",
            marginLeft: "auto",
            transition: transitions.create("transform", {
                duration: transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: "rotate(180deg)",
        },
        avatar: {
            fontSize: 38,
            fontWeight: 500,
            color: palette.secondary.main,
        },
        deletedAvatar: {
            fontSize: 38,
            fontWeight: 500,
            color: '#f44336',
        },
        title: {
            fontSize: 16,
            fontWeight: 700,
            boxShadow: "none",
        },
        subHeader: {
            fontSize: 13,
            boxShadow: "none",
            fontWeight: 500,
        },
        deletedButton: {
            height: '25px !important',
            width:"auto"
        }
    })
);

export const employerPopoverStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        boxShadow: "none !important",
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(0),
        margin: theme.spacing(0),
        width: "100%",
    },

    content: {
        // flex: "1 0 auto",
        border: `0px solid white !impotant`,
        padding: theme.spacing(2, 2, 0, 2),
        margin: theme.spacing(0),

        width: "100%",
    },
    cover: {
        width: 68,
        padding: theme.spacing(0),
        margin: theme.spacing(0),
    },
    email: {
        position: 'relative',
        bottom: '5px'
    },
    phone: {
        position: 'relative',
        bottom: '4px'
    }
}));

export const componentStyles = makeStyles(({ breakpoints, spacing, shape, palette }) => ({
    drawerPaper: {
        width: 400,
        backgroundColor: "red",
        height: "-webkit-fill-available",
        borderLeft: `1px solid ${palette.grey[500]}`,
    },

    table: {
        flex: 1,
        borderRadius: shape.borderRadius / 2,
        border: `1px solid ${palette.grey[500]}`,
        width: "100%",
    },
    "rt-td": {
        height: " 25px!important",
    },
    root: {
        width: "100%",
        // maxWidth: 360,
        backgroundColor: palette.background.paper,
    },
    statusBadgeList: {
        minWidth: '30px !important'
    },
    container: {
        height: "100%",
        paddingTop: spacing(2),
        marginRight: spacing(1),
    },
    card: {
        backgroundColor: palette.common.white,
        border: `1px solid ${palette.grey[500]}`,
        borderRadius: shape.borderRadius,
        height: "100%",
        padding: spacing(2),
    },
    drawer: {
        // width: 400,
        flexShrink: 0,
        // backgroundColor: "red",
    },

    warning: {
        color: "#fff",
        backgroundColor: amber[500],
        width: spacing(3),
        height: spacing(3),
        fontSize: 13,
    },

    ok: {
        color: "#fff",
        backgroundColor: green[500],
        width: spacing(3),
        height: spacing(3),
        fontSize: 13,
    },

    expired: {
        color: "#fff",
        backgroundColor: red[500],
        width: spacing(3),
        height: spacing(3),
        fontSize: 13,
    },

    orange: {
        color: palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
        width: spacing(3),
        height: spacing(3),
        fontSize: 13,
    },

    purple: {
        color: palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
    },

    statsContainer: {
        paddingRight: spacing(0),
        paddingBottom: spacing(1),
        [breakpoints.down("sm")]: {
            paddingRight: spacing(0),
            paddingBottom: spacing(1),
            "&:nth-child(2)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
            "&:nth-child(3)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
            "&:nth-child(4)": { paddingBottom: spacing(0), paddingRight: spacing(0) },
        },
    },

    statsCard: {
        backgroundColor: palette.common.white,
        border: `1px solid ${palette.grey[500]}`,
        borderRadius: shape.borderRadius,
        padding: spacing(1),
        width: "100%",
        minHeight: "190px",
        [breakpoints.down("sm")]: {
            padding: spacing(1),
            fontSize: 13,
            "&:h5": { fontSize: 13 },
        },
    },
    upOrDown: {
        [breakpoints.down("sm")]: {
            fontSize: 11,
        },
    },
    subValue: {
        paddingLeft: spacing(1),
        [breakpoints.down("sm")]: {
            fontSize: 11,
        },
    },
    tileTitle: {
        width: "100%",
        fontSize: 20,
        [breakpoints.down("sm")]: {
            fontSize: 16,
        },
    },
    tileValue: {
        width: "100%",
        marginRight: spacing(2),
        marginTop: spacing(0.5),
        [breakpoints.down("sm")]: {
            fontSize: 13,
        },
    },
}));

export const qbStatsStyles = makeStyles(theme => ({
    root: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: 400,
    },
    input: {
        marginLeft: theme.spacing(0),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

export const pdfTemplateStyles = StyleSheet.create({
    body: {
        padding: 15,
        marginVertical: 30,
        // marginHorizontal: 15,
        backgroundColor: "white",
    },
    header: {
        minHeight: 60,
        width: "100%",
        backgroundColor: "red",
        marginHorizontal: 30,
    },
    billingAddress: {
        color: "#000",
        fontSize: 14,
        textAlign: "right",
    },
    employerName: {
        color: "#000",
        fontSize: 18,
        fontWeight: 700,
    },
    employerAddress: {
        color: "#000",
        fontSize: 14,
    },
    employerBlock: {
        width: "100%",
        marginVertical: 30,
        marginHorizontal: 20,
    },
    leftBlock: {
        width: "50%",
        height: 40,
        paddingHorizontal: 20,
        marginVertical: 20,
    },
    rightBlock: {
        width: "50%",
        height: 40,
        textAlign: "right",
        paddingHorizontal: 20,
        marginVertical: 20,
    },
    content: {
        padding: 2,
        "@media max-width: 400": {
            flexDirection: "column",
        },
        "@media min-width: 400": {
            flexDirection: "row",
        },
    },
    logo: {
        height: 60,
        width: 60,
        backgroundColor: "white",
    },
    section: {
        color: "white",
        textAlign: "center",
        margin: 30,
    },
    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
        paddingTop: 30,
    },
});
export const pdfTableStyles = StyleSheet.create({
    table: {
        marginHorizontal: 30,
        marginRight: 30,
        display: "table",
        // width: "100%",
        fontSize: 9,
        borderStyle: "solid",
        borderWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        textAlign: "left",
        marginBottom: 35,
    },
    //    tableRow: { margin: "auto", textAlign: "left", flexDirection: "row" },
    tableFooterRow: {
        borderTopWidth: 1,
        margin: "auto",
        borderColor: "lightgrey",
        fontSize: 10,
        fontWeight: "bold",
        textAlign: "left",
        flexDirection: "row",
        marginTop: 15,
    },
    tableTaxRow: {
        borderTopWidth: 0,
        margin: "auto",
        borderColor: "lightgrey",
        fontSize: 10,
        fontWeight: "bold",
        textAlign: "left",
        flexDirection: "row",
        marginTop: 2,
    },
    tableHeaderRow: {
        borderBottomWidth: 1,
        margin: "auto",
        borderColor: "lightgrey",
        fontSize: 16,
        fontWeight: "bold",
        textAlign: "left",
        flexDirection: "row",
    },
    tableRow: {
        borderBottomWidth: 0,
        margin: "auto",
        fontSize: 16,
        fontWeight: "bold",
        textAlign: "left",
        flexDirection: "row",
        marginTop: 5,
    },
    tableCell: { marginTop: 5, fontSize: 9 },
    tableHeaderCell: { fontWeight: "bold", paddingVertical: 5, marginTop: 5, fontSize: 9 },

    tableCol1: {
        width: "8%",
        borderStyle: "solid",
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        textAlign: "left",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    tableCol2: {
        width: "14%",
        borderStyle: "solid",
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        textAlign: "left",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    tableCol3: {
        width: "15%",
        borderStyle: "solid",
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        textAlign: "left",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    tableCol4: {
        width: "15%",
        borderStyle: "solid",
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        textAlign: "left",
        // alignSelf: "flex-end",
        // justifySelf: "flex-end",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    tableCol5: {
        width: "10%",
        borderStyle: "solid",
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        textAlign: "left",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    tableCol6: {
        width: "10%",
        borderStyle: "solid",
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        textAlign: "left",
        flexDirection: "column",
    },
    tableCol7: {
        width: "10%",
        borderStyle: "solid",
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        textAlign: "left",
        flexDirection: "column",
    },
    colWidth10: {
        width: "9%",
        borderStyle: "solid",
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        textAlign: "right",
        flexDirection: "column",
    },
});
export const viewerStyle = { width: "100%", height: "100%", minHeight: 800 };

export const styless = StyleSheet.create({
    page: {
        padding: 30,
    },
    container: {
        flex: 1,
        flexDirection: "row",
        "@media max-width: 400": {
            flexDirection: "column",
        },
    },
    image: {
        marginBottom: 10,
    },
    imageStyle: {
        width: 80,
        height: 80,
    },
    leftColumn: {
        flexDirection: "column",
        width: 170,
        paddingTop: 30,
        paddingRight: 15,
        "@media max-width: 400": {
            width: "100%",
            paddingRight: 0,
        },
        "@media orientation: landscape": {
            width: 200,
        },
    },
    footer: {
        fontSize: 12,
        fontFamily: "Lato Bold",
        textAlign: "center",
        marginTop: 25,
        paddingTop: 10,
        borderWidth: 3,
        borderColor: "gray",
        borderStyle: "dashed",
        "@media orientation: landscape": {
            marginTop: 10,
        },
    },
    title: {
        fontFamily: "Lato Bold",
        fontSize: 14,
        marginBottom: 10,
        textTransform: "uppercase",
    },
    container2: {
        flexDirection: "row",
        borderBottomWidth: 2,
        borderBottomColor: "#112131",
        borderBottomStyle: "solid",
        alignItems: "stretch",
    },
    detailColumn: {
        flexDirection: "column",
        flexGrow: 9,
        textTransform: "uppercase",
    },
    linkColumn: {
        flexDirection: "column",
        flexGrow: 2,
        alignSelf: "flex-end",
        justifySelf: "flex-end",
    },
    name: {
        fontSize: 24,
        fontFamily: "Lato Bold",
    },
    subtitle: {
        fontSize: 10,
        justifySelf: "flex-end",
        fontFamily: "Lato",
    },
    link: {
        fontFamily: "Lato",
        fontSize: 10,
        color: "black",
        textDecoration: "none",
        alignSelf: "flex-end",
        justifySelf: "flex-end",
    },
    tableHeader: {
        padding: 10,
        borderWidth: 0,
        // fontFamily: "Lato",
        fontSize: 10,
        // color: "black",
        // textDecoration: "none",
        // alignSelf: "flex-end",
        // justifySelf: "flex-end",
    },
    corpHeader: {
        padding: 10,
        borderWidth: 0,
        // fontFamily: "Lato",
        fontSize: 10,
        // color: "black",
        // textDecoration: "none",
        // alignSelf: "flex-end",
        // justifySelf: "flex-end",
    },

    corpHeaderLeft: {
        padding: 10,
        borderWidth: 0,
        width: 200,
        fontSize: 10,
        // color: "black",
        // textDecoration: "none",
        // alignSelf: "flex-end",
        // justifySelf: "flex-end",
    },

    corpHeaderRight: {
        padding: 10,
        borderWidth: 0,
        // fontFamily: "Lato",
        width: "auto",
        textAlign: "right",
        fontSize: 10,
        // color: "black",
        // textDecoration: "none",
        // alignSelf: "flex-end",
        // justifySelf: "flex-end",
    },

    thStyle: {
        padding: 10,
        borderWidth: 0,
        // fontFamily: "Lato",
        fontSize: 14,
        textTransform: "capitalize",
        // color: "black",
        // textDecoration: "none",
        // alignSelf: "flex-end",
        // justifySelf: "flex-end",
    },
    tdStyle: {
        padding: 10,
        paddingLeft: 40,
        borderWidth: 0.5,
        // fontFamily: "Lato",
        fontSize: 10,
        // color: "black",
        // textDecoration: "none",
        // alignSelf: "flex-end",
        // justifySelf: "flex-end",
    },
    corpHeaderTitle: {
        paddingTop: 10,
        borderWidth: 0,
        // fontFamily: "Lato",
        fontSize: 24,
        color: "black",
        textDecoration: "none",
        // alignSelf: "flex-end",
        // justifySelf: "flex-end",
    },
    tableStyle: {
        paddingTop: 40,
        borderWidth: 0,
        // // fontFamily: "Lato",
        // fontSize: 24,
        // color: "black",
        // textDecoration: "none",
        // alignSelf: "flex-end",
        // justifySelf: "flex-end",
    },
});

export const shiftMenuStyles = makeStyles(({ spacing, typography }) => ({
    icon: {
        fontSize: typography.pxToRem(20),
        marginRight: spacing(2),
    },
}));
export const qbStyles = makeStyles(
    ({ palette, shape, transitions, breakpoints, spacing, typography }) => ({
        tableStyles: {
            /* Styles applied to the root element. */
            root: {
                ...typography.body2,
                display: "table-cell",
                verticalAlign: "inherit",
                // Workaround for a rendering bug with spanned columns in Chrome 62.0.
                // Removes the alpha (sets it to 1), and lightens or darkens the theme color.
                borderBottom: `1px solid lightgrey`,
                textAlign: "left",
                padding: 16,
            },
            /* Styles applied to the root element if `variant="head"` or `context.table.head`. */
            head: {
                color: palette.text.primary,
                lineHeight: typography.pxToRem(24),
                fontWeight: typography.fontWeightMedium,
            },
            /* Styles applied to the root element if `variant="body"` or `context.table.body`. */
            body: {
                color: palette.text.primary,
            },
            /* Styles applied to the root element if `variant="footer"` or `context.table.footer`. */
            footer: {
                color: palette.text.secondary,
                lineHeight: typography.pxToRem(21),
                fontSize: typography.pxToRem(12),
            },
            /* Styles applied to the root element if `size="small"`. */
            sizeSmall: {
                padding: "6px 12px 6px 8px",
                "&:last-child": {
                    paddingRight: 8,
                },
                "&$paddingCheckbox": {
                    width: 24, // prevent the checkbox column from growing
                    padding: "0 12px 0 16px",
                    "&:last-child": {
                        paddingLeft: 12,
                        paddingRight: 16,
                    },
                    "& > *": {
                        padding: 0,
                    },
                },
            },
            /* Styles applied to the root element if `padding="checkbox"`. */
            paddingCheckbox: {
                width: 48, // prevent the checkbox column from growing
                padding: "0 0 0 4px",
                "&:last-child": {
                    paddingLeft: 0,
                    paddingRight: 4,
                },
            },
            /* Styles applied to the root element if `padding="none"`. */
            paddingNone: {
                padding: 0,
                "&:last-child": {
                    padding: 0,
                },
            },
            /* Styles applied to the root element if `align="left"`. */
            alignLeft: {
                textAlign: "left",
            },
            /* Styles applied to the root element if `align="center"`. */
            alignCenter: {
                textAlign: "center",
            },
            /* Styles applied to the root element if `align="right"`. */
            alignRight: {
                textAlign: "right",
                flexDirection: "row-reverse",
            },
            /* Styles applied to the root element if `align="justify"`. */
            alignJustify: {
                textAlign: "justify",
            },
            /* Styles applied to the root element if `context.table.stickyHeader={true}`. */
            stickyHeader: {
                position: "sticky",
                top: 0,
                left: 0,
                zIndex: 2,
                backgroundColor: palette.background.default,
            },
        },
        qbPreferences: {
            listItem: { padding: 0, paddingBottom: 0 },
        },
        icon: {
            fontSize: typography.pxToRem(20),
            marginRight: spacing(0),
            root: {
                fontSize: 62,
            },
        },
        qbCommitInvoiceButton: {
            margin: spacing(1),
            float: "inline-end",
        },
        invoiceStatusIcon: {
            fontSize: typography.pxToRem(16),
            marginRight: spacing(0),
            root: {
                fontSize: 24,
                marginRight: spacing(0),
            },
            check: {
                fontSize: 18,
                color: "green",
                marginRight: spacing(0),
                root: {
                    fontSize: 18,
                    width: 18,
                    height: 18,
                    color: "green",
                    marginRight: spacing(0),
                },
            },
        },

        qbPaymentItem: {
            margin: spacing(0),
            padding: spacing(0),
            color: "darkgrey",
            fontWeight: 400,
            fontSize: 13,
            width: "100%",
        },
        qbPaymentAmount: {
            margin: spacing(0),
            padding: spacing(0),
            color: "green",
            fontWeight: 600,
            fontSize: 14,
            width: "100%",
        },
        qbPaymentTitle: {
            margin: spacing(0),
            padding: spacing(0),
            top: "30%",
            color: "rgb(110 122 138)",
            fontWeight: 600,
            fontSize: 18,
            width: "100%",
            root: {
                paddingBottom: spacing(0),
                width: "100%",
            },
        },
        pieChart: {
            root: {
                flexGrow: 1,
                width: "100%",
                color: "#FFF",
                //   height: 400,
                backgroundColor: palette.background.paper,
            },
        },
        tabNav: {
            colorSecondary: {
                color: "#FFF",
            },
            boxShadow: "none",
            root: {
                "& > *": {
                    margin: spacing(0),
                    padding: spacing(0),
                    width: "100%",
                    // height: 400,
                    boxShadow: "none",
                },
                boxShadow: "none",
            },
        },
        root: {
            padding: `${spacing(0)} !important`,
            width: "100%",
            "& > *": {
                margin: spacing(0),
                padding: spacing(0),
                width: "100%",
                //   height: 400,
            },
        },
        vertTabRoot: {
            root: {
                flexGrow: 1,
                backgroundColor: palette.background.paper,
                display: "flex",
                height: 224,
            },
        },
        tabs: {
            borderRight: `1px solid ${palette.divider}`,
            root: {
                "& > *": {
                    margin: spacing(0),
                    padding: spacing(0),
                    width: "100%",
                    // height: 400,
                },
            },
            textColorPrimary: { color: "#FFF" },
            textColorSecondary: { color: "#FFF" },
        },
        action: {
            margin: spacing(0),
            padding: spacing(0),
            top: "25%",
            fontSize: 42,
        },
        statsDialogTitle: { marginLeft: -20, marginTop: -20, paddingBottom: 8 },
        statsDialogTitleCopy: {
            fontWeight: 500,
            fontSize: 28,
            fontFamily: "Roboto",
            color: "rgb(110 122 138)",
        },
        copy: {
            margin: spacing(0),
            padding: spacing(0),
            paddingTop: spacing(0),
            color: "rgb(110 122 138)",
            fontWeight: 400,
            fontSize: 13,
        },
        card: {
            backgroundColor: palette.common.white,
            border: `0.7px solid ${palette.grey[400]}`,
            borderRadius: shape.borderRadius,
            boxShadow: "none",
            width: "100%",
            padding: `${spacing(0)} !important`,
            paddingRight: `${spacing(0)} !important`,
            marginRight: `${spacing(0.5)} !important`,
            marginLeft: `${spacing(0.5)} !important`,
            marginBottom: `${spacing(1)} !important`,
            [breakpoints.down("sm")]: {
                padding: `${spacing(1)} !important`,
                fontSize: 13,
                "&:h5": { fontSize: 13 },
                // "&:nth-child(2)": { paddingRight: spacing(1) },
                // "&:last-child": { paddingLeft: spacing(1) },
            },
        },
        invoiceListItem: {
            margin: spacing(0),
            padding: spacing(0),
            paddingTop: spacing(0),
            color: "rgb(110 122 138)",
            fontWeight: 400,
            fontSize: 13,
            width: "100%",
        },
        dialogContent: {
            margin: spacing(0),
            padding: spacing(0),
            minHeight: 300,
            width: "100%",
            color: "darkgrey",
        },
        title: {
            margin: spacing(0),
            padding: spacing(0),
            top: "30%",
            color: "rgb(110 122 138)",
            fontWeight: 600,
            fontSize: 18,
            width: "100%",
            root: {
                paddingBottom: spacing(0),
                width: "100%",
            },
        },
        actionIcon: {
            margin: spacing(0),
            padding: spacing(0),
            top: "30%",
            fontSize: 42,
            root: {
                fontSize: 42,
            },
        },
        placeholderContainer: {
            paddingRight: spacing(0),
            boxShadow: "none",
            marginTop: spacing(0),
            [breakpoints.down("sm")]: { paddingRight: spacing(0), paddingBottom: spacing(0) },
        },
        container: {
            paddingRight: spacing(2),
            boxShadow: "none",
            paddingBottom: spacing(0.7),
            // "&:last-child": { paddingRight: 0 },
            "&:nth-child(3n)": { paddingRight: 0 },
            marginTop: spacing(0),
            [breakpoints.down("sm")]: { paddingRight: spacing(0), paddingBottom: spacing(1) },
        },
        wrapper: {
            boxShadow: "none",
            width: "100%",
            padding: `${spacing(1)} ${spacing(0)} !important`,
        },
        cardStyle: {
            backgroundColor: palette.common.white,
            border: `1px solid ${palette.grey[500]}`,
            borderRadius: shape.borderRadius,
            padding: `${spacing(0)} !important`,
            height: "100%",
            boxShadow: "none",
        },
        placeholderCard: {
            backgroundColor: palette.common.white,
            border: `0px solid ${palette.grey[500]}`,
            borderRadius: shape.borderRadius,
            padding: `${spacing(0)} !important`,
            height: "100%",

            "&:last-child": { marginRight: 0 },
        },
        cardRoot: {
            boxShadow: "none",
            padding: `${spacing(0)} !important`,
            width: "100%",
        },
        star: {
            color: palette.text.primary,
        },
        availabilityContainer: {
            backgroundColor: palette.grey[500],
            width: 25,
            height: 25,
        },
        availableCell: {
            backgroundColor: palette.primary.main,
        },
        //   root: {
        //     boxShadow: "none",
        //     padding: `${spacing(0)} !important`,
        //     width: "100%",
        //   },

        paper: {
            width: "100%",
            margin: `${spacing(0)} !important`,
            padding: `${spacing(0)} !important`,
            marginBottom: spacing(2),
            boxShadow: "none",
        },
        table: {
            width: "100%",
            margin: `${spacing(0)} !important`,
            padding: `${spacing(0)} !important`,
        },

        visuallyHidden: {
            border: 0,
            clip: "rect(0 0 0 0)",
            height: 1,
            margin: -1,
            overflow: "hidden",
            padding: 0,
            position: "absolute",
            top: 20,
            width: 1,
        },
        media: {
            height: 0,
            paddingTop: "56.25%", // 16:9
        },
        expand: {
            transform: "rotate(0deg)",
            marginLeft: "auto",
            transition: transitions.create("transform", {
                duration: transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: "rotate(180deg)",
        },
        avatar: {
            fontSize: 38,
            fontWeight: 500,
            color: palette.secondary.main,
        },

        subHeader: {
            fontSize: 13,
            boxShadow: "none",
            fontWeight: 500,
        },
    })
);
export const qbIconStyles = makeStyles(({ spacing, typography }) => ({
    icon: {
        fontSize: typography.pxToRem(20),
        marginRight: spacing(2),
    },
}));
