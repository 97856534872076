import { Button, CircularProgress, DialogActions, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react'
import { MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment as MomentUtils } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Close, Event } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { actions as WorkerActions, selectors as WorkerDetailSelectors } from "store/modules/workerDetails";
import { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { actions as MetaActions } from "store/modules/meta";
import ImageWithPreview from 'components/ImageWithPreview/ImageWithPreview';

const controlPadding = {
    leftColumn: { paddingRight: 4 },
    rightColumn: { paddingLeft: 4 },
};

const TabPanel = props => {
    const { children, value, index, height, ...other } = props;
    return (
        <div
            role="tabpanel"
            style={{ height: height ? height : "100%", paddingTop: 20 }}
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}>
            {value === index && children}
        </div>
    );
};

export default function WorkPermitDocumentTab(props) {
    const [open, setOpen] = useState(false);
    const worker = useSelector(WorkerDetailSelectors.getWorkerDetails).worker;
    const isUpdating = useSelector(WorkerDetailSelectors.getIsUpdatingWorkPermit);
    const [expiryDate, setExpiryDate] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFilePreview, setSelectedFilePreview] = useState(null);
    const classes = useStyles();
    const dispatch = useDispatch();
    const { signedWorkPermitImageUrl, workPermitExpiryDate, workPermitUrl } = worker;
    const { value } = props;
    let disableActions = (workPermitUrl ? !selectedFile && moment(workPermitExpiryDate).format("DD-MM-YYYY") === moment(expiryDate).format("DD-MM-YYYY") : !expiryDate) || (!signedWorkPermitImageUrl && !selectedFile) || isUpdating;

    useEffect(() => {
        if (workPermitExpiryDate) setExpiryDate(moment(workPermitExpiryDate))
        if (signedWorkPermitImageUrl) setSelectedFilePreview(signedWorkPermitImageUrl)
    }, [workPermitExpiryDate, signedWorkPermitImageUrl]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleReset = () => {
        setExpiryDate(workPermitExpiryDate ? moment(workPermitExpiryDate) : null);
        setSelectedFile(null);
        setSelectedFilePreview(signedWorkPermitImageUrl);
    };

    const handleUploadFile = (file, e) => {
        if (new RegExp("image/*").test(file?.type)) {
            const item = new Blob([file], { type: file.type });
            item.name = `logo_${file.name}`;
            setSelectedFilePreview(window.URL.createObjectURL(item));
            setSelectedFile(item);
        } else {
            dispatch(MetaActions.errorToast("Please upload only image files"));
        }
        e.target.value = null
    };

    const handleUploadData = () => {
        let data = {};
        if (selectedFile) data.file = selectedFile
        data.workerPermitExpiryDate = expiryDate ? moment(expiryDate).format("DD-MM-YYYY") : "null";
        dispatch(WorkerActions.uploadWorkPermit(data, callback))
    };

    const callback = (data) => {
        setSelectedFile(null);
        setExpiryDate(data.workerPermitExpiryDate || null);
        setSelectedFilePreview(data.signedWorkPermitImageUrl);
    };


    return (
        <TabPanel value={value} index={2}>
            <Grid
                container
                alignContent="flex-start"
                alignItems="flex-start"
                item
                xs={12}
                marginTop={2}
                style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Grid
                    item
                    xs={12}
                    md={12}
                    alignContent="flex-start"
                    alignItems="flex-start"
                    container
                    style={{ ...controlPadding.leftColumn, paddingLeft: 0, paddingRight: 20 }}>
                    {worker.isWorkerSin9 ? <Grid
                        item
                        container
                        direction="row"
                        xs={12}
                        style={{ paddingLeft: 0, paddingRight: 0, width: "100%", textAlign: "left" }}>
                        <Grid item xs={12} md={6} style={controlPadding.leftColumn}>
                            <LocalizationProvider dateAdapter={MomentUtils}>
                                <MobileDatePicker
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            style={{width:266}}
                                            placeholder="Select work permit expiry date"
                                            InputProps={{
                                                endAdornment: (
                                                    <>
                                                        {expiryDate && <InputAdornment position="end">
                                                            <IconButton edge="end" onClick={(e) => {
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                                setExpiryDate(null);
                                                            }}>
                                                                <Close />
                                                            </IconButton>
                                                        </InputAdornment>}
                                                        <InputAdornment position="end">
                                                            <IconButton edge="end" onClick={handleOpen}>
                                                                <Event />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    </>
                                                ),
                                            }}
                                        />
                                    )}
                                    hideTabs
                                    disablePast
                                    open={open}
                                    onOpen={handleOpen}
                                    inputFormat={"DD MMM, YYYY"}
                                    onClose={handleClose}
                                    label="Work permit expiry date"
                                    showToolbar={false}
                                    value={expiryDate}
                                    onError={a => console.log("error", a)}
                                    onChange={e => setExpiryDate(e)}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} marginTop={2}>
                            <Typography align="left" style={{ textAlign: "left" }}>
                                Work permit document
                            </Typography>
                            {selectedFilePreview && (
                                <ImageWithPreview src={selectedFilePreview} width={266} height={166} />
                                // <img src={selectedFilePreview} alt="Profile picture" className={classes.img} width={"40%"} />
                            )}
                        </Grid>
                        <Grid item xs={12} marginTop={1}>
                            <Button color="secondary" variant="outlined" component="label">
                                {(selectedFile || signedWorkPermitImageUrl) ? "Update Document" : "Add Document"}
                                <input
                                    type="file"
                                    hidden
                                    accept="image/*"
                                    onChange={files => handleUploadFile(files.target.files[0], files)}
                                />
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            <DialogActions className={classes.footer}>
                                <Button
                                    type="button"
                                    className="outline"
                                    onClick={handleReset}
                                    disabled={disableActions}>
                                    Reset
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    type="submit"
                                    onClick={handleUploadData}
                                    disabled={disableActions}
                                >
                                    {isUpdating ? <CircularProgress size={20} /> : signedWorkPermitImageUrl ? "Update" : "Save"}
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Grid> :
                        <Grid xs={12} textAlign="center">
                            <Typography textAlign="center">
                                {"The worker's work permit cannot be added because their SIN is either missing or does not begin with 9."}
                            </Typography>
                        </Grid>}
                </Grid>
            </Grid>
        </TabPanel >
    )
};

const useStyles = makeStyles(theme => ({
    img: {
        marginTop: theme.spacing(1.5),
        width: "20%",
        filter: "drop-shadow(0px 0px 9px #ccc1c1)"
    },
    footer: {
        marginTop: theme.spacing(3),
        textAlign: "center",
        justifyContent: "flex-end",
    },
}))
