export default {
  SET_ERRORS: "workers/SET_ERRORS",
  UPDATE_ADDWORKER_DATA: "workers/UPDATE_ADDWORKER_DATA",
  SET_WORKERS: "workers/SET_WORKERS",
  SET_VERIFIED_WORKERS: "workers/SET_VERIFIED_WORKERS",
  SET_SUSPENDED_WORKERS: "workers/SET_SUSPENDED_WORKERS",
  SET_UNVERIFIED_WORKERS: "workers/SET_UNVERIFIED_WORKERS",
  SET_DEACTIVATED_WORKERS: "workers/SET_DEACTIVATED_WORKERS",
  SET_APPLIED_WORKERS: "workers/SET_APPLIED_WORKERS",
  SET_IDENTIFICATION_WORKERS: "workers/SET_IDENTIFICATION_WORKERS",
  UPDATE_PAGE_SORT: "workers/UPDATE_PAGE_SORT",
  UPDATE_VERIFIED_PAGE_SORT: "workers/UPDATE_VERIFIED_PAGE_SORT",
  UPDATE_SUSPENDED_PAGE_SORT: "workers/UPDATE_SUSPENDED_PAGE_SORT",
  UPDATE_UNVERIFIED_PAGE_SORT: "workers/UPDATE_UNVERIFIED_PAGE_SORT",
  UPDATE_DEACTIVATED_PAGE_SORT: "workers/UPDATE_DEACTIVATED_PAGE_SORT",
  UPDATE_APPLIED_PAGE_SORT: "workers/UPDATE_APPLIED_PAGE_SORT",
  UPDATE_IDENTIFICATION_WORKERS_PAGE_SORT: "workers/SET_IDENTIFICATION_WORKERS_PAGE_SORT",
  UPDATE_PAGE_FILTER: "workers/UPDATE_PAGE_FILTER",
  UPDATE_DEACTIVATED_PAGE_FILTER: "workers/UPDATE_DEACTIVATED_PAGE_FILTER",
  UPDATE_VERIFIED_PAGE_FILTER: "workers/UPDATE_VERIFIED_PAGE_FILTER",
  UPDATE_UNVERIFIED_PAGE_FILTER: "workers/UPDATE_UNVERIFIED_PAGE_FILTER",
  UPDATE_SUSPENDED_PAGE_FILTER: "workers/UPDATE_SUSPENDED_PAGE_FILTER",
  UPDATE_APPLIED_PAGE_FILTER: "workers/UPDATE_APPLIED_PAGE_FILTER",
  UPDATE_IDENTIFICATION_WORKERS_PAGE_FILTER: "workers/SET_IDENTIFICATION_WORKERS_PAGE_FILTER",
  SET_WORKER: "workers/SET_WORKER",
  SET_NEW_WORKER: "workers/SET_NEW_WORKER",
  SET_WORKER_SUSPENSION: "workers/SET_WORKER_SUSPENSION",
  SET_WORKER_PAYROLL: "workers/SET_WORKER_PAYROLL",
  SET_WORKER_GIGS: "workers/SET_WORKER_GIGS",
  SET_WORKER_BANS: "workers/SET_WORKER_BANS",
  SET_WORKER_PREFERRED: "workers/SET_WORKER_PREFERRED",
  ADD_WORKER_BAN: "workers/ADD_WORKER_BAN",
  WORKER_FUTURE_JOBS_LIST: "workers/WORKER_FUTURE_JOBS_LIST",
  ADD_WORKER_PREFERRED: "workers/ADD_WORKER_PREFERRED",
  UPDATE_TOTAL_COUNT: "workers/UPDATE_TOTAL_COUNT",
  UPDATE_PAGE_INDEX: "workers/UPDATE_PAGE_INDEX",
  UPDATE_PAGE_SIZE: "workers/UPDATE_PAGE_SIZE",
  UPDATE_PAGE_OFFSET: "workers/UPDATE_PAGE_OFFSET",
  IS_LOADING_TABLE: "workers/IS_LOADING_TABLE",
  IS_LOADING_DETAIL: "workers/IS_LOADING_DETAIL",
  IS_LOADING_INFO: "workers/IS_LOADING_INFO",
  RESET_EDIT_DATA: "workers/RESET_EDIT_DATA",
  UPDATE_EDIT_DATA: "workers/UPDATE_EDIT_DATA",
  REMOVE_WORKER_REP: "workers/REMOVE_WORKER_REP",
  FETCH_WORKER_STATS: "workers/FETCH_WORKER_STATS",
  FETCH_IDENTITY_WORKER_STATS: "workers/FETCH_IDENTITY_WORKER_STATS",
  SET_WAITLIST: "waitlist/SET_WAITLIST",
  SET_WAITLIST_TODAY: "waitlist/SET_WAITLIST_TODAY",
  SET_WAITLIST_TOMORROW: "waitlist/SET_WAITLIST_TOMORROW",
  UPDATE_WAITLIST_FILTER: "waitlist/UPDATE_WAITLIST_FILTER",
  UPDATE_WAITLIST_TODAY_FILTER: "waitlist/UPDATE_WAITLIST_TODAY_FILTER",
  UPDATE_WAITLIST_TODAY_TABLE_FILTER: "waitlist/UPDATE_WAITLIST_TODAY_TABLE_FILTER",
  UPDATE_WAITLIST_TODAY_PAGE_SORT: "waitlist/UPDATE_WAITLIST_TODAY_PAGE_SORT",
  UPDATE_WAITLIST_TODAY_CRITERIA: "waitlist/UPDATE_WAITLIST_TODAY_CRITERIA",
  UPDATE_WAITLIST_TOMORROW_FILTER: "waitlist/UPDATE_WAITLIST_TOMORROW_FILTER",
  UPDATE_WAITLIST_TOMORROW_TABLE_FILTER: "waitlist/UPDATE_WAITLIST_TOMORROW_TABLE_FILTER",
  UPDATE_WAITLIST_TOMORROW_PAGE_SORT: "waitlist/UPDATE_WAITLIST_TOMORROW_PAGE_SORT",
  UPDATE_WAITLIST_TOMORROW_CRITERIA: "waitlist/UPDATE_WAITLIST_TOMORROW_CRITERIA",
  SET_VALID_SIN: "workers/SET_VALID_SIN",
  SET_PASSWORD_MATCH: "workers/SET_PASSWORD_MATCH",
  SET_PASSWORD: "workers/SET_PASSWORD",
  SET_VALID_EMAIL: "workers/SET_VALID_EMAIL",
  SET_EMAIL_AVAILABLE: "workers/SET_EMAIL_AVAILABLE",
  UPDATE_FIRSTNAME: "workers/UPDATE_FIRSTNAME",
  UPDATE_SIN: "workers/UPDATE_SIN",
  SET_SIN_TAKEN: "workers/SET_SIN_TAKEN",
  VALIDATE_WORKER: "workers/VALIDATE_WRKER",
  SET_LOCATION: "workers/SET_LOCATION",
  SET_LOADING_STATE: "workers/SET_LOADING_STATE",
  SET_WORK_PERMITS_WORKERS: "workers/SET_WORK_PERMITS_WORKERS",
  UPDATE_WORK_PERMITS_WORKERS_PAGE_SORT: "workers/SET_WORK_PERMITS_WORKERS_PAGE_SORT",
  UPDATE_WORK_PERMITS_WORKERS_PAGE_FILTER: "workers/SET_WORK_PERMITS_WORKERS_PAGE_FILTER",
  FETCH_WORK_PERMITS_WORKER_STATS: "workers/FETCH_WORK_pERMITS_WORKER_STATS",
};
