import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import { VisibilityOff } from "@mui/icons-material";
import {
    JobStatusesArray,
    TimeframeArray,
} from "helpers/constants/index";

const styles = ({ spacing }) => ({
    container: {
        "& p": {
            marginRight: spacing(2),
        },
        "& p:nth-last-child(1)": {
            marginRight: (props) => props.isScheduled ? spacing(0) : spacing(2)
        },
        "& svg": {
            marginRight: spacing(1),
        },
    },
});

function StateInfo({ classes, jobId, isActive, isQueued, isScheduled }) {
    return (
        <Grid container item xs={isScheduled ? 12 : 6} alignItems="center" className={classes.container}>
            <Typography variant="body1" color="textSecondary">
                {`#${jobId}`}
            </Typography>
            {!isActive && !isQueued && (
                <React.Fragment>
                    <Typography variant="body1" color="textSecondary">
                        &#8226;
                    </Typography>
                    <VisibilityOff color="primary" />
                    <Typography variant="body1" color="textSecondary">
                        Draft
                    </Typography>
                </React.Fragment>
            )}
        </Grid>
    );
}

StateInfo.propTypes = {
    classes: PropTypes.object.isRequired,
    jobId: PropTypes.number.isRequired,
    status: PropTypes.oneOf(JobStatusesArray).isRequired,
    timeframe: PropTypes.oneOf(TimeframeArray).isRequired,
    isActive: PropTypes.bool.isRequired,
};

export default withStyles(styles)(StateInfo);
