// HelperMessage
import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Button from "components/Button/Button";
import { formatMoney } from "utils/stringUtils";
import { useDispatch, useSelector } from "react-redux";
import { actions as PostingActions, selectors as PostingSelectors } from "store/modules/posting"
import moment from "moment";

const styles = theme => ({
    footer: {
        position: "fixed",
        bottom: theme.spacing(4),
        right: theme.spacing(4),
        zIndex: 99999,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
    },
    button: {
        marginTop: "8px",
    },
});

function SummaryFooter({ totalCost, onNext, canPost, classes }) {
    const calculatedJobBill = useSelector(PostingSelectors.getCalculatedJobBill);
    const {
        breakMins,
        workersNeeded,
        startTime,
        endTime,
        breakType,
        location,
        employer,
        duration
    } = useSelector(state => state.posting);
    const dispatch = useDispatch();

    const handleCalculateBill = useCallback(() => {
        const { hours=0, minutes=0 } = duration;
        const startUTCTime = moment(startTime);
        const endUTCTime = moment(startTime).add((hours * 60) + minutes, 'minutes')
        if (location && location.region && (workersNeeded == 0 || workersNeeded > 0))
            dispatch(
                PostingActions.calculateEmployerBill(employer.id, {
                    breakMins,
                    peopleNeeded: Number(workersNeeded),
                    jobStartTime: startUTCTime,
                    jobEndTime: endUTCTime,
                    isBreakPaid: breakType === 1,
                    region: location?.region
                }),
            );
    }, [employer?.id, breakMins, workersNeeded, startTime, endTime, breakType, location?.region]);

    useEffect(() => {
        handleCalculateBill();
    }, [handleCalculateBill]);

    return (
        <div className={classes.footer}>
            <Typography variant="caption" color="textSecondary">
                Total
            </Typography>
            <Typography variant="h4">{formatMoney(calculatedJobBill?.total)}</Typography>
            <Typography variant="caption" color="textSecondary">
                +GST
            </Typography>
            <Button
                className={classes.button}
                icon={<ChevronRight />}
                label="Next"
                alignIcon="right"
                onClick={onNext}
                disabled={!canPost}
            />
        </div>
    );
}

SummaryFooter.propTypes = {
    classes: PropTypes.object.isRequired,
    totalCost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onNext: PropTypes.func,
    canPost: PropTypes.bool,
};

SummaryFooter.defaultProps = {
    totalCost: 0,
    onNext: () => { },
    canPost: false,
};

export default withStyles(styles)(SummaryFooter);
