import { Grid, Tab, Tabs, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import SwipeableViews from 'react-swipeable-views';
import { actions as holidaysActions, selectors as holidaysSelectors } from "store/modules/holidays"
import { useDispatch, useSelector } from 'react-redux';
import HolidayWorkerTableContainer from './HolidayWorkerTableContainer';
import PayrollSummary from './PayrollSummary/PayrollSummary';
import ResendStubDialog from './ResendPayStub';
import CancelTransferDialog from 'views/Finance/FinanceOverview/components/Dialogs/CancelTransferDialog';
import { CancelScheduleSend } from '@mui/icons-material';
import ResendDialog from 'views/Finance/FinanceOverview/components/Dialogs/ResendDialog';
import RequestReversalDialog from 'views/Holidays/components/RequestReversalDialog';

const useStyles = makeStyles(theme => ({
    tabbedTableContainer: {
        marginTop: theme.spacing(3),
    },
    tableContainer: {
        width: "100%",
    },
    tabIndicator: {
        display: "none !important",
    },
    tabRoot: {
        marginRight: theme.spacing(1),
        borderRadius: `${theme.shape.borderRadius}px`,
        opacity: 1,
        "&:hover": {
            border: `1px solid ${theme.palette.secondary.main}`,
        },
    },
    tabLabelContainer: {
        padding: 0,
    },
    tabLabel: {
        fontSize: "1rem",
    },
    tabSelected: {
        border: `2px solid ${theme.palette.secondary.main}`,
        backgroundColor: theme.customPalette.primary[200],
        "&:hover": {
            border: `2px solid ${theme.palette.secondary.main}`,
        },
    },
}));

export default function ProvinceTabs(props) {
    const holidayDetails = useSelector(holidaysSelectors.getHolidayDetail);
    const isResendPayStubOpen = useSelector(holidaysSelectors.getIsResendPayStubDialog);
    const sendingPaystubLoading = useSelector(holidaysSelectors.getIsCreatePaystubLoading);
    const cancelETransfer = useSelector(holidaysSelectors.getIsCancelETransferDialog);
    const resendETransfer = useSelector(holidaysSelectors.getIsResendETransferDialog);
    const requestReversal = useSelector(holidaysSelectors.getIsRequestReversalDialog);
    const resolveETransfer = useSelector(holidaysSelectors.getIsResolveETransferDialog);
    const resolveReversal = useSelector(holidaysSelectors.getIsResolveReversalDialog);
    const resendReversal = useSelector(holidaysSelectors.getIsResendReversalDialog);
    const cancelReversal = useSelector(holidaysSelectors.getIsCancelReversalDialog);
    const isDialogActionLoading = useSelector(holidaysSelectors.getIsDialogActionLoading);
    const classes = useStyles();
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState(0);
    const selectedProvince = holidayDetails?.provinceHoliday?.[selectedTab];

    useEffect(() => {
        return () => {
            dispatch(holidaysActions.closeAllPaymentDialogs());
        }
    }, []);

    const tabClass = {
        root: classes.tabRoot,
        selected: classes.tabSelected,
    };

    const handleChange = (event, value) => setSelectedTab(value);

    const handleCloseResendPayStub = () => dispatch(holidaysActions.setIsResendPayStubDialog(false));

    const handleConfirmResendStub = () => dispatch(holidaysActions.sendPaystubEmail());

    const handleConfirmCancelETransfer = (reason) => dispatch(holidaysActions.cancelETransfer({ reason, selectedProvince }));
    const handleConfirmResolveETransfer = (reason) => dispatch(holidaysActions.resolveETransfer({ reason, selectedProvince }));
    const handleConfirmResendETransfer = () => dispatch(holidaysActions.resendETransfer({ selectedProvince }));
    const handleConfirmResolveReversal = () => dispatch(holidaysActions.resolveReversal({ selectedProvince }));
    const handleConfirmResendReversal = (reason) => dispatch(holidaysActions.resendReversal({ reason, selectedProvince }));
    const handleConfirmCancelReversal = (reason) => dispatch(holidaysActions.cancelReversal({ reason, selectedProvince }));

    const handleCloseCancelETransfer = () => dispatch(holidaysActions.setIsCancelETransferDialog(false));
    const handleCloseResolveETransfer = () => dispatch(holidaysActions.setIsResolveETransferDialog(false));
    const handleCloseResendETransfer = () => dispatch(holidaysActions.setIsResendETransferDialog(false));
    const handleCloseResolveReversal = () => dispatch(holidaysActions.setIsResolveReversalDialog(false));
    const handleCloseResendReversal = () => dispatch(holidaysActions.setIsResendReversalDialog(false));
    const handleCloseCancelReversal = () => dispatch(holidaysActions.setIsCancelReversalDialog(false));

    return (
        <Grid
            container
            className={classes.tabbedTableContainer}
            alignItems="center"
            justifyContent="flex-end">
            <Grid container item xs={12}>
                <Tabs
                    variant="scrollable"
                    scrollButtons={false}
                    value={selectedTab}
                    onChange={handleChange}
                    classes={{ indicator: classes.tabIndicator }}>
                    {holidayDetails?.provinceHoliday?.map(p => <Tab key={p.province.id} label={p.province.name} classes={tabClass} />)}
                </Tabs>
            </Grid>
            <Grid container item xs={12} className={classes.tabbedTableContainer}>
                <SwipeableViews axis="x" index={selectedTab} className={classes.tableContainer}>
                    {holidayDetails?.provinceHoliday?.map((p, i) => (i === selectedTab ? (
                        <React.Fragment key={p.provinceId}>
                            <HolidayWorkerTableContainer province={p} />
                        </React.Fragment>
                    ) : null))}
                </SwipeableViews>
            </Grid>
            <PayrollSummary
                province={holidayDetails?.provinceHoliday[selectedTab]}
                holiday={holidayDetails}
            />
            <ResendStubDialog
                isLoading={sendingPaystubLoading}
                disabled={sendingPaystubLoading}
                open={Boolean(isResendPayStubOpen)}
                onClose={handleCloseResendPayStub}
                onCancel={handleCloseResendPayStub}
                onConfirm={handleConfirmResendStub}
            />
            <CancelTransferDialog
                open={Boolean(cancelETransfer)}
                title={`Cancel Pending Transfer?`}
                description={`Are you sure you want to cancel this transfer?`}
                confirmText="Cancel Transfer"
                onClose={handleCloseCancelETransfer}
                onConfirm={handleConfirmCancelETransfer}
                onCancel={handleCloseCancelETransfer}
                maxWidth="md"
                alertType="save"
                fullWidth={false}
                transferData={cancelETransfer}
                iconFontSize={94}
                isCancelLoading={isDialogActionLoading}
                icon={<CancelScheduleSend color="error" style={{ fontSize: 96 }} />}
                size="md"
            />

            <ResendDialog
                open={Boolean(resendETransfer)}
                title={`Resend Payroll`}
                description={`Resend this cancelled transfer to the worker?`}
                confirmText="Resend Transfer"
                onClose={handleCloseResendETransfer}
                onConfirm={handleConfirmResendETransfer}
                onCancel={handleCloseResendETransfer}
                maxWidth="md"
                fullWidth={false}
                iconFontSize={94}
                isLoading={isDialogActionLoading}
                icon={<CancelScheduleSend color="secondary" style={{ fontSize: 96 }} />}
                size="md"
            // table={<Grid container item xs={12} style={{ width: 300, display: "block" }}></Grid>}
            />

            {Boolean(requestReversal) && <RequestReversalDialog selectedProvince={selectedProvince} />}

            <ResendDialog
                open={Boolean(resolveETransfer)}
                title={`Resolve Payroll`}
                description={`Resolve this cancelled transfer to the worker?`}
                confirmText="Resolve Transfer"
                onClose={handleCloseResolveETransfer}
                onConfirm={handleConfirmResolveETransfer}
                onCancel={handleCloseResolveETransfer}
                maxWidth="md"
                alertType="save"
                isLoading={isDialogActionLoading}
                fullWidth={false}
                iconFontSize={94}
                icon={<CancelScheduleSend color="secondary" style={{ fontSize: 96 }} />}
                size="md"
            />

            <ResendDialog
                open={Boolean(resolveReversal)}
                title={`Resolve Payroll`}
                description={`Resolve this cancelled transfer to the worker?`}
                confirmText="Resolve Transfer"
                onClose={handleCloseResolveReversal}
                onConfirm={handleConfirmResolveReversal}
                onCancel={handleCloseResolveReversal}
                maxWidth="md"
                alertType="save"
                fullWidth={false}
                isLoading={isDialogActionLoading}
                iconFontSize={94}
                icon={<CancelScheduleSend color="secondary" style={{ fontSize: 96 }} />}
                size="md"
            />

            <CancelTransferDialog
                open={Boolean(resendReversal)}
                title={`Resend Reversal Request`}
                description={`Resend this cancelled transfer reversal request?`}
                confirmText="Resend Request"
                onClose={handleCloseResendReversal}
                onConfirm={handleConfirmResendReversal}
                onCancel={handleCloseResendReversal}
                maxWidth="md"
                alertType="save"
                fullWidth={false}
                transferData={resendReversal}
                iconFontSize={94}
                isCancelLoading={isDialogActionLoading}
                icon={<CancelScheduleSend color="error" style={{ fontSize: 96 }} />}
                size="md"
            />

            <CancelTransferDialog
                open={Boolean(cancelReversal)}
                title={`Cancel Reversal Request?`}
                description={`Are you sure you want to cancel this request?`}
                confirmText="Cancel Request"
                onClose={handleCloseCancelReversal}
                onConfirm={handleConfirmCancelReversal}
                onCancel={handleCloseCancelReversal}
                maxWidth="md"
                alertType="save"
                fullWidth={false}
                transferData={cancelReversal}
                iconFontSize={94}
                isCancelLoading={isDialogActionLoading}
                icon={<CancelScheduleSend color="error" style={{ fontSize: 96 }} />}
                size="md"
            />
        </Grid>
    )
}
