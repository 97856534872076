import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import Downshift from "downshift";
import { fetchGears } from "store/modules/requiredGear/actions";
import { useDispatch, useSelector } from "react-redux";
import { actions as requiredGearActions, selectors as requiredGearSelectors } from "store/modules/requiredGear";
import { filter, includes } from "lodash";

const useStyles = makeStyles(theme => ({
    chip: {
        margin: theme.spacing(0.5, 0.25)
    },
    tagField: {
        '& fieldset': {
            // border: 'none'
        },
        '& .Mui-focused': {
            // borderColor: '#aebecd'
        }
    },
    filterField: {
        border: 'none !important',
        padding: '0 !important',
        flexWrap: 'wrap',
        '& input': {
            padding: '0 !important',
            // height: '20px !important'
        }
    },
    formControl: {
        display: 'flex',
        flexWrap: 'wrap',
        borderRadius: '2px',
        padding: 0
    },
    wrapper: {
        position: "absolute",
        zIndex: 100,
    },
    dropdownContainer: {
        boxShadow: "0 2px 2px #666",
        backgroundColor: "White",
        maxHeight: "100px",
        margin: 0,
        overflowY: "auto",
        overflowX: "hidden",
        boxSizing: "inherit",
        borderRadius: "2px",
        padding: "10px 0px 10px 0px",
    },
    dropdownOption: {
        padding: "10px",
        cursor: "pointer",
        "&:focus": {
            backgroundColor: "#e1e8ec",
        },
        "&:hover": {
            backgroundColor: "#e1e8ec",
        }
    }
}));

function GearsDropdown({ ...props }) {

    const ref = useRef()
    const classes = useStyles();
    const dispatch = useDispatch();
    const { selectedTags, placeholder, tags, defaultValue, isFilterField, ...other } = props;
    const [inputValue, setInputValue] = React.useState("");
    const [selectedItem, setSelectedItem] = React.useState(defaultValue);
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const [filteredGearList, setFilteredGearList] = React.useState([]);
    const [gearNameArray, setGearNameArray] = React.useState([]);
    const gearList = useSelector(requiredGearSelectors.getrequiredGearsList);

    useEffect(() => {
        setFilteredGearList(gearNameArray);
    }, [gearNameArray]);

    useEffect(() => {
        setFilteredGearList(filter(gearNameArray, n => includes(n?.gearName?.toLowerCase?.(), inputValue?.trim()?.toLowerCase?.())))
    }, [inputValue]);

    useEffect(() => {
        // setSelectedItem(tags);
    }, [tags]);

    useEffect(() => {
        if (selectedTags) {
            selectedTags(selectedItem);
        }
        setGearNameArray(filter(gearNameArray, n => selectedItem.findIndex(a => a?.gearName === n?.gearName) <= -1))
    }, [selectedItem, selectedTags]);

    useEffect(() => {
        // setSelectedItem(defaultValue);
        setGearNameArray(filter(gearList, n => selectedItem.findIndex(a => a?.gearName === n.gearName) <= -1))
    }, [defaultValue]);

    useEffect(() => {
        dispatch(fetchGears());
    }, []);

    useEffect(() => {
        let gearsName = gearList.map((option, index) => option);
        setGearNameArray(gearsName);
    }, [gearList]);

    useEffect(() => {
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (dropdownOpen && ref.current && !ref.current.contains(e.target)) {
                setDropdownOpen(false)
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [dropdownOpen])

    function handleKeyDown(event) {
        if (event.key === "Enter") {
            setDropdownOpen(false)
            const newSelectedItem = [...selectedItem];
            const duplicatedValues = newSelectedItem.findIndex(s => s?.gearName?.toLowerCase?.() === event.target.value.trim()?.toLowerCase?.())

            if (duplicatedValues !== -1) {
                setInputValue("");
                return;
            }
            if (!event.target.value.replace(/\s/g, "").length) return;

            const item = gearList.find(s => s.gearName?.toLowerCase?.() === event.target.value.trim()?.toLowerCase?.())

            if (item) {
                newSelectedItem.push(item);
                setSelectedItem(newSelectedItem);
            }
            setInputValue("");
        }
        if (
            selectedItem.length &&
            !inputValue.length &&
            event.key === "Backspace"
        ) {
            setDropdownOpen(false)
            setSelectedItem(selectedItem.slice(0, selectedItem.length - 1));
        }
    }
    function handleChange(item) {
        let newSelectedItem = [...selectedItem];
        if (newSelectedItem.findIndex(a => a?.gearName === item?.gearName) === -1) {
            newSelectedItem = [...newSelectedItem, item];
        }
        setInputValue("");
        setSelectedItem(newSelectedItem);
    }

    const handleDelete = item => {
        const newSelectedItem = [...selectedItem];
        setSelectedItem(newSelectedItem.filter(a => a?.gearName !== item?.gearName));
        setGearNameArray(filter(gearList, n => newSelectedItem.findIndex(a => a?.gearName === n.gearName) <= -1))
    };

    function handleInputChange(event) {
        setDropdownOpen(true)
        if (!/[^a-zA-Z0-9_ ]/.test(event.target.value)) {
            setInputValue(event.target.value);
        }
    }

    function handleChangeValue(value) {
        const newSelectedItem = [...selectedItem];
        const duplicatedValues = newSelectedItem.findIndex(a => a?.gearName === value?.gearName.trim());

        if (duplicatedValues !== -1) {
            setInputValue("");
            return;
        }
        else {
            newSelectedItem.push(value)
            setDropdownOpen(false);
            setInputValue("");
        }
        setSelectedItem(newSelectedItem)
    }

    return (
        <div style={{ position: "relative" }}>
            <Downshift
                id="downshift-multiple-gears"
                inputValue={inputValue}
                onChange={handleChange}
                // selectedItem={selectedItem}
            >
                {({ getInputProps }) => {
                    const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
                        onKeyDown: handleKeyDown,
                        placeholder
                    });
                    return (
                        <div>
                            <TextField
                                className={classes.tagField}
                                InputProps={{
                                    className: isFilterField ? `${classes.filterField} ${classes.formControl}` : `${classes.formControl}`,
                                    startAdornment: selectedItem.map((item,index) => (
                                        <Chip
                                            key={`${item?.id}-${index}`}
                                            tabIndex={-1}
                                            size="small"
                                            label={item?.gearName}
                                            className={classes.chip}
                                            onDelete={()=>handleDelete(item)}
                                        />
                                    )),
                                    onBlur,
                                    onChange: event => {
                                        handleInputChange(event);
                                        onChange(event);
                                    },
                                    onFocus: () => {
                                        setDropdownOpen(true)
                                    }
                                }}
                                {...other}
                                {...inputProps}
                            />
                        </div>
                    );
                }}
            </Downshift>
            {dropdownOpen &&
                <div className={classes.wrapper} style={{ width: "100%" }}>
                    <div className={classes.dropdownContainer} ref={ref}>
                        {filteredGearList.map((option, index) => (
                            <div
                                key={index}
                                className={classes.dropdownOption}
                                onClick={() => handleChangeValue(option)}>
                                {option?.gearName}
                            </div>
                        ))}
                        {filteredGearList && filteredGearList.length === 0 && (
                            <div className={classes.dropdownOption}>
                                No record
                            </div>
                        )}
                    </div>
                </div>
            }
        </div>
    );
}

GearsDropdown.defaultProps = {
    tags: [],
    defaultValue: []
};
GearsDropdown.propTypes = {
    selectedTags: PropTypes.func.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string)
};

export default GearsDropdown
