import { find } from "lodash";
import { getTotalTime } from "utils/time";
import moment from "moment-timezone";

export const getFormattedAddress = state => {
    if (!state.posting.location) {
        return "";
    }

    const { street, city, region, country } = state.posting.location || {};

    return `${street}, ${city}, ${region}, ${country || "Canada"}`;
};
export const getFormattedDuration = state => {
    const { duration } = state.posting;
    if (!duration) {
        return "";
    }

    const { hours, minutes } = duration;
    let formatted = `${hours} hours`;
    if (minutes > 0) {
        formatted += `, ${minutes} minutes`;
    }

    return formatted;
};
export const getJobTypeList = state => {
    const { jobTypeList } = state.posting;

    return jobTypeList;
};
export const getAnnouncement = state => {
    const { announcement } = state.posting
    return announcement;
};
export const getTimeZone = state => {
    return state.posting.timezone
};
export const getSiteManager = state => {
    return state.posting.siteManager
};
export const getCheckInManager = state => {
    return state.posting.checkInManager
};
export const getCheckOutManager = state => {
    return state.posting.checkOutManager
};
export const getIsFixedTermJob = state => {
  return state.posting.isFixedTerm;
};

export const getCanPost = state => {

    const {
        date,
        startTime,
        endTime,
        workersNeeded,
        breakMins,
        requiresBreak,
        jobType,
        location,
        isPast,
        employer,
        isValidDate,
        isValidRecurringEndDate,
        isValidStartTime,
        isValidEndTime,
        positions,
        description,
        jobTypeList,
        isPrivate,
        tags,
        gears,
        isRecurring,
        recurringType,
        recurringEndDate,
        monthDate,
        weekDays,
        skills,
        announcement,
    } = state.posting;

    let isValidAnnouncement = true
    if (announcement.length > 0) {
        const announcementData = find(announcement, n => (n.title === '' || n.description === ''))
        if (!announcementData) {
            isValidAnnouncement = true
        }
        else {
            isValidAnnouncement = false
        }
    }

    let isValidAnnouncementDays = true
    if (announcement.length > 0) {
        const announcementDays = find(announcement, n => (n.repeatType === "Weekly" && n.days.length === 0))
        if (announcementDays === undefined) {
            isValidAnnouncementDays = true
        }
        else {
            isValidAnnouncementDays = false
        }
    }

    const totalTimeInHours = getTotalTime(isRecurring ? recurringEndDate : startTime, moment());
    const weeklyAnnouncement = find(announcement, n => n.repeatType === "Weekly")

    let isValidAnnouncementTime = true

    if (announcement.length > 0) {

        const announcementTime = find(announcement, n => (!isRecurring && totalTimeInHours < 24 && n.repeatType === "Weekly" && n.time.isBetween(moment(), moment(startTime).add(1, 'minutes'))))

        if (!isRecurring && weeklyAnnouncement && totalTimeInHours < 24 && !announcementTime) {
            isValidAnnouncementTime = false
        }
        else {
            isValidAnnouncementTime = true
        }
    }

    const isValidRecurring = (isRecurring === true && (
        (recurringType === 'Weekly' && !weekDays?.length) ||
        (recurringType === 'Monthly' && !monthDate?.length))
    )

    return !!(
        employer &&
        date &&
        isValidDate && // incase the date was set manually and is not valid
        isValidRecurringEndDate &&
        startTime &&
        isValidStartTime && // incase the date was set manually and is not valid
        endTime &&
        isValidEndTime && // incase the date was set manually and is not valid
        (breakMins || requiresBreak === false) &&
        workersNeeded &&
        jobType &&
        jobTypeList.some(({ value }) => value === jobType) &&
        location &&
        // !isPast &&
        positions.length <= workersNeeded &&
        description &&
        gears.length > 0 &&
        skills.length > 0 &&
        isValidAnnouncement &&
        isValidAnnouncementDays &&
        isValidAnnouncementTime &&
        !isValidRecurring
    );
};

export const getManagers = state => {
    return state.posting.managers
};

export const getEmployerDocuments = state => {
    return state.posting.selectedDocumentIds
};

export const getHasSummary = state => {
    const { date, startTime, endTime, duration, workersNeeded, employer } = state.posting;

    return !!(date && startTime && endTime && duration && workersNeeded && employer);
};

export const getTotalCost = state => {
    if (!getHasSummary(state)) {
        return 0;
    }

    const { duration, workersNeeded, requiresBreak, breakType, employer, breakMins } = state.posting;

    const { hours, minutes } = duration;
    // prettier-ignore
    let durationInMinutes = (hours * 60) + minutes;
    if (requiresBreak && breakType === 0) {
        durationInMinutes -= breakMins;
    }

    return (durationInMinutes * (employer.billingRate / 60) * parseInt(workersNeeded, 10)).toFixed(2);
};

export const getCalculatedJobBill = state => {
      return state.posting?.calculatedJobBill;
}
