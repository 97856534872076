import gql from "graphql-tag";

export default gql`mutation CancelHolidayPayment($reason: String!, $paymentId: Int!) {
    cancelHolidayPayment(reason: $reason, paymentId: $paymentId) {
      id
      createdAt
      updatedAt
      holidayWorkerId
      responseAt
      sentAt
      workerId
      holidayPayrollId
      retryPaymentId
      paymentStatusId
      dcTransferId
      dcCustomerNumber
      paymentProviderReferenceCode
      email
      securityQuestion
      securityAnswer
      amount
      errorCode
      errorDescription
      notes
    }
  }`;
