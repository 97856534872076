import { makeStyles } from '@mui/styles';
import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as ProfileActions, selectors as ProfileSelectors } from 'store/modules/employerDetails';
import ReactTableSimple from 'components/ReactTable/ReactTableSimple';
import DynamicWorkTypeAction from './DynamicWorkTypeAction';
import { formatMoney } from 'utils/stringUtils';

const useStyles = makeStyles(theme => ({
    main: {
        marginTop: theme.spacing(2),
        "& > .MuiPaper-root": {
            boxShadow: "none"
        }
    },
    progressOverlay: {
        background: "#0000001c",
        width: "100%",
        height: "100%",
        top: 0,
        position: "absolute",
        textAlign: "center"
    },

}));

export default function DynamicWorkTypeDetail({ setTab }) {
    const dispatch = useDispatch();
    const data = useSelector(ProfileSelectors.getDynamicWorkTypeRateListData);
    const classes = useStyles();
    const isTableLoading = useSelector(ProfileSelectors.getIsDynamicWorkTypeRateListLoading);

    useEffect(() => {
        dispatch(ProfileActions.fetchDynamicWorkTypeRate());
    }, []);

    return (
        <ReactTableSimple
            classes={classes}
            loading={isTableLoading}
            data={data}
            columns={[
                // {
                //     Header: "Id",
                //     id: "id",
                //     disableFilters: true,
                //     disableSortBy: true,
                //     accessor: ({ id }) => id,
                // },
                {
                    Header: "Work Type",
                    id: "workType",
                    disableFilters: true,
                    disableSortBy: true,
                    styles:{
                        textAlign:"left",
                    },
                    accessor: ({ workType }) => workType?.label,
                },
                {
                    Header: "Pay Rate",
                    id: "payRate",
                    disableFilters: true,
                    disableSortBy: true,
                    accessor: ({ payRate }) => payRate,
                    Cell: ({ row: { original: { payRate } } }) => {
                        return formatMoney(payRate)
                    },
                    styles:{
                        textAlign:"left",
                    },
                },
                {
                    Header: "Billing Rate",
                    id: "billingRate",
                    disableFilters: true,
                    disableSortBy: true,
                    accessor: ({ billingRate }) => billingRate,
                    Cell: ({ row: { original: { billingRate } } }) => {
                        return formatMoney(billingRate)
                    },
                    styles:{
                        textAlign:"left",
                    },
                },
                {
                    Header: 'Actions',
                    id: "action",
                    disableFilters: true,
                    disableSortBy: true,
                    collapse: true,
                    Cell: ({ row: { original } }) => {
                        return (
                            <DynamicWorkTypeAction setTab={setTab} dynamicWorkTypeRate={original} />
                        )
                    }
                },

            ]}
        />
    )
}
