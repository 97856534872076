import ApolloClient from "utils/apollo";
import types from "./types";
import { actions as MetaActions } from "store/modules/meta";
import { skillsQuery } from "./graphql/queries/index";
import deleteSkillMutation from "./graphql/mutations/delete-skill";
import createSkillMutation from "./graphql/mutations/create-skill";
import updateSkillMutation from "./graphql/mutations/update-skill";

const setLoadingState = key => value => ({
    type: types.SET_LOADING_STATE,
    payload: { key, value },
});

const setIsTableLoading = setLoadingState("tableLoading");

const setDialogState = key => value => ({
    type: types.SET_DIALOG_STATE,
    payload: { key, value },
});

export const setIsDeleteDialog = setDialogState("deleteDialog");
export const setIsEditDialog = setDialogState("editDialog");
export const setIsaddSkillDialog = setDialogState("addSkillDialog");

const setSkills = (skills = []) => ({
    type: types.SET_SKILLS_DATA,
    payload: { skills }
});

export const fetchSkills = () => (dispatch, getState) => {
    dispatch(setIsTableLoading(true));
    return ApolloClient.query({
        query: skillsQuery,
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            else {
                dispatch(setIsTableLoading(false));
                dispatch(setSkills(data.skills));
            }
        })
        .catch(e => {
            dispatch(setIsTableLoading(false));
            dispatch(MetaActions.errorToast(`Could not find data: ${e.message}`));
        });
};

export const createSkill = (values) => (dispatch, getState) => {
    const payload = {
        skillName: values.skillName,
        frenchSkillName: values.frenchSkillName,
        isActive: values.isActive,
    };
    return ApolloClient.mutate({
        mutation: createSkillMutation,
        variables: { data: payload },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            dispatch(fetchSkills());
            dispatch(setIsaddSkillDialog(false));
            dispatch(MetaActions.successToast("Skill has been created."));
            return data;
        }).catch((e) => {
            dispatch(MetaActions.errorToast(e.message));
            dispatch(setIsaddSkillDialog(false));
        });
};

export const updateSkill = (values) => (dispatch, getState) => {
    const payload = {
        id: values.id,
        skillName: values.skillName,
        frenchSkillName: values.frenchSkillName,
        isActive: values.isActive,
    };
    return ApolloClient.mutate({
        mutation: updateSkillMutation,
        variables: { data: payload },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            dispatch(fetchSkills());
            dispatch(setIsEditDialog(false));
            dispatch(MetaActions.successToast(`Skill updated successfully.`));
            return data;
        }).catch((e) => {
            dispatch(MetaActions.errorToast(e.message));
            // dispatch(setIsEditDialog(false));
        });
};

export const deleteSkill = (id) => (dispatch) => {
    ApolloClient.mutate({
        mutation: deleteSkillMutation,
        variables: { id }
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            } else {
                dispatch(setIsDeleteDialog(false));
                dispatch(fetchSkills());
                dispatch(MetaActions.successToast("Skill has been deleted."));
            }
        })
        .catch(e => {
            dispatch(setIsDeleteDialog(false));
            dispatch(
                MetaActions.errorToast(`${e.message}`)
            );
        });
};
