import { gql } from "@apollo/client";

export default gql`
  mutation createDocument($data: CreateDocument!) {
    createDocument(data: $data) {
        id
        signedDocumentUrl
        documentTypeId
        workerId
        documentName
        documentURL
        status
        approvedBy
        rejectedBy
        rejectReason
        addedBy
        updatedBy
        deletedBy
        deletedAt
        createdAt
        updatedAt
        signedDocumentMasterImageUrl
    }
  }
`;
