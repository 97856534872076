import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import makeStyles from '@mui/styles/makeStyles';
import { actions as JobDetailActions, selectors as JobDetailSelectors } from "store/modules/jobDetails";
import { alpha, CardContent, CircularProgress, Typography } from "@mui/material";
import Card from "components/Card/Card";
import StateInfo from "../JobHeader/components/StateInfo";
import { convertUTCToTimezoneWithOffset, displayLocalDateTime } from "utils/time";
import { useHistory } from "react-router-dom";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";
import { checkInOutColumn, cityColumn, companyNameColumn, confirmedColoumn, getTdPropsV7, jobIdColumn, peopleNeededColumn, reconfirmedColoumn, regionColumn, startDateColumn, startUtcColumn } from "views/Job/JobOverview/components/tables/helpers/tableProps";
import moment from "moment";

const useStyles = makeStyles(({ spacing, palette, shape }) => ({
    filledBreakdown: {
        marginRight: spacing(1),
    },
    titleContainer: {
        padding: `${spacing(1)} ${spacing(2)}`,
        minHeight: 64,
        position: "relative"
    },
    root: {
        backgroundColor: palette.common.white,
        border: `1px solid ${palette.grey[500]}`,
        borderRadius: shape.borderRadius / 2,
        marginTop: spacing(2),
        "& $titleContainer:nth-child(1)": {
            // borderBottom: `1px solid ${palette.grey[500]}`,
        },
        "& $titleContainer:nth-child(2)": {
            // borderBottom: `1px solid ${palette.grey[500]}`,
            padding: 0
        }
    },
    progress: {
        position: "absolute",
        display: "flex",
        background: alpha(palette.grey[500], 0.5),
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
    },
    jobsContainer: {
        display: "flex",
        gap: spacing(2),
        width: "100%",
        overflow: "auto",
        padding: spacing(1)
    },
    card: {
        margin: spacing(1, 0),
        cursor: "pointer",
        minWidth: "fit-content",
        width: 183,
        "& .MuiCardContent-root": {
            paddingBottom: spacing(2)
        },
        "&:hover": {
            background: alpha(palette.grey[300], 0.5)
        }
    },
    jobTimeContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: spacing(2, 0),
        justifyContent: "center"
    },
    jobTime: {
        fontSize: 21,
    },
    jobStartTime: {
        "& p": {
            marginRight: spacing(2),
        },
        "& svg": {
            marginRight: spacing(1),
        },
    },
    notFound: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        "& p": {
            fontSize: 21
        }
    }
}));

export const sharedReactTableProps = {
    showPageSizeOptions: true,
    sortable: false,
    defaultSortDesc: false,
    multiSort: false,
    showPaginationBottom: true,
    showPageJump: false,
    showPaginationTop: false,
    resizable: false,
    classes: "-highlight",
    minRows: 0,
    rowsPerPageOptions: { rowsPerPageOptions: [10, 20, 40, 80, 100] }, // No more than this
};


function ScheduledJobs(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isLoading = useSelector(JobDetailSelectors.getIsScheduledJobLoading);
    const scheduledJobs = useSelector(JobDetailSelectors.getAllScheduledJobs);
    const history = useHistory();

    useEffect(() => {
        if (props.jobId)
            dispatch(JobDetailActions.fetchScheduledJobs(props.jobId));
    }, [props?.jobId]);

    return (
        <Grid container item className={classes.root} direction="column">
            <Grid className={classes.titleContainer} container xs={12}>
                <Grid container item xs={6} direction="row" alignItems="center">
                    <Typography className={classes.filledBreakdown} variant="subtitle2">
                        {`Scheduled Jobs`}
                    </Typography>
                </Grid>
            </Grid>
            <Grid className={classes.titleContainer} container xs={12}>

                <ReactTableSimple
                    {...sharedReactTableProps}
                    classes="-highlight"
                    data={scheduledJobs}
                    loading={isLoading}
                    filterable={false}
                    columns={[
                        jobIdColumn,
                        companyNameColumn,
                        {
                            Header: "Start Date",
                            id: "startDate",
                            accessor: ({ startUtc }) => moment(startUtc).format("MMM Do, YYYY"),
                            disableFilters: true,
                            Cell: ({ row: { original } }) => {
                                return (
                                    <div>
                                        {convertUTCToTimezoneWithOffset(original.startUtc, original.timezone, "MMM Do, YYYY")}
                                        <br />
                                        {displayLocalDateTime(original.startUtc, original.timezone, "MMM Do, YYYY")}
                                    </div>
                                )

                            },
                            disableSortBy: true,
                            maxWidth: 200,
                            className: "-cursor-pointer",
                        },
                        startUtcColumn(),
                        cityColumn,
                        regionColumn,
                        checkInOutColumn,
                        peopleNeededColumn(true),
                        confirmedColoumn,
                        reconfirmedColoumn,
                    ]}
                    onRowClick={getTdPropsV7}
                />
            </Grid>
        </Grid>
    );
}

export default ScheduledJobs;
