import { gql } from "@apollo/client";

export default gql`
  mutation creatingJob($data: CreatingJobInput!) {
    job: creatingJob(data: $data) {
      id
      employerId
      unit
      floor
      locationInstruction
      workTypeId
      peopleNeeded
      hourlyRate
      street
      city
      region
      description
      isActive
      breakMins
      status
      gears
      startUtc
      endUtc
      skills
      isRecurring
      isFixedTerm
      manager{
        id
        firstName
        lastName
        isActive
      }
      jobAnnouncement{
        title
        description
      }
      jobManager{
        id
        role
        managerId
        manager{
          id
          firstName
          lastName
          isActive
        }
      }
      jobRecurring {
        frequency
        frequencyEndAt
        frequencyInterval
        dayOrWeekDay
      }
    }
  }
`;
