import gql from "graphql-tag";

export default gql`
mutation CreateDocument($data: AddAgreementDocumentInput!) {
    createDocument(data: $data) {
      id
      documentName
      documentUrl
      provinceId
      helpText
      documentType
      createdAt
      updatedAt
      deletedAt
      signedDocumentImageUrl
    }
}
`
