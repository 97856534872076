import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import { ArrowDropDown, GetApp, Search } from "@mui/icons-material";
import { FormControl, InputLabel, ListItemText, TextField } from "@mui/material";
import Button from "../../../../components/Button/Button";
import { actions as ReportsActions, selectors as ReportsSelectors } from "store/modules/reports";
import makeStyles from '@mui/styles/makeStyles';
import Select from "@mui/material/Select";
import { StyledMenuItem, weeks } from "views/Finance/helpers";
import { AdapterMoment as MomentUtils } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import moment from "moment";

const useStyles = makeStyles(() => ({
    buttonIcon: {
        marginTop: 10,
        marginLeft: 5,
    },
    searchButton: {
        paddingLeft: 0
    },
    customClass: {
        "& button": {
            fontSize: 16,
            fontWeight: "normal"
        }
    },
    weekSelect: {
        "& > div": {
            paddingTop: "3px !important",
            paddingBottom: "3px !important"
        }
    },
    datePicker: {
        "& > div": {
            borderRadius: "0 !important"
        }
    }
}));

function WeeklyBillingFilter() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const aTag = React.useRef();
    const [csvURL, setCsvURL] = React.useState('');
    const [error, setError] = React.useState('');
    const { week: weekValue, year } = useSelector(ReportsSelectors.getWeeklyBillingReportFilter);

    const updateFormData = (field, value) => {
        if (field === "year" && moment().year() == value && weekValue > moment().week())
            dispatch(ReportsActions.setWeeklyBillingReportsFilter("week", moment().week()))
        dispatch(ReportsActions.setWeeklyBillingReportsFilter(field, value))
    };

    const onSearch = () => {
        dispatch(ReportsActions.fetchWeeklyBillingReportsTableData(0));
    }

    const onDownload = () => {
        dispatch(ReportsActions.downloadWeeklyBillingReportTableData()).then(data => {
            if (data && data.url) {
                setCsvURL(data.url);
                aTag.current.click();
            }
        });
    }

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12} md={12}>
                    <FormControl margin="normal" style={{ zIndex: 110, marginBottom: 0 }}>
                        <InputLabel htmlFor="week">Select Week</InputLabel>
                        <Select
                            id="week"
                            className={classes.weekSelect}
                            value={weekValue}
                            onChange={(e) => updateFormData("week", e.target.value)}
                            style={{ marginBottom: 10, width: 150 }}>
                            {[...Array(moment().set("year", year || moment().year())?.isoWeeksInYear?.())].map((_, i) => (
                                <StyledMenuItem
                                    disabled={moment().week() < (i + 1) && year == moment().year()}
                                    key={i} value={i + 1}>
                                    <ListItemText primary={` Week ${i + 1}`} />
                                </StyledMenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl margin="normal" style={{ marginLeft: 10, zIndex: 110, width: 150, marginBottom: 0 }}>
                        <LocalizationProvider dateAdapter={MomentUtils}>
                            <InputLabel htmlFor="year">Select Year</InputLabel>
                            <DatePicker
                                onError={(error, val) => setError(error)}
                                id="year"
                                className={classes.datePicker}
                                PopperProps={{
                                    className: classes.customClass
                                }}
                                views={['year']}
                                disableFuture
                                renderInput={props => <TextField {...props}
                                    InputProps={{
                                        ...props.InputProps,
                                        disableUnderline: false,
                                        placeholder: "",
                                    }}
                                    inputProps={{
                                        ...props.inputProps,
                                        placeholder: ""
                                    }}
                                    placeholder="" />}
                                size="small"
                                components={{
                                    OpenPickerIcon: ArrowDropDown,
                                }}
                                value={year?.toString() || null}
                                onChange={newValue => {
                                    if (newValue) {
                                        updateFormData("year", +newValue.format("YYYY"))
                                    } else {
                                        updateFormData("year", undefined)
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </FormControl>
                    <FormControl margin="normal" style={{ marginLeft: 20, marginTop: 30, marginBottom: 0 }}>
                        <Button
                            disabled={error || !weekValue}
                            icon={<Search className={classes.buttonIcon} />}
                            alignIcon="left"
                            label="Search"
                            onClick={onSearch}
                        />
                    </FormControl>
                    <FormControl margin="normal" style={{ marginTop: 30, marginBottom: 0 }}>
                        <Button
                            disabled={error || !weekValue}
                            icon={<GetApp className={classes.buttonIcon} />}
                            label="Download CSV"
                            alignIcon="left"
                            onClick={onDownload}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <a ref={aTag} href={csvURL} target="_blank" rel="noopener noreferrer" />
        </React.Fragment>
    );
}

export default WeeklyBillingFilter;
