import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainContent from "components/Containers/MainContent";
import SkillsTable from "./components/SkillsTable";
import Dialog from "components/Dialog/Dialog";
import { Add } from "@mui/icons-material";
import { actions as SkillActions, selectors as SkillSelectors } from "store/modules/skills";
import { deleteSkill } from "store/modules/skills/actions";
import Button from "components/Button/Button";
import AddSkillDialog from "./components/AddSkillDialog";
import EditSkillDialog from "./components/EditSkillDialog";

function SkillsOverview() {

  const dispatch = useDispatch()
  const isdeleteDialog = useSelector(SkillSelectors.getIsdeleteDialog);
  const [deleteSkillId, setDeleteSkillId] = useState(null);
  const [selectedSkill, setSelectedSkill] = useState(null);

  const createSkill = () => {
    dispatch(SkillActions.setIsaddSkillDialog(true));
  }

  const showSkillDeleteDialog = (id) => {
    dispatch(SkillActions.setIsDeleteDialog(true));
    setDeleteSkillId(id);
  }

  const showSkillEditDialog = (skill) => {
    dispatch(SkillActions.setIsEditDialog(true));
    setSelectedSkill(skill);
  }

  const hideSkillDeleteDialog = () => {
    dispatch(SkillActions.setIsDeleteDialog(false));
  }

  const hideSkillEditDialog = () => {
    setSelectedSkill(null);
  }

  const handleDeleteSkill = () => {
    dispatch(deleteSkill(deleteSkillId));
    hideSkillDeleteDialog();
  }

  return (
    <>
      <MainContent>
        <Button
          style={{ alignSelf: "flex-end", marginBottom: 24 }}
          label="Add"
          icon={<Add />}
          alignIcon="left"
          onClick={createSkill}
        />
        <SkillsTable onDelete={showSkillDeleteDialog} onEdit={showSkillEditDialog} />
        <Dialog
          open={isdeleteDialog}
          title="Are you sure?"
          onClose={hideSkillDeleteDialog}
          onConfirm={handleDeleteSkill}
          onCancel={hideSkillDeleteDialog}
          alertType="warning"
          icon="help_outline"
        />
      </MainContent>
      <AddSkillDialog />
      <EditSkillDialog skill={selectedSkill} onHideEditDialog={hideSkillEditDialog}/>
    </>
  )
}

export default SkillsOverview
