import { gql } from "@apollo/client";
import JobFields from "../fragments/job-fragment";

export default gql`
query getEditableJob($id: ID!) {
  job(id: $id) {
    ...JobFields
    id
    start
    end
    startUtc
    endUtc
    timezone
    workTypeId
    peopleNeeded
    breakMins
    paidBreakMins
    street
    city
    region
    unit
    floor
    locationInstruction
    latitude
    longitude
    description
    isPrivate
    tags
    gears
    skills
    jobGear {
        gear {
          createdAt
          frenchGearName
          gearName
          id
          isActive
        }
        id
    }
    jobSkill {
        id
        skillId
        skill {
          skillName
          isActive
          frenchSkillName
          createdAt
          id
        }
    }
    manager{
      id
      firstName
      lastName
      isActive
    }
    jobManager{
      id
      role
      managerId
      manager{
        id
        firstName
        lastName
        isActive
      }
    }
    employer {
      billingRate
      city
      companyName
      country
      email
      firstName
      lastName
      id
      mobilePhone
      payRate
      region
      street
      logoImageUrl
      signedLogoImageUrl
      latitude
      longitude
      canPostPrivateJob
      employerDocumentTypes {
        id
        grizzlyEmployerId
        documentTypeId
        createdAt
        updatedAt
        documentType {
          id
          name
          slug
          helpText
          createdBy
          updatedBy
          createdAt
          updatedAt
        }
      }
    }
    requiredDocumentTypes {
        id
        jobId
        documentTypeId
        deletedAt
        createdAt
        updatedAt
        documentType {
          id
          name
          slug
          helpText
          createdBy
          updatedBy
          deletedBy
          deletedAt
          createdAt
          updatedAt
        }
      }
    positions {
      worker {
        id
        firstName
        lastName
        profileImageUrl
        signedProfileImageUrl
        vaccinationStatus
      }
    }
}
}
${JobFields}
`;
