import React, { useState } from "react";
import TagsInput from "components/TagsInput";
import withStyles from "@mui/styles/withStyles";
import { Grid, TextField, FormControl, IconButton } from "@mui/material";
import Button from "components/Button/Button";
import Close from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { actions as PostingActions, selectors as PostingSelectors } from "store/modules/posting";
import { actions as MetaActions } from "store/modules/meta";
import FormRow from "views/Employer/PostEmployerView/components/FormRow";
import GearsDropdown from "views/JobTemplate/PostJobTemplateView/components/GearsDropdown";
import SkillsDropdown from "views/JobTemplate/PostJobTemplateView/components/SkillsDropdown";

const styles = {
    selectedSection: {
        '& p': {
            textTransform: 'uppercase'
        },
        '& .MuiChip-root': {
            marginRight: '10px'
        }
    },
    addedSkillList: {
        border: '1px solid #e3e3ec',
        padding: '10px',
        borderRadius: '5px',
        fontSize: '1rem',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        marginBottom: '10px',
        '& .content': {
            width: 'calc(100% - 20px)',
        },
        '& .closeButton': {
            position: 'absolute',
            padding: '8px',
            right: '0',
            top: '3px'
        }
    },
    gears: {
        color: '#212a34',
        display: 'block',
        fontSize: '14px',
        paddingBottom: '3px'
    }
};

function GearsAndSkillsSection(props) {

    const {
        classes,
        gears,
        skills
    } = props;

    const dispatch = useDispatch();
    const [skill, setSkills] = useState('');

    const onGearsChange = (data) => {
        dispatch(PostingActions.setGears(data));
    }

    const addedSkill = (data) => {
        dispatch(PostingActions.setSkills(data))
    }

    return (
        <React.Fragment>
            <FormRow style={{ marginBottom: "8px", zIndex: 1051 }}>
                <FormControl fullWidth>
                    <div className={classes.gears}>Gears</div>
                    <GearsDropdown
                        selectedTags={onGearsChange}
                        fullWidth
                        variant="outlined"
                        id="gears"
                        name="gears"
                        placeholder="Add Gear"
                        defaultValue={gears}
                    />
                </FormControl>
            </FormRow>

            <FormRow style={{ marginBottom: "8px", zIndex: 1050 }}>
                <FormControl fullWidth>
                    <div className={classes.gears}>Skills</div>
                    <SkillsDropdown
                        selectedTags={addedSkill}
                        fullWidth
                        variant="outlined"
                        id="skills"
                        name="skills"
                        placeholder="Add Skill"
                        defaultValue={skills}
                    />
                </FormControl>
            </FormRow>

        </React.Fragment>
    )
}

export default withStyles(styles)(GearsAndSkillsSection)
