import { ApolloClient, InMemoryCache, concat, ApolloLink, createHttpLink } from "@apollo/client";
import "whatwg-fetch";
import { createUploadLink } from "apollo-upload-client";
import { store } from "../store";

window.global = window;
// @ts-ignore
window.Buffer = window.Buffer || require('buffer').Buffer;
const uri = process.env.REACT_APP_API_ENDPOINT || `https://dd95-103-165-71-115.in.ngrok.io/graphql`;
const defaultOptions = {
    watchQuery: { errorPolicy: "ignore" },
    query: { errorPolicy: "all", fetchPolicy: "no-cache" },
    mutate: { errorPolicy: "all" },
    connectToDevTools: true,
};
export const getApolloClient = (options = {}) => {
    const UploadLink = createUploadLink({ uri });
    const authLink = new ApolloLink((operation, forward) => {
        operation.setContext(({ headers = {} }) => {
            return {
                headers: {
                    ...headers,
                },
            };
        })
        return forward(operation);
    });

    const link = createHttpLink({
        uri: process.env.REACT_APP_API_ENDPOINT || 'https://dd95-103-165-71-115.in.ngrok.io/graphql',
        opts: {
            credentials: 'include',
        }
    });

    const links = concat(authLink, UploadLink);
    const apolloConfig = {
        uri,
        name: "Grizzly-Admin-Client",
        version: "1.0",
        queryDeduplication: false,
        link: links,
        cache: new InMemoryCache(),
        connectToDevTools: true,
        defaultOptions: {
            ...defaultOptions,
            ...options,
        },
    };
    return new ApolloClient(apolloConfig);
};

export default getApolloClient();
