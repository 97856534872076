import React, { Component } from 'react'
import classNames from "classnames";
import { withStyles } from '@mui/styles';
import { CloudUpload } from '@mui/icons-material';
import { alpha, CircularProgress, Typography } from '@mui/material';

const styles = theme => ({
    dropzone: {
        height: 0,
        width: '100%',
        padding: '15% 0',
        backgroundColor: alpha(theme.palette.primary.main, 0.05),
        border: '2px dashed rgb(187, 186, 186)',
        borderRadius: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        fontSize: 16,
        '&.highlight': {
            backgroundColor: 'rgb(188, 185, 236)'
        },
        marginTop: 20,
        color: alpha(theme.palette.primary.main, 0.5),
    },
    icon: {
        opacity: 0.3,
        height: 64,
        width: 64
    },
    fileInput: {
        display: 'none'
    }
});


class Dropzone extends Component {
    constructor(props) {
        super(props)
        this.state = { hightlight: false }
        this.fileInputRef = React.createRef()

        this.openFileDialog = this.openFileDialog.bind(this)
        this.onFilesAdded = this.onFilesAdded.bind(this)
        this.onDragOver = this.onDragOver.bind(this)
        this.onDragLeave = this.onDragLeave.bind(this)
        this.onDrop = this.onDrop.bind(this)
    }

    openFileDialog(e) {
        if (this.props.disabled) return
        if (this.props.openCamera) {
            this.props.openCamera();
            return;
        } else {
            this.fileInputRef.current.click()
        }
    }

    onFilesAdded(evt) {
        if (this.props.disabled) return
        const files = evt.target.files
        if (this.props.onFilesAdded) {
            const array = this.fileListToArray(files)
            this.props.onFilesAdded(array)
        }
        evt.target.value = "";
    }

    onDragOver(evt) {
        evt.preventDefault()

        if (this.props.disabled) return

        this.setState({ hightlight: true })
    }

    onDragLeave() {
        this.setState({ hightlight: false })
    }

    onDrop(event) {
        event.preventDefault()
        if (this.props.disabled) return
        const files = event.dataTransfer.files
        if (this.props.onFilesAdded) {
            const array = this.fileListToArray(files)
            this.props.onFilesAdded(array)
        }
        this.setState({ hightlight: false })
    }

    fileListToArray(list) {
        const array = []
        for (var i = 0; i < list.length; i++) {
            array.push(list.item(i))
        }
        return array
    }

    render() {
        const { supportedFiles = "", multiple = false, classes, iconClasses = {}, className = {}, uploading } = this.props;
        return (
            <React.Fragment>
                <div
                    className={this.state.hightlight ? classNames(classes.dropzone, 'highlight', className) : classNames(classes.dropzone, className)}
                    onDragOver={this.onDragOver}
                    onDragLeave={this.onDragLeave}
                    onDrop={this.onDrop}
                    onClick={this.openFileDialog}
                    style={{ cursor: this.props.disabled ? 'default' : 'pointer' }}
                >
                    <input
                        ref={this.fileInputRef}
                        className={classes.fileInput}
                        type="file"
                        accept={this.props.accept ? this.props.accept : "*"}
                        multiple={multiple}
                        onChange={this.onFilesAdded}
                    />
                    {
                        uploading === true ?
                            <CircularProgress className={classes.icon} />
                            : <>
                                <CloudUpload className={classNames(classes.icon, iconClasses)} />
                                <Typography>Drag & Drop or Browse</Typography>
                                <Typography>{supportedFiles}</Typography>
                            </>
                    }
                </div>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(Dropzone)
