import React, { useEffect, useState } from "react";
import Dialog from "components/Dialog/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { actions as SkillsActions, selectors as SkillsSelectors } from "store/modules/skills";

const style = ({ spacing }) => ({
    container: {
        marginBottom: spacing(2)
    }
})

function EditSkillDialog({ classes, skill, onHideEditDialog }) {

    const dispatch = useDispatch();
    const [englishSkillName, setEnglishSkillName] = useState("");
    const [frenchSkillName, setFrenchSkillName] = useState("");
    const [skillStatus, setSkillStatus] = useState(true);
    const isEditSkillDialog = useSelector(SkillsSelectors.getIsEditSkillDialog);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (skill) {
            setEnglishSkillName(skill.skillName);
            setFrenchSkillName(skill.frenchSkillName);
            setSkillStatus(skill.isActive);
        }
    }, [skill]);

    // const skillStatusValue = [
    //     {
    //         value: true,
    //         label: "Active"
    //     },
    //     {
    //         value: false,
    //         label: "Inactive"
    //     }
    // ]

    const hideEditSkillDialog = () => {
        dispatch(SkillsActions.setIsEditDialog(false));
        onHideEditDialog();
    }

    const handleChangeEnglishSkill = (event) => {
        setEnglishSkillName(event.target.value);
    }

    const handleChangeFrenchSkill = (event) => {
        setFrenchSkillName(event.target.value);
    }

    const editSkill = async () => {
        setIsLoading(true);
        const values = {
            id: skill.id,
            skillName: englishSkillName,
            frenchSkillName: frenchSkillName,
            isActive: skillStatus
        }
        await dispatch(SkillsActions.updateSkill(values)).then((res) => {
            if (res && res.updateSkill) {
                setEnglishSkillName("");
                setFrenchSkillName("");
                setSkillStatus(true);
                onHideEditDialog();
            }
        });
        setIsLoading(false)
    }

    // const onSkillStatusChange = (event) => {
    //     setSkillStatus(event.target.value)
    // }


    return (
        <Dialog
            open={isEditSkillDialog}
            title="Edit Skill"
            onClose={hideEditSkillDialog}
            onCancel={hideEditSkillDialog}
            onConfirm={editSkill}
            disabled={!englishSkillName?.trim() || !frenchSkillName?.trim() || isLoading}
            alertType="form"
            confirmText="Edit"
            size="sm"
            modalType="scrollable"
        >
            <div className={classes.container}>
                <TextField
                    id="englishSkillName"
                    margin="normal"
                    label="Skill Name In English"
                    value={englishSkillName}
                    onChange={handleChangeEnglishSkill}
                    required
                    fullWidth
                />
                <TextField
                    id="frenchSkillName"
                    margin="normal"
                    label="Skill Name In French"
                    value={frenchSkillName}
                    onChange={handleChangeFrenchSkill}
                    required
                    fullWidth
                />
                {/* <TextField
                    select
                    label="Skill Status"
                    value={skillStatus}
                    onChange={onSkillStatusChange}
                    margin="normal"
                    fullWidth
                    required
                >
                    {skillStatusValue.map(({ value, label }) => (
                        <MenuItem key={`skillStatus-${value}`} value={value}>
                            {label}
                        </MenuItem>
                    ))}
                </TextField> */}
            </div>
        </Dialog>
    )
}

export default (withStyles(style)(EditSkillDialog))
