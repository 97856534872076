/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import Visibility from "@mui/icons-material/Visibility";
import MainContent from "components/Containers/MainContent";
import { actions as JobDetailActions, selectors as JobDetailSelectors } from "store/modules/jobDetails";

import JobHeader from "./components/JobHeader/JobHeader";
import JobPositions from "./components/JobPositions/JobPositions";
import JobSummary from "./components/JobSummary/JobSummary";
import WorkerPicker from "../JobDetails/components/WorkerPicker/WorkerPicker";
import Can from "components/Authorization/Can";
import { JobPermissions, PositionPermissions } from "helpers/constants/permissions";
import { Tab, Tabs } from "@mui/material";
import ScheduledJobs from "../JobDetails/components/ScheduledJobs/ScheduledJobs";

class MultiShiftJobDetails extends React.Component {
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.isJobFilled && prevState.showWorkerPicker) {
            return {
                showWorkerPicker: false,
            };
        }

        return null;
    }

    state = {
        showWorkerPicker: false,
        value: 0
    };

    componentDidMount() {
        //    fetchJob: () => fetchJob(ownProps.match.params.id),
        this.props.jobId && this.props.fetchJob(this.props.jobId, true, true).then(res=>{
            if (!this.props.isFixedTermParentJob) this.props.history.replace(this.props.history.location.pathname.replace("/dashboard", ""))
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.jobId && this.props.jobId && prevProps.jobId !== this.props.jobId)
            this.props.fetchJob(this.props.jobId, true, true);
    }

    closeWorkerPicker = () => {
        this.setState({
            showWorkerPicker: false,
        });
    };

    openWorkerPicker = () => {
        this.setState(
            {
                showWorkerPicker: true,
            },
            this.scrollToEnd
        );
    };

    scrollToEnd = () => {
        this.scrollEnd.scrollIntoView({ behavior: "smooth" });
    };

    setScrollEndRef = ref => {
        this.scrollEnd = ref;
    };

    get visibilityInfo() {
        const { job, employer } = this.props;

        if (employer.allowPreferredOnly) {
            if (job.visibility === 0) {
                return "Visible to Preferred workers only.";
            }
            return "!!! Supposed to be only preferred. Something went wrong !!!";
        }

        if (job.visibility === 0) {
            return "Visible to Preferred workers.";
        }

        if (job.visibility === 1) {
            return "Visible to 4+ star workers.";
        }

        if (job.visibility === 2) {
            return "Visible to 3+ star workers.";
        }

        if (job.visibility === 3) {
            return "Visible to all workers.";
        }

        return "!!! Unknown visibility state !!!";
    }
    handleTabChange = (event, value) => {
        this.setState({ value });
    };

    render() {
        const { classes, isLoading, job, isInitialized, isApplyingAction, isFixedTermParentJob } = this.props;

        if (isLoading) {
            return <CircularProgress classes={{ root: classes.progress }} />;
        }

        if (!isInitialized) {
            return null;
        }

        return (
            <React.Fragment>
                <MainContent isApplyingAction={isApplyingAction}>
                    <JobHeader />
                    <JobSummary job={job} />
                    <br />
                    <Can perform={JobPermissions.read.positions.value}>
                        {allowed => {
                            if (!allowed) {
                                return null;
                            }
                            return (
                                <JobPositions
                                    onChooseWorker={this.openWorkerPicker}
                                    isWorkerPickerOpen={this.state.showWorkerPicker}
                                    {...this.props}
                                />
                            )
                        }}
                    </Can>
                    {/* Scheduled job list */}
                    {isFixedTermParentJob && <ScheduledJobs {...this.props} />}
                </MainContent>
                <Can perform={PositionPermissions.read.list.value}>
                    {allowed => {
                        if (!allowed) {
                            return null;
                        }
                        return (<WorkerPicker
                            open={this.state.showWorkerPicker}
                            onClose={this.closeWorkerPicker}
                            scrollToEnd={this.scrollToEnd}
                        />)
                    }}
                </Can>
                <div className={classes.scrollEnd} ref={this.setScrollEndRef} />


            </React.Fragment>
        );
    }
}

const mapState = state => {
    return {
        isInitialized: state.jobDetails.isInitialized,
        isLoading: JobDetailSelectors.getIsPageLoading(state),
        isApplyingAction: JobDetailSelectors.getIsApplyingAction(state),
        job: state.jobDetails,
        isJobFilled: JobDetailSelectors.getIsJobFilled(state),
        employer: state.jobDetails.employer,
        isBaseJob: JobDetailSelectors.getIsBaseJob(state),
        isFixedTermParentJob: JobDetailSelectors.getIsFixedTermParentJob(state),
    };
};

const mapActions = {
    fetchJob: JobDetailActions.fetchJob,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { fetchJob } = dispatchProps;
    return {
        ...stateProps,
        fetchJob,
        // fetchJob: id => fetchJob(id),
        jobId: ownProps ? ownProps.match.params.id : null,
        ...ownProps,
    };
};

export default connect(
    mapState,
    mapActions,
    mergeProps
)(
    withStyles(({ spacing, palette, shape }) => ({
        root: {
            backgroundColor: `${palette.common.white}`,
            border: `1px solid ${palette.grey[500]}`,
            borderRadius: `${shape.borderRadius / 2}px`,
            // padding: `${spacing.unit * 3}px`,
            marginTop: `${spacing.unit * 2}px`,
        },
        stateInfo: {
            "& p": {
                marginRight: spacing(2),
            },
            "& svg": {
                marginRight: spacing(1),
            },
        },
        progress: {
            color: palette.secondary.light,
            position: "absolute",
            top: "50%",
            left: "45%",
        },
        visibility: {
            paddingTop: spacing(2),
        },
        visibilityFont: {
            color: "#6e7a8a",
            paddingLeft: spacing(1),
        },
        scrollEnd: {
            float: "left",
            clear: "both",
        },
        tabs: {
            width: "100%",
            borderBottom: `1px solid ${palette.grey[500]}`,
            backgroundColor: "White",
        },
    }))(MultiShiftJobDetails)
);
