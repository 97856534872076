import gql from "graphql-tag";

export default gql`
mutation CreateHoliday($data: CreateHolidayInput!) {
    createHoliday(data: $data) {
      id
      createdAt
      updatedAt
      holidayDate
      name
      description
      provinceHoliday {
        id
        createdAt
        provinceId
        holidayId
        holiday {
          name
        }
        province {
          code
          name
        }
      }
    }
  }
` 