import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import withStyles from '@mui/styles/withStyles';
import Contacts from "@mui/icons-material/Contacts";
import MoreVert from "@mui/icons-material/MoreVert";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import Delete from "@mui/icons-material/Delete";
import { makeStyles } from "@mui/styles";
import Dialog from "components/Dialog/Dialog";

const useStyles = makeStyles(({ typography, spacing }) => ({
    icon: {
        fontSize: typography.pxToRem(20),
        marginRight: spacing(2),
    },
}));

const DocumentsActionMenu = ({onDelete,...props}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const id = `action-menu-worker`;

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <IconButton
                aria-owns={anchorEl ? id : undefined}
                aria-haspopup="true"
                disableRipple
                onClick={handleClick}
                size="large">
                <MoreVert color="primary" />
            </IconButton>
            {anchorEl ? (
                <Menu
                    id={id}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    disableAutoFocusItem>
                    <MenuItem onClick={() => {
                        onDelete(props);
                        handleClose();
                    }}>
                        <Delete className={classes.icon} color="action" />
                        Delete
                    </MenuItem>
                </Menu>
            ) : null}
        </React.Fragment>
    );
}

export default DocumentsActionMenu;
