// Loading selectors
export const getLoadingStateFactory = loader => state => {
    return state.requiredGear.loading[loader] || false;
};

export const getIsTableLoading = getLoadingStateFactory("tableLoading");

// Dialog selectors
export const getDialogStateFactory = loader => state => {
    return state.requiredGear.dialog[loader] || false;
};

export const getIsdeleteDialog = getDialogStateFactory("deleteDialog");
export const getIsAddGearDialog = getDialogStateFactory("addGearDialog");
export const getIsEditGearDialog = getDialogStateFactory("editGearDialog");

// Gears selectors
export const getrequiredGearsList = state => {
    return state.requiredGear.requiredGears;
};
