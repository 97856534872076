import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import makeStyles from '@mui/styles/makeStyles';
import MoreVert from "@mui/icons-material/MoreVert";
import { AccountBalanceWalletTwoTone, CancelScheduleSend, PictureAsPdf, SettingsBackupRestore } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { actions as holidayActions } from "store/modules/holidays";

const useStyles = makeStyles(({ spacing, typography }) => ({
    icon: {
        fontSize: typography.pxToRem(20),
        marginRight: spacing(2),
    },
}));

export default function PaymentTransferTableMenu({ payment, worker, props }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const openMenu = e => {
        setAnchorEl(e.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const handleCancelETransfer = () => {
        dispatch(holidayActions.setIsCancelETransferDialog({ payment, worker }));
        closeMenu();
    };

    const handleResendETransfer = () => {
        dispatch(holidayActions.setIsResendETransferDialog({ payment, worker }));
        closeMenu();
    };

    const handleRequestReversal = () => {
        dispatch(holidayActions.setIsRequestReversalDialog({ payment, worker }));
        closeMenu();
    };

    const handleResolveETransfer = () => {
        dispatch(holidayActions.setIsResolveETransferDialog({ payment, worker }));
        closeMenu();
    };


    return (
        <>
            <IconButton
                aria-controls={"menuId"}
                aria-haspopup="true"
                onClick={openMenu}
                disableRipple
                style={{ marginRight: 8, float: "right" }}
                edge="start"
                size="small">
                <MoreVert color="primary" />
            </IconButton>
            <Menu
                id={"menuId"}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}>

                <MenuItem
                    disabled={![3, 7, 8].includes(payment.paymentStatusId)}
                    onClick={handleCancelETransfer}>
                    <CancelScheduleSend className={classes.icon} color="action" />
                    Cancel eTransfer
                </MenuItem>

                <MenuItem
                    disabled={![11, 7].includes(payment.paymentStatusId)}
                    onClick={handleResendETransfer}>
                    <AccountBalanceWalletTwoTone className={classes.icon} color="action" />
                    Resend eTransfer
                </MenuItem>

                <MenuItem
                    disabled={![5].includes(payment.paymentStatusId)}
                    onClick={handleRequestReversal}>
                    <SettingsBackupRestore className={classes.icon} color="action" />
                    Request Reversal
                </MenuItem>

                <MenuItem
                    disabled={![5, 3, 1, 14, 11, 7].includes(payment.paymentStatusId)}
                    onClick={handleResolveETransfer}>
                    <AccountBalanceWalletTwoTone className={classes.icon} color="action" />
                    Resolve eTransfer
                </MenuItem>
            </Menu>
        </>
    )
}
