import React, { useCallback, useEffect, useState } from 'react'
import { Checkbox, FormControlLabel } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ReactTable from 'components/ReactTable';
import { sharedReactTableProps } from "../../../../utils/constant";
import { useDispatch, useSelector } from 'react-redux';
import { actions as customFieldActions, selectors as customFieldSelectors } from "store/modules/customFields";
import classNames from 'classnames';
import FadeHide from 'components/Transitions/FadeHide';
import columns from "../helper/employerPickerTableProps";

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        width: "100%",
        overflow: "auto"
    },
    label: {
        width: 'fit-content',
        "& .MuiFormControlLabel-label": {
            color: theme.palette.text.primary
        }
    }
}));

export default function EmployerPicker({ customFieldId,isShowEmployerPicker = false, hideIsAll = false, ...props }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const employersData = useSelector((state) => customFieldSelectors.getEmployerListData(state, { part: "data" }));
    const pageFilter = useSelector((state) => customFieldSelectors.getEmployerListData(state, { part: "filter" }));
    const pageSort = useSelector((state) => customFieldSelectors.getEmployerListData(state, { part: "sort" }));
    const PaginationData = useSelector(customFieldSelectors.getEmployerPaginationData);
    const isAllSelected = useSelector((state) => customFieldSelectors.getFormData(state, "isAllEmployer"));

    const fetchData = useCallback(({ pageSize, pageIndex, filters, sortBy }) => {
        dispatch(customFieldActions.setEmployerListSort(sortBy));
        dispatch(customFieldActions.setEmployerListFilter(filters));
        dispatch(customFieldActions.setEmployerListPageSize(pageSize));
        dispatch(customFieldActions.fetchEmployersList(pageIndex));
    }, []);

    const handleChange = (event) => {
        dispatch(customFieldActions.setCustomFieldForm("isAllEmployer", event.target.checked));
    };

    useEffect(()=>{
        return ()=>{
            dispatch(customFieldActions.clearEmployerPickerFilters());
        };
    },[])

    return (
        <>
            {!hideIsAll && <FormControlLabel
                classes={{ root: classes.label }}
                label="All Employers"
                control={<Checkbox checked={isAllSelected} onChange={handleChange} />} />}
            <FadeHide in={!hideIsAll ? !isAllSelected : isShowEmployerPicker}>
                <div className={classes.tableWrapper}>
                    <ReactTable
                        {...sharedReactTableProps}
                        fetchData={fetchData}
                        data={employersData}
                        defaultFilter={pageFilter}
                        currentPage={PaginationData.currentPage}
                        defaultPageSize={PaginationData.pageSize}
                        loading={PaginationData.isLoading}
                        pageCount={PaginationData.totalCount}
                        defaultSort={pageSort}
                        columns={columns(customFieldId)}
                        classes={classNames(sharedReactTableProps.classes, classes.table)}
                    />
                </div>
            </FadeHide>
        </>
    )
}
