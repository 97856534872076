import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import withStyles from '@mui/styles/withStyles';
import Assignment from "@mui/icons-material/Assignment";
import Cancel from "@mui/icons-material/Cancel";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import FileCopyOutlined from "@mui/icons-material/FileCopyOutlined";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import { Timeline } from "@mui/icons-material";
import Can from "components/Authorization/Can";
import { JobPermissions } from "helpers/constants/permissions";

const styles = ({ typography, spacing }) => ({
    icon: {
        fontSize: typography.pxToRem(20),
        marginRight: spacing(2),
    },
});

class JobActionMenu extends React.Component {
    static propTypes = {
        jobId: PropTypes.number.isRequired,
        classes: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        onCancel: PropTypes.func.isRequired,
        // onDelete: PropTypes.func.isRequired,
        onEditDescription: PropTypes.func.isRequired,
        canEdit: PropTypes.bool,
        canDelete: PropTypes.bool,
        canCancel: PropTypes.bool,
        canEditDescription: PropTypes.bool,
    };

    static defaultProps = {
        canEdit: false,
        canDelete: false,
        canCancel: false,
        canEditDescription: false,
    };

    state = {
        anchorEl: null,
    };

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleEdit = () => {
        const { history, jobId } = this.props;
        this.handleClose();
        history.push(`/jobs/post?jobId=${jobId}&type=edit`);
    };

    handleDuplicate = () => {
        const { history, jobId } = this.props;
        this.handleClose();
        history.push(`/jobs/post?jobId=${jobId}&type=duplicate`);
    };

    handleCancel = () => {
        this.props.onCancel();
        this.handleClose();
    };

    handleDelete = () => {
        this.props.onDelete();
        this.handleClose();
    };

    handleEditDescription = () => {
        this.props.onEditDescription();
        this.handleClose();
    };
    handleTimelineDialog = () => {
        this.props.openTimelineDialog();
        this.handleClose();
    };

    render() {
        const { anchorEl } = this.state;
        const { classes } = this.props;

        const id = `action-menu-${this.props.jobId}`;

        return (
            <React.Fragment>
                <IconButton
                    aria-owns={anchorEl ? id : undefined}
                    aria-haspopup="true"
                    disableRipple
                    onClick={this.handleClick}
                    size="large">
                    <MoreHoriz color="primary" />
                </IconButton>
                {anchorEl ? (
                    <Menu
                        id={id}
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleClose}
                        disableAutoFocusItem>
                        <Can perform={JobPermissions.update.detail.value}>
                            {allowed => {
                                if (!allowed) {
                                    return null;
                                }
                                return (this.props.canEdit && (
                                    <MenuItem onClick={this.handleEdit}>
                                        <Edit className={classes.icon} color="action" />
                                        Edit Job
                                    </MenuItem>
                                ))
                            }}
                        </Can>
                        <Can perform={JobPermissions.update.detail.value}>
                            {allowed => {
                                if (!allowed) {
                                    return null;
                                }
                                return (this.props.canEditDescription && (
                                    <MenuItem onClick={this.handleEditDescription}>
                                        <Assignment className={classes.icon} color="action" />
                                        Edit Description
                                    </MenuItem>
                                ))
                            }}
                        </Can>
                        {this.props.canDuplicate && <Can perform={JobPermissions.create.duplicate.value}>
                            {allowed => {
                                if (!allowed) {
                                    return null;
                                }
                                return <MenuItem onClick={this.handleDuplicate}>
                                    <FileCopyOutlined className={classes.icon} color="action" />
                                    Duplicate Job
                                </MenuItem>
                            }}
                        </Can>}
                        <Can perform={JobPermissions.update.cancel.value}>
                            {allowed => {
                                if (!allowed) {
                                    return null;
                                }
                                return (this.props.canCancel && (
                                    <MenuItem onClick={this.handleCancel}>
                                        <Cancel className={classes.icon} color="action" />
                                        Cancel Job
                                    </MenuItem>)
                                )
                            }}
                        </Can>
                        <MenuItem onClick={this.handleTimelineDialog}>
                            <Timeline className={classes.icon} color="action" />
                            Timeline
                        </MenuItem>
                        {this.props.canDelete && (
                            <MenuItem onClick={this.handleCancel}>
                                <Delete className={classes.icon} color="action" />
                                Delete Job
                            </MenuItem>
                        )}
                    </Menu>
                ) : null}
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(withRouter(JobActionMenu));
