import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import MainContent from "components/Containers/MainContent";
import { getMe } from "store/modules/login/selectors";
import { actions as ProfileActions, selectors as ProfileSelectors } from "store/modules/profile";
import ChangePassword from "./components/ChangePassword";
import ProfileForm from "./components/ProfileForm";


function ProfileView() {
  const dispatch = useDispatch();
  const profileDetail = useSelector(ProfileSelectors.getProfileDetail);
  const { accountId } = useSelector(getMe);

  useEffect(() => {
    dispatch(ProfileActions.fetchAdminById(accountId));
  }, []);
  return (
    <React.Fragment>
      <MainContent>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ProfileForm profileDetail={profileDetail} />
        </Grid>
        {/* <Grid item  xs={12} sm={12} md={6} lg={6}>
          <ChangePassword id={accountId} />
        </Grid> */}
      </Grid>
      </MainContent>
    </React.Fragment>
  );
}

export default ProfileView;
