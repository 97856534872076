/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { InfoOutlined } from "@mui/icons-material";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import { makeDate, upOrDown } from "helpers";
import { adjustmentStyles, holidayStyle } from "views/Finance/helpers";

import Loader from "components/Loader/Loader";
import { formatMoney, formatNumber } from "utils/stringUtils";
import { actions as BillingActions, selectors as FinanceSelectors } from "store/modules/finance";
import BillingWeeksMenu from "./components/BillingWeeksMenu";
import WeeklySummaryPopover from "./components/WeeklySummaryPopover/WeeklySummaryPopover";
import { BillingRate } from "../../../helpers/constants";
import { isHoliday } from "../../../utils/time";

function BillingOverviewTab() {
  const dispatch = useDispatch();
  const history = useHistory();
  const billingYearByWeek = useSelector(FinanceSelectors.getYearlyReportData);
  const isLoadingBillingSummery = useSelector(FinanceSelectors.getBillingSummeryLoading);
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalData] = useState({ week: {}, lastWeek: {}, calculated: {} });

  useEffect(() => {
    dispatch(BillingActions.fetchEmployerBillingYearSummary());
  }, [dispatch]);

  const Adjustments = ({ adjustments, isHoli }) =>
      <Grid container direction="column" style={adjustmentStyles}>
          {adjustments ? (
              <Tooltip title={`Adjustments`} aria-label="add" style={{ width: 'fit-content' }}>
                  <div style={{ textAlign: "left", justify: "left", width: 'fit-content' }}>
                    <InfoOutlined
                      style={{ textAlign: "center", marginRight: 5, fontSize: 14, color: "#0f3cb8" }}
                    />
                    {adjustments} Timesheets included
                  </div>
                </Tooltip>
          ) : null}
          {isHoli ? (
              <Tooltip title={isHoli} aria-label="add">
                <span style={holidayStyle}>Stat Holiday</span>
              </Tooltip>
              ): null}
      </Grid>

  const renderTabletView = ({ week, calculated, lastWeek, i }) => (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      item
      xs={12}
      onClick={() =>
          history.push(`/finance/billingdetails/week/${week.week + 0}/${week.year + 0}`)
      }
      style={{
        border: " 1px solid rgb(174 190 205)",
        padding: 15,
        backgroundColor: "#fff",
        width: "100%",
        flexGrow: 1,
        cursor: "pointer"
      }}>
      <Grid item md={3}>
        <Typography variant="h6">
          Week {week.week + 0}(week.year)
          {i > 0 && upOrDown(lastWeek.billableTotalHours, calculated.billableTotalHours)}
        </Typography>
        {makeDate(Number(week.week), Number(week.year))}
      </Grid>
      <Grid item md={2}>
        <Typography variant="h6"> {formatNumber(week.shifts)}</Typography>
        Shifts
      </Grid>
      <Grid item md={2} style={{ textAlign: "inherit" }}>
        <Typography variant="h6"> {formatNumber(week.hoursWorked)}</Typography>
        Hours Worked
      </Grid>
      <Grid item md={2} style={{ textAlign: "inherit" }}>
        <Typography variant="h6"> {formatNumber(week.hoursWorkedLessBreak)}</Typography>
        Billable Hours
      </Grid>
      <Grid item md={2} style={{ textAlign: "right" }}>
        <Typography variant="h6">{formatMoney(calculated.billableTotalHours)}</Typography>
        Total Billable
      </Grid>
      <Grid item md={1} style={{ textAlign: "right" }}>
        <IconButton
          aria-haspopup="true"
          disableRipple
          onClick={() =>
            history.push(`/finance/billingdetails/week/${week.week + 0}/${week.year + 0}`)
          }
          size="large">
          <MoreHoriz />
        </IconButton>
      </Grid>
    </Grid>
  );

  const renderBrowserView = ({ week, calculated, lastWeek, i }) => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        item
        xs={12}
        onClick={() =>
            history.push(`/finance/billingdetails/week/${week.week + 0}/${week.year + 0}`)
        }
        style={{
          border: " 1px solid rgb(174 190 205)",
          padding: 15,
          backgroundColor: "#fff",
          width: "100%",
          cursor: "pointer"
        }}>
        <Grid item xs={10} md={3}>
          <Typography variant="h6">
            Week {week.week + 0}, {week.year}
            {i > 0 && upOrDown(lastWeek.billableTotalHours, calculated.billableTotalHours)}
          </Typography>
          {makeDate(Number(week.week), Number(week.year))}
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="h6"> {formatNumber(week.shifts)}</Typography>
          Shifts
        </Grid>
        <Grid item xs={6} md={2} style={{ textAlign: "inherit" }}>
          <Typography variant="h6"> {formatNumber(week.hoursWorked)}</Typography>
          Hours Worked
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="h6"> {formatNumber(week.hoursWorkedLessBreak)}</Typography>
          Billable Hours
        </Grid>
        <Grid item xs={6} md={2} style={{ textAlign: "right" }}>
          {/*  Temp Hack */}
          <Typography variant="h6">{formatMoney(week.approxBilling)}</Typography>
          Approx. Billable
        </Grid>

        {/*<Grid item xs={1} style={{ textAlign: "right" }}>
          <BillingWeeksMenu week={week} payroll={week.adjustedPayroll} />
        </Grid>*/}

        <Adjustments adjustments={week.adjustments} isHoli={isHoliday(week.week)} />
      </Grid>
    );
  };

  return (
    <>
      <WeeklySummaryPopover
        modalData={modalData}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        type="summary"
        config={{
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
          transformOrigin: { vertical: "bottom", horizontal: "center" },
          transitionDuration: { appear: 500, enter: 500, exit: 500 },
          width: 400,
        }}
      />

      {!isLoadingBillingSummery && billingYearByWeek && billingYearByWeek.length ? (
        billingYearByWeek.map((week, i) => {
          let calculated = {};
          let lastWeek = {};
          if (i + 1 < billingYearByWeek.length) {
            calculated = {
              billableRegularHours: formatMoney(week.regularHoursWorked * BillingRate) || 0,
              billableOvertimeHours: formatMoney(week.overtimeHoursWorked * BillingRate) || 0,
              billableDoubletimeHours: formatMoney(week.doubletimeHoursWorked * BillingRate) || 0,
              billableTotalHours:
                week.regularHoursWorked * BillingRate +
                  week.overtimeHoursWorked * (BillingRate * 1.5) +
                  week.doubletimeHoursWorked * (BillingRate * 2) || 0,
            };

            lastWeek = {
              bonusValue: billingYearByWeek[i + 1].bonusValue || null,
              breakValue: billingYearByWeek[i + 1].breakValue || null,
              doubletimeHoursWorked: billingYearByWeek[i + 1].doubletimeHoursWorked || null,
              hoursWorked: billingYearByWeek[i + 1].hoursWorked || null,
              hoursWorkedLessBreak: billingYearByWeek[i + 1].hoursWorkedLessBreak || null,
              overtimeHoursWorked: billingYearByWeek[i + 1].overtimeHoursWorked || null,
              regularHoursWorked: billingYearByWeek[i + 1].regularHoursWorked || null,
              shifts: billingYearByWeek[i + 1].shifts || null,
              week: billingYearByWeek[i + 1].week || week.week,
              billableTotalHours:
                billingYearByWeek[i + 1].regularHoursWorked * BillingRate +
                billingYearByWeek[i + 1].overtimeHoursWorked * (BillingRate * 1.5) +
                billingYearByWeek[i + 1].doubletimeHoursWorked * (BillingRate * 2),
            };
          }

          return (
            <Grid
              key={i}
              direction="row"
              justifyContent="flex-start"
              container
              item
              xs={12}
              style={{ marginTop: 20, width: "100%", flexGrow: 1 }}>

                {renderBrowserView({ week, calculated, lastWeek, i })}
            </Grid>
          );
        })
      ) : (
        isLoadingBillingSummery ? <Loader isLoading={billingYearByWeek && !billingYearByWeek.length} /> : <Typography textAlign={"center"}>No data found</Typography>
      )}
    </>
  );
}

export default BillingOverviewTab;
