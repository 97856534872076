import React from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import withStyles from '@mui/styles/withStyles';
import { LocalOffer, Delete, UploadFile } from "@mui/icons-material";
import CheckCircle from "@mui/icons-material/CheckCircle";
import EventAvailable from "@mui/icons-material/EventAvailable";
import EventBusy from "@mui/icons-material/EventBusy";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import NoteAdd from "@mui/icons-material/NoteAdd";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Report from "@mui/icons-material/Report";
import Security from "@mui/icons-material/Security";
import VerifiedUser from "@mui/icons-material/VerifiedUser";
import WorkOff from "@mui/icons-material/WorkOff";
import Can from "components/Authorization/Can";
import { WorkerPermissions } from "helpers/constants/permissions";

const styles = ({ typography, spacing }) => ({
    icon: {
        fontSize: typography.pxToRem(20),
        marginRight: spacing(2),
    },
});

class WorkerActionMenu extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    state = {
        anchorEl: null,
        testOpen: false,
    };

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleEdit = () => {
        this.props.onEdit();
        this.handleClose();
    };

    handleSuspend = () => {
        this.props.onSuspend();
        this.handleClose();
    };

    handleRemoveSuspension = () => {
        this.props.onSuspensionRemove();
        this.handleClose();
    };

    handleVerify = () => {
        this.props.onVerify();
        this.handleClose();
    };

    handleUnverify = () => {
        this.props.onUnverify();
        this.handleClose();
    };

    handleActivate = () => {
        this.props.onActivate();
        this.handleClose();
    };

    handleDeactivate = () => {
        this.props.onDeactivate();
        this.handleClose();
    };

    handlePrefer = () => {
        this.props.onPrefer();
        this.handleClose();
    };

    handleBan = () => {
        this.props.onBan();
        this.handleClose();
    };

    handleAddWorkerNote = () => {
        this.props.onAddWorkerNote();
        this.handleClose();
    };

    handleUploadWorkerDoc = () => {
        this.props.onUploadWorkerDocument();
        this.handleClose();
    };

    handleAddTags = () => {
        this.props.onAddTags();
        this.handleClose();
    };

    handleDeleteAccount = () => {
        this.props.onDeleteAccount();
        this.handleClose();
    };

    //   handleTestOpen = () => {
    //     this.setState({ anchorEl: event.currentTarget });
    //   };

    render() {
        const { anchorEl } = this.state;
        const {
            classes,
            canSuspend,
            canRemoveSuspension,
            canVerify,
            canUnverify,
            canActivate,
            canDeactivate,
            setCanEdit,
        } = this.props;

        const id = `action-menu-worker`;

        return (
            <React.Fragment>
                <IconButton
                    aria-owns={anchorEl ? id : undefined}
                    aria-haspopup="true"
                    disableRipple
                    onClick={this.handleClick}
                    size="large">
                    <MoreHoriz color="primary" />
                </IconButton>
                {anchorEl ? (
                    <Menu
                        // id={id}
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleClose}
                        disableAutoFocusItem>
                        {/* <MenuItem onClick={this.handleEdit}>
                            <Edit className={classes.icon} color="action" />
                            Edit Worker Info
                        </MenuItem> */}
                        {/*<MenuItem onClick={() => {}}>
                            <Textsms className={classes.icon} color="action" />
                            Message Worker
                        </MenuItem>
                        <MenuItem onClick={() => {}}>
                            <AddAlert className={classes.icon} color="action" />
                            Add to Waitlist
                        </MenuItem>
                        */}
                        <Can perform={WorkerPermissions.update.profile.value}>
                            {allowed => {
                                if (!allowed) return null;
                                return (
                                    <MenuItem onClick={setCanEdit}>
                                        <PersonAdd className={classes.icon} color="action" />
                                        Edit Worker
                                    </MenuItem>
                                )
                            }}
                        </Can>
                        {/* <Contact {...worker} testOpen={testOpen } /> */}

                        <MenuItem onClick={this.handlePrefer}>
                            <PersonAdd className={classes.icon} color="action" />
                            Add to Preferred
                        </MenuItem>
                        <MenuItem onClick={this.handleBan}>
                            <Report className={classes.icon} color="action" />
                            Ban from Employer
                        </MenuItem>
                        {canSuspend && (
                            <Can perform={WorkerPermissions.update.suspend.value}>
                                {allowed => {
                                    if (!allowed) return null;
                                    return (
                                        <MenuItem onClick={this.handleSuspend}>
                                            <EventBusy className={classes.icon} color="action" />
                                            Suspend
                                        </MenuItem>
                                    )
                                }}
                            </Can>
                        )}
                        {canRemoveSuspension && (
                            <MenuItem onClick={this.handleRemoveSuspension}>
                                <EventAvailable className={classes.icon} color="action" />
                                Unsuspend
                            </MenuItem>
                        )}
                        {canUnverify && (
                            <MenuItem onClick={this.handleUnverify}>
                                <Security className={classes.icon} color="action" />
                                Unverify
                            </MenuItem>
                        )}
                        {canVerify && (
                            <MenuItem onClick={this.handleVerify}>
                                <VerifiedUser className={classes.icon} color="action" />
                                Verify
                            </MenuItem>
                        )}
                        {canActivate && (
                            <MenuItem onClick={this.handleActivate}>
                                <CheckCircle className={classes.icon} color="action" />
                                Activate
                            </MenuItem>
                        )}
                        {canDeactivate && (
                            <MenuItem onClick={this.handleDeactivate}>
                                <WorkOff className={classes.icon} color="action" />
                                Deactivate
                            </MenuItem>
                        )}

                        {/* <MenuItem onClick={this.handleUploadWorkerDoc}>
                            <UploadFile className={classes.icon} color="action" />
                            Upload Document
                        </MenuItem> */}

                        <MenuItem onClick={this.handleAddWorkerNote}>
                            <NoteAdd className={classes.icon} color="action" />
                            Add A Note
                        </MenuItem>

                        <MenuItem onClick={this.handleAddTags}>
                            <LocalOffer className={classes.icon} color="action" />
                            Add Tag
                        </MenuItem>
                        <MenuItem onClick={this.handleDeleteAccount}>
                            <Delete className={classes.icon} color="action" />
                            Delete Account
                        </MenuItem>
                    </Menu>
                ) : null}
            </React.Fragment>
        );
    }
}

export default withStyles(styles)((WorkerActionMenu));
