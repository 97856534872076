import { gql } from "@apollo/client";

export const workerPayrollReportQuery = gql`
  query workerPayrollReport($before: String, $after: String, $first: Int, $last: Int, $startDate: DateTime!, $endDate: DateTime!, $workerId: String) {
    workerPayrollReport(before: $before, after: $after, first: $first, last: $last, startDate: $startDate, endDate: $endDate, workerId: $workerId){
        totalCount
        pageInfo{
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges{
          cursor
          cursorDecoded
          node
        }
    }
  }
`;

export const workerWisePayrollReportCSV = gql`
  query workerWisePayrollReportCSV($startDate: DateTime!, $endDate: DateTime!) {
    workerWisePayrollReportCSV(startDate: $startDate, endDate: $endDate)
  }
`;
export const workerWisePayrollReportQuery = gql`
  query workerWisePayrollReport($before: String, $after: String, $first: Int, $last: Int, $startDate: DateTime!, $endDate: DateTime!, $workerId: String) {
    workerWisePayrollReport(before: $before, after: $after, first: $first, last: $last, startDate: $startDate, endDate: $endDate, workerId: $workerId){
        totalCount
        pageInfo{
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges{
          cursor
          cursorDecoded
          node
        }
    }
  }
`;

export const workerPayrollReportCSV = gql`
  query workerPayrollReportCSV($startDate: DateTime!, $endDate: DateTime!) {
    workerPayrollReportCSV(startDate: $startDate, endDate: $endDate)
  }
`;

export const workerJobReportCSV = gql`
  query payrollReportByWorkerIdCSV($startDate: DateTime!, $endDate: DateTime!, $workerId: String) {
    payrollReportByWorkerIdCSV(startDate: $startDate, endDate: $endDate, workerId: $workerId)
  }
`;

export const workerJobReportQuery = gql`
  query payrollReportByWorkerId($before: String, $after: String, $first: Int, $last: Int, $startDate: DateTime!, $endDate: DateTime!, $workerId: String) {
    payrollReportByWorkerId(before: $before, after: $after, first: $first, last: $last, startDate: $startDate, endDate: $endDate, workerId: $workerId){
        totalCount
        pageInfo{
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges{
          cursor
          cursorDecoded
          node
        }
    }
  }
`;

export const activeEmployerSummaryReportQuery = gql`
  query activeEmployerSummaryReport($before: String, $after: String, $first: Int, $last: Int, $employerId: String, $isAll: Boolean) {
    activeEmployerSummaryReport(before: $before, after: $after, first: $first, last: $last, employerId: $employerId, isAll: $isAll){
        totalCount
        pageInfo{
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges{
          cursor
          cursorDecoded
          node
        }
    }
  }
`;

export const activeEmployerSummaryReportCSV = gql`
  query activeEmployerSummaryReportCSV($isAll: Boolean) {
    activeEmployerSummaryReportCSV(isAll: $isAll)
  }
`;

export const WSBCReportQuery = gql`
  query wsbcReport($before: String, $after: String, $first: Int, $last: Int, $startDate: DateTime!, $endDate: DateTime!) {
    wsbcReport(before: $before, after: $after, first: $first, last: $last, startDate: $startDate, endDate: $endDate){
        totalCount
        pageInfo{
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges{
          cursor
          cursorDecoded
          node
        }
    }
  }
`;

export const WSBCReportCSV = gql`
  query wsbcReportCSV($startDate: DateTime!, $endDate: DateTime!) {
    wsbcReportCSV(startDate: $startDate, endDate: $endDate)
  }
`;

export const WorkOrderReportQuery = gql`
  query workorderReport($before: String, $after: String, $first: Int, $last: Int, $startDate: DateTime!, $endDate: DateTime!) {
    workorderReport(before: $before, after: $after, first: $first, last: $last, startDate: $startDate, endDate: $endDate){
        totalCount
        pageInfo{
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges{
          cursor
          cursorDecoded
          node
        }
    }
  }
`;

export const WorkOrderReportCSV = gql`
  query workorderReportCSV($startDate: DateTime!, $endDate: DateTime!) {
    workorderReportCSV(startDate: $startDate, endDate: $endDate)
  }
`;

export const workerListBasedOnEmployerReportQuery = gql`
  query workerListBasedOnEmployerReport($before: String, $after: String, $first: Int, $last: Int, $employerId: String, $startDate: DateTime!, $endDate: DateTime!, $like: [WorkerListByJobFilterInput!], $order: [WorkerListByJobInput!]) {
    workerListBasedOnEmployerReport(before: $before, after: $after, first: $first, last: $last, employerId: $employerId, startDate: $startDate, endDate: $endDate, like: $like, order: $order){
        totalCount
        pageInfo{
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges{
          cursor
          cursorDecoded
          node
        }
    }
  }
`;
export const workerListBasedOnEmployeeReportCSV = gql`
  query workerListBasedOnEmployeeReportCSV($before: String, $after: String, $first: Int, $last: Int, $employerId: String, $startDate: DateTime!, $endDate: DateTime!, $like: [WorkerListByJobFilterInput!], $order: [WorkerListByJobInput!]) {
    workerListBasedOnEmployeeReportCSV(before: $before, after: $after, first: $first, last: $last, employerId: $employerId, startDate: $startDate, endDate: $endDate, like: $like, order: $order)
  }
`;


// Weekly Reports
export const weeklyBillingReportQuery = gql`
query WeeklyBillingReport($before: String, $after: String, $first: Int, $last: Int, $employerId: String,$week: Float!, $year: Float!, $like: [WeeklyBillReportFilterInput!], $order: [WeeklyBillReportOrderInput!]) {
    weeklyBillingReport(before: $before, after: $after, first: $first, last: $last, employerId: $employerId,week: $week, year: $year, like: $like, order: $order) {
      edges {
        node
        cursor
        cursorDecoded
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
}
`;
export const weeklyBillingReportCSV = gql`
    query weeklyBillingReportCSV($before: String, $after: String, $first: Int, $last: Int, $employerId: String,$week: Float!, $year: Float!, $like: [WeeklyBillReportFilterInput!], $order: [WeeklyBillReportOrderInput!]) {
        weeklyBillingReportCSV(before: $before, after: $after, first: $first, last: $last, employerId: $employerId, week: $week, year: $year, like: $like, order: $order)
    }
`;


export default workerPayrollReportQuery;
