import Dialog from 'components/Dialog/Dialog'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions as EmployerActions, selectors as EmployerSelectors } from 'store/modules/employerDetails'
import MobileTimePicker from "components/DateTimePicker/MobileTimePicker";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CardContent, Checkbox, DialogActions, FormControlLabel, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { weekDaysList } from 'utils/constant';
import Card from 'components/Card/Card';
import { makeStyles } from '@mui/styles';
import CurrencyInput from 'components/CurrencyInput/CurrencyInput';
import { find, isEqual } from 'lodash';
import moment from "moment";
import Button from 'components/Button/Button';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
    weekDays: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    days: {
        padding: '14px 0',
        border: '1px solid',
        borderRadius: '100px',
        width: '50px',
        height: '50px',
        textAlign: 'center',
        marginRight: '10px',
        fontWeight: '400',
        borderColor: '#b2b2c4',
        color: '#848499',
        cursor: 'pointer',
        marginBottom: '10px'
    },
    selectedDays: {
        borderColor: '#00c8a9 !important',
        color: '#00c8a9 !important',
        background: '#FFF',
        fontWeight: '400'
    },
    disabledDay: {
        cursor: "not-allowed",
        pointerEvents: "none",
        touchAction: "none",
        color: "#84849985"
    },
    card: {
        boxShadow: "none",
        marginBottom: `${theme.spacing(1)} !important`,
    },
    jobTypeCard: {
        display: "flex",
        flexWrap: "wrap",
        gap: theme.spacing(3),
        "& $card": {
            minWidth: 320,
            width: "calc(50% - 24px)",
            flex: 1,
            cursor: "pointer",
            background: "transparent",
            transition: "1s all",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            "& .MuiCardContent-root": {
                padding: `${theme.spacing(1)} !important`
            }
        },
        "& .MuiFormControlLabel-label": {
            color: theme.palette.text.primary,
        },
        "& .MuiCheckbox-root": {
            paddingTop: 0,
            paddingBottom: 0,
        },
        "& .desc": {
            fontSize: 10,
            marginLeft: theme.spacing(3.6)
        }
    },
    footer: {
        marginTop: theme.spacing(2)
    }
}));

export default function TimeBasePremiumForm({ setTab, ...props }) {
    const classes = useStyles();
    const open = useSelector(EmployerSelectors.GetNewTimeBasePremiumDialog);
    const formData = useSelector(EmployerSelectors.getTimeBasePremiumForm);
    const canPost = useSelector(EmployerSelectors.canAddUpdateTimeBasePremium);
    const isLoading = useSelector(EmployerSelectors.getIsAddTimeBasePremiumListLoading);
    const dispatch = useDispatch();
    const timeBasePremium = useSelector(EmployerSelectors.getSelectedTimeBasePremium);

    useEffect(() => {
        if (!timeBasePremium) {
            dispatch(EmployerActions.setTimeBasePremiumForm({ key: "startTime", value: moment() }));
            dispatch(EmployerActions.setTimeBasePremiumForm({ key: "endTime", value: moment().add(4, "hours") }));
        };
        return () => {
            dispatch(EmployerActions.clearTimeBasePremiumForm());
        };
    }, [timeBasePremium]);

    const onCancel = () => {
        setTab(0);
        dispatch(EmployerActions.clearTimeBasePremiumForm());
    };

    const handleChange = (value, key) => {
        dispatch(EmployerActions.setTimeBasePremiumForm({ key, value }));
    };

    const handleChangeWeekDays = (e) => {
        if (find(formData.days, n => isEqual(n, e))) {
            dispatch(EmployerActions.setTimeBasePremiumForm({ key: "days", value: formData.days.filter(n => n != e) }));
        } else {
            dispatch(EmployerActions.setTimeBasePremiumForm({ key: "days", value: [...formData.days, e] }));
        }
    };

    const onConfirm = async () => {
        dispatch(EmployerActions.addTimeBasePremium()).then((e) => {
            if (!e)
                setTab(0);

        });
    };

    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <div style={{ marginTop: 24 }}>
                    <Grid container spacing={4} textAlign="left">
                        <Grid item md={12} sm={12} xs={12}>
                            <label className={classes.jobTypeCard}>
                                <Card style={{ margin: 0 }} className={classes.card} >
                                    <CardContent>
                                        <FormControlLabel control={
                                            <Checkbox
                                                color="secondary"
                                                onChange={({ target: { checked } }) => handleChange(checked, "isForFullShift")}
                                                checked={formData.isForFullShift} />
                                        } label={"Full Shift"} />
                                    </CardContent>
                                </Card>
                            </label>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <MobileTimePicker
                                label={`Start Time`}
                                value={formData.startTime}
                                onChange={(e) => handleChange(moment(e), "startTime")}
                                renderInput={(params) => <TextField fullWidth {...params} />}
                            />
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <MobileTimePicker
                                label={`End Time`}
                                value={formData.endTime}
                                minTime={moment(formData.startTime).add(1, "hour")}
                                onChange={(e) => handleChange(moment(e), "endTime")}
                                renderInput={(params) => <TextField fullWidth {...params} />}
                            />
                        </Grid>
                        <Grid item md={6} xs={12} sm={6} style={{ marginTop: "8px" }}>
                            <TextField
                                select
                                fullWidth
                                label={"Type"}
                                name="type"
                                value={formData.type}
                                onChange={({ target: { name, value } }) => handleChange(value, name)}
                                margin="none"
                            >
                                <MenuItem value="PERCENTAGE">Percentage (%)</MenuItem>
                                <MenuItem value="VALUE">Value ($)</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item md={6} xs={12} sm={6} style={{ marginTop: "8px" }}>
                            <CurrencyInput
                                label="Value"
                                type={formData.type}
                                minValue={0}
                                minValueHelperText={`Minimum value is: ${formData.type === "PERCENTAGE" ? "0%" : "$0"}`}
                                maxValue={formData.type === "PERCENTAGE" ? 100 : 1000}
                                maxValueHelperText={`Maximum value is: ${formData.type === "PERCENTAGE" ? "100%" : "$1000"}`}
                                value={formData.value}
                                onChange={(value) => handleChange(value, "value")}
                            />
                        </Grid>
                        <Grid item md={12} xs={12} sm={12} style={{ marginTop: "8px" }} display="flex">
                            {weekDaysList.map(n => <div key={n.value} onClick={() => handleChangeWeekDays(n.value)} className={`${classes.days} ${formData.days.indexOf(n.value) > -1 ? classes.selectedDays : ''}`}>{n.label}</div>)}
                        </Grid>
                    </Grid>
                </div>
            </LocalizationProvider>
            <DialogActions className={classes.footer}>
                <Button
                    label={"Cancel"}
                    onClick={onCancel}
                    disabled={isLoading}
                    color="transparent" />
                <Button
                    label={timeBasePremium ? "Update" : "Add"}
                    onClick={onConfirm}
                    isLoading={isLoading}
                    color="secondary"
                    disabled={!canPost || isLoading}
                />
            </DialogActions>
        </div >
    )
};
