import gql from "graphql-tag";

export default gql`
  query($id: Int!) {
     jobTemplateById(id: $id) {
      id
      templateName
      employerId
      managerId
      workTypeId
      peopleNeeded
      street
      city
      country
      region
      unit
      postalCode
      floor
      description
      isBreakPaid
      isActive
      breakMins
      latitude
      longitude
      locationInstruction
      gears
      skills
      jobTemplateGear {
        gear {
          isActive
          id
          gearName
          frenchGearName
          createdAt
        }
        gearId
        id
        createdAt
      }
      jobTemplateSkill {
        skill {
          frenchSkillName
          id
          isActive
          skillName
          createdAt
        }
        id
        skillId
        createdAt
      }
      employer {
        id
        firstName
        lastName
      }
      manager {
        id
        firstName
        lastName
      }
      workType {
        id
        label
      }
    }
  }
`
