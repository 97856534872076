import * as pagination from "utils/graphql-pagination";
import { paginationSettings } from "./constants";

export const getPositionsPaginationData = state => {
  return state.workerDetails[pagination.getPageInfoAttr(paginationSettings.positions)];
};

export const getWorkerNotesPaginationData = state => {
  return state.workerDetails[pagination.getPageInfoAttr(paginationSettings.workerNotes)];
};

export const getWorkerDocumentsPaginationData = state => {
  return state.workerDetails[pagination.getPageInfoAttr(paginationSettings.workerDocuments)];
};

export const getWorkerBannedFromPaginationData = state => {
  return state.workerDetails[pagination.getPageInfoAttr(paginationSettings.workerBannedFrom)];
};

export const getTransportationTypes = state => {
  return state.workerDetails.transportationTypes || [];
};

export const getWorkerDetails = state => {
  return state.workerDetails;
};

// Loading selectors
export const getLoadingStateFactory = loader => state => {
  return state.workerDetails.loading[loader] || false;
};

export const getIsPageLoading = getLoadingStateFactory("page");
export const getIsPositionsLoading = getLoadingStateFactory("positions");
export const getShowUploadWorkerDocument = getLoadingStateFactory("showUploadWorkerDocument");
export const getIsUpdatingWorkPermit = getLoadingStateFactory("updatingWorkPermit");

export const getIsWorkerNotesLoading = getLoadingStateFactory("getIsWorkerNotesLoading");
export const getIsWorkerBannedFromLoading = getLoadingStateFactory("getIsWorkerBannedFromLoading");
export const getIsApplyingAction = getLoadingStateFactory("actions");
export const getIsDCTransactionLoading = getLoadingStateFactory("DCTransaction");
