import React, { useState } from "react";
import Dialog from "components/Dialog/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { actions as SkillsActions, selectors as SkillsSelectors } from "store/modules/skills";

const style = ({ spacing }) => ({
    container: {
        marginBottom: spacing(2)
    }
})

function AddSkillDialog({ classes }) {

    const dispatch = useDispatch();
    const [englishSkillName, setEnglishSkillName] = useState("");
    const [frenchSkillName, setFrenchSkillName] = useState("");
    const [skillStatus, setSkillStatus] = useState(true);
    const isAddSkillDialog = useSelector(SkillsSelectors.getIsAddSkillDialog);

    // const skillStatusValue = [
    //     {
    //         value: true,
    //         label: "Active"
    //     },
    //     {
    //         value: false,
    //         label: "Inactive"
    //     }
    // ]

    const hideAddSkillDialog = () => {
        dispatch(SkillsActions.setIsaddSkillDialog(false));
    }

    const handleChangeEnglishSkill = (event) => {
        setEnglishSkillName(event.target.value);
    }

    const handleChangeFrenchSkill = (event) => {
        setFrenchSkillName(event.target.value);
    }

    const AddSkill = () => {
        const values = {
            skillName: englishSkillName,
            frenchSkillName: frenchSkillName,
            isActive: skillStatus
        }
        dispatch(SkillsActions.createSkill(values));
        setEnglishSkillName("");
        setFrenchSkillName("");
        setSkillStatus(true);
    }

    // const onSkillStatusChange = (event) => {
    //     setSkillStatus(event.target.value)
    // }


    return (
        <Dialog
            open={isAddSkillDialog}
            title="Add Skill"
            onClose={hideAddSkillDialog}
            onCancel={hideAddSkillDialog}
            onConfirm={AddSkill}
            disabled={!englishSkillName?.trim() || !frenchSkillName?.trim()}
            alertType="form"
            confirmText="Add Skill"
            size="sm"
            modalType="scrollable"
        >
            <div className={classes.container}>
                <TextField
                    id="englishSkillName"
                    margin="normal"
                    label="Skill Name In English"
                    value={englishSkillName}
                    onChange={handleChangeEnglishSkill}
                    required
                    fullWidth
                />
                <TextField
                    id="frenchSkillName"
                    margin="normal"
                    label="Skill Name In French"
                    value={frenchSkillName}
                    onChange={handleChangeFrenchSkill}
                    required
                    fullWidth
                />
                {/* <TextField
                    select
                    label="Skill Status"
                    value={skillStatus}
                    onChange={onSkillStatusChange}
                    margin="normal"
                    fullWidth
                    required
                >
                    {skillStatusValue.map(({ value, label }) => (
                        <MenuItem key={`skillStatus-${value}`} value={value}>
                            {label}
                        </MenuItem>
                    ))}
                </TextField> */}
            </div>
        </Dialog>
    )
}

export default (withStyles(style)(AddSkillDialog))
