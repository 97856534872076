import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { sample } from 'lodash';
import React from 'react';
import HolidayActionMenu from 'views/Holidays/components/HolidayActionMenu';
import { actions as holidaysActions, selectors as holidaysSelectors } from "store/modules/holidays"
import { useSelector } from 'react-redux'
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    container: {
        "& p": {
            marginRight: theme.spacing(2),
        },
        "& svg": {
            marginRight: theme.spacing(1),
        },
    },
    moreIconButton: {
        padding: 0
    }
}));


export default function HolidayHeader() {
    const classes = useStyles();
    const holidayDetails = useSelector(holidaysSelectors.getHolidayDetail);

    return (
        <Grid container>
            <Grid container item xs={6} alignItems="center" className={classes.container}>
                <Typography variant="body1" color="textSecondary">
                    {moment(holidayDetails?.holidayDate).isAfter(moment()) ? "Upcoming" : moment(moment(holidayDetails?.holidayDate).format("YYYY-MM-DD")).isSame(moment().format("YYYY-MM-DD")) ? "Today" : "Completed"}
                </Typography>
            </Grid>
            {moment(holidayDetails?.holidayDate).isAfter(moment()) && <Grid container item xs={6} justifyContent="flex-end" alignItems="center">
                <HolidayActionMenu classes={classes} holiday={holidayDetails} />
            </Grid>}
        </Grid>
    )
}
