// HelperMessage
import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import { Button, CircularProgress } from "@mui/material";

const styles = theme => ({
    footer: {
        position: "fixed",
        bottom: theme.spacing(4),
        right: theme.spacing(4),
        zIndex: 200,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
    },
    button: {
        marginTop: "8px",
    },
});

function SummaryFooter({ isEdit,loading, classes, canAdd, onClick = () => 0 }) {
    return (
        <div className={classes.footer}>
            <Button
                className={classes.button}
                color="secondary"
                variant="contained"
                disabled={loading || canAdd}
                onClick={onClick}
            >
                {loading ? <CircularProgress size={18} /> : isEdit?"Update":"Save"}
            </Button>
        </div>
    );
}

SummaryFooter.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(SummaryFooter);
