import * as pagination from "utils/graphql-pagination";
import { paginationSettings } from "./constants";
// Loading selectors
export const getLoadingStateFactory = loader => state => {
    return state.documents.loading[loader] || false;
};

export const getDialogStateFactory = loader => state => {
    return state.documents.dialog[loader] || false;
};

export const getIsDocumentStatusUpdate = getLoadingStateFactory("documentStatusUpdate");
export const getIsOpenRejectDialog = getDialogStateFactory("rejectReason");

export const getListData = (state, { statusframe, part }) => state.documents[statusframe][part];

export const getRejectReason = (state) => state.documents.rejectReason;
export const getSelectedDocument = (state) => state.documents.selectedDocument;

export const getPendingPaginationData = state => {
    return state.documents[pagination.getPageInfoAttr(paginationSettings.pending)];
};

export const getApprovedPaginationData = state => {
    return state.documents[pagination.getPageInfoAttr(paginationSettings.approved)];
};

export const getRejectedPaginationData = state => {
    return state.documents[pagination.getPageInfoAttr(paginationSettings.rejected)];
};
