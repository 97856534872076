import React from "react";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import { employerDetailStyles } from "views/Employer/helpers";
import MainContent from "components/Containers/MainContent";
import { actions as EmployerActions, selectors as EmployerSelectors } from "store/modules/employers";
import AccountSection from "./components/AccountSection";
import Stage from "./components/Stage";
import SummaryFooter from "./components/SummaryFooter";

class PostEmployerView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showPassword: false };
  }
  componentDidMount() {
      this.props.setNewEmployerData('isApproved', true );
      this.props.setNewEmployerData('isActive', true );
  }

  updateFormData = event => {
    if(event.target.id === 'isApproved' || event.target.id === 'isActive'){
        this.props.setNewEmployerData(event.target.id, event.target.checked );
    } else {
        this.props.setNewEmployerData(event.target.id, event.target.value);
    }
  };

  handleRegisterEmployer = () => {
    this.props.registerEmployer().then(response => {
        if(response && response.createEmployer && response.createEmployer.id){
          this.props.history.push(`/employers/${response.createEmployer.id}`);
        }
    });
  };
  render() {
    return (
      <MainContent>
        <Grid container>
          <Stage
            title="Company Info"
            description="Input the employer and location information."
            contentSize={8}>
            <AccountSection
              {...this.props.addEmployerData}
              emailIsValid={this.props.emailIsValid}
              emailIsAvailable={this.props.emailIsAvailable}
              payRateIsValid={this.props.payRateIsValid}
              billingRateIsValid={this.props.billingRateIsValid}
              checkPassword={this.props.checkPassword}
              updateFormData={this.updateFormData}
              setEmail={this.props.setEmail}
              sinIsValid={this.props.sinIsValid}
              setSin={this.props.setSin}
              checkSin={this.checkSin}
              onAddressChange={this.props.onAddressChange}
              setPhone={this.updateFormData}
              selectedLocation={this.props.selectedLocation}
            />
          </Stage>
          <SummaryFooter
            registerEmployer={this.handleRegisterEmployer}
            canSubmitNewEmployerForm={this.props.canSubmitNewEmployerForm && this.props.emailIsAvailable}
            isInfoLoading={this.props.isInfoLoading}
          />
        </Grid>
      </MainContent>
    );
  }
}
const mapState = state => {
  const {
    sinIsValid,
    emailIsAvailable,
    emailIsValid,
    sinIsTaken,
    selectedLocation,
    addEmployerData,
    isInfoLoading,
    payRateIsValid,
    billingRateIsValid
  } = state.employers;
  return {
    addEmployerData: addEmployerData,
    sinIsValid: sinIsValid,
    emailIsValid: emailIsValid,
    selectedLocation: selectedLocation,
    emailIsAvailable: emailIsAvailable,
    sinIsTaken: sinIsTaken,
    canSubmitNewEmployerForm: EmployerSelectors.canSubmitForm(state),
    isInfoLoading,
    payRateIsValid,
    billingRateIsValid
  };
};

const mapActions = dispatch => ({
  setNewEmployerData: (id, value) => dispatch(EmployerActions.setNewEmployerData(id, value)),
  setEmail: event => {
      if(event && event.target && event.target.value){
          dispatch(EmployerActions.setEmail(event.target.value))
      } else {
          dispatch(EmployerActions.setEmail(''))
      }
  },
  setSin: event => dispatch(EmployerActions.setSin(event.target.value)),
  validateForm: event => dispatch(EmployerActions.setSin(event.target.value)),
  registerEmployer: () => dispatch(EmployerActions.registerEmployer()),
  onAddressChange: (selectedLocation, location) => {
    dispatch(EmployerActions.setLocation(selectedLocation, location));
  },
});

export default connect(mapState, mapActions)(employerDetailStyles(PostEmployerView));
