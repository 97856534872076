import {gql} from "@apollo/client";

export default gql`query GetWorkTypeRate($employerId: String) {
    getWorkTypeRate(employerId: $employerId) {
      id
      createdAt
      updatedAt
      employerId
      workTypeId
      payRate
      billingRate
      workType {
        label
        id
      }
    }
}
`;
