import { gql } from "@apollo/client";

export const preMadeJobTemplateQuery = gql`
query {
    preMadeJobTemplate {
      id
      templateName
      employerId
      managerId
      workTypeId
      peopleNeeded
      street
      city
      region
      country
      postalCode
      unit
      floor
      description
      isActive
      breakMins
      latitude
      longitude
      locationInstruction
      gears
      skills
      jobTemplateGear {
        gear {
          isActive
          id
          gearName
          frenchGearName
          createdAt
        }
        gearId
        id
        createdAt
      }
      jobTemplateSkill {
        skill {
          frenchSkillName
          id
          isActive
          skillName
          createdAt
        }
        id
        skillId
        createdAt
      }
      employer {
        id
        firstName
        lastName
      }
      manager {
        id
        firstName
        lastName
      }
      workType {
        id
        label
      }
    }
  }
`;

export const workTypesQuery = gql`
query {
    workTypes{
      id
      label
    }
  }
`
