import ReactTableSimple from "components/ReactTable/ReactTableSimple";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actions as EmployerDetailActions, selectors as EmployerDetailSelectors } from "store/modules/employerDetails";
import EmployerManagerActionMenu from "./EmployerManagerActionMenu";

class EmployerManagerTable extends React.PureComponent {
    componentDidMount = () => this.props.fetchManager();

    render() {
        const {
            isLoading,
            data
        } = this.props;
        return (
            <React.Fragment>
                <ReactTableSimple
                    classes="-highlight"
                    data={data || []}
                    loading={isLoading}
                    rowsPerPageOptions={{ rowsPerPageOptions: [5] }}
                    columns={[
                        {
                            Header: "Title",
                            id: "title",
                            accessor: ({ title }) => title,
                            className: "-cursor-pointer",
                        },
                        {
                            Header: "First Name",
                            id: "firstName",
                            accessor: ({ manager }) => manager.firstName,
                            className: "-cursor-pointer",
                        },
                        {
                            Header: "Last Name",
                            id: "lastName",
                            accessor: ({ manager }) => manager.lastName,
                            className: "-cursor-pointer",
                        },
                        {
                            Header: "Email",
                            id: "email",
                            width: "auto",
                            accessor: ({ manager }) => manager.email,
                            className: "-cursor-pointer",
                        },
                        {
                            id: "actions",
                            disableSortBy: true,
                            collapse: true,
                            style: { textAlign: "right" },
                            Cell: ({ row: { original } }) => {
                                const { id, employerId, manager } = original;
                                return (
                                    <EmployerManagerActionMenu
                                        id={id}
                                        onDelete={() => this.props.onDeleteManager(employerId, manager.id)}
                                        onCancel={this.props.handleCancelManager}
                                    />
                                );
                            },
                        },
                    ]}
                />
            </React.Fragment>
        );
    }
}
const mapState = state => {
    const data = EmployerDetailSelectors.getManagersList(state);
    return {
        data
    };
};
const mapActions = {
    fetchManager: EmployerDetailActions.fetchManager,
};
export default connect(mapState, mapActions)(withRouter(EmployerManagerTable));
