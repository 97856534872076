import React from "react";
import { connect } from "react-redux";
import withStyles from '@mui/styles/withStyles';
import { actions as DocumentsActions, selectors as DocumentsSelectors } from "store/modules/documents";
import DocumentTable from "./DocumentTable";
import { tabStyles } from "./styles";
import { approvedByColumn } from "../helpers/columns";

class ApprovedTab extends React.Component {
    render() {
        const { tableProps } = this.props;

        return (
            <DocumentTable {...tableProps} extraColumns={[approvedByColumn]} />
        );
    }
}

const mapState = state => {
    const tableProps = {
        ...DocumentsSelectors.getApprovedPaginationData(state),
        data: DocumentsSelectors.getListData(state, {
            statusframe: "approved",
            part: "data",
        }),
        pageFilter: DocumentsSelectors.getListData(state, {
            statusframe: "approved",
            part: "filter",
        }),
        pageSort: DocumentsSelectors.getListData(state, {
            statusframe: "approved",
            part: "sort",
        }),
    };

    return {
        tableProps,
    };
};

const mapActions = {
    fetchDocuments: DocumentsActions.fetchApprovedDocuments,
    onPageChange: DocumentsActions.fetchApprovedDocuments,
    onSortedChange: DocumentsActions.setApprovedSort,
    onFilteredChange: DocumentsActions.setApprovedFilter,
    onPageSizeChange: DocumentsActions.setApprovedPageSize,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { tableProps } = stateProps;
    const {
        fetchDocuments,
        onPageChange,
        onSortedChange,
        onFilteredChange,
        onPageSizeChange
    } = dispatchProps;
    return {
        ...stateProps,
        ...dispatchProps,
        tableProps: {
            ...tableProps,
            fetchData: fetchDocuments,
            onPageChange,
            onSortedChange,
            onFilteredChange,
            onPageSizeChange
        },
        ...ownProps,
    };
};

export default connect(mapState, mapActions, mergeProps)(withStyles(tabStyles)(ApprovedTab));
