/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from "react";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Line from "components/Placeholders/Line";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";
import Can from "components/Authorization/Can";
import { PositionPermissions } from "helpers/constants/permissions";

export default function useLocation(disabled) {
    const canAddWorker = useSelector(JobDetailSelectors.getCanAddWorkerInDashboard);
    return {
        Header: "Location",
        id: "location",
        width:  undefined,
        minWidth: 100,
        sortable: false,
        Cell: ({ row: { original: { city, region } } }) => {
            return (
                <>
                    <Typography variant="body1">
                        {!city || !region ? <Line /> : `${city}, ${region}`}
                    </Typography>
                </>
            );
        },
        Footer: (<Can perform={PositionPermissions.create.fill.value}>
            {allowed => {
                if (!allowed) {
                    return null;
                }
                return canAddWorker && !disabled ? <Line /> : null
            }}
        </Can>)
    };
}
