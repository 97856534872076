import { gql } from "@apollo/client";

export default gql`
  mutation createCustomField($customData: CustomInput!) {
    createCustomField(customData: $customData) {
        id
        name
        slug
        type
        isRequired
        isForAll
        createdBy
        updatedBy
        deletedBy
        createdAt
        updatedAt
    }
  }
`;

export const deleteCustomFieldQuery=gql`
    mutation deleteCustomField($id: Float!) {
        deleteCustomField(id: $id)
    }
`;
