import { gql } from "@apollo/client";

export default gql`
  query getIdentityWorkersStats(
    $workerIdVerification: Boolean
    $order: [WorkerOrderInput!]
    $like: [WorkerFilterInput!]
  ) {
    workers(
      order: $order
      workerIdVerification: $workerIdVerification
      like: $like
    ) {
      totalCount
    }
  }
`;
