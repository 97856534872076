import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, DialogActions, Grid, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Form, Formik } from 'formik';
import * as yup from "yup";
import AddressAutocomplete from 'components/Autocomplete/AddressAutocomplete';
import { selectors as EmployerSelectors, actions as EmployerActions } from 'store/modules/employerDetails';

const useStyles = makeStyles(theme => ({
    footer: {
        padding: 0,
        marginTop: theme.spacing(2)
    }
}));

export default function BillingInformation() {
    const { employer } = useSelector(EmployerSelectors.getEmployerDetails) || {};
    const classes = useStyles();
    const [selectedLocation, setSelectedLocation] = useState('');
    const [location, setLocation] = useState({});
    const [address, setAddress] = useState("");
    const dispatch = useDispatch();

    const onAddressChange = (selectedLocation, location) => {
        setSelectedLocation(selectedLocation);
        setLocation(location);
    }
    useEffect(() => {
        const address = employer.street + ', ' + employer.city + ', ' + employer.region + ', ' + employer.country
        setSelectedLocation({ value: undefined, label: address })
        setAddress(address)
    }, [employer]);

    console.log("call")

    return (
        <Grid item xs={12} style={{ width: "100%", paddingLeft: 0, paddingRight: 0 }}>
            <Formik
                initialValues={{
                    billingFirstName: employer.billingFirstName,
                    billingLastName: employer.billingLastName,
                    billingJobRole: employer.billingJobRole,
                    billingEmail: employer.billingEmail,
                }}
                // onReset={() => {
                //     setSelectedLocation({ value: undefined, label: address });
                // }}
                onSubmit={(values, actions) => {
                    const request = {
                        ...values,
                        street: location.street,
                        city: location.city,
                        country: location.country,
                        region: location.region,
                        latitude: location.lat,
                        longitude: location.lng,
                        postalCode: location.postalCode,
                    }
                    dispatch(EmployerActions.saveEmployerProfile(request));
                    actions.setSubmitting(false);
                }}
                validationSchema={yup.object().shape({
                    billingFirstName: yup.string().required("Please enter first name").max(55, 'First name must be less than 55 characters').nullable(),
                    billingLastName: yup.string().required("Please enter last name").max(55, 'Last name must be less than 55 characters').nullable(),
                    billingJobRole: yup.string().required("Please enter job role").max(55, 'Job Role must be less than 55 characters').nullable(),
                    billingEmail: yup.string().email('Please enter valid email').max(100, 'Email address must be less than 100 characters').required("Please enter email address").nullable(),
                })}>
                {props => {
                    const {
                        values,
                        dirty,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                    } = props;

                    return (
                        <Form>
                            <Grid
                                item
                                container
                                direction="row"
                                xs={12}
                            >
                                <Grid container item xs={12} md={12} spacing={1}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="billingFirstName"
                                            name="billingFirstName"
                                            label="First Name *"
                                            className={classes.textField}
                                            value={values.billingFirstName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            error={errors.billingFirstName && touched.billingFirstName}
                                            helperText={errors.billingFirstName && touched.billingFirstName && errors.billingFirstName}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="billingLastName"
                                            name="billingLastName"
                                            label="Last Name *"
                                            className={classes.textField}
                                            value={values.billingLastName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            error={errors.billingLastName && touched.billingLastName}
                                            helperText={errors.billingLastName && touched.billingLastName && errors.billingLastName}
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={12} >
                                    <TextField
                                        id="billingJobRole"
                                        name="billingJobRole"
                                        label="Job Role *"
                                        className={classes.textField}
                                        value={values.billingJobRole}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        error={errors.billingJobRole && touched.billingJobRole}
                                        helperText={errors.billingJobRole && touched.billingJobRole && errors.billingJobRole}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid container item xs={12} md={12}>
                                    <TextField
                                        id="billingEmail"
                                        name="billingEmail"
                                        label="Billing Email Address *"
                                        className={classes.textField}
                                        value={values.billingEmail}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        error={errors.billingEmail && touched.billingEmail}
                                        helperText={errors.billingEmail && touched.billingEmail && errors.billingEmail}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid container item xs={12} md={12} display="block" marginTop={1.5}>
                                    <AddressAutocomplete
                                        id="address"
                                        name="address"
                                        label="Address"
                                        value={selectedLocation}
                                        error={errors.address && touched.address}
                                        helperText={errors.address && touched.address && errors.address}
                                        margin="normal"
                                        onChange={onAddressChange}
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <DialogActions className={classes.footer}>
                                {/* <Button
                                    type="reset"
                                    className="outline"
                                    onClick={resetForm}
                                    disabled={(!dirty && (selectedLocation?.label === address))}
                                >
                                    Reset
                                </Button> */}
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    type="submit"
                                    disabled={(!dirty && (selectedLocation?.label === address)) || !_.isEmpty(errors) || !selectedLocation}
                                >
                                    Save
                                </Button>
                            </DialogActions>
                        </Form>
                    );
                }}
            </Formik>
        </Grid>
    )
}
