import { verifyEmail } from "utils/stringUtils";

export const isAuthenticated = ({ login }) => !!login.me;

export const getProfile = ({ login }) => login.profile;

export const getisValidLoginData = ({ login }) =>
  verifyEmail(login.email);

export const getRoles = ({ login }) => login.roles;
export const getTimezoneList = ({ login }) => login.timezoneList;
export const getPermissions = ({ login }) => login.permissions;
export const getIsUsernameVerify = ({ login }) => login.isUsernameVerify;
export const getMe = ({ login }) => login.me;
// Loading selectors
export const getLoadingStateFactory = loader => state => {
  return state.login.loading[loader] || false;
};

export const getIsLoginLoading = getLoadingStateFactory("login");
export const getIsOtpLoading = getLoadingStateFactory("otp");
