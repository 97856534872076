import { gql } from "@apollo/client";

export default gql`
  mutation UpdateWorkerIdentityStatus($data: WorkerIdentityStatusInput!) {
    updateWorkerIdentityStatus(data: $data) {
      isApproved
      isActive
      approvedAt
    }
  }
`;
