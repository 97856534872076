import React, { useCallback, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import ReactTable from 'components/ReactTable';
import { sharedReactTableProps } from "../../../../utils/constant";
import { useDispatch, useSelector } from 'react-redux';
import { actions as customFieldActions, selectors as customFieldSelectors } from "store/modules/customFields";
import classNames from 'classnames';
import columns from "../helper/workerColumnProps";
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        width: "100%",
        overflow: "auto"
    },
    label: {
        width: 'fit-content',
        "& .MuiFormControlLabel-label": {
            color: theme.palette.text.primary
        }
    }
}));

export default function WorkerList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const workersData = useSelector((state) => customFieldSelectors.getDetailWorkerListData(state, { part: "data" }));
    const pageFilter = useSelector((state) => customFieldSelectors.getDetailWorkerListData(state, { part: "filter" }));
    const pageSort = useSelector((state) => customFieldSelectors.getDetailWorkerListData(state, { part: "sort" }));
    const PaginationData = useSelector(customFieldSelectors.getDetailWorkerPaginationData);
    const params = useParams();

    useEffect(() => {
        return () => {
            dispatch(customFieldActions.setDetailWorkerListSort([{ id: "id", desc: true }]));
            dispatch(customFieldActions.setDetailWorkerListFilter([]));
            dispatch(customFieldActions.setDetailWorkerListPageSize(10));
        };
    }, []);

    const fetchData = useCallback(({ pageSize, pageIndex, filters, sortBy }) => {
        dispatch(customFieldActions.setDetailWorkerListSort(sortBy));
        dispatch(customFieldActions.setDetailWorkerListFilter(filters));
        dispatch(customFieldActions.setDetailWorkerListPageSize(pageSize));
        dispatch(customFieldActions.fetchDetailsWorkerList(+params.id, pageIndex));
    }, []);

    return (
        <>
            <div className={classes.tableWrapper}>
                <ReactTable
                    {...sharedReactTableProps}
                    fetchData={fetchData}
                    data={workersData}
                    defaultFilter={pageFilter}
                    currentPage={PaginationData.currentPage}
                    defaultPageSize={PaginationData.pageSize}
                    loading={PaginationData.isLoading}
                    pageCount={PaginationData.totalCount}
                    defaultSort={pageSort}
                    columns={columns}
                    classes={classNames(sharedReactTableProps.classes, classes.table)}
                />
            </div>
        </>
    )
}
