import { BrokenImage, Close } from '@mui/icons-material'
import { Avatar, Dialog, IconButton, Slide } from '@mui/material'
import React, { useState } from 'react'
import { useLoaderStyels } from './styles'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction={props.in ? "up" : "down"} ref={ref} {...props} />;
});

export default React.memo(function ImageColumn({ src }) {
    const classes = useLoaderStyels();
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Avatar
                aria-describedby={src}
                onClick={handleClick}
                key={src}
                className={classes.root}
                src={src}
                variant="rounded">
                <div className={classes.fallback} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}>
                    <BrokenImage />
                </div>
            </Avatar>
            <Dialog
                TransitionComponent={Transition}
                open={open}
                onClose={handleClose}
                className={classes.imageViewer}
                scroll={"body"}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >

                <Avatar
                    aria-describedby={src}
                    src={src}
                    style={{
                        margin: 0,
                        minHeight: 56,
                        minWidth: 56,
                        height: "auto",
                        width: "auto",
                        maxHeight: "auto",
                        maxWidth: "auto"
                    }}
                    variant="square" />
                <IconButton
                    size="small"
                    onClick={handleClose}
                    color="error"
                    className={classes.closePreviewButton}>
                    <Close />
                </IconButton>
            </Dialog>
        </>
    )
}, (p, c) => p.src && c.src && p.src === c.src);
