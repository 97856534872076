// This is a custom filter UI for selecting
// a unique option from a list
import React from 'react';
import { AdapterMoment as MomentUtils } from '@mui/x-date-pickers/AdapterMoment';
import classNames from 'classnames';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { withStyles } from '@mui/styles';
import { IconButton, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Close } from '@mui/icons-material';
const styles = () => ({
    customClass: {
        '& > .MuiFormHelperText-root': {
            display: 'none'
        }
    }
});
function DatePickerComponents({
    // eslint-disable-next-line react/prop-types
    column: {
        filterValue, setFilter
    },
    // eslint-disable-next-line react/prop-types
    classes
}) {
    return (
        <LocalizationProvider dateAdapter={MomentUtils}>
            <DatePicker
                className={classNames(classes.customClass, 'filter-textfield')}
                inputFormat="dddd Do MMM, YYYY"
                inputVariant="standard"
                renderInput={props => <TextField {...props}
                    variant="standard"
                    InputProps={{
                        ...props.InputProps,
                        disableUnderline: false,
                        placeholder: "",
                        endAdornment: (
                            <React.Fragment>
                                {filterValue ? <IconButton onClick={() => setFilter(undefined)} size="small"><Close size="small" /></IconButton> : null}
                                {props.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    inputProps={{
                        ...props.inputProps,
                        placeholder: ""
                    }}
                    placeholder="" />}
                size="small"
                value={filterValue || null}
                onChange={newValue => {
                    if (newValue) {
                        setFilter(moment({
                            year: newValue.year(),
                            month: newValue.month(),
                            date: newValue.date(),
                            hour: 0,
                            minute: 0
                        }).format("YYYY-MM-DD"));
                    } else {
                        setFilter(undefined);
                    }
                }}
            />
        </LocalizationProvider>
    );
}

export default withStyles(styles)(DatePickerComponents);
