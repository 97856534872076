import ApolloClient from "utils/apollo";
import types from "./types";
import { actions as MetaActions } from "store/modules/meta";
import { DocumentsQuery } from "./graphql/queries/index";
import AddDocumentMutation from "./graphql/mutations/add-document";
import DeleteDocumentMutation from "./graphql/mutations/delete-document";
import UpdateDocumentMutation from "./graphql/mutations/update-document";

const setLoadingState = key => value => ({
    type: types.SET_LOADING_STATE,
    payload: { key, value },
});

const setIsTableLoading = setLoadingState("tableLoading");
const setIsFormActionLoading = setLoadingState("formDialogLoading");
const setIsDeleteLoading = setLoadingState("deleteLoading");

const setDialogState = key => value => ({
    type: types.SET_DIALOG_STATE,
    payload: { key, value },
});


export const setIsAddProvinceDocumentsDialog = setDialogState("addProvinceDocumentDialog");
export const setIsDeleteProvinceDocumentsDialog = setDialogState("deleteProvinceDocumentDialog");
export const setIsEditProvinceDocumentsDialog = setDialogState("editProvinceDocumentDialog");

const setProvinceDocumentForm = key => value => ({
    type: types.UPDATE_PROVINCE_DOCUMENT_FORM,
    payload: { key, value },
});

export const setProvinceDocumentFormData = (key, value) => ({
    type: types.UPDATE_PROVINCE_DOCUMENT_FORM,
    payload: { key, value },
});
export const setProvinceDocumentId = setProvinceDocumentForm("id");
export const setProvinceDocumentName = setProvinceDocumentForm("name");
export const setFormDocument = setProvinceDocumentForm("document");
export const setProvinceDocumentHelpText = setProvinceDocumentForm("helpText");
export const setFormProvinces = setProvinceDocumentForm("province");

export const resetForm = () => ({
    type: types.RESET_FORM,
    payload: {}
});

const setProvinceDocuments = documents => ({
    type: types.SET_PROVINCE_DOCUMENTS_DATA,
    payload: { documents }
});

export const setEditData = (data) => (dispatch) => {
    dispatch(setIsEditProvinceDocumentsDialog(true));
    dispatch(setProvinceDocumentId(data.id));
    dispatch(setProvinceDocumentName(data.documentName));
    dispatch(setFormDocument(data.signedDocumentImageUrl));
    dispatch(setProvinceDocumentHelpText(data.helpText));
    dispatch(setFormProvinces({ value: data?.region?.id, label: data?.region?.name, data: data?.region }));
};

export const fetchProvinceDocuments = () => (dispatch, getState) => {
    dispatch(setIsTableLoading(true));
    return ApolloClient.query({
        query: DocumentsQuery,
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            else {
                dispatch(setIsTableLoading(false));
                dispatch(setProvinceDocuments(data.getDocumentList));
            }
        })
        .catch(e => {
            dispatch(setIsTableLoading(false));
            dispatch(MetaActions.errorToast(`Could not find data: ${e.message}`));
        });
};

export const addProvinceDocument = () => (dispatch, getState) => {
    const state = getState();
    const formData = state.provinceDocuments.documentForm;
    const query = formData.id ? UpdateDocumentMutation : AddDocumentMutation;

    const data = {
        ...(formData.id ? { id: formData.id } : {}),
        documentName: formData.name,
        ...((typeof formData.document === "string") ? {} : { documentFile: formData.document }),
        helpText: formData.helpText,
        provinceId: formData.province?.data?.id
    };

    dispatch(setIsFormActionLoading(true));
    return ApolloClient.mutate({
        mutation: query,
        variables: { data },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            dispatch(fetchProvinceDocuments());
            dispatch(setIsAddProvinceDocumentsDialog(false));
            dispatch(setIsEditProvinceDocumentsDialog(false));
            dispatch(setIsFormActionLoading(false));
            dispatch(resetForm());
            if (formData.id)
                dispatch(MetaActions.successToast(`Document updated successfully`));
            else
                dispatch(MetaActions.successToast("Document has been created."));
            return data;
        }).catch((e) => {
            dispatch(MetaActions.errorToast(e.message));
            dispatch(setIsFormActionLoading(false));
            // dispatch(setIsAddProvinceDocumentsDialog(false))
        });
};


export const deleteProvinceDocument = (id) => (dispatch) => {
    const deleteAgreementDocumentId = parseInt(id);
    dispatch(setIsDeleteLoading(true))
    return ApolloClient.mutate({
        mutation: DeleteDocumentMutation,
        variables: { deleteAgreementDocumentId }
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            } else {
                dispatch(setIsDeleteLoading(false))
                dispatch(setIsDeleteProvinceDocumentsDialog(false))
                dispatch(fetchProvinceDocuments());
                dispatch(MetaActions.successToast("Document has been deleted."));
            }
        })
        .catch(e => {
            // dispatch(setIsDeleteProvinceDocumentsDialog(false))
            dispatch(setIsDeleteLoading(false))
            dispatch(
                MetaActions.errorToast(`${e.message}`),
            );
        });
};
