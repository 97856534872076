import React, { useState } from "react";

import { useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { AccountBalanceWalletTwoTone, CancelScheduleSend, Receipt } from "@mui/icons-material";
import MoreVert from "@mui/icons-material/MoreVert";
import SettingsBackupRestore from "@mui/icons-material/SettingsBackupRestore";
import { subTransfersActionsStyles } from "views/Finance/helpers";

function SubTransfersActions({
  payment,
  handleClickOpenResendDialog,
  handleClickOpenCancelDialog,
  handleViewProfile,
  handleClickOpenResolveDialog,

  toggleOpenDcReversal,
}) {
  const classes = subTransfersActionsStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const menuId = `action-menu-`;
  const openMenu = e => {
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const viewJob = jobId => history.push(`/jobs/${jobId}`);

  return (
    <>
      <IconButton size="small" aria-controls={menuId} aria-haspopup="true" onClick={openMenu}>
        <MoreVert color="primary" />
      </IconButton>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}>
        <MenuItem
          onClick={() => {
            handleViewProfile(payment.workerId);
            closeMenu();
          }}>
          <Receipt className={classes.icon} color="action" />
          View Profile
        </MenuItem>

        <MenuItem
          onClick={() => {
            viewJob(payment.job.id);
            closeMenu();
          }}>
          <Receipt className={classes.icon} color="action" />
          View Job
        </MenuItem>

        <MenuItem
          disabled={![3, 7, 8].includes(payment.paymentStatusId)}
          onClick={() => {
            handleClickOpenCancelDialog(payment);
            closeMenu();
          }}>
          <CancelScheduleSend className={classes.icon} color="action" />
          Cancel eTransfer
        </MenuItem>

        <MenuItem
          disabled={![11, 7].includes(payment.paymentStatusId)}
          onClick={() => {
            handleClickOpenResendDialog(payment);
            closeMenu();
          }}>
          <AccountBalanceWalletTwoTone className={classes.icon} color="action" />
          Resend eTransfer
        </MenuItem>

        <MenuItem
          disabled={![5].includes(payment.paymentStatusId)}
          onClick={() => {
            toggleOpenDcReversal(payment);
            closeMenu();
          }}>
          <SettingsBackupRestore className={classes.icon} color="action" />
          Request Reversal
        </MenuItem>

        {/* <MenuItem
          disabled={![5, 3, 1, 14, 11, 7].includes(payment.paymentStatusId)}
          onClick={() => {
            handleClickOpenResolveDialog(payment);
            closeMenu();
          }}>
          <AccountBalanceWalletTwoTone className={classes.icon} color="action" />
          Resolve eTransfer
        </MenuItem> */}
      </Menu>
    </>
  );
}

export default SubTransfersActions;
