import React, { useEffect, useRef, useState } from "react";
import CurrencyFormat from "react-currency-format";
import PropTypes from "prop-types";
import CurrencyTextField from "./CurrencyTextField";
import { isNaN } from "lodash";

function CurrencyInput({
    label,
    minValue,
    maxValue,
    value,
    onChange,
    maxValueHelperText,
    minValueHelperText,
    type,
    errorMessage
}) {
    const inputEl = useRef(null);
    function handleChange({ floatValue }) {
        onChange(floatValue);
    }

    const handleKeyDown = e => {
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            e.preventDefault();
            const change = e.key === "ArrowUp" ? 0.5 : -0.5;
            const newValue = +(parseFloat(value) + change).toFixed(2);
            onChange(newValue);
        }
    };

    return <CurrencyFormat
        ref={inputEl}
        errorMessage={errorMessage}
        type={type}
        label={label}
        minValue={minValue}
        maxValue={maxValue}
        minValueHelperText={minValueHelperText}
        maxValueHelperText={maxValueHelperText}
        isNumericString
        allowNegative
        decimalScale={2}
        fixedDecimalScale
        customInput={CurrencyTextField}
        value={!isNaN(value) ? value : 0}
        onValueChange={handleChange}
        onKeyDown={handleKeyDown}
    />
}

CurrencyInput.propTypes = {
    minValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxValue: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    tooltip: PropTypes.string,
    label: PropTypes.string,
    minValueHelperText: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

CurrencyInput.defaultProps = {
    maxValue: 100,
    tooltip: null,
    label: null,
};

export default CurrencyInput;
