import gql from "graphql-tag";

export const holidayQuery = gql`
query Holiday {
    holiday {
      id
      createdAt
      updatedAt
      holidayDate
      name
      description
      provinceHoliday {
        id
        createdAt
        provinceId
        holidayId
        holiday {
          id
          createdAt
          updatedAt
          holidayDate
          name
          description
        }
        province {
          id
          name
          code
          isActive
          countryId
        }
      }
    }
  }
`;

export const holidayByIdQuery = gql`
query HolidayById($holidayById: ID!) {
    holidayById(id: $holidayById) {
      id
      createdAt
      updatedAt
      holidayDate
      name
      description
      provinceHoliday {
        id
        createdAt
        provinceId
        holidayId
        holiday {
          id
          createdAt
          updatedAt
          holidayDate
          name
          description
        }
        province {
          id
          name
          code
          isActive
          countryId
        }
      }
    }
  }
`;
