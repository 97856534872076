import React from 'react'
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ListItemIcon, Typography } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { actions as EmployerActions } from "store/modules/employerDetails";

export default function DynamicWorkTypeAction({ setTab, dynamicWorkTypeRate, ...props }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        dispatch(EmployerActions.setRemoveDynamicWorkTypeRateDialog(true));
        dispatch(EmployerActions.setSelectedDynamicWorkTypeRate(dynamicWorkTypeRate));
        handleClose();
    };

    const handleEdit = async () => {
        await dispatch(EmployerActions.setSelectedDynamicWorkTypeRate(dynamicWorkTypeRate));
        await dispatch(EmployerActions.setEditDynamicWorkTypeRateForm(dynamicWorkTypeRate));
        await dispatch(EmployerActions.setNewDynamicWorkTypeRateDialog(true));
        handleClose();
        setTab && setTab(1);
    };


    return (
        <>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleEdit}>
                    <ListItemIcon>
                        <Edit fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Edit</Typography>
                </MenuItem>
                <MenuItem onClick={handleDelete}>
                    <ListItemIcon>
                        <Delete fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Delete</Typography>
                </MenuItem>
            </Menu>
        </>
    )
}
