import { gql } from "@apollo/client";

export default gql`
  fragment PositionFields on Position {
    id
    isPayable
    isEditable
    payrollStatus
    isDeletable
    checkedInBy
    checkedOutBy
    addedBy
    positionDocument {
        agreementDocument {
          id
          documentName
          documentUrl
          provinceId
          helpText
          region {
            name
            id
            code
            isActive
          }
          documentType
          signedDocumentImageUrl
        }
        id
        documentId
        positionId
        documentStatus
    }
    payroll {
      id
      createdAt
      payrollTypeId
      positionId
      taxRegionId
      employmentStandardsRegionId
      rate
      checkIn
      checkOut
      breakDeductionMins
      bonus
      totalUnits
      payableUnits
      payrollType {
        id
        name
        code
      }
      payments {
        paymentStatus {
          id
          code
          description
        }
        id
        createdAt
        sentAt
        responseAt
        workerId
        payrollId
        positionId
        # paymentBatchId
        retryPaymentId
        paymentStatusId
        # dcTransferId
        paymentProviderReferenceCode
        email
        securityQuestion
        securityAnswer
        # amount
        errorCode
        errorDescription
        notes
      }
      earnings {
        id
        payrollId
        payrollEarningsTypeId
        rate
        units
        amount
      }
      wageEarnings {
        id
        payrollId
        payrollEarningsTypeId
        rate
        units
        amount
      }
      additionalEarnings {
        id
        payrollId
        payrollEarningsTypeId
        rate
        units
        amount
      }
      employeeDeductions {
        id
        payrollId
        amount
        payrollDeductionsTypeId
      }
      employerDeductions {
        id
        payrollId
        amount
        payrollDeductionsTypeId
      }
      totalEmployeeDeductions
      totalEmployerDeductions
      grossWages
      grossEarnings
      netEarnings
    }
    createdAt
    billingRate
    bonus
    payRate
    createdAtUtc
    employerRating
    workerRating
    startShift
    endShift
    startShiftUtc
    endShiftUtc
    workerId
    defaultWorkerRating
    breakMins
    durationMins
    isConfirmed
    confirmedAt
    confirmedAtUtc
    isReconfirmed
    reconfirmedAt
    reconfirmedAtUtc
  }
`;
