import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import ReactTable from 'components/ReactTable';
import { sharedReactTableProps } from "../../../../utils/constant";
import { useDispatch, useSelector } from 'react-redux';
import { actions as customFieldActions, selectors as customFieldSelectors } from "store/modules/customFields";
import classNames from 'classnames';
import FadeHide from 'components/Transitions/FadeHide';
import columns from "../helper/workerPickerTableProps";

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        width: "100%",
        overflow: "auto"
    },
    label: {
        width: 'fit-content',
        "& .MuiFormControlLabel-label": {
            color: theme.palette.text.primary
        }
    }
}));

export default function WorkerPicker({ customFieldId, isShowWorkerPicker = false, ...props }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const workersData = useSelector((state) => customFieldSelectors.getWorkerListData(state, { part: "data" }));
    const pageFilter = useSelector((state) => customFieldSelectors.getWorkerListData(state, { part: "filter" }));
    const pageSort = useSelector((state) => customFieldSelectors.getWorkerListData(state, { part: "sort" }));
    const PaginationData = useSelector(customFieldSelectors.getWorkerPaginationData);

    const fetchData = useCallback(({ pageSize, pageIndex, filters, sortBy }) => {
        dispatch(customFieldActions.setWorkerListSort(sortBy));
        dispatch(customFieldActions.setWorkerListFilter(filters));
        dispatch(customFieldActions.setWorkerListPageSize(pageSize));
        dispatch(customFieldActions.fetchWorkersList(pageIndex));
    }, []);


    useEffect(() => {
        return () => {
            dispatch(customFieldActions.clearWorkerPickerFilters());
        };
    }, [])

    return (
        <>
            <FadeHide in={isShowWorkerPicker}>
                <div className={classes.tableWrapper}>
                    <ReactTable
                        {...sharedReactTableProps}
                        fetchData={fetchData}
                        data={workersData}
                        defaultFilter={pageFilter}
                        currentPage={PaginationData.currentPage}
                        defaultPageSize={PaginationData.pageSize}
                        loading={PaginationData.isLoading}
                        pageCount={PaginationData.totalCount}
                        defaultSort={pageSort}
                        columns={columns(customFieldId)}
                        classes={classNames(sharedReactTableProps.classes, classes.table)}
                    />
                </div>
            </FadeHide>
        </>
    )
}
