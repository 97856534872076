import gql from "graphql-tag";

export default gql`
mutation UpdateHoliday($data: UpdateHolidayInput!) {
    updateHoliday(data: $data) {
      id
      createdAt
      updatedAt
      holidayDate
      name
      description
      provinceHoliday {
        id
        createdAt
        provinceId
        holidayId
        province {
          id
          name
          code
          isActive
          countryId
        }
      }
    }
  }
`;
