import { Alert, Avatar, Collapse, Grid, IconButton, TextField, Typography } from '@mui/material'
import Dialog from 'components/Dialog/Dialog'
import React, { useEffect, useState } from 'react'
import Dropzone from "components/Dropzone/Dropzone";
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { actions as ProvinceDocumentsActions, selectors as ProvinceDocumentsSelectors } from "store/modules/provinceDocuments";
import { actions as MetaActions } from "store/modules/meta";
import { allowedProvinceDocumentsTypes, isValidWorkerUploadDocuments } from 'utils/file';
import Button from 'components/Button/Button';
import ProvinceAutocomplete from 'components/Autocomplete/ProvinceAutocomplete';
import { TransitionGroup } from 'react-transition-group';
import { Close, Description, Visibility } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
    container: {
        width: 450,
        maxWidth: "100%",
        marginTop: theme.spacing(2)
    },
    documents: {
        color: theme.palette.secondary.main,
        fontSize: "1rem",
    },
    document: {
        borderColor: "#c4c4c4",
        padding: theme.spacing(0, 2, 0, 1),
        color: theme.palette.text.primary,
        "& .MuiAlert-action": {
            alignItems: "center",
            marginTop: 0,
            paddingTop: 0
        }
    },
    selectedDocument: {
        marginTop: theme.spacing(1.5),
        textAlign: "left",
    },
    actionContainer: {
        display: "flex",
        marginTop: theme.spacing(3),
        justifyContent: "center",
        alignItems: "center"
    }
}));

export default function AddDocumentDialog(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isAdd = useSelector(ProvinceDocumentsSelectors.getIsAddProvinceDocumentDialog);
    const isEdit = useSelector(ProvinceDocumentsSelectors.getIsEditProvinceDocumentDialog);
    const openDialog = isAdd || isEdit;
    const documentForm = useSelector(ProvinceDocumentsSelectors.getProvinceDocumentsForm);
    const canAddDocument = useSelector(ProvinceDocumentsSelectors.canAddProvinceDocument);
    const isLoading = useSelector(ProvinceDocumentsSelectors.getIsFormActionLoading);
    const [documentPreview, setDocumentPreview] = useState(null);

    useEffect(() => {
        // Cleanup function
        () => {
            setDocumentPreview(null);
            URL.revokeObjectURL(documentPreview);
        }
    }, []);

    const handleClose = () => {
        dispatch(ProvinceDocumentsActions.setIsAddProvinceDocumentsDialog(false))
        dispatch(ProvinceDocumentsActions.setIsEditProvinceDocumentsDialog(false))
        dispatch(ProvinceDocumentsActions.resetForm());
    };

    const handleConfirm = () => {
        dispatch(ProvinceDocumentsActions.addProvinceDocument(documentForm.id));
    };

    const onFormDataChange = e => {
        dispatch(ProvinceDocumentsActions.setProvinceDocumentFormData(e.target.name, e.target.value))
    };

    const onUploadDocumentChange = document => {
        if (!document || (document && isValidWorkerUploadDocuments([document]))) {
            let item = document;
            if (document) {
                item = new Blob([document], { type: document.type });
                item.name = `Document_${document.name}`;
                const url = URL.createObjectURL(document);
                setDocumentPreview(url)
            }
            dispatch(ProvinceDocumentsActions.setFormDocument(item))
        } else {
            dispatch(MetaActions.errorToast("Selected document type is not supported"));
        }
    };


    const onChangeProviences = value => {
        dispatch(ProvinceDocumentsActions.setFormProvinces(value))
    };

    return (
        <Dialog
            open={openDialog}
            title={`${isEdit ? "Update" : "Add"} Document`}
            description={isEdit ? "Update existing document" : `Add a new document.`}
            confirmText={isEdit ? "Update" : "Add"}
            disabled={isLoading || !canAddDocument}
            onClose={handleClose}
            icon="upload_file"
            hideActions
            modalType="editEmployer"
            alertType="question">
            <div className={classes.container}>
                <TextField
                    fullWidth={true}
                    value={documentForm?.name}
                    onChange={onFormDataChange}
                    margin="normal"
                    name="name"
                    placeholder="Document Name..."
                    label="Document Name"
                    error={documentForm?.name?.length > 50}
                    helperText={documentForm?.name?.length > 50 ? "Document name shoud must be less 50 characters" : ""}
                />
                <TextField
                    fullWidth={true}
                    value={documentForm?.helpText}
                    onChange={onFormDataChange}
                    margin="normal"
                    name="helpText"
                    placeholder="Help text..."
                    label="Help text"
                    error={documentForm?.helpText?.length > 50}
                    helperText={documentForm?.helpText?.length > 50 ? "Document help text shoud must be less 50 characters" : ""}

                />

                <div style={{ position: "relative", zIndex: 9999, marginTop: 28 }}>
                    <ProvinceAutocomplete
                        value={documentForm?.province}
                        menuPortalTarget={document.body}
                        onChange={onChangeProviences}
                    />
                </div>
                <TransitionGroup>
                    {documentForm?.document ?
                        <Collapse key={documentForm?.document?.name}>
                            <div className={classes.selectedDocument}>
                                <Typography>Selected Document</Typography>
                                <Grid container alignItems={"center"} border={1} borderColor="rgba(0, 0, 0, 0.23)" borderRadius={1} overflow="hidden">
                                    <Grid xs={10} display="flex" alignItems={"center"} overflow="hidden">
                                        <Avatar src={documentForm?.document?.name ? documentPreview : documentForm?.document} variant="square" sx={{ bgcolor: "grey.400", marginRight: 1 }}>
                                            <Description />
                                        </Avatar>
                                        <Typography variant="body2" whiteSpace={"nowrap"} overflow="hidden" textOverflow={"ellipsis"}>
                                            {(isEdit && !documentForm?.document?.name) ? documentForm?.document : documentForm?.document?.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton size="small" href={documentForm?.document?.name ? documentPreview : documentForm?.document} target="_blank">
                                            <Visibility fontSize='small' />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={1} paddingRight={1}>
                                        <IconButton size="small" onClick={() => onUploadDocumentChange(null)}>
                                            <Close fontSize='small' />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </div>
                        </Collapse>
                        :
                        <Collapse key={"upload-document"}>
                            <Dropzone
                                supportedFiles={"Supported files image, pdf and doc"}
                                accept={allowedProvinceDocumentsTypes?.map?.(a => a.mime)?.join(",")}
                                onFilesAdded={(files) => onUploadDocumentChange(files[0])}
                            />
                        </Collapse>
                    }
                </TransitionGroup>
                <div className={classes.actionContainer}>
                    <Button label={"Cancel"} onClick={handleClose} color="transparent" />
                    <Button
                        isLoading={isLoading}
                        label={isEdit ? "Update" : "Add"}
                        onClick={handleConfirm}
                        color="secondary"
                        disabled={isLoading || !canAddDocument}
                    />
                </div>
            </div>
        </Dialog>
    )
};
