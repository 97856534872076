import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as customFieldActions, selectors as customFieldSelectors } from "store/modules/customFields";
import { sharedReactTableProps } from "../../../../utils/constant";
import { Link, useHistory } from "react-router-dom";
import ReactTable from "components/ReactTable";
import classNames from "classnames";
import { useStyles } from "views/Reports/styles";
import columns from "../helper/customFieldTableProps";

function CustomFieldTable() {
    const history = useHistory();
    const dispatch = useDispatch();
    const customFieldsData = useSelector((state) => customFieldSelectors.getCustomFieldsData(state, { part: "data" }));
    const pageFilter = useSelector((state) => customFieldSelectors.getCustomFieldsData(state, { part: "filter" }));
    const pageSort = useSelector((state) => customFieldSelectors.getCustomFieldsData(state, { part: "sort" }));
    const PaginationData = useSelector(customFieldSelectors.getCustomFieldListPagingData);
    const classes = useStyles();

    const fetchData = useCallback(({ pageIndex, pageSize, filters, sortBy }) => {
        dispatch(customFieldActions.setCustomFieldListSort(sortBy));
        dispatch(customFieldActions.setCustomFieldListFilter(filters));
        dispatch(customFieldActions.changeCustomFieldListPageSize(pageSize));
        dispatch(customFieldActions.fetchCustomFieldsTableData(pageIndex));
    }, []);

    const onRowClick = (row, cell, event) => {
        dispatch(customFieldActions.setIsOpenAddUserCustomField(false));
        history.push(`/custom-field/detail/${row.original.id}`);
    };

    return (
        <React.Fragment>
            <ReactTable
                {...sharedReactTableProps}
                fetchData={fetchData}
                data={customFieldsData}
                loading={PaginationData.isLoading}
                pageCount={PaginationData.totalCount}
                currentPage={PaginationData.currentPage}
                defaultPageSize={PaginationData.pageSize}
                columns={columns}
                defaultFilter={pageFilter}
                defaultSort={pageSort}
                onRowClick={onRowClick}
                classes={classNames(sharedReactTableProps.classes, classes.table)}
            />
        </React.Fragment>
    );
}

export default CustomFieldTable;
