import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import makeStyles from '@mui/styles/makeStyles';
import MoreVert from "@mui/icons-material/MoreVert";
import { AccountBalanceWalletTwoTone, CancelScheduleSend, PictureAsPdf, Receipt, SettingsBackupRestore } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { actions as holidayActions } from "store/modules/holidays";

const useStyles = makeStyles(({ spacing, typography }) => ({
    icon: {
        fontSize: typography.pxToRem(20),
        marginRight: spacing(2),
    },
}));

export default function PaymentResolverTableMenu({ payment, worker, props }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();

    const openMenu = e => {
        setAnchorEl(e.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const handleResolveReversal = () => {
        dispatch(holidayActions.setIsResolveReversalDialog({ payment, worker }));
        closeMenu();
    };

    const handleResendReversal = () => {
        dispatch(holidayActions.setIsResendReversalDialog({ payment, worker }));
        closeMenu();
    };

    const handleCancelReversal = () => {
        dispatch(holidayActions.setIsCancelReversalDialog({ payment, worker }));
        closeMenu();
    };

    return (
        <>
            <IconButton
                aria-controls={"menuId"}
                aria-haspopup="true"
                onClick={openMenu}
                disableRipple
                style={{ marginRight: 8, float: "right" }}
                edge="start"
                size="small">
                <MoreVert color="primary" />
            </IconButton>
            <Menu
                id={"menuId"}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}>

                <MenuItem
                    disabled={![5, 3, 1, 14, 11, 7].includes(payment.paymentStatusId)}
                    onClick={handleResolveReversal}>
                    <AccountBalanceWalletTwoTone className={classes.icon} color="action" />
                    Resolve Reversal
                </MenuItem>

                <MenuItem
                    disabled={![7, 8, 10, 11, 13].includes(payment.paymentStatusId)}
                    onClick={handleResendReversal}>
                    <Receipt className={classes.icon} color="action" />
                    Resend Reversal
                </MenuItem>

                <MenuItem
                    hidden
                    disabled={[13, 11, 7, 8].includes(payment.paymentStatusId)}
                    onClick={handleCancelReversal}>
                    <Receipt className={classes.icon} color="action" />
                    Cancel Reversal
                </MenuItem>
            </Menu>
        </>
    )
}
