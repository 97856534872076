import gql from "graphql-tag";

export const gearsQuery = gql`
query{
    gears{
      id
      gearName
      frenchGearName
      isActive
      createdAt
    }
  }
`;
