import { JobPermissions } from "helpers/constants/permissions";
import ApolloClient from "utils/apollo";
import { applyActionPermissions, hasPermission } from "utils/authorize";
import { flattenGraphQLArray } from "utils/graphql";
import * as pagination from "utils/graphql-pagination";
import { getRelation } from "utils/redux";
import { getTimeZone } from "utils/time";
import { actions as MetaActions } from "store/modules/meta";

import { paginationSettings } from "./constants";
import FetchJobListStats from "./graphql/queries/fetch-job-list-stats";
import FetchImportedPrivateJobList from "./graphql/queries/fetch-imported-private-job";
import FetchImportedPrivateJobDetailById from "./graphql/queries/fetch-imported-private-job-by-id";
import FetchPrivateJobPositionsById from "./graphql/queries/fetch-private-job-positions";
import ImportPrivateJobCSV from "./graphql/mutations/import-private-job-csv";
import FillPositionByPrivateMutation from "./graphql/mutations/fill-position-private-job";
import RemovePositionByPrivateMutation from "./graphql/mutations/remove-position-private-job";
import UpdateImportedPrivateMutation from "./graphql/mutations/update-imported-private-job";
import FetchPrivateJobSampleCSVQuery from "./graphql/queries/fetch-private-job-sample-csv";
import FetchJobTimeframeStats from "./graphql/queries/fetch-job-timeframe-stats";
import FetchJobsQuery from "./graphql/queries/fetch-jobs";
import {
    getDraftsPaginationData,
    getHistoryPaginationData,
    getListData,
    getPrivateJobPaginationData,
    getQueuedPaginationData,
    getTodayActivePaginationData,
    getTodayCompletedPaginationData,
    getTodayFilledPaginationData,
    getTodayTotalPaginationData,
    getTodayUnfilledPaginationData,
    getTomorrowFilledPaginationData,
    getTomorrowTotalPaginationData,
    getTomorrowUnfilledPaginationData,
    getUpcomingFilledPaginationData,
    getUpcomingTotalPaginationData,
    getUpcomingUnfilledPaginationData,
} from "./selectors";
import types from "./types";
import { find, remove } from "lodash";

const setLoadingState = key => value => ({
    type: types.SET_LOADING_STATE,
    payload: { key, value },
});
export const setPrivateJobLoading = setLoadingState("privateJob");
export const setImportPrivateJobLoading = setLoadingState("importPrivateJob");
export const setFillPositionLoading = setLoadingState("fillPosition");
export const openEditPrivateJobDialog = setLoadingState("editPrivateJobDialog");
export const openDeletePrivateJobPositionDialog = setLoadingState("deletePrivateJobPositionDialog");
export const openPrivateJobFillPositionDialog = setLoadingState("privateJobFillPositionDialog");
export const openUploadPrivateJobCSVDialog = setLoadingState("uploadPrivateJobCSVDialog");

export const fetchTimeframeStats = () => dispatch => {
    if (!hasPermission(JobPermissions.read.list.value)) {
        return Promise.resolve();
    }
    const isFixedTerm = window?.location?.pathname?.includes("multi-shift");

    dispatch({ type: types.FETCH_TIMEFRAME_STATS_REQUEST });
    return ApolloClient.query({
        query: FetchJobTimeframeStats,
        variables: {
            ...applyActionPermissions(JobPermissions.read.list.value),
            timezone: getTimeZone(),
            isFixedTerm,
        },
    })
        .then(({ data }) => {
            const payload = {
                today: data.todayCount.totalCount,
                tomorrow: data.tomorrowCount.totalCount,
                upcoming: data.upcomingCount.totalCount,
                drafts: data.draftCount.totalCount,
                queued: data.queueCount.totalCount,
            };
            dispatch({ type: types.FETCH_TIMEFRAME_STATS_SUCCESS, payload });
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(`Could not get timeframe stats: ${e.message}`));
            dispatch({ type: types.FETCH_TIMEFRAME_STATS_FAILURE, payload: e });
        });
};

export const fetchListStats = timeframe => dispatch => {
    if (!hasPermission(JobPermissions.read.list.value)) {
        return Promise.resolve();
    }

    const isFixedTerm = window?.location?.pathname?.includes("multi-shift");
    dispatch({ type: types.FETCH_LIST_STATS_REQUEST });
    return ApolloClient.query({
        query: FetchJobListStats,
        variables: {
            ...applyActionPermissions(JobPermissions.read.list.value),
            timeframe,
            timezone: getTimeZone(),
            isFixedTerm,
        },
    })
        .then(({ data }) => {
            dispatch({
                type: types.FETCH_LIST_STATS_SUCCESS,
                payload: { timeframe: timeframe.toLowerCase(), data },
            });
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(`Could not get list stats state: ${e.message}`));
            dispatch({ type: types.FETCH_LIST_STATS_FAILURE, payload: e });
        });
};

export const fetchJobsListFactory = config => pageIndex => (dispatch, getState) => {
    const isFixedTerm = window?.location?.pathname?.includes("multi-shift");
    if (!hasPermission(JobPermissions.read.list.value)) {
        return Promise.resolve();
    }

    const state = getState();
    const treeConfig = {
        timeframe: config.timeframe,
        status: config.status,
    };
    const pageInfo = config.getPageInfo(state);
    const pagingVars = dispatch(config.getPagingVars(pageInfo, pageIndex));
    const relations = {
        Employer: ["companyName"],
    };
    const extraVars = config.variables || {};
    const filterInfo = JSON.parse(JSON.stringify(getListData(state, { ...treeConfig, part: "filter" })));
    const tags = find(filterInfo, { id: "tags" });
    const filters = {};
    if (tags) {
        filters.tags = tags.value;
        remove(filterInfo, { id: "tags" });
    }

    return ApolloClient.query({
        query: config.query,
        variables: {
            ...pagingVars,
            ...extraVars,
            ...filters,
            isFixedTerm,
            order: getListData(state, { ...treeConfig, part: "sort" }).map(sort => {
                return {
                    field: sort.id,
                    direction: sort.desc ? "DESC" : "ASC",
                    relation: getRelation(relations, sort.id),
                };
            }),
            like: filterInfo.map(filter => {
                return {
                    field: filter.id,
                    value: filter.value,
                    relation: getRelation(relations, filter.id),
                };
            }),
            ...applyActionPermissions(JobPermissions.read.list.value),
        },
    })
        .then(({ data }) => {
            const { jobs } = data;
            const paginationData = {
                ...jobs.pageInfo,
                totalCount: jobs.totalCount,
            };

            dispatch({
                type: types.SET_LIST_DATA,
                payload: {
                    data: flattenGraphQLArray(jobs),
                    timeframe: config.timeframe,
                    status: config.status,
                    part: "data",
                },
            });
            dispatch(pagination.updatePageInfo(config.paginationSetting, paginationData));
            dispatch(pagination.doneLoading(config.paginationSetting));
        })
        .catch(() => {
            dispatch(pagination.doneLoading(config.paginationSetting));
        });
};

export const setSortFactory = config => sort => dispatch => {
    dispatch({
        type: types.SET_LIST_DATA,
        payload: {
            data: sort,
            timeframe: config.timeframe,
            status: config.status,
            part: "sort",
        },
    });
    if (config.refresh)
        return dispatch(config.refresh());
};

export const setFilterFactory = config => filter => dispatch => {
    dispatch({
        type: types.SET_LIST_DATA,
        payload: {
            data: filter,
            timeframe: config.timeframe,
            status: config.status,
            part: "filter",
        },
    });
    if (config.refresh)
        return dispatch(config.refresh());
};

export const setPageSizeFactory = config => pageSize => dispatch => {
    dispatch(pagination.updatePageInfo(config.paginationSetting, { pageSize }));
    if (config.refresh)
        return dispatch(config.refresh());
};

export const fetchTodayTotalJobs = fetchJobsListFactory({
    getPageInfo: getTodayTotalPaginationData,
    getPagingVars: pagination.pagingVarsFactory(paginationSettings.todayTotal),
    query: FetchJobsQuery,
    variables: {
        timeframe: "Today",
        isActive: true,
        timezone: getTimeZone(),
    },
    timeframe: "today",
    status: "total",
    paginationSetting: paginationSettings.todayTotal,
});

export const setTodayTotalSort = setSortFactory({
    timeframe: "today",
    status: "total",
    // refresh: fetchTodayTotalJobs,
});

export const setTodayTotalFilter = setFilterFactory({
    timeframe: "today",
    status: "total",
    // refresh: fetchTodayTotalJobs,
});

export const setTodayTotalPageSize = setPageSizeFactory({
    paginationSetting: paginationSettings.todayTotal,
    // refresh: fetchTodayTotalJobs,
});

export const fetchTodayActiveJobs = fetchJobsListFactory({
    getPageInfo: getTodayActivePaginationData,
    getPagingVars: pagination.pagingVarsFactory(paginationSettings.todayActive),
    query: FetchJobsQuery,
    variables: {
        timeframe: "Today",
        status: "InProgress",
        isActive: true,
        timezone: getTimeZone(),
    },
    timeframe: "today",
    status: "active",
    paginationSetting: paginationSettings.todayActive,
});

export const setTodayActiveSort = setSortFactory({
    timeframe: "today",
    status: "active",
    // refresh: fetchTodayActiveJobs,
});

export const setTodayActiveFilter = setFilterFactory({
    timeframe: "today",
    status: "active",
    // refresh: fetchTodayActiveJobs,
});

export const setTodayActivePageSize = setPageSizeFactory({
    paginationSetting: paginationSettings.todayActive,
    // refresh: fetchTodayActiveJobs,
});

export const fetchTodayUnfilledJobs = fetchJobsListFactory({
    getPageInfo: getTodayUnfilledPaginationData,
    getPagingVars: pagination.pagingVarsFactory(paginationSettings.todayUnfilled),
    query: FetchJobsQuery,
    variables: {
        timeframe: "Today",
        status: "NotStarted",
        filled: false,
        timezone: getTimeZone(),
    },
    timeframe: "today",
    status: "unfilled",
    paginationSetting: paginationSettings.todayUnfilled,
});

export const setTodayUnfilledSort = setSortFactory({
    timeframe: "today",
    status: "unfilled",
    // refresh: fetchTodayUnfilledJobs,
});

export const setTodayUnfilledFilter = setFilterFactory({
    timeframe: "today",
    status: "unfilled",
    // refresh: fetchTodayUnfilledJobs,
});

export const setTodayUnfilledPageSize = setPageSizeFactory({
    paginationSetting: paginationSettings.todayUnfilled,
    // refresh: fetchTodayUnfilledJobs,
});

export const fetchTodayFilledJobs = fetchJobsListFactory({
    getPageInfo: getTodayFilledPaginationData,
    getPagingVars: pagination.pagingVarsFactory(paginationSettings.todayFilled),
    query: FetchJobsQuery,
    variables: {
        timeframe: "Today",
        status: "NotStarted",
        isActive: true,
        filled: true,
        timezone: getTimeZone(),
    },
    timeframe: "today",
    status: "filled",
    paginationSetting: paginationSettings.todayFilled,
});

export const setTodayFilledSort = setSortFactory({
    timeframe: "today",
    status: "filled",
    // refresh: fetchTodayFilledJobs,
});

export const setTodayFilledFilter = setFilterFactory({
    timeframe: "today",
    status: "filled",
    // refresh: fetchTodayFilledJobs,
});

export const setTodayFilledPageSize = setPageSizeFactory({
    paginationSetting: paginationSettings.todayFilled,
    // refresh: fetchTodayFilledJobs,
});

export const fetchTodayCompletedJobs = fetchJobsListFactory({
    getPageInfo: getTodayCompletedPaginationData,
    getPagingVars: pagination.pagingVarsFactory(paginationSettings.todayCompleted),
    query: FetchJobsQuery,
    variables: {
        timeframe: "Today",
        status: "Completed",
        isActive: true,
        timezone: getTimeZone(),
    },
    timeframe: "today",
    status: "completed",
    paginationSetting: paginationSettings.todayCompleted,
});

export const setTodayCompletedSort = setSortFactory({
    timeframe: "today",
    status: "completed",
    // refresh: fetchTodayCompletedJobs,
});

export const setTodayCompletedFilter = setFilterFactory({
    timeframe: "today",
    status: "completed",
    // refresh: fetchTodayCompletedJobs,
});

export const setTodayCompletedPageSize = setPageSizeFactory({
    paginationSetting: paginationSettings.todayCompleted,
    // refresh: fetchTodayCompletedJobs,
});

export const fetchTomorrowTotalJobs = fetchJobsListFactory({
    getPageInfo: getTomorrowTotalPaginationData,
    getPagingVars: pagination.pagingVarsFactory(paginationSettings.tomorrowTotal),
    query: FetchJobsQuery,
    variables: {
        timeframe: "Tomorrow",
        isActive: true,
        timezone: getTimeZone(),
    },
    timeframe: "tomorrow",
    status: "total",
    paginationSetting: paginationSettings.tomorrowTotal,
});

export const setTomorrowTotalSort = setSortFactory({
    timeframe: "tomorrow",
    status: "total",
    // refresh: fetchTomorrowTotalJobs,
});

export const setTomorrowTotalFilter = setFilterFactory({
    timeframe: "tomorrow",
    status: "total",
    // refresh: fetchTomorrowTotalJobs,
});

export const setTomorrowTotalPageSize = setPageSizeFactory({
    paginationSetting: paginationSettings.tomorrowTotal,
    // refresh: fetchTomorrowTotalJobs,
});

export const fetchTomorrowUnfilledJobs = fetchJobsListFactory({
    getPageInfo: getTomorrowUnfilledPaginationData,
    getPagingVars: pagination.pagingVarsFactory(paginationSettings.tomorrowUnfilled),
    query: FetchJobsQuery,
    variables: {
        timeframe: "Tomorrow",
        status: "NotStarted",
        isActive: true,
        filled: false,
        timezone: getTimeZone(),
    },
    timeframe: "tomorrow",
    status: "unfilled",
    paginationSetting: paginationSettings.tomorrowUnfilled,
});

export const setTomorrowUnfilledSort = setSortFactory({
    timeframe: "tomorrow",
    status: "unfilled",
    // refresh: fetchTomorrowUnfilledJobs,
});

export const setTomorrowUnfilledFilter = setFilterFactory({
    timeframe: "tomorrow",
    status: "unfilled",
    // refresh: fetchTomorrowUnfilledJobs,
});

export const setTomorrowUnfilledPageSize = setPageSizeFactory({
    paginationSetting: paginationSettings.tomorrowUnfilled,
    // refresh: fetchTomorrowUnfilledJobs,
});

export const fetchTomorrowFilledJobs = fetchJobsListFactory({
    getPageInfo: getTomorrowFilledPaginationData,
    getPagingVars: pagination.pagingVarsFactory(paginationSettings.tomorrowFilled),
    query: FetchJobsQuery,
    variables: {
        timeframe: "Tomorrow",
        status: "NotStarted",
        isActive: true,
        filled: true,
        timezone: getTimeZone(),
    },
    timeframe: "tomorrow",
    status: "filled",
    paginationSetting: paginationSettings.tomorrowFilled,
});

export const setTomorrowFilledSort = setSortFactory({
    timeframe: "tomorrow",
    status: "filled",
    // refresh: fetchTomorrowFilledJobs,
});

export const setTomorrowFilledFilter = setFilterFactory({
    timeframe: "tomorrow",
    status: "filled",
    // refresh: fetchTomorrowFilledJobs,
});

export const setTomorrowFilledPageSize = setPageSizeFactory({
    paginationSetting: paginationSettings.tomorrowFilled,
    // refresh: fetchTomorrowFilledJobs,
});

export const fetchUpcomingTotalJobs = fetchJobsListFactory({
    getPageInfo: getUpcomingTotalPaginationData,
    getPagingVars: pagination.pagingVarsFactory(paginationSettings.upcomingTotal),
    query: FetchJobsQuery,
    variables: {
        timeframe: "Upcoming",
        status: "NotStarted",
        isActive: true,
        timezone: getTimeZone(),
    },
    timeframe: "upcoming",
    status: "total",
    paginationSetting: paginationSettings.upcomingTotal,
});

export const setUpcomingTotalSort = setSortFactory({
    timeframe: "upcoming",
    status: "total",
    // refresh: fetchUpcomingTotalJobs,
});

export const setUpcomingTotalFilter = setFilterFactory({
    timeframe: "upcoming",
    status: "total",
    // refresh: fetchUpcomingTotalJobs,
});

export const setUpcomingTotalPageSize = setPageSizeFactory({
    paginationSetting: paginationSettings.upcomingTotal,
    // refresh: fetchUpcomingTotalJobs,
});

export const fetchUpcomingUnfilledJobs = fetchJobsListFactory({
    getPageInfo: getUpcomingUnfilledPaginationData,
    getPagingVars: pagination.pagingVarsFactory(paginationSettings.upcomingUnfilled),
    query: FetchJobsQuery,
    variables: {
        timeframe: "Upcoming",
        status: "NotStarted",
        isActive: true,
        filled: false,
        timezone: getTimeZone(),
    },
    timeframe: "upcoming",
    status: "unfilled",
    paginationSetting: paginationSettings.upcomingUnfilled,
});

export const setUpcomingUnfilledSort = setSortFactory({
    timeframe: "upcoming",
    status: "unfilled",
    // refresh: fetchUpcomingUnfilledJobs,
});

export const setUpcomingUnfilledFilter = setFilterFactory({
    timeframe: "upcoming",
    status: "unfilled",
    // refresh: fetchUpcomingUnfilledJobs,
});

export const setUpcomingUnfilledPageSize = setPageSizeFactory({
    paginationSetting: paginationSettings.upcomingUnfilled,
    // refresh: fetchUpcomingUnfilledJobs,
});

export const fetchUpcomingFilledJobs = fetchJobsListFactory({
    getPageInfo: getUpcomingFilledPaginationData,
    getPagingVars: pagination.pagingVarsFactory(paginationSettings.upcomingFilled),
    query: FetchJobsQuery,
    variables: {
        timeframe: "Upcoming",
        status: "NotStarted",
        isActive: true,
        filled: true,
        timezone: getTimeZone(),
    },
    timeframe: "upcoming",
    status: "filled",
    paginationSetting: paginationSettings.upcomingFilled,
});

export const setUpcomingFilledSort = setSortFactory({
    timeframe: "upcoming",
    status: "filled",
    // refresh: fetchUpcomingFilledJobs,
});

export const setUpcomingFilledFilter = setFilterFactory({
    timeframe: "upcoming",
    status: "filled",
    // refresh: fetchUpcomingFilledJobs,
});

export const setUpcomingFilledPageSize = setPageSizeFactory({
    paginationSetting: paginationSettings.upcomingFilled,
    // refresh: fetchUpcomingFilledJobs,
});

export const fetchHistoryJobs = fetchJobsListFactory({
    getPageInfo: getHistoryPaginationData,
    getPagingVars: pagination.pagingVarsFactory(paginationSettings.history),
    query: FetchJobsQuery,
    variables: {
        timeframe: "Past",
        status: "Completed",
        isActive: true,
        timezone: getTimeZone(),
    },
    timeframe: "history",
    paginationSetting: paginationSettings.history,
});

export const setHistorySort = setSortFactory({
    timeframe: "history",
    // refresh: fetchHistoryJobs,
});

export const setHistoryFilter = setFilterFactory({
    timeframe: "history",
    // refresh: fetchHistoryJobs,
});

export const setHistoryPageSize = setPageSizeFactory({
    paginationSetting: paginationSettings.history,
    // refresh: fetchHistoryJobs,
});

export const fetchDraftsJobs = fetchJobsListFactory({
    getPageInfo: getDraftsPaginationData,
    getPagingVars: pagination.pagingVarsFactory(paginationSettings.drafts),
    query: FetchJobsQuery,
    variables: {
        isActive: false,
        isQueued: false,
        timezone: getTimeZone(),
    },
    timeframe: "drafts",
    paginationSetting: paginationSettings.drafts,
});

export const setDraftsSort = setSortFactory({
    timeframe: "drafts",
    // refresh: fetchDraftsJobs,
});

export const setDraftsFilter = setFilterFactory({
    timeframe: "drafts",
    // refresh: fetchDraftsJobs,
});

export const setDraftsPageSize = setPageSizeFactory({
    paginationSetting: paginationSettings.drafts,
    // refresh: fetchDraftsJobs,
});

// Queue jobs actions
export const fetchQueuedJobs = fetchJobsListFactory({
    getPageInfo: getQueuedPaginationData,
    getPagingVars: pagination.pagingVarsFactory(paginationSettings.queued),
    query: FetchJobsQuery,
    variables: {
        isActive: false,
        isQueued: true,
        timezone: getTimeZone(),
    },
    timeframe: "queued",
    paginationSetting: paginationSettings.queued,
});

export const setQueuedSort = setSortFactory({
    timeframe: "queued",
});

export const setQueuedFilter = setFilterFactory({
    timeframe: "queued",
});

export const setQueuedPageSize = setPageSizeFactory({
    paginationSetting: paginationSettings.queued,
});

export const setImportedPrivateJobData = data => dispatch => {
    dispatch({
        type: types.SET_IMPORTED_PRIVATE_JOB_DATA,
        payload: { data },
    });
};
export const fetchImportedPrivateJob = () => dispatch => {
    dispatch(setPrivateJobLoading(true));
    return ApolloClient.query({
        query: FetchImportedPrivateJobList,
        variables: {},
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            } else {
                dispatch(setImportedPrivateJobData(data.importedPrivateJobList));
                dispatch(setPrivateJobLoading(false));
            }
        })
        .catch(e => {
            dispatch(setPrivateJobLoading(false));
            dispatch(MetaActions.errorToast(`Could not get list: ${e.message}`));
        });
};

export const setImportCSVErrorData = data => dispatch => {
    dispatch({
        type: types.SET_IMPORT_CSV_ERROR_DATA,
        payload: { data },
    });
};
export const importPrivateJobCSV = (file) => dispatch => {
    dispatch(setImportPrivateJobLoading(true))
    return ApolloClient.mutate({
        mutation: ImportPrivateJobCSV,
        variables: { file },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            } else {
                if (data?.importPrivateJobs?.errors?.length === 0) {
                    dispatch(openUploadPrivateJobCSVDialog(false))
                }
                dispatch(setImportCSVErrorData(data.importPrivateJobs.errors));
                dispatch(fetchImportedPrivateJob());
                dispatch(setImportPrivateJobLoading(false))
            }
        })
        .catch(e => {
            dispatch(setImportPrivateJobLoading(false))
            dispatch(MetaActions.errorToast(`${e.message}`));
        });
};

const setImportedPrivateJobDetail = data => dispatch => {
    dispatch({
        type: types.SET_IMPORTED_PRIVATE_JOB_DETAIL,
        payload: { data },
    });
};
export const fetchImportedPrivateJobDetail = (id) => dispatch => {
    dispatch(setPrivateJobLoading(true));
    return ApolloClient.query({
        query: FetchImportedPrivateJobDetailById,
        variables: { id },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            } else {
                dispatch(setImportedPrivateJobDetail(data.importedPrivateJobById));
                dispatch(setPrivateJobLoading(false));
            }
        })
        .catch(e => {
            dispatch(setPrivateJobLoading(false));
            dispatch(MetaActions.errorToast(`Could not get list: ${e.message}`));
        });
};

export const fetchPrivateJobs = fetchJobsListFactory({
    getPageInfo: getPrivateJobPaginationData,
    getPagingVars: pagination.pagingVarsFactory(paginationSettings.privateJob),
    query: FetchJobsQuery,
    variables: {
        isActive: true,
        timezone: getTimeZone(),
    },
    timeframe: 'private',
    paginationSetting: paginationSettings.privateJob,
});

export const setPrivateJobSort = setSortFactory({
    timeframe: 'private',
    // refresh: fetchPrivateJobs,
});

export const setPrivateJobFilter = setFilterFactory({
    timeframe: 'private',
    // refresh: fetchPrivateJobs,
});

export const setPrivateJobPageSize = setPageSizeFactory({
    paginationSetting: paginationSettings.privateJob,
    // refresh: fetchPrivateJobs,
});

const setPrivateJobPositionsData = data => dispatch => {
    dispatch({
        type: types.SET_PRIVATE_JOB_POSITIONS_DATA,
        payload: { data },
    });
}
export const fetchPrivateJobPositionWorker = (privateJobRecurringId) => dispatch => {
    // dispatch(setPrivateJobLoading(true));
    return ApolloClient.query({
        query: FetchPrivateJobPositionsById,
        variables: { privateJobRecurringId },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            } else {
                dispatch(setPrivateJobPositionsData(data.positionByPrivateJobRecurringId));
                dispatch(setPrivateJobLoading(false));
            }
        })
        .catch(e => {
            dispatch(setPrivateJobLoading(false));
            dispatch(MetaActions.errorToast(`Could not get list: ${e.message}`));
        });
};

export const setFillPositionErrorData = data => dispatch => {
    dispatch({
        type: types.SET_FILL_POSITION_ERROR_DATA,
        payload: { data },
    });
};
export const fillPositionByPrivateJobRecurringId = (requestData) => dispatch => {
    dispatch(setFillPositionLoading(true))
    return ApolloClient.mutate({
        mutation: FillPositionByPrivateMutation,
        variables: { data: requestData },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            } else {
                if (data?.fillPositionByPrivateJobRecurringId?.errors?.length === 0) {
                    dispatch(openPrivateJobFillPositionDialog(false))
                    dispatch(MetaActions.successToast(`Worker added successfully`));
                }
                dispatch(setFillPositionErrorData(data.fillPositionByPrivateJobRecurringId.errors));
                dispatch(fetchPrivateJobPositionWorker(requestData.privateJobRecurringId))
                dispatch(setFillPositionLoading(false))
            }
        })
        .catch(e => {
            dispatch(setFillPositionLoading(false))
            dispatch(MetaActions.errorToast(`${e.message}`));
        });
};


export const setDeletePrivateJObPositionErrorData = data => dispatch => {
    dispatch({
        type: types.SET_DELETE_PRIVATE_JOB_POSITION_ERROR_DATA,
        payload: { data },
    });
};
export const removePositionByPrivateJobRecurringId = (requestData) => dispatch => {
    dispatch(setFillPositionLoading(true))
    return ApolloClient.mutate({
        mutation: RemovePositionByPrivateMutation,
        variables: { data: requestData },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            } else {
                dispatch(setFillPositionLoading(false))
                if (data?.removePositionByPrivateJobRecurringId?.errors?.length === 0) {
                    dispatch(openDeletePrivateJobPositionDialog(false))
                    dispatch(MetaActions.successToast(`Worker removed successfully`));
                }
                dispatch(setDeletePrivateJObPositionErrorData(data.removePositionByPrivateJobRecurringId.errors))
                dispatch(fetchPrivateJobPositionWorker(requestData.privateJobRecurringId))
            }
        })
        .catch(e => {
            dispatch(setFillPositionLoading(false))
            dispatch(MetaActions.errorToast(`${e.message}`));
        });
};

export const setUpdatePrivateJobErrorData = data => dispatch => {
    dispatch({
        type: types.SET_UPDATE_PRIVATE_JOB_ERROR_DATA,
        payload: { data },
    });
};
export const UpdateimportPrivateJob = (requestData) => dispatch => {
    dispatch(setImportPrivateJobLoading(true))
    return ApolloClient.mutate({
        mutation: UpdateImportedPrivateMutation,
        variables: { data: requestData },
    })
        .then(({ data, errors }) => {
            if (errors && errors.length > 0) {
                throw new Error(errors[0].message);
            } else {
                if (data.updateimportedPrivateJobs.errors.length === 0) {
                    dispatch(openEditPrivateJobDialog(false));
                }
                dispatch(setUpdatePrivateJobErrorData(data.updateimportedPrivateJobs.errors));
                dispatch(fetchImportedPrivateJobDetail(requestData.id))
                dispatch(setImportPrivateJobLoading(false))
                dispatch(MetaActions.successToast(`Job date updated successfully`));
            }
        })
        .catch(e => {
            console.log(e)
            dispatch(setImportPrivateJobLoading(false))
            dispatch(MetaActions.errorToast(`${e.message}`));
        });
};

export const downloadPrivateJobSampleCSV = () => dispatch => {
    return ApolloClient.query({
        query: FetchPrivateJobSampleCSVQuery,
        variables: {},
    })
        .then(({ data, errors }) => {
            if (errors && errors.length > 0) {
                throw new Error(errors[0].message);
            } else {
                return data?.sampleCSVForImportPrivateJob;
            }
        })
        .catch(e => {
            console.log(e)
            dispatch(MetaActions.errorToast(`${e.message}`));
        });
};
