import TagsInput from "components/TagsInput";
import { isEqual } from "lodash";
import React from "react";
import CustomFieldOptionsColumns from "./CustomFieldOptionsColumns";
import CustomFieldTableActionColumn from "./CustomFieldTableActionColumn";

const columns = [
    {
        Header: "Id",
        disableFilters:true,
        accessor: "id",
        width:70
    },
    {
        Header: "Name",
        accessor: "name",
    },
    {
        Header: "Slug",
        accessor: "slug",
    },
    {
        Header: "Type",
        accessor: "type",
    },
    {
        Header: "Options",
        disableFilters:true,
        disableSortBy: true,
        accessor: "label",
        minWidth: 200,
        Filter: ({ column, setFilter }) => {
            return (
                <div>
                    <TagsInput
                        selectedTags={(data) => {
                            if (data) {
                                if (column) {
                                    if (!isEqual(column.filterValue, data)) {
                                        setFilter(column.id, data)
                                    }
                                } else {
                                    if (data.length == 0 && column) {
                                        setFilter(column.id, data)
                                    } else if (data.length > 0) {
                                        setFilter(column.id, data)
                                    }
                                }
                            }
                        }}
                        fullWidth
                        defaultValue={column.filterValue ? column.filterValue : []}
                        isFilterField
                        id="label"
                        name="label"
                    />
                </div>
            )
        },
        Cell: CustomFieldOptionsColumns
    },
    {
        Header: "",
        disableSortBy: true,
        disableFilters: true,
        accessor: "action",
        collapse: true,
        isNotClickable: true,
        Cell: ({ row: { original } }) => <CustomFieldTableActionColumn field={original} />
    },
];

export default columns;
