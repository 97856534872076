import gql from "graphql-tag";

export default gql`
mutation UpdateAgreementDocument($data: EditAgreementDocumentInput!) {
    updateAgreementDocument(data: $data) {
      id
      documentName
      documentUrl
      provinceId
      helpText
      documentType
      createdAt
      updatedAt
      deletedAt
      signedDocumentImageUrl
    }
  }
`;
