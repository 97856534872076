import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as ProvinceDocumentsActions, selectors as ProvinceDocumentsSelectors } from "store/modules/provinceDocuments";

import ReactTableSimple from "components/ReactTable/ReactTableSimple";
import { IconButton } from "@mui/material";
import { CloudDownload } from "@mui/icons-material";
import DocumentActionMenu from "./DocumentActionMenu";
import { allowedProvinceDocumentsTypes } from "utils/file";

function DocumentsTable(props) {
    const dispatch = useDispatch();
    const data = useSelector(ProvinceDocumentsSelectors.getProvinceDocumentsList);
    const isLoading = useSelector(ProvinceDocumentsSelectors.getIsTableLoading);

    useEffect(() => {
        dispatch(ProvinceDocumentsActions.fetchProvinceDocuments());
    }, []);

    return (
        <ReactTableSimple
            classes="-highlight"
            loading={isLoading}
            data={data}
            hasFilter
            initialSort={[{ id: 'id', desc: true }]}
            columns={[
                {
                    Header: "Id",
                    id: "id",
                    disableFilters: true,
                    collapse: true,
                    headerStyle: { minWidth: 70 },
                    accessor: ({ id }) => id,
                },
                {
                    Header: "Name",
                    id: "documentName",
                    accessor: ({ documentName }) => documentName,
                },
                {
                    Header: "Help Text",
                    id: "helpText",
                    accessor: ({ helpText }) => helpText,
                },
                {
                    Header: "Type",
                    id: "documentType",
                    accessor: ({ documentType }) => {
                        const docType = allowedProvinceDocumentsTypes.find(d => d.mime === documentType);
                        if (documentType) return docType.extension.replace(".","").toUpperCase();
                        return documentType;
                    },
                },
                {
                    Header: "Document",
                    id: "document",
                    disableFilters: true,
                    disableSortBy: true,
                    styles: { textAlign: "center" },
                    Cell: ({ row: { original: { signedDocumentImageUrl } } }) => {
                        return (
                            <>
                                <IconButton href={signedDocumentImageUrl} target="_blank" >
                                    <CloudDownload />
                                </IconButton>
                            </>
                        );
                    }
                },
                {
                    Header: "Province",
                    id: "region",
                    accessor: ({ region }) => region?.name,
                },
                {
                    id: "actions",
                    disableSortBy: true,
                    collapse: true,
                    disableFilters: true,
                    styles: { textAlign: "right" },
                    Cell: ({ row: { original } }) => {
                        return (
                            <DocumentActionMenu document={original} />
                        );
                    },
                },
            ]}
        />
    );
};

export default DocumentsTable;
