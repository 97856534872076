import React from "react";
import { Link } from "react-router-dom";
import ActionColumn from "./ActionColumn";
import ActionColumnHeader from "./ActionColumnHeader"

export default [
    {
        Header: "First Name",
        accessor: "firstName",
        isNotClickable: true,
        Cell: ({ row: { original }, value }) => {
            return <Link to={`/workers/${original.id}`}>{value || "-"}</Link>;
        },
    },
    {
        Header: "Last Name",
        accessor: "lastName",
    },
    {
        Header: "Email",
        accessor: "email",
    },
    {
        Header: ActionColumnHeader,
        isNotClickable: true,
        disableFilters: true,
        collapse: true,
        disableSortBy: true,
        accessor: "action",
        style: { textAlign: "right" },
        Cell: ActionColumn
    },
];
