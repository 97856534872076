import { Button } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions as CustomFieldActions, selectors as CustomFieldSelectors } from "store/modules/customFields";

export default function ActionColumnHeader() {
    const dispatch = useDispatch();
    const isOpen = useSelector(CustomFieldSelectors.getIsOpenAddUserCustomField);

    const toggleHandler = () => {
        dispatch(CustomFieldActions.clearCustomFieldForm());
        dispatch(CustomFieldActions.setIsOpenAddUserCustomField(!isOpen))
    };

    return (
        <Button
            color="secondary"
            variant="text"
            size="small"
            onClick={toggleHandler}
        >
            Add
        </Button>
    )
}
