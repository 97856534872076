import gql from "graphql-tag";

export default gql`mutation ReverseHolidayPayment($reason: String!, $paymentId: Int!) {
    reverseHolidayPayment(reason: $reason, holidayPaymentId: $paymentId) {
      id
      createdAt
      updatedAt
      responseAt
      sentAt
      holidayWorkerId
      workerId
      holidayPayrollId
      holidayPaymentId
      paymentStatusId
      dcTransferId
      reversedDcTransferId
      dcCustomerNumber
      paymentProviderReferenceCode
      email
      amount
      errorCode
      errorDescription
      notes
    }
  }`;
