import ApolloClient from "utils/apollo";
import { flattenGraphQLArray } from "utils/graphql";
import * as pagination from "utils/graphql-pagination";
import { getRelation } from "utils/redux";
import { paginationSettings } from "./constants";
import FetchDocumentsQuery from "./graphql/queries/fetch-documents";
import { actions as MetaActions } from "store/modules/meta";
import UpdateDocument from "./graphql/mutation/update-document";
import {
    getListData,
    getPendingPaginationData,
    getApprovedPaginationData,
    getRejectedPaginationData
} from "./selectors";
import types from "./types";

const setLoadingState = key => value => ({
    type: types.SET_LOADING_STATE,
    payload: { key, value },
});

const setDialogState = key => value => ({
    type: types.SET_DIALOG_STATE,
    payload: { key, value },
});

const setIsUpdateDocumentStatus = setLoadingState("documentStatusUpdate");
export const setIsOpenRejectDialog = setDialogState("rejectReason");

export const setRejectReason = (value) => ({
    type: types.SET_REJECT_REASON_STATE,
    payload: { key: "rejectReason", value }
});

export const setSelectedDocument = (doc) => ({
    type: types.SET_SELECTED_DOCUMENT_STATE,
    payload: { value: doc }
});

export const fetchDocumentsListFactory = config => pageIndex => (dispatch, getState) => {
    const state = getState();
    const treeConfig = {
        statusframe: config.statusframe,
    };
    const pageInfo = config.getPageInfo(state);
    const pagingVars = dispatch(config.getPagingVars(pageInfo, pageIndex));
    const relations = {
        Worker: ["firstName","lastName","email"],
        DocumentTypeMaster: ["name"]
    };
    const extraVars = config.variables || {};
    const filterInfo = JSON.parse(JSON.stringify(getListData(state, { ...treeConfig, part: "filter" })));
    const filters = {};

    return ApolloClient.query({
        query: config.query,
        variables: {
            ...pagingVars,
            ...extraVars,
            ...filters,
            order: getListData(state, { ...treeConfig, part: "sort" }).map(sort => {
                return {
                    field: sort.id,
                    direction: sort.desc ? "DESC" : "ASC",
                    relation: getRelation(relations, sort.id),
                };
            }),
            like: filterInfo.map(filter => {
                return {
                    field: filter.id,
                    value: filter.value,
                    relation: getRelation(relations, filter.id),
                };
            }),
        },
    })
        .then(({ data }) => {
            const { getAllDocumentMaster } = data;
            const paginationData = {
                ...getAllDocumentMaster.pageInfo,
                totalCount: getAllDocumentMaster.totalCount,
            };

            dispatch({
                type: types.SET_LIST_DATA,
                payload: {
                    data: flattenGraphQLArray(getAllDocumentMaster),
                    statusframe: config.statusframe,
                    part: "data",
                },
            });
            dispatch(pagination.updatePageInfo(config.paginationSetting, paginationData));
            dispatch(pagination.doneLoading(config.paginationSetting));
        })
        .catch(() => {
            dispatch(pagination.doneLoading(config.paginationSetting));
        });
};

export const setSortFactory = config => sort => dispatch => {
    dispatch({
        type: types.SET_LIST_DATA,
        payload: {
            data: sort,
            statusframe: config.statusframe,
            part: "sort",
        },
    });
    if (config.refresh)
        return dispatch(config.refresh());
};

export const setFilterFactory = config => filter => dispatch => {
    dispatch({
        type: types.SET_LIST_DATA,
        payload: {
            data: filter,
            statusframe: config.statusframe,
            part: "filter",
        },
    });
    if (config.refresh)
        return dispatch(config.refresh());
};

export const setPageSizeFactory = config => pageSize => dispatch => {
    dispatch(pagination.updatePageInfo(config.paginationSetting, { pageSize }));
};

export const fetchPendingDocuments = fetchDocumentsListFactory({
    getPageInfo: getPendingPaginationData,
    getPagingVars: pagination.pagingVarsFactory(paginationSettings.pending),
    query: FetchDocumentsQuery,
    variables: {
        status: "PENDING",
    },
    paginationSetting: paginationSettings.pending,
    statusframe: "pending",
});

export const setPendingSort = setSortFactory({
    statusframe: "pending",
});

export const setPendingFilter = setFilterFactory({
    statusframe: "pending",
});

export const setPendingPageSize = setPageSizeFactory({
    paginationSetting: paginationSettings.pending,
});


export const fetchApprovedDocuments = fetchDocumentsListFactory({
    getPageInfo: getApprovedPaginationData,
    getPagingVars: pagination.pagingVarsFactory(paginationSettings.approved),
    query: FetchDocumentsQuery,
    variables: {
        status: "APPROVED",
    },
    paginationSetting: paginationSettings.approved,
    statusframe: "approved",
});

export const setApprovedSort = setSortFactory({
    statusframe: "approved",
});

export const setApprovedFilter = setFilterFactory({
    statusframe: "approved",
});

export const setApprovedPageSize = setPageSizeFactory({
    paginationSetting: paginationSettings.approved,
});

export const fetchRejectedDocuments = fetchDocumentsListFactory({
    getPageInfo: getRejectedPaginationData,
    getPagingVars: pagination.pagingVarsFactory(paginationSettings.rejected),
    query: FetchDocumentsQuery,
    variables: {
        status: "REJECT",
    },
    statusframe: "rejected",
    paginationSetting: paginationSettings.rejected,
});

export const setRejectedSort = setSortFactory({
    statusframe: "rejected",
});

export const setRejectedFilter = setFilterFactory({
    statusframe: "rejected",
});

export const setRejectedPageSize = setPageSizeFactory({
    paginationSetting: paginationSettings.rejected,
});


export const updateDocumentStatus = updateDocument => dispatch => {
    dispatch(setIsUpdateDocumentStatus(updateDocument.id))
    return ApolloClient.mutate({
        mutation: UpdateDocument,
        variables: { updateDocument },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            dispatch(fetchPendingDocuments());
            dispatch(setIsUpdateDocumentStatus(false));
            dispatch(setRejectReason(""))
            dispatch(setIsOpenRejectDialog(false))
            dispatch(setSelectedDocument(null))
            return data;
        }).catch((e) => {
            dispatch(MetaActions.errorToast(e.message));
            dispatch(setIsUpdateDocumentStatus(false))
        });
}
