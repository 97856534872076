import gql from "graphql-tag";

export default gql`
mutation($data: CreateDocumentType!) {
    createDocumentType(data: $data){
        name
        slug
        helpText
    }
}
`
