import { gql } from "@apollo/client";
import ApolloClient from "utils/apollo";
import { flattenGraphQLArray } from "utils/graphql";
import * as pagination from "utils/graphql-pagination";

import { verifyEmail } from "utils/stringUtils";
import { getTimeZone } from "utils/time";
import { actions as MetaActions } from "store/modules/meta";
import { paginationSettings } from "./constants";

// Queries
// Mutation
import { editEmployerMutation } from "./graphql/mutations";
import banWorkerMutation from "./graphql/mutations/ban-worker";
import preferEmployerMutation from "./graphql/mutations/prefer-worker";
import createEmployerMutation from "./graphql/mutations/create-employer";
import removeWorkerReputation from "./graphql/mutations/remove-ban-worker";
import employerBannedQuery from "./graphql/queries/employer-bans";
import employerCheckExistsQuery from "./graphql/queries/employer-checkExists";
import employerDetailQuery from "./graphql/queries/employer-detail";
import employerPreferredQuery from "./graphql/queries/employer-preferred";
import employerTableQuery from "./graphql/queries/employers-table";
import {
    employerBannedByPageInfo,
    getDeactivatedEmployerListFilterData,
    getDeactivatedEmployerListPaginationData,
    getDeactivatedEmployerListSortData,
    getEmployerListFilterData,
    getEmployerListPaginationData,
    getEmployerListSortData,
    getEmployerPreferredPaginationData,
    getUnapprovedEmployerListFilterData,
    getUnapprovedEmployerListPaginationData,
    getUnapprovedEmployerListSortData,
} from "./selectors";
import types from "./types";

const getEmployerListPagingVars = pagination.pagingVarsFactory(paginationSettings.employerList);
const getDeactivatedEmployerListPagingVars = pagination.pagingVarsFactory(
  paginationSettings.deactivatedEmployerList
);
const getUnapprovedEmployerListPagingVars = pagination.pagingVarsFactory(
  paginationSettings.unapprovedEmployerList
);
const getEmployerBansPagingVars = pagination.pagingVarsFactory(paginationSettings.employerBannedBy);
const getEmployerPreferredPagingVars = pagination.pagingVarsFactory(
  paginationSettings.employerPreferredBy
);

const paginationFunctionList = {
  employerList: getEmployerListPaginationData,
  deactivatedEmployerList: getDeactivatedEmployerListPaginationData,
  unapprovedEmployerList: getUnapprovedEmployerListPaginationData,
};

const alertMessages = {
  fetchError: "Oops!  Something went wrong, please try again",
  updateError: "Oops!  Something went wrong, please try again",
  defaultError: "Oops!  Something went wrong, please try again",
  updateSuccess: "Success!",
  defaultSuccess: "Success!",
};
const setEmployers = employers => ({
  type: types.SET_EMPLOYERS,
  payload: { employers },
});

// Unapproved
const setUnapprovedEmployers = employers => ({
  type: types.SET_UNAPPROVED_EMPLOYERS,
  payload: { employers },
});

export const fetchUnapprovedEmployers = pageIndex => (dispatch, getState) => {
  const state = getState();

  const pageInfo = getUnapprovedEmployerListPaginationData(state);
  const pagingVars = dispatch(getUnapprovedEmployerListPagingVars(pageInfo.paging, pageIndex));

  return ApolloClient.query({
    query: employerTableQuery,
    variables: {
      ...pagingVars,
      order: getUnapprovedEmployerListSortData(state).map(sort => {
        return {
          field: sort.id,
          direction: sort.desc ? "DESC" : "ASC",
        };
      }),
      isApproved: false,
      like: getUnapprovedEmployerListFilterData(state).map(filter => {
        return {
          field: filter.id,
          value: filter.value,
        };
      }),
    },
  })
    .then(({ data }) => {
      const { employers } = data;
      const paginationData = {
        ...employers.pageInfo,
        totalCount: employers.totalCount,
      };
      dispatch(setUnapprovedEmployers(flattenGraphQLArray(employers)));
      dispatch(
        pagination.updatePageInfo(paginationSettings.unapprovedEmployerList, paginationData)
      );
      dispatch(pagination.doneLoading(paginationSettings.unapprovedEmployerList));
    })
    .catch(() => {
      dispatch(pagination.doneLoading(paginationSettings.unapprovedEmployerList));
    });
};

export const changeUnapprovedPageSize = pageSize => dispatch => {
  dispatch(pagination.updatePageInfo(paginationSettings.unapprovedEmployerList, { pageSize }));
//   return dispatch(fetchUnapprovedEmployers());
};

export const changeUnapprovedSort = pageSort => dispatch => {
  dispatch({
    type: types.UPDATE_UNAPPROVED_PAGE_SORT,
    payload: { pageSort },
  });
//   return dispatch(fetchUnapprovedEmployers());
};

export const changeUnapprovedFilter = pageFilter => dispatch => {
  dispatch({
    type: types.UPDATE_UNAPPROVED_PAGE_FILTER,
    payload: { pageFilter },
  });
//   return dispatch(fetchUnapprovedEmployers());
};

const setDeactivatedEmployers = employers => ({
  type: types.SET_DEACTIVATED_EMPLOYERS,
  payload: { employers },
});

export const fetchDeactivatedEmployers = pageIndex => (dispatch, getState) => {
  const state = getState();
  const pageInfo = getDeactivatedEmployerListPaginationData(state);
  const pagingVars = dispatch(getDeactivatedEmployerListPagingVars(pageInfo.paging, pageIndex));
  return ApolloClient.query({
    query: employerTableQuery,
    variables: {
      ...pagingVars,
      order: getDeactivatedEmployerListSortData(state).map(sort => {
        return {
          field: sort.id,
          direction: sort.desc ? "DESC" : "ASC",
        };
      }),
      isActive: false,
      like: getDeactivatedEmployerListFilterData(state).map(filter => {
        return {
          field: filter.id,
          value: filter.value,
        };
      }),
    },
  })
    .then(({ data }) => {
      const { employers } = data;
      const paginationData = {
        ...employers.pageInfo,
        totalCount: employers.totalCount,
      };
      dispatch(setDeactivatedEmployers(flattenGraphQLArray(employers)));
      dispatch(
        pagination.updatePageInfo(paginationSettings.deactivatedEmployerList, paginationData)
      );
      dispatch(pagination.doneLoading(paginationSettings.deactivatedEmployerList));
    })
    .catch(() => {
      dispatch(pagination.doneLoading(paginationSettings.deactivatedEmployerList));
    });
};

export const changeDeactivatedPageSize = pageSize => dispatch => {
  dispatch(pagination.updatePageInfo(paginationSettings.deactivatedEmployerList, { pageSize }));
//   return dispatch(fetchDeactivatedEmployers());
};

export const changeDeactivatedFilter = pageFilter => dispatch => {
  dispatch({
    type: types.UPDATE_DEACTIVATED_PAGE_FILTER,
    payload: { pageFilter },
  });
//   return dispatch(fetchDeactivatedEmployers());
};

export const changeDeactivatedSort = pageSort => dispatch => {
  dispatch({
    type: types.UPDATE_DEACTIVATED_PAGE_SORT,
    payload: { pageSort },
  });
//   return dispatch(fetchDeactivatedEmployers());
};

export const changeFilter = pageFilter => dispatch => {
  dispatch({
    type: types.UPDATE_PAGE_FILTER,
    payload: { pageFilter },
  });

//   return dispatch(fetchEmployers());
};

export const typeByListType = listType => {
  switch (listType) {
    case "employerList": {
      return {
        set: "SET_EMPLOYERS",
        sort: "UPDATE_PAGE_SORT",
        filter: "UPDATE_PAGE_FILTER",
      };
    }
    case "deactivatedEmployerList": {
      return {
        set: "SET_DEACTIVATED_EMPLOYERS",
        sort: "UPDATE_DEACTIVATED_PAGE_SORT",
        filter: "UPDATE_DEACTIVATED_PAGE_FILTER",
      };
    }
    case "unapprovedEmployerList": {
      return {
        set: "SET_UNAPPROVED_EMPLOYERS",
        sort: "UPDATE_UNAPPROVED_PAGE_SORT",
        filter: "UPDATE_UNAPPROVED_PAGE_FILTER",
      };
    }

    default:
      return {
        set: "SET_EMPLOYERS",
        sort: "UPDATE_PAGE_SORT",
        filter: "UPDATE_PAGE_FILTER",
      };
  }
};

export const fetchEmployerTable = (pageIndex, listType) => (dispatch, getState) => {
  const state = getState();
  listType = listType ? listType : "employerList";
  const pageInfo = paginationFunctionList[listType](state);
  const orderInfo = paginationFunctionList[listType](state).sort;
  const filterInfo = paginationFunctionList[listType](state).filter;
  const pagingVars = dispatch(getDeactivatedEmployerListPagingVars(pageInfo.paging, pageIndex));
  return ApolloClient.query({
    query: employerTableQuery,
    variables: {
      ...pagingVars,
      ...paginationFunctionList[listType](state).params,
      order: orderInfo.map(sort => {
        return {
          field: sort.id,
          direction: sort.desc ? "DESC" : "ASC",
        };
      }),
      like: filterInfo.map(filter => {
        return {
          field: filter.id,
          value: filter.value,
        };
      }),
    },
  })
    .then(({ data }) => {
      const { employers } = data;
      const paginationData = {
        ...employers.pageInfo,
        totalCount: employers.totalCount,
      };
      dispatch(setEmployers(flattenGraphQLArray(employers), typeByListType(listType).set));
      dispatch(pagination.updatePageInfo(paginationSettings[listType], paginationData));
      dispatch(pagination.doneLoading(paginationSettings[listType]));
    })
    .catch(() => {
      dispatch(pagination.doneLoading(paginationSettings.employerList));
    });
};
export const fetchEmployers = pageIndex => (dispatch, getState) => {
  const state = getState();
  const pageInfo = getEmployerListPaginationData(state);
  const pagingVars = dispatch(getEmployerListPagingVars(pageInfo.paging, pageIndex));
  return ApolloClient.query({
    query: employerTableQuery,
    variables: {
      ...pagingVars,
      order: getEmployerListSortData(state).map(sort => {
        return {
          field: sort.id,
          direction: sort.desc ? "DESC" : "ASC",
        };
      }),
      isActive: true,
      like: getEmployerListFilterData(state).map(filter => {
        return {
          field: filter.id,
          value: filter.value,
        };
      }),
    },
  })
    .then(({ data }) => {
      const { employers } = data;
      const paginationData = {
        ...employers.pageInfo,
        totalCount: employers.totalCount,
      };
      dispatch(setEmployers(flattenGraphQLArray(employers)));
      dispatch(pagination.updatePageInfo(paginationSettings.employerList, paginationData));
      dispatch(pagination.doneLoading(paginationSettings.employerList));
    })
    .catch(() => {
      dispatch(pagination.doneLoading(paginationSettings.employerList));
    });
};
export const changePageSize = pageSize => dispatch => {
  dispatch(pagination.updatePageInfo(paginationSettings.employerList, { pageSize }));
//   return dispatch(fetchEmployers());
};

export const changeSort = pageSort => dispatch => {
  dispatch({
    type: types.UPDATE_PAGE_SORT,
    payload: { pageSort },
  });
//   return dispatch(fetchEmployers());
};

const setEmployerPreferred = preferred => ({
  type: types.SET_EMPLOYER_PREFERRED,
  payload: { preferred },
});
const addEmployerPreferred = preferred => ({
  type: types.ADD_EMPLOYER_PREFERRED,
  payload: { preferred },
});
const addEmployerBan = ban => ({ type: types.ADD_EMPLOYER_BAN, payload: { ban } });
const removeEmployerRep = employer => ({ type: types.REMOVE_EMPLOYER_REP, payload: { employer } });

export const banWorker = banData => dispatch => {
  dispatch(setIsInfoLoading(true));
  return ApolloClient.mutate({ mutation: banWorkerMutation, variables: { data: banData } })
    .then(({ data }) => {
      dispatch(fetchEmployerPreferred(banData.employerId));
      dispatch(fetchEmployerBans(banData.employerId));
      dispatch(addEmployerBan(data.banWorker));
      dispatch(setIsInfoLoading(false));
    })
    .catch(() => {
      dispatch(setIsInfoLoading(false));
    });
};

export const preferWorker = preferData => dispatch => {
  dispatch(setIsInfoLoading(true));
  return ApolloClient.mutate({
    mutation: preferEmployerMutation,
    variables: { data: preferData },
  })
    .then(({ data }) => {
      dispatch(addEmployerPreferred(data.preferWorker));
      dispatch(fetchEmployerPreferred(preferData.employerId));
      dispatch(fetchEmployerBans(preferData.employerId));
      dispatch(setIsInfoLoading(false));
    })
    .catch(() => {
      dispatch(setIsInfoLoading(false));
    });
};

export const removeReputation = (workerId, employerId) => dispatch => {
  dispatch(setIsInfoLoading(true));
  return ApolloClient.mutate({
    mutation: removeWorkerReputation,
    variables: { data: { workerId: workerId, employerId: employerId } },
  })
    .then(({ data }) => {
      if (!data.clearException) {
        throw new Error("Failed to remove");
      }
      dispatch(removeEmployerRep(data));
      dispatch(setIsInfoLoading(false));
      dispatch(fetchEmployerBans(employerId));
      dispatch(fetchEmployerPreferred(employerId));
    })
    .catch(() => {
      dispatch(setIsInfoLoading(false));
    });
};

export const fetchEmployerPreferred = (employerId, pageIndex) => (dispatch, getState) => {
  const state = getState();
  const pageInfo = getEmployerPreferredPaginationData(state);
  const pagingVars = dispatch(getEmployerPreferredPagingVars(pageInfo, pageIndex));
  return ApolloClient.query({
    query: employerPreferredQuery,
    variables: { ...pagingVars, employerId: employerId },
  })
    .then(({ data }) => {
      const { preferred } = data;
      const paginationData = { ...preferred.pageInfo, totalCount: preferred.totalCount };
      dispatch(setEmployerPreferred(flattenGraphQLArray(preferred)));
      dispatch(pagination.updatePageInfo(paginationSettings.employerPreferredBy, paginationData));
      dispatch(pagination.doneLoading(paginationSettings.employerPreferredBy));
    })
    .catch(() => {
      dispatch(pagination.doneLoading(paginationSettings.employerPreferredBy));
    });
};

export const changeBannedSort = (pageSort, employerId) => dispatch => {
  dispatch({
    type: types.UPDATE_PAGE_SORT,
    payload: { pageSort },
  });
  return dispatch(fetchEmployerBans(employerId));
};

export const changePreferredSort = (pageSort, employerId) => dispatch => {
  dispatch({
    type: types.UPDATE_PAGE_SORT,
    payload: { pageSort },
  });
  return dispatch(fetchEmployerBans(employerId));
};

export const fetchEmployerBans = (employerId, pageIndex) => (dispatch, getState) => {
  const state = getState();
  const pageInfo = employerBannedByPageInfo(state);
  const pagingVars = dispatch(getEmployerBansPagingVars(pageInfo, pageIndex));
  return ApolloClient.query({
    query: employerBannedQuery,
    variables: {
      ...pagingVars,
      employerId: employerId,
    },
  })

    .then(({ data }) => {
      const { banned } = data;
      const paginationData = { ...banned.pageInfo, totalCount: banned.totalCount };
      const sortedBans = flattenGraphQLArray(banned).sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      dispatch(setEmployerBans(sortedBans));
      dispatch(pagination.updatePageInfo(paginationSettings.employerBannedBy, paginationData));
      dispatch(pagination.doneLoading(paginationSettings.employerBannedBy));
    })

    .then(({ data }) => {
      const { banned } = data;
      const paginationData = { ...banned.pageInfo, totalCount: banned.totalCount };
      const sortedBans = flattenGraphQLArray(banned).sort(function (a, b) {
        return new Date(b.createdAtUtc) - new Date(a.createdAtUtc);
      });
      dispatch(setEmployerBans(sortedBans));
      dispatch(pagination.updatePageInfo(paginationSettings.employerBannedBy, paginationData));
      dispatch(pagination.doneLoading(paginationSettings.employerBannedBy));
    })
    .catch(() => {
      dispatch(pagination.doneLoading(paginationSettings.employerBannedBy));
    });
};

const setEmployerBans = bans => ({
  type: types.SET_EMPLOYER_BANS,
  payload: { bans },
});

export const changePreferredPageSize = (pageSize, employerId) => dispatch => {
  dispatch(pagination.updatePageInfo(paginationSettings.employerPreferredBy, { pageSize }));
  return dispatch(fetchEmployerPreferred(employerId));
};

export const changeBansPageSize = (pageSize, employerId) => dispatch => {
  dispatch(pagination.updatePageInfo(paginationSettings.employerBannedBy, { pageSize }));
  return dispatch(fetchEmployerBans(employerId));
};

export const setEmployerList = (employers, type) => {
  return {
    type: types[type],
    payload: { employers },
  };
};
//
//
//

const setIsDetailLoading = isDetailLoading => ({
  type: types.IS_LOADING_DETAIL,
  payload: { isDetailLoading },
});

const setEmployer = employer => ({
  type: types.SET_EMPLOYER,
  payload: { employer },
});

export const fetchEmployer = employerId => dispatch => {
  dispatch(setIsDetailLoading(true));
  return ApolloClient.query({
    query: employerDetailQuery,
    variables: { id: employerId },
  })
    .then(({ data }) => {
      dispatch(setEmployer(data.employer));
      dispatch(setIsDetailLoading(false));
    })
    .catch(() => {
      dispatch(setIsDetailLoading(false));
    });
};

export const updateEditData = (field, value) => ({
  type: types.UPDATE_EDIT_DATA,
  payload: { field, value },
});

export const resetEditData = () => ({
  type: types.RESET_EDIT_DATA,
});

const setIsInfoLoading = isInfoLoading => ({
  type: types.IS_LOADING_INFO,
  payload: { isInfoLoading },
});

export const updateEmployer = employerId => (dispatch, getState) => {
  let { editData } = getState().employers;
  if (editData.payRate) {
    editData.payRate = parseFloat(editData.payRate);
  }
  if (editData.billingRate) {
    editData.billingRate = parseFloat(editData.billingRate);
  }
  editData.timezone = getTimeZone();
  dispatch(setIsInfoLoading(true));
  return ApolloClient.mutate({
    mutation: editEmployerMutation,
    variables: {
      data: {
        id: employerId,
        ...editData,
      },
    },
  })
    .then(({ data }) => {
      dispatch(resetEditData());
      dispatch(setEmployer(data.editEmployer));
      dispatch(setIsInfoLoading(false));
    })
    .catch(() => {
      dispatch(resetEditData());
      dispatch(setIsInfoLoading(false));
    });
};

export const changeEmployerListFilter = pageFilter => () => {
  return ApolloClient.query({
    query: gql`
      query employers(
        $first: Int
        $last: Int
        $after: String
        $before: String
        $order: [OrderInput!]
        $like: [FilterInput!]
      ) {
        employers(
          first: $first
          last: $last
          after: $after
          before: $before
          order: $order
          like: $like
        ) {
          edges {
            node {
              id
              companyName
              street
              city
              region
              country
              firstName
              lastName
              email
              logoImageUrl
              signedLogoImageUrl
              mobilePhone
              billingRate
              payRate
            }
          }
        }
      }
    `,
    variables: {
      first: 5,
      order: [
        {
          id: "companyName",
          direction: "ASC",
        },
      ],
      like: [pageFilter],
    },
  })
    .then(({ data }) => {
      const { employers } = data;
      const empArray = flattenGraphQLArray(employers);
      return empArray.map(i => ({
        value: i.id,
        label: i.companyName,
        data: i,
      }));
    })
    .catch(e => console.log("error", e));
};

// TODO: MOVE THIS
export const changeWorkerListFilter = pageFilter => {
  return ApolloClient.query({
    query: gql`
      query getWorkers(
        $first: Int
        $last: Int
        $after: String
        $before: String
        $orderBy: [OrderByInput!]
        $filterBy: [FilterByInput!]
      ) {
        workers(
          first: $first
          last: $last
          after: $after
          before: $before
          orderBy: $orderBy
          filterBy: $filterBy
        ) {
          edges {
            node {
              id
              firstName
              lastName
              email

              phone
              sin
              authId
              profileImageUrl
              signedProfileImageUrl
              vaccinationStatus
              identificationImageUrl
              isActive
              isApproved
              isSuspended

              fullName
              rating
              payrollCount
              positionCount
              grossPay
            }
          }
        }
      }
    `,
    variables: {
      first: 15,
      orderBy: [
        {
          id: "firstName",
          direction: "ASC",
        },
        {
          id: "lastName",
          direction: "ASC",
        },
      ],
      filterBy: [pageFilter],
    },
  })
    .then(({ data }) => {
      const { workers } = data;
      const workersArray = flattenGraphQLArray(workers);

      return workersArray.map(i => ({
        value: i.id,
        label: i.firstName + " " + i.lastName,
        data: i,
      }));
    })
    .catch(() => {});
};

export const registerEmployer = () => (dispatch, getState) => {
  dispatch(setIsInfoLoading(true));
  const payload = {
    mobilePhone: getState().employers.addEmployerData.phone,
    companyName: getState().employers.addEmployerData.companyName,
    email: getState().employers.addEmployerData.email,
    firstName: getState().employers.addEmployerData.firstName,
    lastName: getState().employers.addEmployerData.lastName,
    password: 'Grizzly@Employer',
    city: getState().employers.addEmployerData?.location?.city,
    street: getState().employers.addEmployerData?.location?.street,
    region: getState().employers.addEmployerData?.location?.region,
    postalCode: getState().employers.addEmployerData?.location?.postalCode,
    country: getState().employers.addEmployerData?.location?.country,
    countryCode: '+1',
    title: getState().employers.addEmployerData.title,
    logo: getState().employers.addEmployerData.logo,
    timezone: getTimeZone(),
    payRate: Number(getState().employers.addEmployerData.payRate),
    billingRate: Number(getState().employers.addEmployerData.billingRate),
    isActive: Boolean(getState().employers.addEmployerData.isActive),
    isApproved: Boolean(getState().employers.addEmployerData.isApproved),
  };

  return ApolloClient.mutate({
    mutation: createEmployerMutation,
    variables: {
      data: payload,
    },
  }).then(({ data, errors }) => {
      dispatch(setIsInfoLoading(false));
      if (!data && errors.length > 0) {
          throw new Error(errors[0].message);
      }
      dispatch(resetAddEmployerData());
      return data;
  }).catch((e) => {
    dispatch(MetaActions.errorToast(e.message));
    dispatch(setIsInfoLoading(false));
  });
};

export const employerCheck = email => dispatch => {
  return ApolloClient.query({
    query: employerCheckExistsQuery,
    variables: { email: email },
  })
    .then(({ data }) => {
      return data;
    })
    .catch(() => {
      dispatch(setIsInfoLoading(false));
    });
};

export const setValidEmail = email => {
  return {
    type: types.SET_VALID_EMAIL,
    payload: { email },
  };
};

export const validateEmail = value => {
  return {
    type: types.SET_VALID_SIN,
    payload: { value },
  };
};

export const checkPassword = password => {
  return {
    type: types.SET_PASSWORD_MATCH,
    payload: { password },
  };
};

export const setNewWorkerData = (field, value) => {
  if (field === "phone") {
    value = value.replace(/\D/g, "");
  }

  return {
    type: types.UPDATE_ADDWORKER_DATA,
    payload: { field, value },
  };
};

export const setPhone = phone => {
  return {
    type: types.UPDATE_FIRSTNAME,
    payload: { phone },
  };
};

export const setSin = sin => {
  sin = sin.replace(/\D/g, "");

  return {
    type: types.UPDATE_SIN,
    payload: { sin },
  };
};

export const setAvailableEmail = status => {
  return {
    type: types.SET_EMAIL_AVAILABLE,
    payload: { availability: status },
  };
};

export const EmployerEmailTaken = email => dispatch => {
  return ApolloClient.query({
    query: employerCheckExistsQuery,
    variables: { email: email },
  })
    .then(({ data }) => {
      dispatch(setAvailableEmail(!data.employerExists));
    })
    .catch(() => {
      dispatch(setIsInfoLoading(false));
      dispatch(MetaActions.errorToast(alertMessages.defaultError));
    });
};

export const setEmail = email => dispatch => {
  dispatch(setValidEmail(email));
  if (verifyEmail(email)) {
    dispatch(EmployerEmailTaken(email));
  }
};

export const setLocation = (selectedLocation, location) => ({
  type: types.SET_LOCATION,
  payload: { selectedLocation, location },
});

export const validateForm = () => (dispatch, getState) => {
  const {
    firstName,
    lastName,
    sinIsValid,
    emailIsAvailable,
    emailIsValid,
    sinIsTaken,
  } = getState().workers;

  if (
    firstName &&
    lastName &&
    sinIsValid &&
    emailIsAvailable &&
    emailIsValid &&
    sinIsTaken === false
  ) {
    return true;
  } else return false;
};
export const setNewEmployerData = (field, value) => {
  if (field === "phone") {
    value = value.replace(/\D/g, "");
  }
  return {
    type: types.UPDATE_ADDEMPLOYER_DATA,
    payload: { field, value },
  };
};
export const resetAddEmployerData = () => {
    return {
        type: types.RESET_ADDEMPLOYER_DATA,
    };
};


export const fetchEmployerAutoComplete = pageFilter => () => {
  return ApolloClient.query({
    query: gql`
      query employers(
        $first: Int
        $last: Int
        $after: String
        $before: String
        $order: [OrderInput!]
        $like: [FilterInput!]
        $canPost: Boolean
      ) {
        employers(
          first: $first
          last: $last
          after: $after
          before: $before
          order: $order
          like: $like
          canPost: $canPost
        ) {
          edges {
            node {
              id
              companyName
              street
              city
              region
              country
              firstName
              lastName
              email
              logoImageUrl
              signedLogoImageUrl
              mobilePhone
              billingRate
              payRate
            }
          }
        }
      }
    `,
    variables: {
      first: 5,
      order: [
        {
          field: "companyName",
          direction: "ASC",
        },
      ],
      like: [
        {
          field: "companyName",
          value: pageFilter,
        },
      ],
      canPost: true,
    },
  })
    .then(({ data }) => {
      const employers = flattenGraphQLArray(data.employers).map(employer => ({
        value: employer.id,
        label: employer.companyName,
        data: employer,
      }));

      // callback(employers);
      return employers;
    })
    .catch(e => {
      console.log("error", e);
    });
};
