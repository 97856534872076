export const paginationSettings = {
  positions: {
    prefix: "pagination_Positions",
    defaultPageSize: 5,
  },
  workerNotes: {
    prefix: "pagination_WorkerNotes",
    defaultPageSize: 5,
  },
  workerDocuments: {
    prefix: "pagination_WorkerDocuments",
    defaultPageSize: 5,
  },
  workerBannedFrom: {
    prefix: "pagination_WorkerBannedFrom",
    defaultPageSize: 5,
  },
};
