import { createReducer } from "utils/redux";
import types from "./types";
const initialLoadingState = {
  Otp: false,
  Login: false,
};
const authResponseInitalState = {
  profile: {},
  me:null,
  rememberUser: false,
  email: "",
  otp: "",
  auth0Id: null,
  isCheckingSession: false,
  isUsernameVerify: false,
  loading: { ...initialLoadingState },
  isLoggingOut: false
};

const initialState = { ...authResponseInitalState };

const actionsMap = {
  [types.SET_IS_LOGGING_OUT]: (state, { payload }) => {
    return { ...state, isLoggingOut: payload.isLoggingOut };
  },
  [types.SET_LOGIN_EMAIL]: (state, { payload }) => {
    return { ...state, email: payload.email };
  },
  [types.SET_IS_USERNAME_VERIFY]: (state, { payload }) => {
    return { ...state, isUsernameVerify: payload.isUsernameVerify };
  },
  [types.SET_OTP]: (state, { payload }) => {
    return { ...state, otp: payload.otp };
  },
  [types.RESET]: state => {
    return {
      ...state,
      ...initialState,
      email: state.email,
      otp: "",
      rememberUser: false,
    };
  },
  [types.FORGET_USER]: state => {
    return {
      ...state,
      rememberUser: false,
      email: "",
    };
  },
  [types.SET_CREDENTIALS]: (state, { payload }) => {
    const { permissions, roles, userId } = payload.auth;


    const profile = {
        locationData: {},
        roles: roles || [],
    };


    const newState = {
      ...state,
      profile,
      permissions: permissions || [],
      roles: roles || [],
      auth0Id: userId,
      otp: "",
    };

    return newState;
  },
  [types.IS_CHECKING_SESSION]: (state, { payload }) => {
    const { isCheckingSession } = payload;
    return {
      ...state,
      isCheckingSession,
    };
  },
  [types.SET_ME]: (state, { payload }) => {
    const { me } = payload;
    return {
      ...state,
      me: {
        ...me,
      },
    };
  },
  [types.SET_TIMEZONE_LIST]: (state, { payload }) => {
    return { ...state, timezoneList: payload };
  },
  [types.SET_LOADING_STATE]: (state, { payload }) => {
    const { key, value } = payload;
    return {
      ...state,
      loading: {
        ...state.loading,
        [key]: value,
      },
    };
  },
};

export default createReducer(initialState, actionsMap);
