import types from "./types";
import { createReducer } from "utils/redux";

const initialLoadingState = {
    tableLoading: true,
    formDialogLoading:false,
    deleteLoading:false
};

const initialDialogState = {
    addProvinceDocumentDialog: false,
    deleteProvinceDocumentDialog: false,
    editProvinceDocumentDialog: false
};

const initialState = {
    loading: { ...initialLoadingState },
    dialog: { ...initialDialogState },
    documents: [],
    documentForm: {
        id: null,
        name: "",
        document: null,
        province: null,
        helpText: '',
    }
};

const actionsMap = {
    [types.SET_LOADING_STATE]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            loading: {
                ...state.loading,
                [key]: value,
            },
        };
    },
    [types.UPDATE_PROVINCE_DOCUMENT_FORM]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            documentForm: {
                ...state.documentForm,
                [key]: value,
            },
        };
    },
    [types.RESET_FORM]: (state) => {
        return {
            ...state,
            documentForm: {
                id:null,
                name: "",
                document: null,
                province: null,
                helpText: '',
            }
        };
    },
    [types.SET_PROVINCE_DOCUMENTS_DATA]: (state, { payload }) => {
        const { documents } = payload;
        return {
            ...state,
            documents,
        };
    },
    [types.SET_DIALOG_STATE]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            dialog: {
                ...state.dialog,
                [key]: value,
            },
        };
    }
}

export default createReducer(initialState, actionsMap);
