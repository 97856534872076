import { gql } from "@apollo/client";
import EmployerFields from "../fragments/employer-fragment";

export default gql`
  query getEmployer($id: ID!) {
    employer(id: $id) {
      profileImageUrl
      signedProfileImageUrl
      logoImageUrl
      employerDocumentTypes{
        id
        grizzlyEmployerId
        documentTypeId
        createdAt
        updatedAt
        documentType {
            id
            name
            slug
            helpText
            createdBy
            updatedBy
            createdAt
            updatedAt
          }
      }
      signedLogoImageUrl
      ...EmployerFields
    }
    hoursTrendData: getHoursTrendByEmployer(employerId: $id) {
      monthTimestamp
      hoursWorked
    }
  }
  ${EmployerFields}
`;
