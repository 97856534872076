import withStyles from '@mui/styles/withStyles';
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Remove } from "@mui/icons-material";

export const DashIcon = withStyles({ root: { fontSize: 12 } })(Remove);
export const CustomTableCell = withStyles({
  root: {
    border: "none",
  },
  head: {
    fontSize: 14,
    color: "#404b5a",
    borderBottom: "1px solid lightgrey",
  },
  body: {
    fontSize: 12,
  },
})(TableCell);

export const CustomTableRow = withStyles({
  head: {
    height: 24,
    backgroundColor: "inherit",
    borderBottom: "0.7px solid lightgrey",
  },
})(TableRow);
