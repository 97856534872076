import ApolloClient from "utils/apollo";
import types from "./types";
import { actions as MetaActions } from "store/modules/meta";
import { gearsQuery } from "./graphql/queries/index";
import deleteGearMutation from "./graphql/mutations/delete-required-gear";
import createRequiredGearMutation from "./graphql/mutations/create-required-gear";
import updateRequiredGearMutation from "./graphql/mutations/update-required-gear";

const setLoadingState = key => value => ({
    type: types.SET_LOADING_STATE,
    payload: { key, value },
});

const setIsTableLoading = setLoadingState("tableLoading");

const setDialogState = key => value => ({
    type: types.SET_DIALOG_STATE,
    payload: { key, value },
});

export const setIsDeleteDialog = setDialogState("deleteDialog");
export const setIsaddGearDialog = setDialogState("addGearDialog");
export const setIsEditGearDialog = setDialogState("editGearDialog");

const setRequiredGears = requiredGears => ({
    type: types.SET_REQUIRED_GEAR_DATA,
    payload: { requiredGears }
});

export const fetchGears = () => (dispatch, getState) => {
    dispatch(setIsTableLoading(true));
    return ApolloClient.query({
        query: gearsQuery,
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            else {
                dispatch(setIsTableLoading(false));
                dispatch(setRequiredGears(data.gears));
            }
        })
        .catch(e => {
            dispatch(setIsTableLoading(false));
            dispatch(MetaActions.errorToast(`Could not find data: ${e.message}`));
        });
};

export const createRequiredGear = (values) => (dispatch, getState) => {
    const payload = {
        gearName: values.gearName,
        frenchGearName: values.frenchGearName,
        isActive: values.isActive,
    };
    return ApolloClient.mutate({
        mutation: createRequiredGearMutation,
        variables: { data: payload },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            dispatch(fetchGears());
            dispatch(setIsaddGearDialog(false));
            dispatch(MetaActions.successToast("Required Gear has been created."));
            return data;
        }).catch((e) => {
            dispatch(MetaActions.errorToast(e.message));
            dispatch(setIsaddGearDialog(false));
        });
};

export const updateRequiredGear = (values) => (dispatch, getState) => {
    const payload = {
        id: values.id,
        gearName: values.gearName,
        frenchGearName: values.frenchGearName,
        isActive: values.isActive,
    };
    return ApolloClient.mutate({
        mutation: updateRequiredGearMutation,
        variables: { data: payload },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            dispatch(fetchGears());
            dispatch(setIsEditGearDialog(false));
            dispatch(MetaActions.successToast(`Required Gear updated successfully.`));
            return data;
        }).catch((e) => {
            dispatch(MetaActions.errorToast(e.message));
            // dispatch(setIsEditGearDialog(false));
        });
};

export const deleteGear = (id) => (dispatch) => {
    ApolloClient.mutate({
        mutation: deleteGearMutation,
        variables: { id }
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            } else {
                dispatch(setIsDeleteDialog(false));
                dispatch(fetchGears());
                dispatch(MetaActions.successToast("Gear has been deleted."));
            }
        })
        .catch(e => {
            dispatch(setIsDeleteDialog(false));
            dispatch(
                MetaActions.errorToast(`${e.message}`)
            );
        });
};
