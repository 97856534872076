import React, { useEffect, useRef, useState } from "react";
import Dialog from "components/Dialog/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { Button, CircularProgress, DialogActions, TextField } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { actions as documentTypesActions, selectors as documentTypeSelectors } from "store/modules/documentType";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { isEmpty } from "lodash";

const style = ({ spacing }) => ({
    container: {
        marginBottom: spacing(2)
    },
    footer: {
        padding: spacing(1, 0)
    }
})

function AddDocumentTypeDialog({ classes, selectedDocument }) {
    const dispatch = useDispatch();
    const ref = useRef();
    const isOpen = useSelector(documentTypeSelectors.getIsAddDocumentTypeDialog);
    const isLoading = useSelector(documentTypeSelectors.getIsAddDocumentLoading);

    const hideAddDocumentTypeDialog = () => {
        dispatch(documentTypesActions.setIsAddDocumentTypeDialog(false));
    }

    return (
        <Dialog
            open={isOpen}
            title={`${selectedDocument ? "Update" : "Add"} Document Type`}
            onClose={hideAddDocumentTypeDialog}
            onCancel={hideAddDocumentTypeDialog}
            onConfirm={() => {
                console.log("test")
                ref?.current?.handleSubmit()
            }}
            disabled={!ref?.current?.dirty || !isEmpty(!ref?.current?.errors)}
            alertType="form"
            confirmText="Add"
            size="sm"
            modalType="scrollable"
            hideActions
        >
            <Formik
                innerRef={ref}
                initialValues={{
                    id: selectedDocument?.id,
                    name: selectedDocument?.name || "",
                    slug: selectedDocument?.slug || "",
                    // type: selectedDocument?.type || "",
                    helpText: selectedDocument?.helpText || "",
                }}
                onSubmit={(values) => {
                    dispatch(documentTypesActions.createDocumentType(values));
                }}
                enableReinitialize
                validationSchema={validationSchema}
            >
                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        dirty,
                        handleChange,
                        handleBlur,
                    } = props;

                    return (
                        <Form style={{ width: "100%" }}>
                            <TextField
                                className={classes.textField}
                                fullWidth
                                margin="normal"
                                label="Name"
                                name="name"
                                value={values.name}
                                autoFocus
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={errors.name && touched.name && errors.name}
                                error={errors.name && touched.name && errors.name}
                            />
                            <TextField
                                className={classes.textField}
                                fullWidth
                                margin="normal"
                                label="Slug"
                                name="slug"
                                value={values.slug}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={errors.slug && touched.slug && errors.slug}
                                error={errors.slug && touched.slug && errors.slug}
                            />
                            {/* <TextField
                                className={classes.textField}
                                fullWidth
                                margin="normal"
                                label="Type"
                                name="type"
                                value={values.type}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={errors.type && touched.type && errors.type}
                                error={errors.type && touched.type && errors.type}
                            /> */}
                            <TextField
                                className={classes.textField}
                                fullWidth
                                margin="normal"
                                label="Help Text"
                                name="helpText"
                                value={values.helpText}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={errors.helpText && touched.helpText && errors.helpText}
                                error={errors.helpText && touched.helpText && errors.helpText}
                            />
                            <DialogActions className={classes.footer}>
                                <Button disabled={isLoading} color="error" type="button" className="outline" onClick={hideAddDocumentTypeDialog}>
                                    Close
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    type="submit"
                                    disabled={!dirty || !isEmpty(errors) || isLoading}
                                >
                                    {isLoading ? <CircularProgress size={20} /> : selectedDocument ? "Update" : "Add"}
                                </Button>
                            </DialogActions>
                        </Form>
                    )
                }}
            </Formik>
        </Dialog>
    )
};


const validationSchema = yup.object().shape({
    name: yup
        .string().typeError('Required field')
        .required("Document name is required")
        .max(50, 'Document Name shoud must be less 50 characters'),
    slug: yup
        .string().typeError('Required field')
        .required("Document slug is required")
        .max(50, 'Document Slug shoud must be less 50 characters'),
    // type: yup
    //     .string().typeError('Required field')
    //     .required("Document type is required"),
    helpText: yup
        .string().typeError('Required field')
        .required("Document help text is required")
        .max(50, 'Document help text shoud must be less 50 characters'),
});


export default (withStyles(style)(AddDocumentTypeDialog));
