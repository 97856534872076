import * as pagination from "utils/graphql-pagination";
import { createReducer } from "utils/redux";
import { verifyEmail } from "utils/stringUtils";
import { paginationSettings } from "./constants";
import types from "./types";

const initialState = {
  details: {},
  unapprovedEmployerDetails: {},
  deactivatedEmployerDetails: {},
  editData: {},
  ban: {},
  prefer: {},
  newEmployer: {},
  addEmployerData: { isActive: true, isApproved: true },
  pageSort: [{ id: "createdAt", desc: true }],
  deactivatedPageSort: [{ id: "createdAt", desc: true }],
  unapprovedPageSort: [{ id: "createdAt", desc: true }],
  // pageSort: [{ id: "createdAtUtc", desc: true }],
  // deactivatedPageSort: [{ id: "createdAtUtc", desc: true }],
  // unapprovedPageSort: [{ id: "createdAtUtc", desc: true }],
  pageFilter: [],
  deactivatedPageFilter: [],
  unapprovedPageFilter: [],
  isDetailLoading: false,
  isInfoLoading: false,
  emailIsAvailable: null,
  payRateIsValid:null,
  billingRateIsValid:null,
  ...pagination.initialState(paginationSettings),
};

const actionsMap = {
  [types.UPDATE_PAGE_FILTER]: (state, { payload }) => {
    const { pageFilter } = payload;
    return {
      ...state,
      pageFilter,
    };
  },

  [types.UPDATE_UNAPPROVED_PAGE_FILTER]: (state, { payload }) => {
    const { pageFilter } = payload;
    return {
      ...state,
      unapprovedPageFilter: pageFilter,
    };
  },

  [types.UPDATE_DEACTIVATED_PAGE_FILTER]: (state, { payload }) => {
    const { pageFilter } = payload;
    return {
      ...state,
      deactivatedPageFilter: pageFilter,
    };
  },

  //
  //
  //

  [types.SET_EMPLOYERS]: (state, { payload }) => {
    const { employers } = payload;
    return {
      ...state,
      details: employers.reduce((acc, employer) => {
        acc[employer.id] = employer;
        return acc;
      }, {}),
    };
  },

  [types.SET_DEACTIVATED_EMPLOYERS]: (state, { payload }) => {
    const { employers } = payload;
    return {
      ...state,
      deactivatedEmployerDetails: employers.reduce((acc, employer) => {
        acc[employer.id] = employer;
        return acc;
      }, {}),
    };
  },

  [types.SET_UNAPPROVED_EMPLOYERS]: (state, { payload }) => {
    const { employers } = payload;
    return {
      ...state,
      unapprovedEmployerDetails: employers.reduce((acc, employer) => {
        acc[employer.id] = employer;
        return acc;
      }, {}),
    };
  },
  //
  //

  [types.SET_LOCATION]: (state, { payload }) => {
    const { selectedLocation, location } = payload;
    return {
      ...state,
      addEmployerData: {
        ...state.addEmployerData,
        location: location,
      },
      location: location,
      selectedLocation: selectedLocation,
    };
  },

  //
  [types.UPDATE_ADDEMPLOYER_DATA]: (state, { payload }) => {
    const { field, value } = payload;
    if(field === "payRate"){
      const payRateIsValid = Number(value) > 0;
      const billingRateIsValid = Number(state.addEmployerData.billingRate) > Number(value);
      return {
        ...state,
        addEmployerData: {
          ...state.addEmployerData,
          [field]: value,
        },
        payRateIsValid,
        billingRateIsValid
      };
    }
    if(field === "billingRate"){
      const billingRateIsValid = Number(value) > state.addEmployerData.payRate;
      return {
        ...state,
        addEmployerData: {
          ...state.addEmployerData,
          [field]: value,
        },
        billingRateIsValid,
      };
    }
    return {
      ...state,
      addEmployerData: {
        ...state.addEmployerData,
        [field]: value,
      },
    };
  },

    //
    [types.RESET_ADDEMPLOYER_DATA]: (state) => {
        return {
            ...state,
            addEmployerData: {},
            selectedLocation: ''
        };
    },
  //

  [types.SET_EMAIL_AVAILABLE]: (state, { payload }) => {
    return {
      ...state,
      emailIsAvailable: payload.availability,
    };
  },
  //

  [types.SET_VALID_EMAIL]: (state, { payload }) => {
    return {
      ...state,
      emailIsValid: verifyEmail(payload.email),
      addEmployerData: {
        ...state.addEmployerData,
        email: payload.email,
      },
    };
  },

  [types.ADD_EMPLOYER_PREFERRED]: (state, { payload }) => {
    const { preferred } = payload;
    return {
      ...state,
      prefer: preferred,
    };
  },

  [types.ADD_EMPLOYER_BAN]: (state, { payload }) => {
    const { ban } = payload;
    return {
      ...state,
      ban: ban,
    };
  },

  [types.REMOVE_EMPLOYER_REP]: (state, action) => {
    return {
      ...state,
      rep: action.payload,
    };
  },
  [types.SET_EMPLOYER_BANS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  },
  [types.SET_EMPLOYER_PREFERRED]: (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  },
  //
  //
  [types.UPDATE_PAGE_SORT]: (state, { payload }) => {
    const { pageSort } = payload;
    return {
      ...state,
      pageSort,
    };
  },
  [types.UPDATE_DEACTIVATED_PAGE_SORT]: (state, { payload }) => {
    const { pageSort } = payload;
    return {
      ...state,
      deactivatedPageSort: pageSort,
    };
  },
  [types.UPDATE_UNAPPROVED_PAGE_SORT]: (state, { payload }) => {
    const { pageSort } = payload;
    return {
      ...state,
      unapprovedPageSort: pageSort,
    };
  },
  //
  //
  [types.UPDATE_LIST_FILTER]: (state, { payload }) => {
    const { pageFilter } = payload;
    return {
      ...state,
      pageFilter,
    };
  },
  [types.SET_NEW_EMPLOYER]: (state, { payload }) => {
    const { employer } = payload;
    return {
      ...state,
      newEmployer: employer,
    };
  },

  [types.SET_EMPLOYER]: (state, { payload }) => {
    const { employer } = payload;
    return {
      ...state,
      details: {
        ...state.details,
        [employer.id]: employer,
      },
    };
  },

  [types.IS_LOADING_DETAIL]: (state, { payload }) => {
    const { isDetailLoading } = payload;
    return {
      ...state,
      isDetailLoading,
    };
  },
  [types.UPDATE_EDIT_DATA]: (state, { payload }) => {
    const { field, value } = payload;
    return {
      ...state,
      editData: {
        ...state.editData,
        [field]: value,
      },
    };
  },
  [types.RESET_EDIT_DATA]: state => {
    return {
      ...state,
      editData: {},
    };
  },
  [types.IS_LOADING_INFO]: (state, { payload }) => {
    const { isInfoLoading } = payload;
    return {
      ...state,
      isInfoLoading,
    };
  },
  ...pagination.createPaginationActions(paginationSettings),
};

export default createReducer(initialState, actionsMap);
