import { gql } from "@apollo/client";

export default gql`
  query getWorkPermitWorkersStats(
    $order: [WorkerOrderInput!]
    $like: [WorkerFilterInput!]
    $isSin9: Boolean
  ) {
    workers(
      order: $order
      like: $like
      isSin9: $isSin9
    ) {
      totalCount
    }
  }
`;
