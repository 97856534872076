import { MonetizationOn, Refresh } from '@mui/icons-material';
import { CircularProgress, Grid, Typography, } from '@mui/material'
import { makeStyles } from '@mui/styles';
import Can from 'components/Authorization/Can';
import Button from 'components/Button/Button';
import { JobPermissions } from 'helpers/constants/permissions';
import React, { useEffect } from 'react'
import HolidayWorkerTable from './HolidayWorkerTable';
import { actions as HolidayActions, selectors as HolidaySelectors } from "store/modules/holidays"
import { useDispatch, useSelector } from 'react-redux';


const useStyles = makeStyles(({ spacing, palette, shape }) => ({
    root: {
        backgroundColor: palette.common.white,
        border: `1px solid ${palette.grey[500]}`,
        borderRadius: shape.borderRadius / 2,
        marginBottom: spacing(1)
    },
    titleContainer: {
        padding: `${spacing(1)} ${spacing(2)}`,
        minHeight: 64,
    },
    filledBreakdown: {
        marginRight: spacing(1),
    },
    pay: {
        "&:nth-child(1)": {
            marginLeft: spacing(2),
        },
        "& button": {
            paddingLeft: spacing(2),
            paddingRight: spacing(2),
        },
    },
    payLabel: {
        marginTop: 1,
    },
    loader: {
        "& svg": {
            margin: "0 !important",
            marginTop: "0 !important",
        }
    }
}));

export default function HolidayWorkerTableContainer({ province }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isRefreshing = useSelector(HolidaySelectors.getIsRefreshWorkers);
    const workers = useSelector(HolidaySelectors.getHolidayWorkers);
    const isLoading = useSelector(HolidaySelectors.getIsWorkersLoading);
    const holidayDetails = useSelector(HolidaySelectors.getHolidayDetail);

    useEffect(() => {
        return () => {
            dispatch(HolidayActions.setIsPayrollSummaryDialog(false))
        };
    }, []);

    const handleRefresh = () => {
        dispatch(HolidayActions.refreshHolidayWorkersData(province?.provinceId))
    };

    const toggleReviewingPayroll = () => {
        dispatch(HolidayActions.setIsPayrollSummaryDialog(true))
    };

    return (
        <Grid container className={classes.root}>
            <Grid container className={classes.titleContainer}>
                <Grid container item xs={6} direction="row" alignItems="center">
                    <Typography className={classes.filledBreakdown} variant="subtitle2">
                        {`Workers`}
                    </Typography>
                </Grid>
                <Grid container item xs={6} justifyContent="flex-end" alignItems="center">
                    <>
                        <Button
                            label="Fetch Workers"
                            className={classes.pay}
                            labelClassName={classes.payLabel}
                            icon={ <Refresh />}
                            alignIcon="left"
                            isLoading={isRefreshing || isLoading}
                            onClick={handleRefresh}
                            disabled={isRefreshing || isLoading}
                        />
                        {Boolean(workers?.length) && <Can perform={JobPermissions.action.payWorkers.value}>
                            {allowed => {
                                return allowed ? (
                                    <Button
                                        label="Pay"
                                        className={classes.pay}
                                        labelClassName={classes.payLabel}
                                        icon={<MonetizationOn />}
                                        alignIcon="left"
                                        disabled={isRefreshing || isLoading}
                                        onClick={toggleReviewingPayroll}
                                    />
                                ) : null;
                            }}
                        </Can>}
                    </>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <HolidayWorkerTable province={province} holiday={holidayDetails} />
            </Grid>
        </Grid>
    )
}
