import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import _debounce from "lodash/debounce";
import { actions as JobActions, selectors as JobSelectors } from "store/modules/jobs";
import {
    checkInOutColumn,
    createActionColumn,
    getTdProps,
    getTdPropsV7,
    payrollStatusColumn,
    sharedColumns,
    sharedProps,
    tagsColumn,
} from "./helpers/tableProps";
import ReactTableCustom from "components/ReactTable";

const TableWrapper = (props) => {
    const dispatch = useDispatch()
    const [showFilters, setShowFilters] = useState(false);
    const fetchData = useCallback(({ pageSize, pageIndex, filters, sortBy }) => {
        dispatch(() => props.onSortedChange(sortBy));
        dispatch(() => props.onFilteredChange(filters));
        dispatch(() => props.onPageSizeChange(pageSize));
        dispatch(() => props.onPageChange(pageIndex));
    }, []);

    useEffect(() => {
        setShowFilters(false);
    }, []);

    const toggleFilters = () => {
        setShowFilters(p => !p);
    };

    return (
        <ReactTableCustom
            {...sharedProps}
            fetchData={fetchData}
            defaultFilter={props.pageFilter}
            currentPage={props.currentPage}
            defaultPageSize={props.pageSize}
            loading={props.isLoading}
            pageCount={props.totalCount}
            defaultSort={props.pageSort}
            style={{ marginTop: "16px" }}
            data={props.jobs}
            columns={[
                ...sharedColumns,
                checkInOutColumn,
                payrollStatusColumn,
                tagsColumn,
                createActionColumn({
                    // toggleFilters: toggleFilters,
                    // showFilters: showFilters,
                }),
            ]}
            onRowClick={getTdPropsV7}
            hiddenColumns={props.hiddenColumns}
            // filterable={showFilters}

        />
    );
}

class HistoryTable extends React.Component {
    render() {
        return (
            <TableWrapper {...this.props} />
        );
    }
}

const mapState = state => {
    const { totalCount, pageCount, pageSize, currentPage, isLoading } = JobSelectors.getHistoryPaginationData(
        state
    );
    return {
        jobs: JobSelectors.getListData(state, {
            timeframe: "history",
            part: "data",
        }),
        pageFilter: JobSelectors.getListData(state, {
            timeframe: "history",
            part: "filter",
        }),
        pageSort: JobSelectors.getListData(state, {
            timeframe: "history",
            part: "sort",
        }),
        pageCount,
        pageSize,
        currentPage,
        isLoading,
        totalCount
    };
};

const mapAction = {
    fetchJobs: JobActions.fetchHistoryJobs,
    onPageChange: JobActions.fetchHistoryJobs,
    onSortedChange: JobActions.setHistorySort,
    onFilteredChange: JobActions.setHistoryFilter,
    onPageSizeChange: JobActions.setHistoryPageSize,
};

export default connect(mapState, mapAction)(withRouter(HistoryTable));
