import gql from "graphql-tag";

export default gql`mutation ResolveHolidayPayReversal($reversalId: Int!) {
    resolveHolidayPayReversal(reversalId: $reversalId) {
      id
      createdAt
      updatedAt
      responseAt
      sentAt
      holidayWorkerId
      workerId
      holidayPayrollId
      holidayPaymentId
      paymentStatusId
      dcTransferId
      reversedDcTransferId
      dcCustomerNumber
      paymentProviderReferenceCode
      email
      amount
      errorCode
      errorDescription
      notes
    }
  }`;
