import React, { useEffect } from "react";
import Stage from "./components/Stage";
import { useHistory, useParams, withRouter } from "react-router-dom";
import SummaryFooter from "./components/SummaryFooter";
import MainContent from "components/Containers/MainContent";
import { Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material";
import FormRow from "./components/FormRow";
import EmployerPicker from "./components/EmployerPicker";
import CustomFieldTypeSelection from "./components/CustomFieldTypeSelection";
import { useDispatch, useSelector } from "react-redux";
import { actions as CustomFieldActions, selectors as CustomFieldSelectors } from "store/modules/customFields";
import { makeStyles } from "@mui/styles";
import WorkerPicker from "./components/WorkerPicker";

const useStyles = makeStyles(theme => ({
    label: {
        width: 'fit-content',
        marginTop: 8,
        "& .MuiFormControlLabel-label": {
            color: theme.palette.text.primary
        }
    }
}));

function AddCustomField({ ...props }) {
    const history = useHistory();
    const isLoading = useSelector(CustomFieldSelectors.getIsFillUpdateCustomFieldDataLoading)
    const activeTab = useSelector(CustomFieldSelectors.getActiveTab)
    const isAdding = useSelector(CustomFieldSelectors.getIsAddCustomField)
    const customFieldName = useSelector((state) => CustomFieldSelectors.getFormData(state, "name"))
    const customFieldSlug = useSelector((state) => CustomFieldSelectors.getFormData(state, "slug"))
    const customFieldAssignTo = useSelector((state) => CustomFieldSelectors.getFormData(state, "assignTo"))
    const isRequired = useSelector((state) => CustomFieldSelectors.getFormData(state, "isRequired"))
    const dispatch = useDispatch();
    const classes = useStyles();
    const params = useParams();
    const customFieldId = Number(params?.id);
    const isEdit = !isNaN(customFieldId)
    const canAdd = useSelector((state) => CustomFieldSelectors.canAddCustomField(state, isEdit))

    useEffect(() => {
        if (isEdit) {
            dispatch(CustomFieldActions.getEditCustomFieldDetails(customFieldId));
        }
    }, [customFieldId])

    const handleChange = ({ target: { value, name } }) => {
        if (name === "assignTo") {
            dispatch(CustomFieldActions.clearSelectedPickerData({ key: "workers", value: [] }));
            dispatch(CustomFieldActions.clearSelectedPickerData({ key: "employers", value: [] }));
        };
        dispatch(CustomFieldActions.setCustomFieldForm(name, value));
    };

    const handleChangeIsRequired = (e) => {
        dispatch(CustomFieldActions.setCustomFieldIsRequired(e.target.checked))
    };

    const handleSubmit = (e) => {
        dispatch(CustomFieldActions.createCustomField(isEdit ? customFieldId : null, callback));
    };

    const callback = () => {
        if (isEdit) {
            history.goBack();
        } else {
            history.push(`/custom-field/overview?type=${activeTab === null ? 0 : activeTab}`);
        };
    };


    return (
        <MainContent isLoading={isLoading}>
            <Grid container>
                <Stage
                    title="Field name and slug"
                    description="Input all of the information for custom field."
                    style={{ borderBottom: "1px solid #aebecd" }}
                >
                    <FormRow>
                        <FormControl fullWidth>
                            <TextField
                                name="name"
                                value={customFieldName}
                                onChange={handleChange}
                                fullWidth={true}
                                margin="normal"
                                placeholder="Enter custom field name"
                                label="Name"
                                error={customFieldName?.length > 50}
                                helperText={customFieldName?.length > 50 ? "Name must be less 50 characters" : ""}
                            />
                        </FormControl>
                    </FormRow>
                    <FormRow>
                        <FormControl fullWidth>
                            <TextField
                                name="slug"
                                value={customFieldSlug}
                                onChange={handleChange}
                                fullWidth={true}
                                margin="normal"
                                placeholder="Enter slug"
                                label="Slug"
                                error={customFieldSlug?.length > 50}
                                helperText={customFieldSlug?.length > 50 ? "Slug must be less 50 characters" : ""}
                            />
                        </FormControl>
                    </FormRow>
                    <FormRow>
                        <FormControlLabel
                            classes={{ root: classes.label }}
                            label="This field is required"
                            control={<Checkbox checked={isRequired} onChange={handleChangeIsRequired} />} />
                    </FormRow>
                </Stage>
                {!isEdit && <Stage
                    title="Assign custom field"
                    description="Assign custom field for user profile"
                    style={{ borderBottom: isEdit ? 0 : "1px solid #aebecd" }}
                >
                    <FormRow>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="assignTo"
                            value={customFieldAssignTo}
                            onChange={handleChange}
                        >
                            <FormControlLabel
                                classes={{ root: classes.label }}
                                value="EMPLOYER"
                                control={<Radio />}
                                label="Employer" />
                            <FormControlLabel
                                classes={{ root: classes.label }}
                                value="WORKER"
                                control={<Radio />}
                                label="Worker" />
                        </RadioGroup>
                    </FormRow>
                </Stage>}
                <Stage
                    title="Select type"
                    description="Custom field type"
                    style={{ borderBottom: isEdit ? 0 : "1px solid #aebecd" }}
                >
                    <CustomFieldTypeSelection />
                </Stage>
                {!isEdit && <Stage
                    title={`Select ${customFieldAssignTo === "EMPLOYER" ? "employers" : "workers"}`}
                    description={`Link field with ${customFieldAssignTo === "EMPLOYER" ? "employer" : "worker"}`}
                >
                    <FormRow>
                        {customFieldAssignTo === "EMPLOYER" ? <EmployerPicker /> : <WorkerPicker isShowWorkerPicker={true} />}
                    </FormRow>
                </Stage>}
                <SummaryFooter isEdit={isEdit} loading={isAdding} onClick={handleSubmit} canAdd={!canAdd} />
            </Grid>
        </MainContent>
    )
}

export default (withRouter(AddCustomField))
