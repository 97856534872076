import { gql } from "@apollo/client";

export default gql`
query CustomField($id: Float!) {
    CustomField(id: $id) {
      id
      name
      slug
      assignTo
      type
      isRequired
      isForAll
      createdBy
      updatedBy
      deletedBy
      createdAt
      updatedAt
      getCustomFieldsOptions {
        id
        customFieldId
        label
        value
        createdAt
        updatedAt
      }
      getEmployerCustomFieldsValues {
        id
        employerId
        customFieldId
        value
        createdAt
        updatedAt
        customField {
            id
            name
            slug
            type
            isRequired
            isForAll
            createdBy
            updatedBy
            deletedBy
            createdAt
            updatedAt
        }
      }
    }
  }
`;
