import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import makeStyles from '@mui/styles/makeStyles';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import WorkerProfileTab from "./WorkerProfileTab";
import WorkerSettingsTab from "./WorkerSettingsTab";
import WorkPermitDocumentTab from "./WorkPermitDocumentTab";
const isMobile = false;
function EditWorkerProfileTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [setIsTouched] = React.useState();
  const a11yProps = index => {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  };
  const tabProps = {
    indicatorColor: "secondary",
    textColor: "secondary",
    variant: isMobile ? "scrollable" : "standard",
    scrollButtons: "auto",
    icon: <Box color="action" />,
    TabIndicatorProps: { style: { float: "right" } },
  };
  const styles = {
    //   borderRight: `1px solid ${palette.divider}`,

    textColorPrimary: { color: "#FFF" },
    textColorSecondary: { color: "#FFF" },
  };
  const handleChange = (event, newValue) => {
    switch (newValue) {
      case 0:
        setValue(newValue);
        break;
      case 1:
        setValue(newValue);
        break;
      case 2:
        setValue(newValue);
        break;
      case 3:
        setValue(newValue);
        break;
      case 4:
        setValue(newValue);
        break;
      default:
        setValue(newValue);
        break;
    }
  };

  const handleFormState = state => {
    setIsTouched(state);
  };

  const RenderAppBar = () => (
    <AppBar position="static" color="transparent" className={classes.tabNav}>
      <Tabs {...tabProps} centered value={value} onChange={handleChange} style={styles}>
        <Tab label="Worker Profile" {...a11yProps(0)} />
        <Tab label="Account Settings" {...a11yProps(1)} />
        <Tab label="Work Permit Document" {...a11yProps(2)} />
      </Tabs>
    </AppBar>
  );
  return (
    <>
      <RenderAppBar />
      <WorkerProfileTab {...props} value={value} handleFormState={handleFormState} index={0} />
      <WorkerSettingsTab {...props} value={value} index={1} />
      <WorkPermitDocumentTab {...props} value={value} index={2} />
    </>
  );
}

const useStyles = makeStyles(
  ({ palette, shape, transitions, breakpoints, spacing, typography }) => ({
    tableStyles: {
      /* Styles applied to the root element. */
      root: {
        ...typography.body2,
        display: "table-cell",
        verticalAlign: "inherit",
        // Workaround for a rendering bug with spanned columns in Chrome 62.0.
        // Removes the alpha (sets it to 1), and lightens or darkens the theme color.
        borderBottom: `1px solid lightgrey`,
        textAlign: "left",
        padding: 16,
      },
      /* Styles applied to the root element if `variant="head"` or `context.table.head`. */
      head: {
        color: palette.text.primary,
        lineHeight: typography.pxToRem(24),
        fontWeight: typography.fontWeightMedium,
      },
      /* Styles applied to the root element if `variant="body"` or `context.table.body`. */
      body: {
        color: palette.text.primary,
      },
      /* Styles applied to the root element if `variant="footer"` or `context.table.footer`. */
      footer: {
        color: palette.text.secondary,
        lineHeight: typography.pxToRem(21),
        fontSize: typography.pxToRem(12),
      },
      /* Styles applied to the root element if `size="small"`. */
      sizeSmall: {
        padding: "6px 12px 6px 8px",
        "&:last-child": {
          paddingRight: 8,
        },
        "&$paddingCheckbox": {
          width: 24, // prevent the checkbox column from growing
          padding: "0 12px 0 16px",
          "&:last-child": {
            paddingLeft: 12,
            paddingRight: 16,
          },
          "& > *": {
            padding: 0,
          },
        },
      },
      /* Styles applied to the root element if `padding="checkbox"`. */
      paddingCheckbox: {
        width: 48, // prevent the checkbox column from growing
        padding: "0 0 0 4px",
        "&:last-child": {
          paddingLeft: 0,
          paddingRight: 4,
        },
      },
      /* Styles applied to the root element if `padding="none"`. */
      paddingNone: {
        padding: 0,
        "&:last-child": {
          padding: 0,
        },
      },
      /* Styles applied to the root element if `align="left"`. */
      alignLeft: {
        textAlign: "left",
      },
      /* Styles applied to the root element if `align="center"`. */
      alignCenter: {
        textAlign: "center",
      },
      /* Styles applied to the root element if `align="right"`. */
      alignRight: {
        textAlign: "right",
        flexDirection: "row-reverse",
      },
      /* Styles applied to the root element if `align="justify"`. */
      alignJustify: {
        textAlign: "justify",
      },
      /* Styles applied to the root element if `context.table.stickyHeader={true}`. */
      stickyHeader: {
        position: "sticky",
        top: 0,
        left: 0,
        zIndex: 2,
        backgroundColor: palette.background.default,
      },
    },
    qbPreferences: {
      listItem: { padding: 0, paddingBottom: 0 },
    },
    icon: {
      fontSize: typography.pxToRem(20),
      marginRight: spacing(0),
      root: {
        fontSize: 62,
      },
    },
    qbCommitInvoiceButton: {
      margin: spacing(1),
      float: "inline-end",
    },
    invoiceStatusIcon: {
      fontSize: typography.pxToRem(16),
      marginRight: spacing(0),
      root: {
        fontSize: 24,
        marginRight: spacing(0),
      },
      check: {
        fontSize: 18,
        color: "green",
        marginRight: spacing(0),
        root: {
          fontSize: 18,
          width: 18,
          height: 18,
          color: "green",
          marginRight: spacing(0),
        },
      },
    },

    qbPaymentItem: {
      margin: spacing(0),
      padding: spacing(0),
      color: "darkgrey",
      fontWeight: 400,
      fontSize: 13,
      width: "100%",
    },
    qbPaymentAmount: {
      margin: spacing(0),
      padding: spacing(0),
      color: "green",
      fontWeight: 600,
      fontSize: 14,
      width: "100%",
    },
    qbPaymentTitle: {
      margin: spacing(0),
      padding: spacing(0),
      top: "30%",
      color: "rgb(110 122 138)",
      fontWeight: 600,
      fontSize: 18,
      width: "100%",
      root: {
        paddingBottom: spacing(0),
        width: "100%",
      },
    },
    pieChart: {
      root: {
        flexGrow: 1,
        width: "100%",
        color: "#FFF",
        //   height: 400,
        backgroundColor: palette.background.paper,
      },
    },
    tabNav: {
      colorSecondary: {
        color: "#FFF",
      },
      boxShadow: "none",
      root: {
        "& > *": {
          margin: spacing(0),
          padding: spacing(0),
          width: "100%",
          // height: 400,
          boxShadow: "none",
        },
        boxShadow: "none",
      },
    },
    root: {
      padding: `${spacing(0)} !important`,
      width: "100%",
      "& > *": {
        margin: spacing(0),
        padding: spacing(0),
        width: "100%",
        //   height: 400,
      },
    },
    vertTabRoot: {
      root: {
        flexGrow: 1,
        backgroundColor: palette.background.paper,
        display: "flex",
        height: 224,
      },
    },
    tabs: {
      borderRight: `1px solid ${palette.divider}`,
      root: {
        "& > *": {
          margin: spacing(0),
          padding: spacing(0),
          width: "100%",
          // height: 400,
        },
      },
      textColorPrimary: { color: "#FFF" },
      textColorSecondary: { color: "#FFF" },
    },
    action: {
      margin: spacing(0),
      padding: spacing(0),
      top: "25%",
      fontSize: 42,
    },
    statsDialogTitle: { marginLeft: -20, marginTop: -20, paddingBottom: 8 },
    statsDialogTitleCopy: {
      fontWeight: 500,
      fontSize: 28,
      fontFamily: "Roboto",
      color: "rgb(110 122 138)",
    },
    copy: {
      margin: spacing(0),
      padding: spacing(0),
      paddingTop: spacing(0),
      color: "rgb(110 122 138)",
      fontWeight: 400,
      fontSize: 13,
    },
    card: {
      backgroundColor: palette.common.white,
      border: `0.7px solid ${palette.grey[400]}`,
      borderRadius: shape.borderRadius,
      boxShadow: "none",
      width: "100%",
      padding: `${spacing(0)} !important`,
      paddingRight: `${spacing(0)} !important`,
      marginRight: `${spacing(0.5)} !important`,
      marginLeft: `${spacing(0.5)} !important`,
      marginBottom: `${spacing(1)} !important`,
      [breakpoints.down("sm")]: {
        padding: `${spacing(1)} !important`,
        fontSize: 13,
        "&:h5": { fontSize: 13 },
        // "&:nth-child(2)": { paddingRight: spacing(1) },
        // "&:last-child": { paddingLeft: spacing(1) },
      },
    },
    invoiceListItem: {
      margin: spacing(0),
      padding: spacing(0),
      paddingTop: spacing(0),
      color: "rgb(110 122 138)",
      fontWeight: 400,
      fontSize: 13,
      width: "100%",
    },
    dialogContent: {
      margin: spacing(0),
      padding: spacing(0),
      minHeight: 300,
      width: "100%",
      color: "darkgrey",
    },
    title: {
      margin: spacing(0),
      padding: spacing(0),
      top: "30%",
      color: "rgb(110 122 138)",
      fontWeight: 600,
      fontSize: 18,
      width: "100%",
      root: {
        paddingBottom: spacing(0),
        width: "100%",
      },
    },
    actionIcon: {
      margin: spacing(0),
      padding: spacing(0),
      top: "30%",
      fontSize: 42,
      root: {
        fontSize: 42,
      },
    },
    placeholderContainer: {
      paddingRight: spacing(0),
      boxShadow: "none",
      marginTop: spacing(0),
      [breakpoints.down("sm")]: { paddingRight: spacing(0), paddingBottom: spacing(0) },
    },
    container: {
      paddingRight: spacing(2),
      boxShadow: "none",
      paddingBottom: spacing(0.7),
      // "&:last-child": { paddingRight: 0 },
      "&:nth-child(3n)": { paddingRight: 0 },
      marginTop: spacing(0),
      [breakpoints.down("sm")]: { paddingRight: spacing(0), paddingBottom: spacing(1) },
    },
    wrapper: {
      boxShadow: "none",
      width: "100%",
      padding: `${spacing(1)} ${spacing(0)} !important`,
    },
    cardStyle: {
      backgroundColor: palette.common.white,
      border: `1px solid ${palette.grey[500]}`,
      borderRadius: shape.borderRadius,
      padding: `${spacing(0)} !important`,
      height: "100%",
      boxShadow: "none",
    },
    placeholderCard: {
      backgroundColor: palette.common.white,
      border: `0px solid ${palette.grey[500]}`,
      borderRadius: shape.borderRadius,
      padding: `${spacing(0)} !important`,
      height: "100%",

      "&:last-child": { marginRight: 0 },
    },
    cardRoot: {
      boxShadow: "none",
      padding: `${spacing(0)} !important`,
      width: "100%",
    },
    star: {
      color: palette.text.primary,
    },
    availabilityContainer: {
      backgroundColor: palette.grey[500],
      width: 25,
      height: 25,
    },
    availableCell: {
      backgroundColor: palette.primary.main,
    },
    //   root: {
    //     boxShadow: "none",
    //     padding: `${spacing(0)} !important`,
    //     width: "100%",
    //   },

    paper: {
      width: "100%",
      margin: `${spacing(0)} !important`,
      padding: `${spacing(0)} !important`,
      marginBottom: spacing(2),
      boxShadow: "none",
    },
    table: {
      width: "100%",
      margin: `${spacing(0)} !important`,
      padding: `${spacing(0)} !important`,
    },

    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: transitions.create("transform", {
        duration: transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    avatar: {
      fontSize: 38,
      fontWeight: 500,
      color: palette.secondary.main,
    },

    subHeader: {
      fontSize: 13,
      boxShadow: "none",
      fontWeight: 500,
    },
  })
);
export default EditWorkerProfileTabs;
