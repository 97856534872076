import { AssignmentTurnedIn, Block, PendingActions } from '@mui/icons-material';
import { CircularProgress, TextField } from '@mui/material';
import MainContent from 'components/Containers/MainContent';
import Dialog from 'components/Dialog/Dialog';
import SubNav from 'components/Navigation/SubNav';
import SubNavTab from 'components/Navigation/SubNavTab';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import ApprovedTab from './Components/ApprovedTab';
import PendingTab from './Components/PendingTab';
import RejectedTab from './Components/RejectedTab';
import { actions as DocumentsActions, selectors as DocumentsSelectors } from "store/modules/documents";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    svgRoot: {
        marginTop: `0 !important`
    }
}))

export default function Documents({ ...props }) {
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(0);
    const statusframes = ['pending', 'approved', 'reject'];
    const isOpenDialog = useSelector(DocumentsSelectors.getIsOpenRejectDialog);
    const selectedDocument = useSelector(DocumentsSelectors.getSelectedDocument);
    const isUpdateStatus = useSelector(DocumentsSelectors.getIsDocumentStatusUpdate);
    const rejectReason = useSelector(DocumentsSelectors.getRejectReason);
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.history.location.search) {
            const params = new URLSearchParams(props.history.location.search);
            const statusframe = parseInt(params.get("statusframe"), 10);
            if (!isNaN(statusframe)) {
                setTabValue(parseInt(statusframe, 10));
            }
        } else {
            props.history.push(`/documents?statusframe=${tabValue}`);
        }
    }, []);

    const tabs = [
        <SubNavTab key="Pending" label="Pending" icon={<PendingActions />} />,
        <SubNavTab key="Approved" label="Approved" icon={<AssignmentTurnedIn />} />,
        <SubNavTab key="Rejected" label="Rejected" icon={<Block />} />,
    ];

    const handleChange = (event, value) => {
        // fetchListStats(statusframes[value]);
        props.history.push(`/documents?statusframe=${value}`);
        setTabValue(value);
    };


    const handleCloseRejectDialog = () => {
        dispatch(DocumentsActions.setRejectReason(""))
        dispatch(DocumentsActions.setIsOpenRejectDialog(false))
        dispatch(DocumentsActions.setSelectedDocument(null))

    };

    const handleConfirmRejectDialog = () => {
        dispatch(DocumentsActions.updateDocumentStatus({ id: selectedDocument.id, rejectReason, status: "REJECT" }));
    };

    const handleChangeReason = ({ target: { value } }) => {
        dispatch(DocumentsActions.setRejectReason(value))
    }

    return (
        <>
            <div>
                <SubNav value={tabValue} onChange={handleChange} tabs={tabs} />
                <MainContent>
                    <SwipeableViews axis="x" index={tabValue}>
                        {tabValue === 0 && <PendingTab />}
                        {tabValue === 1 && <ApprovedTab />}
                        {tabValue === 2 && <RejectedTab />}
                        {/* {<PendingTab />} */}
                        {/* {<ApprovedTab />} */}
                        {/* {<RejectedTab />} */}
                    </SwipeableViews>
                </MainContent>
            </div>
            <Dialog
                open={isOpenDialog}
                title={`Reject Document Reason`}
                confirmText={isUpdateStatus ? <CircularProgress classes={{ svg: classes.svgRoot }} size={18} /> : "Reject"}
                disabled={isUpdateStatus || !rejectReason}
                onClose={handleCloseRejectDialog}
                onConfirm={handleConfirmRejectDialog}
                onCancel={handleCloseRejectDialog}
                icon="block"
                // fullWidth={true}
                alertType="question">

                <TextField
                    fullWidth={true}
                    multiline
                    value={rejectReason}
                    onChange={handleChangeReason}
                    margin="normal"
                    placeholder="Reason"
                    label="Reject Reason"
                />
            </Dialog>
        </>
    )
}
