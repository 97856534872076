import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { makeStyles } from "@mui/styles";
import { EditorState, convertToRaw, convertFromHTML, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import Button from "components/Button/Button";

const useStyle = makeStyles({
    saveButton: {
        marginTop: 24,
        padding: 0,
        width: "fit-content",
    },
});

const TermsOfServiceEditor = ({ termsOfService, saveChange, isLoading, ...props }) => {
    const classes = useStyle();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(
        () => {
            setEditorState(
                EditorState.createWithContent(
                    ContentState.createFromBlockArray(convertFromHTML(termsOfService)),
                ),
            );
        },
        [termsOfService],
    );

    const onEditorStateChange = e => {
        setEditorState(e);
    };

    const onSave = () => {
        const request = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        saveChange(request);
    };


    return (
        <>
            <Editor
                wrapperStyle={{
                    border: "1px solid #d5dde5",
                    background: "#FFF",
                }}
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
            />
            <Button
                className={classes.saveButton}
                label="Save Change"
                alignIcon="left"
                onClick={onSave}
                isLoading={isLoading}
            />
        </>
    );
};

export default TermsOfServiceEditor;
