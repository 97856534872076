import { Delete, Edit, MoreVert } from '@mui/icons-material'
import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import { actions as holidaysActions, selectors as holidaysSelectors } from "store/modules/holidays";
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import moment from 'moment';

export default function HolidayActionMenu({ classes, holiday, ...props }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const id = `action-menu-${holiday?.id}`;
    const dispatch = useDispatch();

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        dispatch(holidaysActions.setIsDeleteHolidayDialog(holiday.id));
        handleClose();
    };

    const handleEdit = () => {
        dispatch(holidaysActions.setEditFormData({
            id: holiday.id,
            name: holiday.name,
            description: holiday.description,
            date: moment(holiday.holidayDate),
            provinces: holiday.provinceHoliday.map(p => ({ label: p.province.name, value: p.province.id, data: p.province }))
        }));
        dispatch(holidaysActions.setIsAddHolidayDialog(true));
        handleClose();
    }

    return (
        <React.Fragment>
            <IconButton
                className={classes?.moreIconButton || ""}
                aria-owns={anchorEl ? id : undefined}
                aria-haspopup="true"
                disableRipple
                onClick={handleClick}
                size="large">
                <MoreVert />
            </IconButton>
            {anchorEl ? (
                <Menu
                    id={id}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    disableAutoFocusItem>
                    <MenuItem onClick={handleEdit}>
                        <ListItemIcon>
                            <Edit fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Edit</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleDelete}>
                        <ListItemIcon>
                            <Delete fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Delete</ListItemText>
                    </MenuItem>
                </Menu>) : null}
        </React.Fragment>
    )
}
