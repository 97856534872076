export default {
    SET_LOADING_STATE: "customFields/SET_LOADING_STATE",
    SET_DIALOG_STATE: "customFields/SET_DIALOG_STATE",
    SET_CUSTOM_FIELDS_DATA: "customFields/SET_CUSTOM_FIELDS_DATA",
    SET_EMPLOYERS_LIST_DATA: "customFields/SET_EMPLOYERS_LIST_DATA",
    SET_WORKERS_LIST_DATA: "customFields/SET_WORKERS_LIST_DATA",
    SET_FORM_VALUES: "customFields/SET_FORM_VALUES",
    SET_EDIT_FORM_VALUES: "customFields/SET_EDIT_FORM_VALUES",
    CLEAR_SELECTED_PICKER_DATA: "customFields/CLEAR_SELECTED_PICKER_DATA",
    ADD_NEW_OPTION: "customFields/ADD_NEW_OPTION",
    REMOVE_OPTION: "customFields/REMOVE_OPTION",
    SET_OPTION_VALUE: "customFields/SET_OPTION_VALUE",
    CLEAR_TYPE_OPTIONS: "customFields/CLEAR_TYPE_OPTIONS",
    CLEAR_FORM: "customFields/CLEAR_FORM",
    SET_CUSTOM_FIELD_DETAIL: "customFields/SET_CUSTOM_FIELD_DETAIL",
    SET_CUSTOM_FIELD_DETAIL_EMPLOYERS: "customFields/SET_CUSTOM_FIELD_DETAIL_EMPLOYERS",
    SET_CUSTOM_FIELD_DETAIL_WORKERS: "customFields/SET_CUSTOM_FIELD_DETAIL_WORKERS",
    SET_ACTIVE_TAB: "customFields/SET_ACTIVE_TAB",
};
