import { gql } from "@apollo/client";

export default gql`
  query termsAndConditions {
    termsAndConditions {
      id
      termsAndConditions
      work
      workFrench
      hire
      hireFrench
    }
  }
`;
