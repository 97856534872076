import { gql } from "@apollo/client";

export default gql`
  query getEmployer($id: ID!) {
    employer(id: $id) {
      qbId
      billingRate
      canPostPrivateJob
      city
      companyName
      country
      email
      firstName
      lastName
      id
      mobilePhone
      payRate
      region
      street
      logoImageUrl
      signedLogoImageUrl
      employerDocumentTypes {
        id
        grizzlyEmployerId
        documentTypeId
        createdAt
        updatedAt
        documentType {
          id
          name
          slug
          helpText
          createdBy
          updatedBy
          createdAt
          updatedAt
        }
      }
    }
  }
`;
