/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from "react";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";

import { selectors as JobDetailSelectors } from "store/modules/jobDetails";
import DisableCellOverlay from "../../components/Controls/DisableCellOverlay";
import PositionMenu from "../../components/Menus/PositionMenu";
import { Tooltip } from "@mui/material";
import { Timelapse } from "@mui/icons-material";
import { convertLocalToUTC, convertUTCToLocal } from "../../../../../../../../../utils/time";
import { pluralize } from "utils/stringUtils";
import Can from "components/Authorization/Can";
import { PositionPermissions } from "helpers/constants/permissions";

export default function usePositionMenu({ isWorkerPickerOpen, onChooseWorker, disabled }) {
    const canAddWorker = useSelector(JobDetailSelectors.getCanAddWorkerInDashboard);
    const jobStartUtc = useSelector(JobDetailSelectors.getJobStartUtc);
    return {
        Header: "",
        id: "actions",
        collapse: true,
        style: { textAlign: "right" },
        Cell: ({ row: { original } }) => {
            const [minutes, setMinutes] = React.useState(0);
            const [seconds, setSeconds] = React.useState(0);
            let isInCancelWindow = convertLocalToUTC(moment())
                .add(-5, "minutes")
                .isSameOrBefore(original.createdAtUtc);
            if (isInCancelWindow) {
                const target = convertUTCToLocal(original.createdAtUtc).add(5, "minutes");
                const cancelTimer = setInterval(() => {
                    const distance = target.diff(moment());
                    const min = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    const sec = Math.floor((distance % (1000 * 60)) / 1000);
                    setMinutes(min);
                    setSeconds(sec);
                    if (distance < 0) {
                        setMinutes(0);
                        setSeconds(0);
                        isInCancelWindow = false;
                        clearInterval(cancelTimer);
                    }
                }, 1000);
            }

            if (isInCancelWindow && convertUTCToLocal(jobStartUtc).diff(moment(), "minutes") <= 600) {
                const title = (
                    <div style={{ textAlign: "center" }}>
                        <p>Cancellation window</p>
                        <p>{minutes > 0 && `${minutes} ${pluralize("min", minutes)}, `}{`${seconds} ${pluralize("sec", seconds)} `} remaining</p>
                    </div>
                );
                return (
                    <Tooltip title={title} placement="top">
                        <div style={{ padding: 12, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Timelapse color="disabled" />
                        </div>
                    </Tooltip>
                );
            } else {
                return (
                    <>
                        <PositionMenu position={original} isWorkerPickerOpen={isWorkerPickerOpen} />
                        <DisableCellOverlay visible={disabled} />
                    </>
                )
            }
        },
        Footer: (<Can perform={PositionPermissions.create.fill.value}>
            {allowed => {
                if (!allowed) {
                    return null;
                }
                return (
                    canAddWorker && !disabled ? (
                        <IconButton disableRipple onClick={onChooseWorker} size="large">
                            <AddCircleOutline style={{ fontSize: "18px" }} />
                        </IconButton>
                    ) : null
                )
            }}
        </Can>)
    };
}
