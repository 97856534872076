import { Chip, Tooltip } from '@mui/material';
import DateColumnFilter from 'components/ReactTable/filterFunctions/DateColumnFilter';
import ReactTableSimple from 'components/ReactTable/ReactTableSimple'
import TagsInput from 'components/TagsInput';
import { isEqual } from 'lodash';
import React, { useEffect } from 'react';
import { actions as holidaysActions, selectors as holidaysSelectors } from "store/modules/holidays";
import { useDispatch, useSelector } from 'react-redux';
import SelectColumnFilter from 'components/ReactTable/filterFunctions/SelectColumnFilter';
import { useHistory } from 'react-router-dom';
import HolidayActionMenu from './HolidayActionMenu';
import moment from 'moment';


export default function HolidaysTable() {
    const dispatch = useDispatch();
    const data = useSelector(holidaysSelectors.getHolidaysList);
    const isLoading = useSelector(holidaysSelectors.getIsTableLoading);
    const history = useHistory();

    useEffect(() => {
        dispatch(holidaysActions.fetchsHolidaysList());
    }, []);

    const handleClickRow = ({ original }) => {
        history.push(`/holiday/${original.id}`);
    };

    return (
        <>
            <ReactTableSimple
                classes="-highlight"
                hasFilter
                loading={isLoading}
                data={data}
                initialSort={[{ id: "holidayDate", desc: true }]}
                onRowClick={handleClickRow}
                columns={[
                    {
                        Header: "Id",
                        id: "id",
                        headerStyle: { minWidth: 60 },
                        collapse: true,
                        disableFilters: true,
                        accessor: ({ id }) => id,
                    },
                    {
                        Header: "Holiday",
                        id: "name",
                        accessor: ({ name }) => name,
                    },
                    {
                        Header: "Description",
                        id: "description",
                        disableFilters: true,
                        accessor: ({ description }) => description,
                        disableSortBy: true,
                    },
                    {
                        Header: "Year",
                        id: "holidayYear",
                        accessor: ({ holidayDate }) => moment(holidayDate).format("YYYY"),
                        Filter: SelectColumnFilter,
                        width: 60,
                    },
                    {
                        Header: "Date",
                        id: "holidayDate",
                        accessor: "holidayDate",
                        Filter: DateColumnFilter,
                        Cell: ({ row: { original: { holidayDate } } }) => moment(holidayDate).format("dddd Do MMM"),
                    },
                    {
                        Header: "Province",
                        id: "provinceHoliday",
                        isNotClickable: true,
                        disableSortBy: true,
                        accessor: ({ provinceHoliday }) => provinceHoliday?.map(a => a?.province?.name),
                        Filter: ({ column, setFilter }) => {
                            return (
                                <div>
                                    <TagsInput
                                        selectedTags={(data) => {
                                            if (data) {
                                                if (column) {
                                                    if (!isEqual(column.filterValue, data)) {
                                                        setFilter(column.id, data)
                                                    }
                                                } else {
                                                    if (data.length == 0 && column) {
                                                        setFilter(column.id, data)
                                                    } else if (data.length > 0) {
                                                        setFilter(column.id, data)
                                                    }
                                                }
                                            }
                                        }}
                                        fullWidth
                                        defaultValue={column.filterValue ? column.filterValue : []}
                                        isFilterField
                                        id="province"
                                        name="province"
                                    />
                                </div>
                            )
                        },
                        Cell: ({ row }) => {
                            return (
                                <div>
                                    {row.original.provinceHoliday && row.original.provinceHoliday.map((n, index) => {
                                        if (index < 2) {
                                            return <Chip
                                                key={n?.province?.name}
                                                size="small"
                                                style={{ margin: '1px' }}
                                                label={n?.province?.name}
                                            />
                                        }
                                    })}
                                    {row.original.provinceHoliday && row.original.provinceHoliday.length > 2 && (
                                        <Tooltip title={row.original.provinceHoliday.map((n, index) => `${index > 1 ? n?.province?.name + (index + 1 != row.original.provinceHoliday.length ? ', ' : '') : ''}`)} placement="top">
                                            <Chip
                                                size="small"
                                                style={{ margin: '1px' }}
                                                label={'More'}
                                            />
                                        </Tooltip>
                                    )}
                                </div>
                            )
                        }
                    },
                    {
                        id: "actions",
                        disableSortBy: true,
                        collapse: true,
                        disableFilters: true,
                        isNotClickable: true,
                        Cell: ({ row: { original } }) => {
                            return moment(original.holidayDate).isAfter(moment()) && <HolidayActionMenu holiday={original} />
                        },
                    },
                ]}
            />
        </>
    )
}
