import { gql } from "@apollo/client";

export default gql`
mutation UpdateWorkerPermitData($status: Boolean, $workerId: String, $workerPermitExpiryDate: String, $file: Upload) {
    updateWorkerPermitData(status: $status, workerId: $workerId, workerPermitExpiryDate: $workerPermitExpiryDate, file: $file) {
      firstName
      lastName
      fullName
      workPermitUrl
      signedWorkPermitImageUrl
      workPermitExpiryDate
      workPermitStatus
    }
  }
`;
