import React, { useCallback } from "react";
import { withRouter } from "react-router-dom";
import ReactTable from "components/ReactTable";
import { useDispatch } from "react-redux";
import { addedByColumn, documentColumn, documentNameColumn, documentTypeColumn, idColumn, workerFirstNameColumn, workerLastNameColumn, workerEmailNameColumn } from "../helpers/columns";

const DocumentTable = ({ extraColumns = [], ...props }) => {
    const dispatch = useDispatch()
    const fetchData = useCallback(({ pageSize, pageIndex, filters, sortBy }) => {
        dispatch(() => props.onSortedChange(sortBy));
        dispatch(() => props.onFilteredChange(filters));
        dispatch(() => props.onPageSizeChange(pageSize));
        dispatch(() => props.onPageChange(pageIndex));
    }, []);
    return (

        <ReactTable
            classes="-highlight"
            columns={[
                idColumn,
                documentTypeColumn,
                workerFirstNameColumn,
                workerLastNameColumn,
                workerEmailNameColumn,
                documentNameColumn,
                documentColumn,
                addedByColumn,
                ...extraColumns
            ]}
            fetchData={fetchData}
            defaultFilter={props.pageFilter}
            currentPage={props.currentPage}
            defaultPageSize={props.pageSize}
            loading={props.isLoading}
            pageCount={props.totalCount}
            data={props.data}
            defaultSort={props.pageSort}
        />
    )
}

export default withRouter(DocumentTable);
