import React from 'react'
import { AccessTime, CheckCircleOutline, CloudDownload, HighlightOff } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import Dialog from 'components/Dialog/Dialog';
import ReactTableSimple from 'components/ReactTable/ReactTableSimple';
import { allowedProvinceDocumentsTypes } from 'utils/file';

const documentStatusIcons = {
    "PENDING": <AccessTime />,
    "ACCEPT": <CheckCircleOutline color='success' />,
    "REJECT": <HighlightOff color='error' />,
};

export default function DocumentStatusDialog(props) {
    const { open, onClose, onCancel, onConfirm, position } = props;
    const column = [
        {
            Header: "Document Name",
            id: "documentName",
            disableSortBy: true,
            accessor: ({ agreementDocument: { documentName } }) => documentName,
            disableFilters: true,
            styles: { textAlign: "left" },
        },
        {
            Header: "Type",
            id: "documentType",
            styles: { textAlign: "center" },
            disableSortBy: true,
            accessor: ({ agreementDocument: { documentType } }) => {
                const docType = allowedProvinceDocumentsTypes.find(d => d.mime === documentType);
                if (documentType) return docType.extension.replace(".", "").toUpperCase();
                return documentType;
            },
            disableFilters: true,
        },
        {
            Header: "Document",
            id: "document",
            disableFilters: true,
            disableSortBy: true,
            styles: { textAlign: "center" },
            Cell: ({ row: { original: { agreementDocument: { signedDocumentImageUrl } } } }) => {
                return (
                    <>
                        <IconButton href={signedDocumentImageUrl} target="_blank" >
                            <CloudDownload />
                        </IconButton>
                    </>
                );
            },
        },
        {
            Header: "Status",
            id: "documentStatus",
            disableSortBy: true,
            accessor: ({ documentStatus }) => documentStatus,
            disableFilters: true,
            styles: { textAlign: "center" },
            Cell: ({ row: { original: { documentStatus } } }) => {

                return (
                    <>
                        <Tooltip title={documentStatus} >
                            {documentStatusIcons[documentStatus]}
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    return (
        <Dialog
            open={open}
            title={`Document status for ${position.fullName}`}
            // description="This will remove the worker from the job and suspend them for a period of time"
            onClose={onClose}
            iconFontSize={"0px"}
            confirmText={"Close"}
            onConfirm={onConfirm}
            onCancel={onCancel}
            alertType="info"
            modalType="editEmployer"
        >
            <ReactTableSimple
                // classes="-striped"
                style={{ marginTop: 10, boxShadow: "none" }}
                data={position?.positionDocument || []}
                filterable={false}
                columns={column}
            />
        </Dialog>
    )
}
