import React from "react";
import { connect } from "react-redux";
import withStyles from '@mui/styles/withStyles';
import { actions as DocumentsActions, selectors as DocumentsSelectors } from "store/modules/documents";
import { actionColumn } from "../helpers/columns";
import DocumentTable from "./DocumentTable";
import { tabStyles } from "./styles";

class PendingTab extends React.Component {


    render() {
        const { tableProps } = this.props;
        return (
            <>
                <DocumentTable {...tableProps} extraColumns={[actionColumn]} />
            </>
        );
    }
}

const mapState = state => {
    const tableProps = {
        ...DocumentsSelectors.getPendingPaginationData(state),
        data: DocumentsSelectors.getListData(state, {
            statusframe: "pending",
            part: "data",
        }),
        pageFilter: DocumentsSelectors.getListData(state, {
            statusframe: "pending",
            part: "filter",
        }),
        pageSort: DocumentsSelectors.getListData(state, {
            statusframe: "pending",
            part: "sort",
        }),
    };

    return {
        tableProps,
    };
};

const mapActions = {
    fetchDocuments: DocumentsActions.fetchPendingDocuments,
    onPageChange: DocumentsActions.fetchPendingDocuments,
    onSortedChange: DocumentsActions.setPendingSort,
    onFilteredChange: DocumentsActions.setPendingFilter,
    onPageSizeChange: DocumentsActions.setPendingPageSize,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { tableProps } = stateProps;
    const {
        fetchDocuments,
        onPageChange,
        onSortedChange,
        onFilteredChange,
        onPageSizeChange
    } = dispatchProps;
    return {
        ...stateProps,
        ...dispatchProps,
        tableProps: {
            ...tableProps,
            fetchData: fetchDocuments,
            onPageChange,
            onSortedChange,
            onFilteredChange,
            onPageSizeChange
        },
        ...ownProps,
    };
};

export default connect(mapState, mapActions, mergeProps)(withStyles(tabStyles)(PendingTab));
