import { gql } from "@apollo/client";

export default gql`
mutation AddWorkTypeRate($data: AddWorkTypeRateInput!) {
    addWorkTypeRate(data: $data) {
      id
      createdAt
      updatedAt
      employerId
      workTypeId
      payRate
      billingRate
      workType {
        label
      }
    }
  }
`;
