import { CheckCircleOutline, HighlightOff } from '@mui/icons-material';
import { Button, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { actions as WorkerActions, selectors as WorkerSelections } from 'store/modules/workers';

const WorkPermitActionColumnCell = ({ row: { original } }) => {
    const dispatch = useDispatch();
    const { isInfoLoading } = useSelector(state => (state?.workers || {}));
    const selectedWorkerId = useSelector(WorkerSelections?.getSelectedWorkerWorkPermitId);
    const [selectedType, setSelectedType] = useState(null);

    useEffect(() => {
        if (!isInfoLoading && selectedType) setSelectedType(null);
    }, [isInfoLoading]);

    const handleApprove = () => {
        setSelectedType("APPROVED");
        dispatch(WorkerActions.updateWorkerWorkPermitStatus({ workerId: original.id, status: true }));
    };
    const handleReject = () => {
        dispatch(WorkerActions.setWorkerWorkPermitRejectDialog(original.id));
        setSelectedType("REJECT");
    };

    return (
        <div style={{ display: "flex" }}>
            <Button
                onClick={handleApprove}
                size={"small"}
                disabled={selectedWorkerId === original.id}
                color="secondary"
                variant="outlined"
                startIcon={<CheckCircleOutline />}
                style={{ marginRight: 8 }}>
                {selectedWorkerId === original.id && selectedType === "APPROVED" ?
                    <CircularProgress size={18} /> :
                    "Approve"
                }
            </Button>
            <Button
                onClick={handleReject}
                size={"small"}
                disabled={selectedWorkerId === original.id}
                color="error"
                variant="outlined"
                startIcon={<HighlightOff />}>
                {selectedWorkerId === original.id && selectedType === "REJECT" ?
                    <CircularProgress size={18} /> :
                    "Reject"
                }
            </Button>
        </div >
    );
};

export default WorkPermitActionColumnCell;
