import { createReducer } from "utils/redux";
import types from "./types";

const initialLoadingState = {
    overtimeSpecs: false,
    isOpenEditDialog: false,
    updateOvertimeSpecs: false
};
const initialState = {
    overtimeSpecs: [],
    updateOvertimeSpecsData: {},
    loading: { ...initialLoadingState },
};

const actionsMap = {
    [types.SET_LOADING_STATE]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            loading: {
                ...state.loading,
                [key]: value,
            },
        };
    },
    [types.SET_OT_CALCULATION_DATA]: (state, { payload }) => {
        const { data } = payload;
        return {
            ...state,
            overtimeSpecs: [...data],
        };
    },
    [types.SET_OT_CALCULATION_UPDATE_DATA]: (state, { payload }) => {
        const { data } = payload;

        return {
            ...state,
            updateOvertimeSpecsData: {
                ...data,
            },
        };
    },
    [types.UPDATE_OT_CALCULATION_DATA]: (state, { payload }) => {
        const { data } = payload;
        const overtimeSpecs = Object.assign([], state.overtimeSpecs);
        const index = overtimeSpecs.findIndex(a => a.id === data.id);
        if (index >= 0) {
            overtimeSpecs[index] = data;
        }
        return {
            ...state,
            overtimeSpecs,
            updateOvertimeSpecsData: {},
        };
    },
};

export default createReducer(initialState, actionsMap);
