import React, { useCallback, useEffect, useRef, useState } from "react";
import CurrencyFormat from "react-currency-format";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import CurrencyTextField from "./CurrencyTextField";
import { isNaN } from "lodash";

function CurrencyInput({
    label,
    minValue,
    minValueHelperText,
    maxValue,
    value: inputValue,
    onChange,
    tooltip,
}) {
    const [value, setValue] = useState(inputValue);
    const inputEl = useRef(null);

    useEffect(
        () => {
            if (!isNaN(inputValue)) {
                setValue(inputValue);
            } else {
                setValue(0);
            }
        },
        [inputValue],
    );

    function handleChange({ floatValue }) {
        onChange(floatValue);
    }

    const handleKeyDown = e => {
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            e.preventDefault();
            const change = e.key === "ArrowUp" ? 0.05 : -0.05;
            const newValue = +(parseFloat(value) + change).toFixed(2);
            // if (isAllowed({ floatValue: newValue })) {
            setValue(newValue);
            onChange(newValue);
            // }
        }
    };

    const Content = useCallback((props) => (
        <CurrencyFormat
            {...props}
        />
    ), []);

    if (tooltip) {
        return (
            <Tooltip title={tooltip} placement="top">
                <Content label={label}
                    minValue={minValue}
                    minValueHelperText={minValueHelperText}
                    isNumericString
                    allowNegative
                    decimalScale={2}
                    fixedDecimalScale
                    customInput={CurrencyTextField}
                    value={value}
                    // isAllowed={isAllowed}
                    onValueChange={handleChange}
                    onKeyDown={handleKeyDown} />
            </Tooltip>
        );
    }

    return <Content label={label}
        minValue={minValue}
        minValueHelperText={minValueHelperText}
        isNumericString
        allowNegative
        decimalScale={2}
        fixedDecimalScale
        customInput={CurrencyTextField}
        value={value}
        // isAllowed={isAllowed}
        onValueChange={handleChange}
        onKeyDown={handleKeyDown} />;
}

CurrencyInput.propTypes = {
    minValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxValue: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    tooltip: PropTypes.string,
    label: PropTypes.string,
    minValueHelperText: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

CurrencyInput.defaultProps = {
    maxValue: 100,
    tooltip: null,
    label: null,
};

export default CurrencyInput;
