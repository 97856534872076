import gql from "graphql-tag";

export default gql`mutation UpdateSkill($data: UpdateSkillInput!) {
    updateSkill(data: $data) {
      id
      skillName
      isActive
      createdAt
      frenchSkillName
    }
  }
`
