import gql from "graphql-tag";

export default gql`
mutation UpdateGear($data: UpdateGearInput!) {
    updateGear(data: $data) {
      frenchGearName
      createdAt
      id
      isActive
      gearName
    }
  }
`
