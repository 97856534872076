// Loading selectors
export const getLoadingStateFactory = loader => state => {
    return state.provinceDocuments.loading[loader] || false;
};

export const getIsTableLoading = getLoadingStateFactory("tableLoading");
export const getIsFormActionLoading = getLoadingStateFactory("formDialogLoading");
export const getIsDeleteLoading = getLoadingStateFactory("deleteLoading");

// Dialog selectors
export const getDialogStateFactory = loader => state => {
    return state.provinceDocuments.dialog[loader] || false;
};

export const getIsAddProvinceDocumentDialog = getDialogStateFactory("addProvinceDocumentDialog");
export const getIsDeleteProvinceDocumentDialog = getDialogStateFactory("deleteProvinceDocumentDialog");
export const getIsEditProvinceDocumentDialog = getDialogStateFactory("editProvinceDocumentDialog");

// Documents selectors
export const getProvinceDocumentsList = state => {
    return state.provinceDocuments.documents;
};

export const getProvinceDocumentsForm = (state, part) => {
    return part ? state.provinceDocuments?.documentForm[part] : state.provinceDocuments.documentForm;
};

export const canAddProvinceDocument = (state) => {
    return (
        state.provinceDocuments?.documentForm?.name?.length &&
        state.provinceDocuments?.documentForm?.name?.length <= 50 &&
        state.provinceDocuments?.documentForm?.helpText?.length &&
        state.provinceDocuments?.documentForm?.helpText?.length <= 50 &&
        state.provinceDocuments?.documentForm?.province &&
        state.provinceDocuments?.documentForm?.document
    );
}
