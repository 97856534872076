import React, { useState } from "react";
import MainContent from "components/Containers/MainContent";
import { Add } from "@mui/icons-material";
import Button from "components/Button/Button";
import Dialog from "components/Dialog/Dialog";
import { useDispatch, useSelector } from "react-redux";
import DocumentsTable from "./components/DocumentsTable";
import { actions as ProvinceDocumentsActions, selectors as ProvinceDocumentsSelectors } from "store/modules/provinceDocuments";
import AddDocumentDialog from "./components/AddDocumentDialog";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    loader: {
        "& svg": {
            margin: `0px !important`,
            marginTop: `0px !important`
        }
    }
}));

function DocumentsOverView() {
    const classes = useStyles();
    const dispatch = useDispatch()
    const selectedDocumentId = useSelector(ProvinceDocumentsSelectors.getIsDeleteProvinceDocumentDialog);
    const isDeleting = useSelector(ProvinceDocumentsSelectors.getIsDeleteLoading);

    const handleAddDocument = () => {
        dispatch(ProvinceDocumentsActions.setIsAddProvinceDocumentsDialog(true));
    };

    const hideDeleteDialog = () => {
        dispatch(ProvinceDocumentsActions.setIsDeleteProvinceDocumentsDialog(false));
    };

    const handleDeleteDocument = () => {
        dispatch(ProvinceDocumentsActions.deleteProvinceDocument(selectedDocumentId));
    };

    return (
        <>
            <MainContent>
                <Button
                    style={{ alignSelf: "flex-end", marginBottom: 24 }}
                    label="Add"
                    icon={<Add />}
                    alignIcon="left"
                    onClick={handleAddDocument}
                />
                <DocumentsTable />
            </MainContent>
            <AddDocumentDialog />
            <Dialog
                open={Boolean(selectedDocumentId)}
                title="Are you sure?"
                onClose={hideDeleteDialog}
                onConfirm={handleDeleteDocument}
                onCancel={hideDeleteDialog}
                alertType="warning"
                disabled={isDeleting}
                icon="help_outline"
                confirmText={isDeleting ? <CircularProgress className={classes.loader} size={18} /> : "Yes"}
            />
        </>
    )
}

export default DocumentsOverView;
