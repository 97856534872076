import gql from "graphql-tag";

export default gql`
mutation InitializeHolidayPayroll($provinceId: Int!, $holidayId: Int!) {
    initializeHolidayPayroll(provinceId: $provinceId, holidayId: $holidayId) {
      id
      createdAt
      updatedAt
      workerId
      provinceId
      holidayId
      grossPay
      province {
        id
        name
        code
        isActive
        countryId
      }
      holiday {
        id
        createdAt
        updatedAt
        holidayDate
        name
        description
      }
      worker {
        pk
        createdAt
        updatedAt
        isDeleted
        id
        firstName
        lastName
        email
        isEmailVerified
        birthdate
        street
        city
        region
        postalCode
        country
        phone
        sin
        authId
        identitiesProvider
        identitiesUserId
        identitiesIsSocial
        identitiesConnection
        profileImageUrl
        identificationImageUrl
        vaccineCertificateUrl
        vaccineDoseTaken
        consent
        isActive
        isApproved
        approvedAt
        noShowCount
        hoursWorkedCount
        rating
        isOnboarded
        latitude
        longitude
        unit
        isStudent
        employmentType
        vaccinationStatus
        travelRange
        isProfileComplete
        timezone
        createdAtUtc
        updatedAtUtc
        unsubscribedAtUtc
        approvedAtUtc
        tags
        lateJobCancelCount
        fullName
        payrollCount
        positionCount
        grossPay
        netPay
        totalDeductions
        dcCustomerNumber
        futureJobCount
        signedProfileImageUrl
        signedIdentificationImageUrl
        signedCompareImageUrl
        signedVaccineCertificateUrl
        banCount
        preferCount
        identityApprovalState
        isSuspended
        suspensionStartUtc
        suspensionEndUtc
        adminOnboardedWorker
      }
      holidayPayroll {
        id
        createdAt
        updatedAt
        holidayWorkerId
        grossPay
        netPay
        cpp
        ei
        fedTax
        provTax
        grizzlyCPP
        grizzlyEI
        grizzlyWCB
        eht
      }
    }
  }
`
