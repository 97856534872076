import { find, remove } from "lodash";
import ApolloClient from "utils/apollo";
import { flattenGraphQLArray } from "utils/graphql";
import * as pagination from "utils/graphql-pagination";
import { verifyEmail } from "utils/stringUtils";
import { actions as MetaActions } from "store/modules/meta";
import { paginationSettings } from "./constants";
import banWorkerMutation from "./graphql/mutations/ban-worker";
import editWorkerMutation from "./graphql/mutations/edit-worker";
import preferWorkerMutation from "./graphql/mutations/prefer-worker";
import removeWorkerReputation from "./graphql/mutations/remove-ban-worker";
import suspendWorkerMutation from "./graphql/mutations/suspend-worker";
import UpdateWorkerIdentityStatus from "./graphql/mutations/update-worker-identity-status";
import { checkWorkerPhoneQuery } from "./graphql/queries";
import workerListStatsQuery from "./graphql/queries/fetch-worker-list-stats";
import workerCheckExistsQuery from "./graphql/queries/worker-checkExists";
import workerCheckSinQuery from "./graphql/queries/worker-checkSin";
import workerDetailQuery from "./graphql/queries/worker-detail";
import workerBannedQuery from "./graphql/queries/workers-bans";
import workerPreferredQuery from "./graphql/queries/workers-preferred";
import workerTableQuery from "./graphql/queries/workers-table";
import workerFutureJobsQuery from "./graphql/queries/workers-future-jobs";
import getIdentityWorkersStatsQuery from "./graphql/queries/identification-worker-stats";
import {
    getAppliedWorkerListPagingData,
    getIdentityWorkerListPagingData,
    getDeactivatedWorkerListPagingData,
    getSuspendedWorkerListPagingData,
    getUnverifiedWorkerListPagingData,
    getVerifiedWorkerListPagingData,
    getWaitlistTodayPagingData,
    getWaitlistTomorrowPagingData,
    getWorkerListPagingData,
    getWorkerPreferredPaginationData,
    workerBannedByPageInfo,
    getWorkPermitWorkerListPagingData,
} from "./selectors";
// Queriess
// Mutations
import types from "./types";
import getWorkPermitWorkersStatsQuery from "./graphql/queries/work-permits-worker-stats";
import UpdateWorkPermitMutation from "./graphql/mutations/update-work-permit";

const alertMessages = {
    fetchError: "Oops!  Something went wrong, please try again",
    updateError: "Oops!  Something went wrong, please try again",
    defaultError: "Oops!  Something went wrong, please try again",
    updateSuccess: "Success!",
    defaultSuccess: "Success!",
};

const paginationFunctionList = {
    waitlistToday: getWaitlistTodayPagingData,
    waitlistTomorrow: getWaitlistTomorrowPagingData,
    workerList: getWorkerListPagingData,
    verifiedWorkerList: getVerifiedWorkerListPagingData,
    unverifiedWorkerList: getUnverifiedWorkerListPagingData,
    deactivatedWorkerList: getDeactivatedWorkerListPagingData,
    suspendedWorkerList: getSuspendedWorkerListPagingData,
    appliedWorkerList: getAppliedWorkerListPagingData,
    identityWorkerList: getIdentityWorkerListPagingData,
    workPermitWorkerList: getWorkPermitWorkerListPagingData,
};

// export const clearWorkerUpdateData = () => ({
//   type: types.CLEAR_UPDATE_UPDATE_WORKER_DATA,
//   payload: {},
// });

// export const setUpdateWorkerData = (field, value) => {
//   if (field === "phone") {
//     value = value.replace(/\D/g, "");
//   }
//   console.log("setUpdateWorkerData", setUpdateWorkerData);
//   return {
//     type: types.SET_UPDATE_UPDATE_WORKER_DATA,
//     payload: { field, value },
//   };
// };

// export const updateWorkerProfile = () => (dispatch, getState) => {
//   const { worker, updateWorkerData } = getState().workerDetails;

//   if (_.isEmpty(updateWorkerData)) {
//     console.log("updateWorkerData", updateWorkerData);
//     dispatch(clearWorkerUpdateData());
//     return dispatch(MetaActions.successToast(`Nothing to update.`));
//   }

//   return ApolloClient.mutate({
//     mutation: UpdateWorkerProfileMutation,
//     variables: {
//       data: {
//         id: worker.id,
//         ...updateWorkerData,
//       },
//     },
//   })
//     .then(({ data }) => {
//       dispatch(setUpdateWorkerData());
//       dispatch(setWorker(data.editWorker));
//       dispatch(MetaActions.successToast(`${worker.firstName} has been updated.`));
//       return data.editEmployer;
//     })
//     .catch(e => {
//       dispatch(setUpdateWorkerData());
//       dispatch(MetaActions.errorToast(`Updating ${worker.firstName} has failed. ${e}`));
//     });
// };
const setLoadingState = key => value => ({
    type: types.SET_LOADING_STATE,
    payload: { key, value },
});
export const setFutureJobLoading = setLoadingState("futureJob");
export const setWorkerIdentityRejectDialog = setLoadingState("workerIdentityRejectDialog");
export const setSelectedWorkerIdentityId = setLoadingState("selectedWorkerIdentityId");
export const setWorkerWorkPermitRejectDialog = setLoadingState("workerWorkPermitRejectDialog");
export const setSelectedWorkerWorkPermitId = setLoadingState("selectedWorkerWorkPermitId");


export const typeByListType = listType => {
    switch (listType) {
        case "waitlistToday": {
            return {
                set: "SET_WAITLIST_TODAY",
                sort: "UPDATE_WAITLIST_TODAY_PAGE_SORT",
                filter: "UPDATE_WAITLIST_TODAY_TABLE_FILTER",
            };
        }
        case "waitlistTomorrow": {
            return {
                set: "SET_WAITLIST_TOMORROW",
                sort: "UPDATE_WAITLIST_TOMORROW_PAGE_SORT",
                filter: "UPDATE_WAITLIST_TOMORROW_TABLE_FILTER",
            };
        }
        case "workerList": {
            return {
                set: "SET_WORKERS",
                sort: "UPDATE_PAGE_SORT",
                filter: "UPDATE_PAGE_FILTER",
            };
        }
        case "verifiedWorkerList": {
            return {
                set: "SET_VERIFIED_WORKERS",
                sort: "UPDATE_VERIFIED_PAGE_SORT",
                filter: "UPDATE_VERIFIED_PAGE_FILTER",
            };
        }

        case "unverifiedWorkerList": {
            return {
                set: "SET_UNVERIFIED_WORKERS",
                sort: "UPDATE_UNVERIFIED_PAGE_SORT",
                filter: "UPDATE_UNVERIFIED_PAGE_FILTER",
            };
        }
        case "deactivatedWorkerList": {
            return {
                set: "SET_DEACTIVATED_WORKERS",
                sort: "UPDATE_DEACTIVATED_PAGE_SORT",
                filter: "UPDATE_DEACTIVATED_PAGE_FILTER",
            };
        }
        case "suspendedWorkerList": {
            return {
                set: "SET_SUSPENDED_WORKERS",
                sort: "UPDATE_SUSPENDED_PAGE_SORT",
                filter: "UPDATE_SUSPENDED_PAGE_FILTER",
            };
        }
        case "appliedWorkerList": {
            return {
                set: "SET_APPLIED_WORKERS",
                sort: "UPDATE_APPLIED_PAGE_SORT",
                filter: "UPDATE_APPLIED_PAGE_FILTER",
            };
        }
        case "identityWorkerList": {
            return {
                set: "SET_IDENTIFICATION_WORKERS",
                sort: "UPDATE_IDENTIFICATION_WORKERS_PAGE_SORT",
                filter: "UPDATE_IDENTIFICATION_WORKERS_PAGE_FILTER",
            };
        }

        case "workPermitWorkerList": {
            return {
                set: "SET_WORK_PERMITS_WORKERS",
                sort: "UPDATE_WORK_PERMITS_WORKERS_PAGE_SORT",
                filter: "UPDATE_WORK_PERMITS_WORKERS_PAGE_FILTER",
            };
        }
        default:
            return;
    }
};

export const setWorkerList = (workers, type) => {
    return {
        type: types[type],
        payload: { workers },
    };
};

export const fetchTableData = (pageIndex, listType, extraVariables = {}) => (dispatch, getState) => {
    const state = getState();

    listType = listType === 0 ? "waitlistToday" : listType === 1 ? "waitlistTomorrow" : listType;
    const pageInfo = paginationFunctionList[listType](state).paging;
    const orderInfo = paginationFunctionList[listType](state).sort;
    const filterInfo = JSON.parse(JSON.stringify(paginationFunctionList[listType](state).filter));
    const status = find(filterInfo, { id: "workerStatus" });
    const vaccinated = find(filterInfo, { id: "vaccinationStatus" });
    const tags = find(filterInfo, { id: "tags" });
    let statusFilter = {};
    if (status) {
        if (status.value === "Verified") {
            statusFilter.isApproved = true;
            // statusFilter.isSuspended = false;
            // statusFilter.isActive = true;
        } else if (status.value === "Unverified") {
            statusFilter.isApproved = false;
            // statusFilter.isSuspended = false;
            // statusFilter.isActive = true;
        } else if (status.value === "Suspended") {
            statusFilter.isSuspended = true;
        } else if (status.value === "Deactivated") {
            statusFilter.isActive = false;
        } else if (status.value === "Applied") {
            statusFilter.isApplied = true;
        }
        remove(filterInfo, { id: "workerStatus" });
    }
    if (vaccinated && vaccinated.value) {
        statusFilter.vaccinationStatus = vaccinated.value
        remove(filterInfo, { id: "vaccinationStatus" });
    }
    if (tags) {
        statusFilter.tags = tags.value
        remove(filterInfo, { id: "tags" });
    }
    const pagingVars = dispatch(
        paginationFunctionList[listType](state).pagingVars(pageInfo, pageIndex),
    );

    let criteria = {};
    if (listType === "waitlistToday") criteria = state.workers.waitlistTodayCriteria;
    else if (listType === "waitlistTomorrow") criteria = state.workers.waitlistTomorrowCriteria;
    else if (listType === "workPermitWorkerList") criteria = { isSin9: true }

    const criteriaInfo = {
        ...paginationFunctionList[listType](state).params,
        ...criteria,
    };

    return ApolloClient.query({
        query: workerTableQuery,
        variables: {
            ...pagingVars,
            ...criteriaInfo,
            ...extraVariables,
            ...statusFilter,
            order: orderInfo.map(sort => {
                return {
                    field: sort.id,
                    direction: sort.desc ? "DESC" : "ASC",
                };
            }),

            like: filterInfo.map(filter => {
                return {
                    field: filter.id,
                    value: filter.value,
                };
            }),
        },
    })
        .then(({ data }) => {
            const { workers } = data;
            const paginationData = {
                ...workers.pageInfo,
                totalCount: workers.totalCount,
            };

            dispatch(setWorkerList(flattenGraphQLArray(workers), typeByListType(listType).set));
            dispatch(pagination.updatePageInfo(paginationSettings[listType], paginationData));
            dispatch(pagination.doneLoading(paginationSettings[listType]));
        })
        .catch(() => {
            dispatch(MetaActions.errorToast(alertMessages.defaultError));
            dispatch(pagination.doneLoading(paginationSettings[listType]));
        });
};

export const changePageSize = (pageSize, listType) => dispatch => {
    dispatch(pagination.updatePageInfo(paginationSettings[listType], { pageSize }));
    //   return dispatch(fetchTableData(0, listType));
};

export const changeFilter = (pageFilter, listType) => dispatch => {
    dispatch({
        type: types[typeByListType(listType).filter],
        payload: { pageFilter },
    });
    //   return dispatch(fetchTableData(0, listType));
};

export const changeSort = (pageSort, listType) => dispatch => {
    dispatch({
        type: types[typeByListType(listType).sort],
        payload: { pageSort },
    });
    //   return dispatch(fetchTableData(0, listType));
};

export const changeCriteria = (criteria, listType) => dispatch => {
    let list = "waitlistToday";
    if (listType === 1) list = "waitlistTomorrow";

    const type =
        list === "waitlistToday"
            ? "UPDATE_WAITLIST_TODAY_CRITERIA"
            : "UPDATE_WAITLIST_TOMORROW_CRITERIA";

    dispatch({
        type: types[type],
        payload: { criteria },
    });

    //   return dispatch(fetchTableData(0, listType));
};

export const getWorkerBansPagingVars = pagination.pagingVarsFactory(
    paginationSettings.workerBannedBy,
);

export const getWorkerPreferredPagingVars = pagination.pagingVarsFactory(
    paginationSettings.workerPreferredBy,
);

export const setIsDetailLoading = isDetailLoading => ({
    type: types.IS_LOADING_DETAIL,
    payload: { isDetailLoading },
});

export const setIsInfoLoading = isInfoLoading => ({
    type: types.IS_LOADING_INFO,
    payload: { isInfoLoading },
});

export const fetchListStats = () => dispatch => {
    return ApolloClient.query({
        query: workerListStatsQuery,
    })
        .then(({ data }) => {
            dispatch({ type: types.FETCH_WORKER_STATS, payload: { data } });
        })
        .catch(() => {
            dispatch(MetaActions.errorToast(alertMessages.defaultError));
        });
};
export const getIdentityWorkersStats = () => dispatch => {
    return ApolloClient.query({
        query: getIdentityWorkersStatsQuery,
        variables: {
            order: [],
            like: [{ field: "isApproved", value: "0" }, { field: "approvalState", value: "2" }],
            workerIdVerification: true
        }
    })
        .then(({ data }) => {
            dispatch({ type: types.FETCH_IDENTITY_WORKER_STATS, payload: { data } });
        })
        .catch(() => {
            dispatch(MetaActions.errorToast(alertMessages.defaultError));
        });
};

export const setWorkerBans = bans => ({
    type: types.SET_WORKER_BANS,
    payload: { bans },
});

export const setWorkerPreferred = preferred => ({
    type: types.SET_WORKER_PREFERRED,
    payload: { preferred },
});

export const changePreferredPageSize = (pageSize, workerId) => dispatch => {
    dispatch(pagination.updatePageInfo(paginationSettings.workerPreferredBy, { pageSize }));
    return dispatch(fetchWorkerPreferred(workerId));
};

export const fetchWorkerPreferred = (workerId, pageIndex) => (dispatch, getState) => {
    const state = getState();
    const pageInfo = getWorkerPreferredPaginationData(state);
    const pagingVars = dispatch(getWorkerPreferredPagingVars(pageInfo, pageIndex));
    return ApolloClient.query({
        query: workerPreferredQuery,
        variables: { ...pagingVars, workerId: workerId },
    })
        .then(({ data }) => {
            const { preferred } = data;
            const paginationData = { ...preferred.pageInfo, totalCount: preferred.totalCount };
            dispatch(setWorkerPreferred(flattenGraphQLArray(preferred)));
            dispatch(pagination.updatePageInfo(paginationSettings.workerPreferredBy, paginationData));
            dispatch(pagination.doneLoading(paginationSettings.workerPreferredBy));
        })
        .catch(() => {
            dispatch(pagination.doneLoading(paginationSettings.workerPreferredBy));
            dispatch(MetaActions.errorToast(alertMessages.defaultError));
        });
};

export const changeBansPageSize = (pageSize, workerId) => dispatch => {
    dispatch(pagination.updatePageInfo(paginationSettings.workerBannedBy, { pageSize }));
    return dispatch(fetchWorkerBans(workerId));
};

export const fetchWorkerBans = (workerId, pageIndex) => (dispatch, getState) => {
    const state = getState();
    const pageInfo = workerBannedByPageInfo(state);
    const pagingVars = dispatch(getWorkerBansPagingVars(pageInfo, pageIndex));

    return ApolloClient.query({
        query: workerBannedQuery,
        variables: {
            ...pagingVars,
            workerId: workerId,
        },
    })
        .then(({ data }) => {
            const { banned } = data;
            const paginationData = { ...banned.pageInfo, totalCount: banned.totalCount };
            dispatch(setWorkerBans(flattenGraphQLArray(banned)));
            dispatch(pagination.updatePageInfo(paginationSettings.workerBannedBy, paginationData));
            dispatch(pagination.doneLoading(paginationSettings.workerBannedBy));
        })
        .catch(() => {
            dispatch(pagination.doneLoading(paginationSettings.workerBannedBy));
            dispatch(MetaActions.errorToast(alertMessages.defaultError));
        });
};

export const setWorker = worker => ({
    type: types.SET_WORKER,
    payload: { worker },
});

export const fetchWorker = workerId => dispatch => {
    dispatch(setIsDetailLoading(true));
    ApolloClient.query({
        query: workerDetailQuery,
        variables: { id: workerId },
    })
        .then(({ data }) => {
            dispatch(setWorker(data.worker));
            dispatch(setIsDetailLoading(false));
        })
        .catch(() => {
            dispatch(MetaActions.errorToast(alertMessages.defaultError));
            dispatch(setIsDetailLoading(false));
        });
};

export const updateEditData = (field, value) => ({
    type: types.UPDATE_EDIT_DATA,
    payload: { field, value },
});

export const resetEditData = () => ({
    type: types.RESET_EDIT_DATA,
});

export const updateWorker = workerId => (dispatch, getState) => {
    const { editData } = getState().workers;
    dispatch(setIsInfoLoading(true));
    return ApolloClient.mutate({
        mutation: editWorkerMutation,
        variables: {
            data: {
                id: workerId,
                ...editData,
            },
        },
    })
        .then(({ data }) => {
            dispatch(resetEditData());

            dispatch(MetaActions.successToast(alertMessages.defaultSuccess));
            dispatch(setWorker(data.editWorker));
            dispatch(setIsInfoLoading(false));
        })
        .catch(() => {
            dispatch(resetEditData());
            dispatch(setIsInfoLoading(false));
            dispatch(MetaActions.errorToast(alertMessages.defaultError));
        });
};

export const setWorkerSuspension = (workerId, isSuspended) => ({
    type: types.SET_WORKER_SUSPENSION,
    payload: { workerId, isSuspended },
});

export const suspendWorker = (workerId, duration, reason = '') => dispatch => {
    dispatch(setIsInfoLoading(true));
    return ApolloClient.mutate({
        mutation: suspendWorkerMutation,
        variables: {
            data: { workerId: workerId, duration, reason },
        },
    })
        .then(({ data }) => {
            dispatch(setWorkerSuspension(data.suspendWorker.id, data.suspendWorker.isSuspended));
            dispatch(setIsInfoLoading(false));
            dispatch(MetaActions.successToast("Worker suspended successfully!"));
        })
        .catch(() => {
            dispatch(resetEditData());
            dispatch(setIsInfoLoading(false));
            dispatch(MetaActions.errorToast(alertMessages.defaultError));
        });
};

export const updateWorkerIdentityStatus = (data) => dispatch => {
    dispatch(setIsInfoLoading(true));
    dispatch(setSelectedWorkerIdentityId(data.workerId));
    return ApolloClient.mutate({
        mutation: UpdateWorkerIdentityStatus,
        variables: {
            data,
        },
    })
        .then((res) => {
            dispatch(setIsInfoLoading(false));
            dispatch(setWorkerIdentityRejectDialog(false));
            dispatch(setSelectedWorkerIdentityId(false));
            if (res.errors && res.errors.length) {
                dispatch(MetaActions.errorToast(res.errors[0].message));
            } else {
                dispatch(fetchTableData(0, "identityWorkerList", { workerIdVerification: true }));
                dispatch(getIdentityWorkersStats());
                dispatch(MetaActions.successToast("Worker identity updated successfully!"));
            }
        })
        .catch((e) => {
            dispatch(setIsInfoLoading(false));
            dispatch(setSelectedWorkerIdentityId(false));
            // dispatch(setWorkerIdentityRejectDialog(false));
            dispatch(MetaActions.errorToast(e.message));
        });
};

export const addWorkerPreferred = preferred => ({
    type: types.ADD_WORKER_PREFERRED,
    payload: { preferred },
});

export const preferWorker = preferData => dispatch => {
    dispatch(setIsInfoLoading(true));
    return ApolloClient.mutate({
        mutation: preferWorkerMutation,
        variables: { data: preferData },
        refetchQueries: () => ["workerPreferredQuery"],
    })
        .then(({ data }) => {
            dispatch(addWorkerPreferred(data));
            dispatch(MetaActions.successToast(alertMessages.defaultSuccess));
            dispatch(fetchWorkerPreferred(preferData.workerId));
            dispatch(fetchWorkerBans(preferData.workerId));
            dispatch(setIsInfoLoading(false));
        })
        .catch(() => {
            dispatch(setIsInfoLoading(false));
            dispatch(MetaActions.errorToast(alertMessages.defaultError));
        });
};

export const addWorkerBan = ban => ({ type: types.ADD_WORKER_BAN, payload: { ban } });

export const banWorker = banData => dispatch => {
    dispatch(setIsInfoLoading(true));
    return ApolloClient.mutate({ mutation: banWorkerMutation, variables: { data: banData } })
        .then(({ data }) => {
            dispatch(fetchWorkerPreferred(banData.workerId));
            dispatch(fetchWorkerBans(banData.workerId));
            dispatch(addWorkerBan(data));
            dispatch(MetaActions.successToast(alertMessages.defaultSuccess));
            dispatch(setIsInfoLoading(false));
        })
        .catch(() => {
            dispatch(setIsInfoLoading(false));
            dispatch(MetaActions.errorToast(alertMessages.defaultError));
        });
};

export const removeWorkerRep = worker => ({ type: types.REMOVE_WORKER_REP, payload: { worker } });

export const removeReputation = (workerId, employerId) => dispatch => {
    dispatch(setIsInfoLoading(true));
    return ApolloClient.mutate({
        mutation: removeWorkerReputation,
        variables: { data: { workerId: workerId, employerId: employerId } },
    })
        .then(({ data }) => {
            if (!data.clearException) {
                throw new Error("Failed to remove");
            }
            dispatch(removeWorkerRep(data));
            dispatch(setIsInfoLoading(false));

            dispatch(MetaActions.successToast(alertMessages.defaultSuccess));
            dispatch(fetchWorkerPreferred(workerId));
            dispatch(fetchWorkerBans(workerId));
        })
        .catch(() => {
            dispatch(setIsInfoLoading(false));
            dispatch(MetaActions.errorToast(alertMessages.defaultError));
        });
};

export const setNewWorker = worker => ({
    type: types.SET_NEW_WORKER,
    payload: { worker },
});

export const handleErrors = errors => ({
    type: types.SET_ERRORS,
    payload: { errors },
});

export const registerWorker = () => () => {
    // dispatch(setIsInfoLoading(true));
    // const payload = {
    //     sin: getState().workers.addWorkerData.sin,
    //     phone: getState().workers.addWorkerData.phone,
    //     email: getState().workers.addWorkerData.email,
    //     firstName: getState().workers.addWorkerData.firstName,
    //     lastName: getState().workers.addWorkerData.lastName,
    //     city: getState().workers.addWorkerData.location.city,
    //     street: getState().workers.addWorkerData.location.street,
    //     region: getState().workers.addWorkerData.location.region,
    //     postalCode: getState().workers.addWorkerData.location.postalCode,
    //     country: getState().workers.addWorkerData.location.country,
    // };
    // return ApolloClient.mutate({
    //     mutation: registerWorkerMutation,
    //     errorPolicy: "all",
    //     variables: { data: payload },
    // })
    //     .then(({ data }) => {
    //         dispatch(setNewWorker(data.registerWorker));
    //         dispatch(setIsInfoLoading(false));
    //         dispatch(MetaActions.successToast(alertMessages.defaultSuccess));
    //         return data;
    //     })
    //     .catch(err => {
    //         dispatch(MetaActions.errorToast(alertMessages.defaultError));
    //         dispatch(setNewWorker({ error: err }));
    //         dispatch(setIsInfoLoading(false));
    //         return err;
    //     });
};

export const workerCheck = email => dispatch => {
    return ApolloClient.query({
        query: workerCheckExistsQuery,
        variables: { email: email },
    })
        .then(({ data }) => {
            return data;
        })
        .catch(() => {
            dispatch(setIsInfoLoading(false));
            dispatch(MetaActions.errorToast(alertMessages.defaultError));
        });
};

export const workerCheckSin = sin => dispatch => {
    return ApolloClient.query({
        query: workerCheckSinQuery,
        variables: { sin: sin },
    })
        .then(({ data }) => {
            //   dispatch(setSinTaken(data.sinTaken));
            return data;
        })
        .catch(() => {
            dispatch(setIsInfoLoading(false));
            dispatch(MetaActions.errorToast(alertMessages.defaultError));
        });
};

export const setSinTaken = taken => ({
    type: types.SET_SIN_TAKEN,
    payload: { taken },
});

export const toggleIsApproved = (id, isApproved) => dispatch => {
    const toastMessage = isApproved;
    dispatch(setIsInfoLoading(true));
    return ApolloClient.mutate({
        mutation: editWorkerMutation,
        variables: { data: { id, isApproved } },
    })
        .then(() => {
            dispatch(MetaActions.successToast(toastMessage));
            dispatch(fetchTableData(0, "workerList"));
        })
        .catch(() => {
            dispatch(MetaActions.errorToast(alertMessages.updateError));
        });
};

export const setValidSin = value => {
    return {
        type: types.SET_VALID_SIN,
        payload: { value },
    };
};
export const setValidEmail = email => {
    return {
        type: types.SET_VALID_EMAIL,
        payload: { email },
    };
};
export const validateEmail = value => {
    return {
        type: types.SET_VALID_SIN,
        payload: { value },
    };
};

export const checkPassword = password => {
    return {
        type: types.SET_PASSWORD_MATCH,
        payload: { password },
    };
};

export const setNewWorkerData = (field, value) => {
    if (field === "phone") {
        value = value.replace(/\D/g, "");
    }

    return {
        type: types.UPDATE_ADDWORKER_DATA,
        payload: { field, value },
    };
};

export const setPhone = phone => {
    return {
        type: types.UPDATE_FIRSTNAME,
        payload: { phone },
    };
};
export const setSin = sin => {
    sin = sin.replace(/\D/g, "");

    return {
        type: types.UPDATE_SIN,
        payload: { sin },
    };
};
export const setAvailableEmail = status => {
    return {
        type: types.SET_EMAIL_AVAILABLE,
        payload: { availability: status },
    };
};

export const workerPhoneTaken = phone => dispatch => {
    return ApolloClient.query({
        query: checkWorkerPhoneQuery,
        variables: { phone: phone },
    })
        .then(({ data }) => {
            dispatch(setAvailableEmail(!data.workerPhoneTaken));
            dispatch(MetaActions.successToast(data.workerPhoneTaken ? "Taken" : "Available"));
            return data;
        })
        .catch(() => {
            dispatch(setIsInfoLoading(false));
            dispatch(MetaActions.errorToast(alertMessages.defaultError));
        });
};

export const workerEmailTaken = email => dispatch => {
    return ApolloClient.query({
        query: workerCheckExistsQuery,
        variables: { email: email },
    })
        .then(({ data }) => {
            dispatch(setAvailableEmail(!data.workerEmailTaken));
            dispatch(MetaActions.successToast(data.workerEmailTaken ? "Taken" : "Available"));
            return data;
        })
        .catch(() => {
            dispatch(setIsInfoLoading(false));
            dispatch(MetaActions.errorToast(alertMessages.defaultError));
        });
};

export const setEmail = email => dispatch => {
    dispatch(setValidEmail(email));
    if (verifyEmail(email)) {
        dispatch(workerEmailTaken(email));
    }
};

export const setLocation = (selectedLocation, location) => ({
    type: types.SET_LOCATION,
    payload: { selectedLocation, location },
});

export const validateForm = () => (dispatch, getState) => {
    const {
        firstName,
        lastName,
        sinIsValid,

        emailIsAvailable,
        emailIsValid,
        sinIsTaken,
    } = getState().workers;

    if (
        firstName &&
        lastName &&
        sinIsValid &&
        emailIsAvailable &&
        emailIsValid &&
        sinIsTaken === false
    ) {
        return true;
    } else return false;
};


export const setWorkerFutureJobs = (data) => ({
    type: types.WORKER_FUTURE_JOBS_LIST,
    payload: { data },
});

export const getWorkerFutureJobs = workerId => dispatch => {

    dispatch(setFutureJobLoading(true));
    return ApolloClient.query({
        query: workerFutureJobsQuery,
        variables: { workerId },
    })
        .then(({ data }) => {
            dispatch(setWorkerFutureJobs(data.workerFutureJobs));
            dispatch(setFutureJobLoading(false));
        })
        .catch(() => {
            dispatch(setFutureJobLoading(false));
            dispatch(MetaActions.errorToast(alertMessages.defaultError));
        });
};

export const getWorkPermitWorkersStats = () => dispatch => {
    return ApolloClient.query({
        query: getWorkPermitWorkersStatsQuery,
        variables: {
            order: [],
            like: [{ field: "workPermitStatus", value: "0" }],
            isSin9: true
        }
    })
        .then(({ data }) => {
            dispatch({ type: types.FETCH_WORK_PERMITS_WORKER_STATS, payload: { data } });
        })
        .catch(() => {
            dispatch(MetaActions.errorToast(alertMessages.defaultError));
        });
};

export const updateWorkerWorkPermitStatus = (data = {}) => dispatch => {
    dispatch(setIsInfoLoading(true));
    dispatch(setSelectedWorkerWorkPermitId(data.workerId));
    return ApolloClient.mutate({
        mutation: UpdateWorkPermitMutation,
        variables: {
            ...data,
        },
    })
        .then((res) => {
            dispatch(setIsInfoLoading(false));
            dispatch(setWorkerWorkPermitRejectDialog(false));
            dispatch(setSelectedWorkerWorkPermitId(false));
            if (res.errors && res.errors.length) {
                dispatch(MetaActions.errorToast(res.errors[0].message));
            } else {
                dispatch(getWorkPermitWorkersStats());
                dispatch(fetchTableData(0, "workPermitWorkerList"));
                dispatch(MetaActions.successToast("Worker work permit updated successfully!"));
            }
        })
        .catch((e) => {
            dispatch(setIsInfoLoading(false));
            dispatch(setSelectedWorkerWorkPermitId(false));
            // dispatch(setWorkerWorkPermitRejectDialog(false));
            dispatch(MetaActions.errorToast(e.message));
        });
};
