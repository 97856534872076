import types from "./types";
import { createReducer } from "utils/redux";

const initialLoadingState = {
    tableLoading: false,
    isAddLoading: false,
    isDeleteLoading: false,
};

const initialDialogState = {
    addDocumentTypeDialog: false,
    deleteDocumentTypeDialog: false,
};

const initialState = {
    loading: { ...initialLoadingState },
    dialog: { ...initialDialogState },
    documentType: [],
};

const actionsMap = {
    [types.SET_LOADING_STATE]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            loading: {
                ...state.loading,
                [key]: value,
            },
        };
    },
    [types.SET_DOCUMENT_TYPE_DATA]: (state, { payload }) => {
        const { documentType } = payload;
        return {
            ...state,
            documentType,
        };
    },
    [types.SET_ADD_DOCUMENT_TYPE_STATE]: (state, { payload }) => {
        const { documentType } = payload;
        return {
            ...state,
            documentType: [documentType, ...state.documentType],
        };
    },
    [types.SET_UPDATE_DOCUMENT_TYPE_STATE]: (state, { payload }) => {
        const { documentType } = payload;
        const index = state.documentType.findIndex(d => d.id === documentType.id);
        return {
            ...state,
            documentType: Object.assign(state.documentType, { [index]: documentType }),
        };
    },
    [types.SET_DIALOG_STATE]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            dialog: {
                ...state.dialog,
                [key]: value,
            },
        };
    }
}

export default createReducer(initialState, actionsMap);
