import { gql } from "@apollo/client";

export default gql`
  query getJobs(
    $employerId: String!
    $first: Int
    $last: Int
    $after: String
    $before: String
    $timezone: String
    $like: [JobFilterInput!]
    $order: [JobOrderInput!]
  ) {
    jobs(
      employerId: $employerId
      first: $first
      last: $last
      after: $after
      before: $before
      timezone: $timezone
      like: $like
      order: $order
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          start
          end
          startUtc
          endUtc
          timezone
          isActive
          timeframe
          positionsFilled
          workedDurationMins
          billableDurationMins
          employerRating
          breakDeductionMins
          hourlyRate
          status
          isFixedTerm
        }
      }
    }
  }
`;
