import { Alert, Button, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import DocumentTypeAutocomplete from 'components/Autocomplete/DocumentTypeAutocomplete'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { actions as MetaActions } from "store/modules/meta";
import { getEmployerDocumentTypes } from 'store/modules/employerDetails/selectors';
import { saveEmployerProfile } from 'store/modules/employerDetails/actions';

const useStyles = makeStyles(theme => ({
    documents: {
        color: theme.palette.secondary.main,
        fontSize: "1rem",
    },
    document: {
        borderColor: "#c4c4c4",
        padding: theme.spacing(0, 2, 0, 1),
        color: theme.palette.text.primary,
    },
    selectedDocument: {
        marginTop: theme.spacing(1),
        textAlign: "left",
    },
    footer: {
        marginTop: theme.spacing(3),
        display: "flex",
        justifyContent: "flex-end"
    }
}))

export default function DocumentTypeSelection() {
    const [selectedDocumentTypes, setSelectedDocumentTypes] = useState([]);
    const classes = useStyles();
    const employerDocumentTypes = useSelector(getEmployerDocumentTypes);
    const dispatch = useDispatch();

    useEffect(() => {
        setSelectedDocumentTypes(employerDocumentTypes);
    }, [employerDocumentTypes]);

    const handleSelect = (option) => {
        if (selectedDocumentTypes.find(a => a.documentTypeId === option.value)) {
            dispatch(MetaActions.errorToast("Selected Document type is already added."));
            return;
        }
        setSelectedDocumentTypes(p => [...p, { documentTypeId: option.value, documentType: option.data, ...option }]);
    }

    const handleRemoveDocument = (doc) => {
        setSelectedDocumentTypes(p => p.filter(d => d.documentTypeId !== doc.documentTypeId));
    }

    const handleUpdate = () => {
        dispatch(saveEmployerProfile({ documentTypes: selectedDocumentTypes.map(a => a.documentTypeId) }));
    }

    return (
        <Grid container style={{ paddingLeft: 0, paddingRight: 0, marginBottom: 8, maxWidth: 630 }}>
            <Typography variant="h6" align="left" style={{ width: "100%", marginTop: 1 }}>
                Add Required Document Types
            </Typography>
            <Grid xs={12} item >
                <div style={{ position: "relative", zIndex: 2, marginTop: 36, marginBottom: 16 }}>
                    <DocumentTypeAutocomplete
                        value={null}
                        menuPortalTarget={document.body}
                        selectedDocumentsTypeIds={selectedDocumentTypes.map(a => a.documentTypeId)}
                        onChange={handleSelect}
                    />
                </div>
            </Grid>
            <Grid xs={12} item >
                <Typography align="left">
                    Selected Document Types
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {!selectedDocumentTypes.length && <div className={classes.notFound}>There is not any documents are seleted</div>}
                {selectedDocumentTypes.map(doc => (
                    <div key={doc.documentTypeId} className={classes.selectedDocument}>
                        <Alert className={classes.document} icon={false} severity="success" variant="outlined" onClose={() => handleRemoveDocument(doc)}>
                            {doc?.documentType?.name}
                        </Alert>
                    </div>
                ))}
            </Grid>
            <Grid xs={12} item className={classes.footer}>
                <Button
                    disabled={
                        employerDocumentTypes.length === selectedDocumentTypes.length &&
                        employerDocumentTypes.every(a => Boolean(selectedDocumentTypes.find(s => s.documentTypeId === a.documentTypeId)))
                    }
                    onClick={handleUpdate}
                    variant="contained"
                    color="secondary"
                >Update</Button>
            </Grid>
        </Grid>
    )
}
