import { Checkbox } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { actions as customFieldActions, selectors as customFieldSelectors } from "store/modules/customFields";

const useStyles = makeStyles(theme => ({
    tableCheckbox: {
        position: "relative",
        left: -10,
    },
}))

export default function CheckboxColumn({ row: { original }, customFieldId, assignTo }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const selectedEmployers = useSelector((state) => customFieldSelectors.getFormData(state, "employers"));
    const selectedWorkers = useSelector((state) => customFieldSelectors.getFormData(state, "workers"));
    const isSelected = original?.customFieldValues?.some(a => a.customFieldId === customFieldId);
    const isAddingEmployer = useSelector(customFieldSelectors.getIsAddUserLoading);

    const handleChange = () => {
        dispatch(assignTo === "EMPLOYER" ? customFieldActions.setSelectedEmployers(original.id) : customFieldActions.setSelectedWorkers(original.id));
    };

    return (
        <Checkbox
            disabled={isSelected || isAddingEmployer}
            className={classes.tableCheckbox}
            checked={isSelected || selectedEmployers?.includes(original.id) || selectedWorkers?.includes(original.id)}
            onChange={isSelected ? () => 0 : handleChange} />
    );
};
