import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import makeStyles from '@mui/styles/makeStyles';
import AccountCircle from "@mui/icons-material/AccountCircle";
import Alarm from "@mui/icons-material/Alarm";
import AlarmOn from "@mui/icons-material/AlarmOn";
import AttachMoney from "@mui/icons-material/AttachMoney";
import LocalAtm from "@mui/icons-material/LocalAtm";
import MoreVert from "@mui/icons-material/MoreVert";
import PersonAdd from "@mui/icons-material/PersonAdd";
import PersonAddDisabled from "@mui/icons-material/PersonAddDisabled";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import RemoveCircle from "@mui/icons-material/RemoveCircle";
import Report from "@mui/icons-material/Report";
import ReportOff from "@mui/icons-material/ReportOff";
import Stars from "@mui/icons-material/Stars";
import Timeline from "@mui/icons-material/Timeline";
import WorkOff from "@mui/icons-material/WorkOff";
import noop from "lodash/noop";
import TransferTimelineDialog from "views/Finance/FinanceOverview/components/Dialogs/TransferTimelineDialog";
import { actions as FinanceActions } from "store/modules/finance";
import { actions as JobDetailActions, selectors as JobDetailSelectors } from "store/modules/jobDetails";
import BanDialog from "../Dialogs/BanDialog";
import BonusDialog from "../Dialogs/BonusDialog";
import CheckInDialog from "../Dialogs/CheckInDialog";
import CheckOutDialog from "../Dialogs/CheckOutDialog";
import EditRatesDialog from "../Dialogs/EditRatesDialog";
import NoShowDialog from "../Dialogs/NoShowDialog";
import PdfDialog from "../Dialogs/PayStub/PdfDialog/PdfDialog";
import PreferDialog from "../Dialogs/PreferDialog";
import RatingDialog from "../Dialogs/RatingDialog";
import RemoveDialog from "../Dialogs/RemoveDialog";
import ResendStubDialog from "../Dialogs/ResendStubDialog";
import Can from "components/Authorization/Can";
import { PositionPermissions } from "helpers/constants/permissions";
import { Task } from "@mui/icons-material";
import DocumentStatusDialog from "../Dialogs/DocumentStatusDialog";

const useStyles = makeStyles(({ spacing, typography }) => ({
    icon: {
        fontSize: typography.pxToRem(20),
        marginRight: spacing(2),
    },
}));

function PositionMenu({ position, isWorkerPickerOpen }) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const aTag = React.useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const [url, setUrl] = useState(null);
    const [isCheckingIn, setIsCheckingIn] = useState(false);
    const [isCheckingOut, setIsCheckingOut] = useState(false);
    const [isEditingRates, setIsEditingRates] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);
    const [isFlaggingNoShow, setIsFlaggingNoShow] = useState(false);
    const [isOpenDocStatusDialog, setIsOpenDocStatusDialog] = useState(false);
    const [isPreferring, setIsPreferring] = useState(false);
    const [isBanning, setIsBanning] = useState(false);
    const [isRating, setIsRating] = useState(false);
    const [isAddingBonus, setIsAddingBonus] = useState(false);
    const [isViewingPdf] = useState(false);
    const [isResendingStub, setIsResendingStub] = useState(false);
    const [openTransferTimelineDialog, setOpenTransferTimelineDialog] = useState(false);
    const menuId = `action-menu-${position.id}`;

    const openMenu = e => {
        setAnchorEl(e.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const { currentPage: allWorkersPage } = useSelector(
        JobDetailSelectors.getAllWorkersPaginationData
    );
    const { currentPage: preferredWorkersPage } = useSelector(
        JobDetailSelectors.getPreferredWorkersPaginationData
    );

    const refreshWorkerLists = () => {
        if (isWorkerPickerOpen) {
            dispatch(JobDetailActions.fetchAllWorkers(allWorkersPage));
            dispatch(JobDetailActions.fetchPreferredWorkers(preferredWorkersPage));
        }
    };

    const handleViewProfile = () => {
        history.push(`/workers/${position.workerId}`);
        closeMenu();
    };

    const toggleFactory = (fn = noop) => () => {
        fn(v => !v);
        closeMenu();
    };

    const toggleCheckIn = toggleFactory(setIsCheckingIn);
    const toggleCheckOut = toggleFactory(setIsCheckingOut);
    const toggleEditRates = toggleFactory(setIsEditingRates);
    const toggleRemoving = toggleFactory(setIsRemoving);
    const toggleFlaggingNoShow = toggleFactory(setIsFlaggingNoShow);
    const toggleDocStatusDialog = toggleFactory(setIsOpenDocStatusDialog);
    const togglePreferring = toggleFactory(setIsPreferring);
    const toggleBanning = toggleFactory(setIsBanning);
    const toggleRating = toggleFactory(setIsRating);
    const toggleAddBonus = toggleFactory(setIsAddingBonus);

    const canViewStub = position && position.payroll.length;
    const canEditRates = useSelector(JobDetailSelectors.getCanEditPositionRates(position.id));
    const canCheckIn = useSelector(JobDetailSelectors.getCanCheckInWorker(position.id));
    const canCheckOut = useSelector(JobDetailSelectors.getCanCheckOutWorker(position.id));
    const canRemove = useSelector(JobDetailSelectors.getCanRemovePosition(position.id));
    const canFlagNoShow = useSelector(JobDetailSelectors.getCanFlagNoShow(position.id));
    const canPrefer = useSelector(JobDetailSelectors.getCanPreferWorker(position.workerId));
    const canUnprefer = useSelector(JobDetailSelectors.getCanUnpreferWorker(position.workerId));
    const canBan = useSelector(JobDetailSelectors.getCanBanWorker(position.workerId));
    const canUnban = useSelector(JobDetailSelectors.getCanUnbanWorker(position.workerId));
    const canRate = useSelector(JobDetailSelectors.getCanRateWorker(position.id));
    const canAddBonus = useSelector(JobDetailSelectors.getCanAddWorkerBonus(position.id));
    const jobDetails = useSelector(JobDetailSelectors.getJobDetails);
    // const isPaystubDownloading = useSelector(FinanceSelectors.getIsdownloadingPaystubZip);
    //   console.log(isPaystubDownloading);
    const handleCheckout = () => {
        toggleCheckOut();
        dispatch(JobDetailActions.checkoutWorker(position.id));
    };

    const handleEditRates = () => {
        toggleEditRates();
        dispatch(JobDetailActions.editWorkerRates(position.id));
    };

    const handleRemove = async (suspend = false, ban = false, withFuture) => {
        toggleRemoving();
        await dispatch(JobDetailActions.removeWorker(position.id, suspend, ban, withFuture));
        refreshWorkerLists();
    };

    const handleNoShow = async (ban = false) => {
        toggleFlaggingNoShow();
        await dispatch(JobDetailActions.noShowWorker(position.workerId, ban));
        refreshWorkerLists();
    };

    const handlePrefer = async () => {
        togglePreferring();
        await dispatch(JobDetailActions.preferWorker(position.workerId));
        refreshWorkerLists();
    };

    const handleBan = async reason => {
        toggleBanning();
        await dispatch(JobDetailActions.banWorker(position.workerId, reason));
        refreshWorkerLists();
    };

    const handleClearException = type => async () => {
        closeMenu();
        await dispatch(JobDetailActions.clearException(position.workerId, type));
        refreshWorkerLists();
    };

    const handleRating = () => {
        toggleRating();
        dispatch(JobDetailActions.rateWorker(position.workerId));
    };
    const stopPropagationForTab = event => {
        //   if (event.key === "Tab") {
        event.stopPropagation();
        //   }
    };
    const handleAddBonus = () => {
        toggleAddBonus();
        dispatch(JobDetailActions.addPositionBonus(position.id));
    };
    const onBonusChange = value => {
        setIsAddingBonus(true);
        dispatch(JobDetailActions.updatePositionData("bonus")(position.id, value));
        setIsAddingBonus(true);
    };

    const handleResendStub = () => {
        // toggleAddBonus();
        dispatch(FinanceActions.createAndSendPaystub(position.id));
        setIsResendingStub(false);
    };
    const toggleResendStubDialog = () => {
        closeMenu();
        setIsResendingStub(v => !v);
    };
    const openBonusDialog = () => {
        // fn(v => !v);
        closeMenu();
        setIsAddingBonus(true);
    };
    const toggleIsViewingPdf = () => {
        dispatch(FinanceActions.downloadPaystubSummaryZip(position.id)).then(url => {
            if (url) {
                setUrl(url);
                setTimeout(() => {
                    aTag.current.click();
                }, 2000);

            }
            closeMenu();
            // setSheetLoading(false);
        });
    };
    const handleCloseTransferTimelineDialog = () => {
        setOpenTransferTimelineDialog(false);
    };
    const handleOpenTransferTimelineDialog = () => {
        closeMenu();
        setOpenTransferTimelineDialog(true);
    };
    return <>
        <IconButton
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={openMenu}
            disableRipple
            size="large">
            <MoreVert color="primary" />
        </IconButton>
        <Menu
            id={menuId}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={closeMenu}>
            <MenuItem onClick={handleViewProfile}>
                <AccountCircle className={classes.icon} color="action" />
                View profile
            </MenuItem>
            {canViewStub ? (
                <MenuItem onClick={handleOpenTransferTimelineDialog}>
                    <Timeline className={classes.icon} color="action" />
                    View Timeline
                </MenuItem>
            ) : null}
            {canViewStub ? (
                <MenuItem onClick={toggleIsViewingPdf}>
                    <PictureAsPdf className={classes.icon} color="action" />
                    Download Paystub
                </MenuItem>
            ) : null}
            {canViewStub ? (
                <MenuItem onClick={toggleResendStubDialog}>
                    <PictureAsPdf className={classes.icon} color="action" />
                    Email Paystub
                </MenuItem>
            ) : null}
            {canCheckIn && (
                <Can perform={PositionPermissions.update.checkIn.value}>
                    {allowed => {
                        if (!allowed) {
                            return null;
                        }
                        return (<MenuItem onClick={toggleCheckIn}>
                            <Alarm className={classes.icon} color="action" />
                            Check in
                        </MenuItem>)
                    }}
                </Can>
            )}
            {canCheckOut && (
                <Can perform={PositionPermissions.update.checkOut.value}>
                    {allowed => {
                        if (!allowed) {
                            return null;
                        }
                        return (<MenuItem onClick={toggleCheckOut}>
                            <AlarmOn className={classes.icon} color="action" />
                            Check out
                        </MenuItem>)
                    }}
                </Can>
            )}
            {canEditRates && (
                <MenuItem onClick={toggleEditRates}>
                    <LocalAtm className={classes.icon} color="action" />
                    Edit rates
                </MenuItem>
            )}
            {canAddBonus && (
                <MenuItem onClick={() => openBonusDialog()}>
                    <AttachMoney className={classes.icon} color="action" />
                    Add bonus
                </MenuItem>
            )}
            {canRate && (
                <MenuItem onClick={toggleRating}>
                    <Stars className={classes.icon} color="action" />
                    Rate
                </MenuItem>
            )}
            {canPrefer && (
                <MenuItem onClick={togglePreferring}>
                    <PersonAdd className={classes.icon} color="action" />
                    Prefer
                </MenuItem>
            )}
            {canUnprefer && (
                <MenuItem onClick={handleClearException("preferred")}>
                    <PersonAddDisabled className={classes.icon} color="action" />
                    Remove prefer
                </MenuItem>
            )}
            {canBan && (
                <MenuItem onClick={toggleBanning}>
                    <Report className={classes.icon} color="action" />
                    Ban
                </MenuItem>
            )}
            {canUnban && (
                <MenuItem onClick={handleClearException("banned")}>
                    <ReportOff className={classes.icon} color="action" />
                    Remove ban
                </MenuItem>
            )}
            {canFlagNoShow && (
                <Can perform={PositionPermissions.update.noShow.value}>
                    {allowed => {
                        if (!allowed) {
                            return null;
                        }
                        return (<MenuItem onClick={toggleFlaggingNoShow}>
                            <WorkOff className={classes.icon} color="action" />
                            No show
                        </MenuItem>)
                    }}
                </Can>
            )}
            <MenuItem onClick={toggleDocStatusDialog}>
                <Task className={classes.icon} color="action" />
                View document status
            </MenuItem>
            {canRemove && <Can perform={PositionPermissions.delete.hard.value}>
                {allowed => {
                    if (!allowed) {
                        return null;
                    }
                    return (<MenuItem onClick={toggleRemoving}>
                        <RemoveCircle className={classes.icon} color="action" />
                        Remove
                    </MenuItem>)
                }}
            </Can>}
        </Menu>
        <ResendStubDialog
            open={isResendingStub}
            onClose={toggleResendStubDialog}
            onCancel={toggleResendStubDialog}
            onConfirm={handleResendStub}
            position={position}
        />
        <TransferTimelineDialog
            open={openTransferTimelineDialog}
            onClose={() => handleCloseTransferTimelineDialog()}
            maxWidth="sm"
            fullWidth={true}
            data={position.payroll[0]}
            size="sm"
        />
        <CheckInDialog
            open={isCheckingIn}
            onClose={toggleCheckIn}
            onCancel={toggleCheckIn}
            onConfirm={toggleCheckIn}
            position={position}
        />
        <CheckOutDialog
            open={isCheckingOut}
            onClose={toggleCheckOut}
            onCancel={toggleCheckOut}
            onConfirm={handleCheckout}
            position={position}
        />
        <EditRatesDialog
            open={isEditingRates}
            onClose={toggleEditRates}
            onCancel={toggleEditRates}
            onConfirm={handleEditRates}
            position={position}
        />
        <RemoveDialog
            open={isRemoving}
            onClose={toggleRemoving}
            onCancel={toggleRemoving}
            onConfirm={handleRemove}
            position={position}
        />
        <NoShowDialog
            open={isFlaggingNoShow}
            onClose={toggleFlaggingNoShow}
            onCancel={toggleFlaggingNoShow}
            onConfirm={handleNoShow}
            position={position}
        />
        <DocumentStatusDialog
            open={isOpenDocStatusDialog}
            onClose={toggleDocStatusDialog}
            onCancel={toggleDocStatusDialog}
            onConfirm={toggleDocStatusDialog}
            position={position}
        />
        <PreferDialog
            open={isPreferring}
            onClose={togglePreferring}
            onCancel={togglePreferring}
            onConfirm={handlePrefer}
            position={position}
        />
        <BanDialog
            open={isBanning}
            onClose={toggleBanning}
            onCancel={toggleBanning}
            onConfirm={handleBan}
            position={position}
        />
        <RatingDialog
            open={isRating}
            onClose={toggleRating}
            onCancel={toggleRating}
            onConfirm={handleRating}
            position={position}
        />
        <BonusDialog
            open={isAddingBonus}
            onClose={toggleAddBonus}
            onCancel={toggleAddBonus}
            onConfirm={handleAddBonus}
            position={position}
            onBonusChange={onBonusChange}
            onKeyDown={stopPropagationForTab}
            disableBackdropClick
            disableEscapeKeyDown
            disableRestoreFocus
        />
        <a ref={aTag} href={url} target="_blank" rel="noopener noreferrer" />
        {position && position.payroll && (
            <PdfDialog
                open={isViewingPdf}
                onClose={toggleIsViewingPdf}
                position={position}
                payroll={position.payroll[0]}
                jobDetails={jobDetails}
                handleOpen={() => toggleIsViewingPdf()}
                handleClose={() => toggleIsViewingPdf()}
            />
        )}
    </>;
}

PositionMenu.propTypes = {
    position: PropTypes.object.isRequired,
    isWorkerPickerOpen: PropTypes.bool.isRequired,
};

export default PositionMenu;
