export const allowedProvinceDocumentsTypes = [
    {
        "extension": ".doc",
        "mime": "application/msword"
    },
    {
        "extension": ".docx",
        "mime": "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    },
    {
        "extension": ".gif",
        "mime": "image/gif"
    },
    {
        "extension": ".jpg",
        "mime": "image/jpeg"
    },
    {
        "extension": ".jpeg",
        "mime": "image/jpeg"
    },
    {
        "extension": ".pdf",
        "mime": "application/pdf"
    },
    {
        "extension": ".png",
        "mime": "image/png"
    },
    {
        "extension": ".svg",
        "mime": "image/svg+xml"
    },
    {
        "extension": ".jfif",
        "mime": "image/jpeg"
    },
    {
        "extension": ".tiff",
        "mime": "image/tiff"
    },
    {
        "extension": ".tif",
        "mime": "image/tiff"
    },
];

export const isValidWorkerUploadDocuments = (files) => files.every(p => allowedProvinceDocumentsTypes.find(f => f.mime === p.type))
