import React from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import { tabStyles } from "../tables/helpers/tabStyles";
import WorkerTable from "../tables/WorkerTable";

class WorkerTabs extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <div className={classes.contentContainer}>
                    <div>
                        <WorkerTable />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
WorkerTabs.propTypes = {
    classes: PropTypes.object.isRequired,
    workerStats: PropTypes.object,
};

WorkerTabs.defaultProps = {
    className: null,
    workerStats: null,
};
export default withStyles(tabStyles)(withRouter(WorkerTabs));
